import {
  // AmountFilter,
  CommonButton,
  getInputSearchFilter,
  getMultiSelectSearchFilter,
  getPriceFilter,
} from '../../components';
// import { FilterFilled } from '@ant-design/icons';
import {
  ButtonTextFormatter,
  checkValidData,
  checkValidPrice,
  ModalOpenFormatterTask,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';

import i18next from 'i18next';
import { getFromToDateFilter } from '../../components/Filter/Common/date.filter';

import { providerListService } from '../TeamSetting/provider.service';
import { CustomersService } from '../Customers';
import { ListManageLocationService } from '../GeoLocation';
import { getBooleanYesNoList, getSupplyHouseService } from '../index.service';
import { accessRoute } from '../../routes/EquipmentOrder';
import { accessRoute as dashboard } from '../../routes/Dashboard';
import moment from 'moment';
import { getFullName } from '../../utils/text.util';
import { Select } from 'antd';
import {
  dateFormatter,
  phoneNumberFormate,
  readMoreTextShow,
} from '../../utils';
import { generatePath } from 'react-router-dom';

// const getColumnSelectAmountProps = (dataIndex) => ({
//   filterDropdown: (props) => <AmountFilter dataIndex={dataIndex} {...props} />,
//   filterIcon: (filtered) => (
//     <FilterFilled
//       type="search"
//       style={{ color: filtered ? '#1890ff' : undefined }}
//     />
//   ),
//   onFilterDropdownVisibleChange: (visible) => {},
// });

export const equipmentOrderBreadcrumb = () => {
  return [
    {
      path: dashboard.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: 'Equipment Order',
    },
  ];
};
export const equipmentOrderBookingLogBreadcrumb = () => {
  return [
    {
      path: dashboard.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: accessRoute.EQUIPMENT_ORDER.path,
      name: i18next.t('text.equipmentOrder.title'),
    },
    {
      path: '#',
      name: i18next.t('text.serviceManagement.bookingLog'),
    },
  ];
};
const getProviderListData = () => {
  return [
    {
      name: 'abhishekYadav',
      value: 'Abhishek Yadav',
    },
    {
      name: 'aditiTest',
      value: 'Aditi Test',
    },
  ];
};

const permitStatus = [
  {
    id: ' needsAttention ',
    name: ' Needs Attention ',
  },
  {
    id: ' requested ',
    name: ' Requested ',
  },
  {
    id: 'completed',
    name: 'completed',
  },
];
const orderedData = [
  {
    id: ' Picked Up ',
    name: 'Picked Up',
  },
  {
    id: 'Ordered  ',
    name: 'Ordered',
  },
  {
    id: 'Staged',
    name: 'Staged',
  },
];
const totalCostFields = {
  fromOperatorField: 'firstOperator',
  fromOperatorAmountField: 'firstAmount',
  conditionalField: 'conditional',
  toOperatorField: 'secondOperator',
  toOperatorAmountField: 'secondAmount',
};
const getPermitStatus = () => {
  return [
    {
      name: 'Needs Attention',
      value: 'needs_attention',
    },
    {
      name: 'Requested',
      value: 'requested',
    },

    {
      name: 'Completed',
      value: 'completed',
    },
    {
      name: 'N/A',
      value: 'na',
    },
  ];
};
const getOrderedData = () => {
  return [
    {
      name: 'Needs Attention',
      value: 'need_attention',
    },
    {
      name: 'Ordered',
      value: 'ordered',
    },
    {
      name: 'Will Call',
      value: 'will_call',
    },
    {
      name: 'Picked Up',
      value: 'picked_up',
    },
    {
      name: 'Staged',
      value: 'staged',
    },
    {
      name: 'Installed',
      value: 'installed',
    },
  ];
};
export const EquipmentOrderTableColumn = ({
  saveUpdates,
  cancelUpdates,
  onSort,
  onFilterChange,
  filter = {},
  equipmentModalShow,
  materialListModalShow,
  page,
  sizePerPage,
  isUserCanEdit,
  options,
  handleChangeOption,
  isLoading,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (_, __, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (_, __, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'bookingNo',
      title: i18next.t('text.common.jobNo'),
      headerClasses: 'jobNumber',
      // sorter: true,
      onSort: (_, order) => onSort('id', order),
      render: (cell, row) =>
        // ButtonTextFormatter(accessRoute.BOOKING_LOG.path, cell),
        ButtonTextFormatter(
          generatePath(accessRoute.BOOKING_LOG.path, {
            id: row?.id,
            viewQuote:
              row?.BookingQuote?.submitQuote === 'submitted'
                ? 'isViewQuote'
                : 'isNoViewQuote',
          }),
          cell,
          false,
          {},
          '_blank'
        ),
      ...getInputSearchFilter({
        dataIndex: 'bookingNo',
        name: 'bookingNo',
        placeholder: 'job number',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'Provider.firstName',
      filterDataIndex: ['provider'],
      title: i18next.t('text.equipmentOrder.serviceProviderName'),
      headerClasses: 'sorting',
      // sorter: true,
      onSort: (field, order) => onSort('serviceProviderName', order),
      render: (cell, row) =>
        checkValidData(
          `${row?.Provider?.firstName} ${row?.Provider?.lastName}`
        ),
      ...getMultiSelectSearchFilter({
        dataIndex: 'provider',
        onFilterChange,
        name: 'service provider name',
        list: [],
        mode: 'multiple',
        // scope: 'activeUser',
        listKeys: { nameKey: ['firstName', 'lastName'], nameId: 'id' },
        searchOrNot: true,
        listService: providerListService,
        filter,
      }),
    },
    {
      dataIndex: 'Provider.phoneNumber',
      filterDataIndex: ['providerNumber'],
      title: i18next.t('text.equipmentOrder.serviceProviderNumber'),
      headerClasses: 'sorting',
      // sorter: true,
      onSort: (field, order) => onSort('serviceProviderNumber', order),
      render: (cell, row) =>
        phoneNumberFormate(
          row?.Provider?.phoneNumber,
          row?.Provider?.countryPhoneCode
        ),
      ...getInputSearchFilter({
        dataIndex: 'providerNumber',
        placeholder: 'Service Provider Number',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'Customer.firstName',
      filterDataIndex: ['customer'],
      title: i18next.t('text.equipmentOrder.customerName'),
      headerClasses: 'sorting',
      onSort: (field, order) => onSort('customerName', order),
      render: (cell, row) =>
        getFullName(
          row?.Customer?.firstName,
          row?.Customer?.lastName,
          '',
          row?.Customer?.CustomerMembership?.MembershipPlan?.name
        ),

      ...getMultiSelectSearchFilter({
        dataIndex: 'customer',
        onFilterChange,
        name: 'customer name',
        list: [],
        mode: 'multiple',
        // scope: 'activeUser',
        listKeys: { nameKey: ['firstName', 'lastName'], nameId: 'id' },
        searchOrNot: true,
        listService: CustomersService?.CustomersListService,
        filter,
        extraQuary: { isFilter: 1 },
      }),
    },
    {
      dataIndex: 'Customer.phoneNumber',
      filterDataIndex: ['customerNumber'],
      title: i18next.t('text.equipmentOrder.customerPhoneNumber'),
      headerClasses: 'sorting',
      // sorter: true,
      onSort: (field, order) => onSort('customerNumber', order),
      render: (cell, row) =>
        phoneNumberFormate(
          row?.Customer?.phoneNumber,
          row?.Customer?.countryPhoneCode
        ),
      ...getInputSearchFilter({
        dataIndex: 'customerNumber',
        placeholder: 'customer phone number',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'jobLocationAddress',
      filterDataIndex: ['address'],
      title: i18next.t('text.equipmentOrder.customerAddress'),
      headerClasses: 'sorting',
      // sorter: true,
      onSort: (field, order) => onSort('customerAddress', order),
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'address',
        placeholder: 'customer address',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'BookingQuote.isPullPermit',
      filterDataIndex: ['permit'],
      title: i18next.t('text.equipmentOrder.needInstallPermit'),
      headerClasses: 'sorting',
      render: (cell, row) => (row?.BookingQuote?.isPullPermit ? 'Yes' : 'No'),
      // sorter: true,
      ...getMultiSelectSearchFilter({
        dataIndex: 'permit',
        onFilterChange,
        name: 'need install permit',
        list: [],
        mode: false,
        listService: getBooleanYesNoList,
        filter,
      }),
    },
    {
      dataIndex: 'BookingQuote.Location.Country.name',
      filterDataIndex: ['location'],
      title: i18next.t('text.equipmentOrder.CityCountyName'),
      headerClasses: 'sorting',
      render: (cell, row) => checkValidData(row?.BookingQuote?.Location?.name),
      // sorter: true,
      ...getMultiSelectSearchFilter({
        dataIndex: 'location',
        onFilterChange,
        name: 'city/county name',
        list: [],
        mode: false,
        scope: 'activeLocation',
        listService: ListManageLocationService,
        listKeys: { nameKey: 'name', nameId: 'name' },
        filter,
      }),
    },
    {
      dataIndex: 'BookingQuote.isHomeFlood',
      filterDataIndex: ['floodZone'],
      title: i18next.t('text.equipmentOrder.floodZone'),
      headerClasses: 'sorting',
      // sorter: true,
      render: (cell, row) => (row?.BookingQuote?.isHomeFlood ? 'Yes' : 'No'),
      ...getMultiSelectSearchFilter({
        dataIndex: 'floodZone',
        onFilterChange,
        name: 'flood zone',
        list: [],
        mode: false,
        listService: getBooleanYesNoList,
        filter,
      }),
    },
    {
      dataIndex: 'total',
      filterDataIndex: [
        'conditional',
        'firstAmount',
        'firstOperator',
        'secondAmount',
        'secondOperator',
      ],
      title: i18next.t('text.equipmentOrder.amountJobApprovedFor'),
      headerClasses: 'sorting',
      render: (cell, row) => checkValidPrice(cell),
      // sorter: true,
      // ...getColumnSelectAmountProps(),
      ...getPriceFilter({
        dataIndex: 'price',
        onFilterChange,
        fields: totalCostFields,
        filter,
      }),
    },
    {
      dataIndex: 'BookingQuote.EquipmentLocation.name',
      filterDataIndex: ['supplyHouseName'],
      title: i18next.t('text.equipmentOrder.supplyHouseName'),
      headerClasses: 'sorting',
      // sorter: true,
      render: (cell, row) =>
        checkValidData(row?.BookingQuote?.EquipmentLocation?.name),
      ...getMultiSelectSearchFilter({
        dataIndex: 'supplyHouseName',
        onFilterChange,
        name: 'supply house name',
        list: [],
        mode: 'multiple',
        scope: 'activeEquipmentLocation',
        listService: getSupplyHouseService,
        listKeys: { nameKey: 'name', nameId: 'id' },
        // listService: getSupplyHouseNameData,
        filter,
      }),
    },
    {
      dataIndex: 'supplyHouseLocation',
      title: i18next.t('text.quote.supplyHouseLocation'),
      headerClasses: 'sorting',
      // sorter: true,
      render: (cell, row) =>
        checkValidData(row?.BookingQuote?.EquipmentLocation?.address),
      ...getInputSearchFilter({
        dataIndex: 'supplyHouseLocation',
        placeholder: 'Supply House Location',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'equipmentPickupDate',
      filterDataIndex: ['fromDate', 'toDate'],
      title: i18next.t('text.equipmentOrder.equipmentPickupDate'),
      headerClasses: 'sorting',
      // sorter: true,
      render: (_, row) =>
        row?.BookingQuote?.selectedDateTime
          ? dateFormatter(row?.BookingQuote?.selectedDateTime, 'L')
          : row?.bookingScheduleDate
          ? dateFormatter(row?.bookingScheduleDate, 'L')
          : '-',
      ...getFromToDateFilter({
        dataStartIndex: 'fromDate',
        dataEndIndex: 'toDate',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY-MM-DD',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'equipment',
      title: i18next.t('text.equipmentOrder.equipment'),
      headerClasses: 'sorting',
      // sorter: true,
      // render: (cell, row) =>
      // ButtonTextFormatter(equipmentRoutes.EQUIPMENT_LIST.path, 'Link'),
      render: (cell, row) =>
        ModalOpenFormatterTask((e) => {
          e?.preventDefault?.();
          return equipmentModalShow(row);
        }, 'Link'),
    },
    {
      dataIndex: 'materialList',
      title: i18next.t('text.equipmentOrder.materialList'),
      headerClasses: 'sorting',
      // sorter: true,
      render: (_, row) =>
        ModalOpenFormatterTask((e) => {
          e?.preventDefault?.();
          return materialListModalShow(row);
        }, 'Link'),
    },
    {
      dataIndex: 'BookingQuote.otherNotes',
      filterDataIndex: ['notes'],
      title: i18next.t('text.equipmentOrder.otherNotes'),
      headerClasses: 'sorting',
      // sorter: true,
      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(row?.BookingQuote?.otherNotes),
          i18next.t('text.equipmentOrder.otherNotes'),
          20
        ),
      ...getInputSearchFilter({
        dataIndex: 'notes',
        placeholder: 'other notes',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'permitStatus',
      title: i18next.t('text.equipmentOrder.permitStatus'),
      headerClasses: 'sorting',
      // sorter: true,
      ...getMultiSelectSearchFilter({
        dataIndex: 'permitStatus',
        onFilterChange,
        name: 'permit status',
        listKeys: { nameKey: 'name', nameId: 'value' },
        mode: false,
        listService: getPermitStatus,
        filter,
      }),
      render: (_, row) => {
        return row?.BookingQuote?.permitStatus ? (
          <Select
            id="permitStatus"
            size="medium"
            name="permitStatus"
            disabled={!isUserCanEdit || isLoading}
            value={
              options?.[row.id]?.permitStatus
                ? options[row.id].permitStatus
                : row?.BookingQuote?.permitStatus ?? ''
            }
            onSelect={(e) => handleChangeOption('permitStatus', e, row?.id)}
            variant="standard"
            placeholder="Select status"
            options={getPermitStatus().map((item) => ({
              label: item.name,
              value: item.value,
            }))}
          />
        ) : (
          '-'
        );
      },
    },
    {
      dataIndex: 'ordered',
      title: i18next.t('text.equipmentOrder.ordered'),
      headerClasses: 'sorting',
      // sorter: true,
      ...getMultiSelectSearchFilter({
        dataIndex: 'ordered',
        onFilterChange,
        name: 'ordered',
        listKeys: { nameKey: 'name', nameId: 'value' },
        mode: false,
        listService: getOrderedData,
        filter,
      }),
      render: (_, row) => {
        return row?.BookingQuote?.orderStatus ? (
          <Select
            id="orderStatus"
            size="medium"
            name="orderStatus"
            disabled={!isUserCanEdit || isLoading}
            value={
              options?.[row.id]?.orderStatus
                ? options[row.id].orderStatus
                : row?.BookingQuote?.orderStatus ?? ''
            }
            onSelect={(e) => handleChangeOption('orderStatus', e, row?.id)}
            variant="standard"
            placeholder="Select options"
            options={getOrderedData().map((item) => ({
              label: item.name,
              value: item.value,
            }))}
          />
        ) : (
          '-'
        );
      },
    },
    {
      dataIndex: 'lastUpdatedSave',
      title: i18next.t('text.equipmentOrder.lastUpdatedSave'),
      render: (_, row) => {
        const prevDetails = {
          orderStatus: row?.BookingQuote?.orderStatus ?? '',
          permitStatus: row?.HomeInventoryEquipments?.[0]?.permitStatus ?? '',
        };
        const updateDetails = {
          ...prevDetails,
          ...options?.[row?.id],
        };
        const isUpdate =
          JSON.stringify(prevDetails) !== JSON.stringify(updateDetails);
        if (options?.[row?.id] && isUpdate) {
          return (
            <>
              <CommonButton
                onClick={() => saveUpdates(row?.id)}
                className="btn btn-primary btn-sm"
              >
                {i18next.t('text.common.save')}
              </CommonButton>
              <CommonButton
                onClick={() => cancelUpdates(row?.id)}
                className="btn btn-light btn-sm ms-1"
              >
                {i18next.t('text.common.cancel')}
              </CommonButton>
            </>
          );
        } else {
          return (
            <>
              {row?.BookingQuote?.updatedByUser
                ? `Admin ${getFullName(
                    row?.BookingQuote?.updatedByUser?.firstName ?? '',
                    row?.BookingQuote?.updatedByUser?.lastName ?? ''
                  )} Saved ${moment(row?.BookingQuote?.updatedAt).format('L')}`
                : row?.BookingQuote?.updatedAt
                ? moment(row?.BookingQuote?.updatedAt).format('llll')
                : null}
            </>
          );
        }
      },
    },
  ];
};
