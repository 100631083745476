import React, { useState, useEffect } from 'react';
import { Container, FormGroup } from 'react-bootstrap';
import {
  Link,
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Rate } from 'antd';

import {
  Breadcrumb,
  ImageElement,
  CommonButton,
  NoDataFound,
  GlobalLoader,
  ModalWrapper,
} from '../../../../components';
import { accessRoute } from '../../../../routes/CustomerApp/payment';
import { accessRoute as bookingRoute } from '../../../../routes/CustomerApp/booking';
import { accessRoute as bookingHome } from '../../../../routes/CustomerApp/home';
import {
  getBookingDetailsById,
  getBookingRemainingAmountService,
  getReceiptService,
  postBookingReview,
} from '../../../../services/CustomerApp/index.service';
import moment from 'moment';
import { modalNotification } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/lib/input/TextArea';
import {
  checkValidData,
  checkValidPrice,
} from '../../../../components/UIElements/Formatter';
import { useSelector } from 'react-redux';
import RequestDetailsModal from '../../RequestDetails/requestDetailsModal';
import { getSocialMediaLinks } from '../../../../redux/index.redux';

function CustomerPayment() {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState({
    isRating: '',
    rate: '',
    comment: '',
    globalLoading: false,
    isLoading: false,
    bookingDetails: {},
    showModal: '',
    isReceiptLoading: false,
    isOpenToolTip: false,
    remainingAmountDetails: {},
    discountQuoteListObj: {},
  });
  let { discountQuoteListObj } = state;
  const [ratingData, setRatingData] = useState({});
  const { isRating } = state;
  const [showModalMember, setShowModalMember] = useState({
    show: false,
    data: {},
  });
  const userProfile = useSelector((state) => state?.auth?.customerData);
  const socialMediaLinks = useSelector(getSocialMediaLinks);

  const breadcrumb = [
    {
      path: bookingHome.HOME.path,
      name: t('text.common.home'),
    },
    isRating
      ? {
        path: '#',
        name: t('text.common.myBookRate'),
      }
      : {
        path: '#',
        name: t('text.common.myBookPay'),
      },
  ];

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const getBookingDetails = async () => {
    try {
      setState({
        ...state,
        globalLoading: true,
      });
      const response = await getBookingDetailsById(
        location?.state || params?.id
      );

      const responseRemainingAmount = await getBookingRemainingAmountService(
        location?.state || params?.id
      );

      if (response?.success) {
        let getCustomerRating = response?.data?.BookingReviews?.length
          ? response?.data?.BookingReviews?.sort(function (a, b) {
            return b?.id - a?.id;
          })
          : [];
        let ratingObj = {
          review: getCustomerRating?.length ? getCustomerRating[0]?.review : '',
          rate:
            response?.data?.Customer?.isRated === 1 && getCustomerRating?.length
              ? getCustomerRating[0]?.rating
              : 0,
        };
        // getCustomerRating.forEach((item) => {
        //   ratingObj = {
        //     ...ratingObj,
        //     ...item,
        //   };
        // });
        let discountQuoteListTypeObj = {};
        response?.data?.quote?.length > 0 &&
          response?.data?.quote.map(item => {
            discountQuoteListTypeObj = {
              ...discountQuoteListTypeObj,
              [item?.id]: item?.discount_list?.length ? item : [],
            };
          })
        setRatingData(ratingObj);
        setState({
          ...state,
          discountQuoteListObj: discountQuoteListTypeObj,
          globalLoading: false,
          bookingDetails: response?.data,
          isRating:
            response?.data?.BookingDetail?.paymentStatus !== 'pending'
              ? true
              : false,
          remainingAmountDetails: responseRemainingAmount?.success
            ? responseRemainingAmount?.data
            : {},
          rate: ratingObj?.rate ? ratingObj?.rate : 0,
          comment: ratingObj?.review,
        });
        if (response?.data?.BookingDetail?.paymentStatus !== 'pending') {
          window.history.pushState(null, null, document.URL);
          window.addEventListener('popstate', function () {
            window.location.replace(`${bookingRoute.BOOKING.path}?tab=past`);
          });
        }
      }
    } catch (error) {
      setState({
        ...state,
        globalLoading: false,
      });
    }
  };

  const handleGetReceipt = async () => {
    try {
      setState({
        ...state,
        isReceiptLoading: true,
      });
      const response = await getReceiptService(
        location.state || params?.id,
        userProfile?.email
      );
      if (response?.success) {
        setState({
          ...state,
          isReceiptLoading: false,
        });
        modalNotification({
          type: 'success',
          message: response?.message,
        });
      }
    } catch (error) {
      setState({
        ...state,
        isReceiptLoading: false,
      });
    }
  };

  const handleSubmitRate = async () => {
    if (!state.rate) {
      modalNotification({
        type: 'error',
        message: t('text.ratingReview.ratingRequired'),
      });
      return;
    }
    try {
      setState({
        ...state,
        isLoading: true,
      });
      const bodyData = {
        bookingId: location?.state || params?.id,
        review: state?.comment,
        rating: state?.rate,
      };
      const response = await postBookingReview(bodyData);
      if (response.success) {
        setRatingData(response?.data ?? {});
        modalNotification({
          type: 'success',
          message: t('text.ratingReview.ratingSuccess'),
        });
        setState({
          ...state,
          isRating: response?.success ? true : false,
          isLoading: false,
          showModal: 'rateSuccess',
        });
      } else {
        setState({
          ...state,
          isLoading: false,
        });
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
    }
  };

  const openInNewTab = (href) => {
    window.open(href, '_blank');
  };

  const totalCost = (discountDetails) => {
    let cost = 0;
    discountDetails?.forEach((e) => {
      cost =
        cost +
        (e?.discountType === 'flat'
          ? parseFloat(e.cost)
          : e?.cost < 101
            ? (e?.admin_material_total_amount ||
              state?.modalData?.admin_material_total_amount / 100) * e?.cost
            : 0);
    });
    return cost;
  };

  const handleData = (type) => {
    socialMediaLinks?.rows?.find(
      (item) => item?.key === type && openInNewTab(item?.value)
    );
  };

  const modalObj = {
    requestDetails: (
      <RequestDetailsModal
        isLoading={state?.isLoading}
        type={state?.modalKey}
        modalData={state?.modalData}
      />
    ),
    rateSuccess: (
      <>
        <div className="text-center">
          <ImageElement
            previewSource="/assets/images/service-complated.svg"
            alt="service-complated"
          />
          <h2>{t('text.customerPayment.serviceCompleted')}</h2>
          <p className="mb-0">
            {t('text.customerPayment.currentBookingSuccessfully')} <br />
            {t('text.customerPayment.completed')}
          </p>
        </div>
        { !(userProfile?.Customer?.CompanyNca?.code) && (       
          <div className="text-center">
          <h3> {t('text.customerPayment.LeaveReview')}</h3>
          <div className="servicecomplate_review">
            <ul className="list-unstyled d-flex justify-content-center">
              <li>
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleData('google_link');
                  }}
                >
                  <span>
                    <ImageElement
                      previewSource="/assets/images/google.svg"
                      source="/google.svg"
                      alt="google"
                    />
                  </span>
                  {t('text.customerPayment.google')}
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleData('faceBook_link');
                  }}
                >
                  <span>
                    <ImageElement previewSource="/assets/images/fb.svg" />
                  </span>
                  {t('text.customerPayment.facebook')}
                </Link>
              </li>
            </ul>
          </div>
          <Link to={bookingHome.HOME.path} className="skip">
            {t('text.common.skip')}
          </Link>
          </div>
        )}
      </>
    ),
    promoCodeInfo: (
      <>
        <p>{t('text.ratingReview.promoCodeInfoIconDetails')}</p>
      </>
    )
  };

  useEffect(() => {
    if (params?.id || location?.state) {
      getBookingDetails();
    }
  }, []);

  const compareDiscountApplied = () => {
    let res = [];
    Object.keys(discountQuoteListObj).map((e) => {
      discountQuoteListObj[e]?.discount_list.map((element, index) => {
        res = [
          ...res,
          {
            ...element,
            admin_material_total_amount:
              discountQuoteListObj[e]?.admin_material_total_amount,
          }
        ]
      })
    });
    return res;
  };

  const handleMemberShipData = (memberShipData) => {
    return (
      <>
        {memberShipData?.data?.membershipBenefitAvailable && (
          <div className="listService">
            {(memberShipData?.data?.membershipType === "service_category" || memberShipData?.data?.membershipType === "combo") &&
              <div className="listService_items d-flex justify-content-between">
                {state?.bookingDetails.ServiceCategory?.isSalesQuote === 1 ? t('text.memberShip.quoteDiscount') : t('text.common.serviceDiscount')}
                <span>
                  {checkValidPrice(
                    memberShipData?.data?.serviceMembershipBenefit
                  )}
                </span>
              </div>
            }
            {(memberShipData?.data?.membershipType === "quote") &&
              <div className="listService_items d-flex justify-content-between">
                {t('text.common.quoteMembershipDiscount')}
                <span>
                  {checkValidPrice(
                    memberShipData?.data?.serviceMembershipBenefit
                  )}
                </span>
              </div>
            }
            {(memberShipData?.data?.membershipType === "line_item" || memberShipData?.data?.membershipType === "combo") &&
              <div className="listService_items d-flex justify-content-between">
                {t('text.common.lineItemDiscount')}
                <span>
                  {checkValidPrice(
                    memberShipData?.data?.lineItemMembershipBenefit
                  )}
                </span>
              </div>
            }
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <div className="main-content ratingPage">
        <div className="pageContent">
          <div className="pageContent_wide">
            <Container className="container-lg">
              <Breadcrumb
                title={
                  isRating
                    ? t('text.customerPayment.rating')
                    : t('text.customerPayment.paynow')
                }
                type="customer"
                breadcrumb={breadcrumb}
              />
              {!state.globalLoading &&
                Object.keys(state?.bookingDetails)?.length > 0 ? (
                <div>
                  <div className="ratingPage_Box br-10 p-30 bg-white">
                    <div className="ratingPage_Booking  mb-3 mb-sm-4 d-sm-flex justify-content-between align-items-end">
                      <div className="ratingPage_Booking_left">
                        <h2 className="mb-0">Total</h2>
                        <h3 className="mb-0">
                          {Number(state?.bookingDetails?.total)
                            ? checkValidPrice(state?.bookingDetails?.total)
                            : '$ 0.00'}
                        </h3>
                        <ul className="list-unstyled mb-0">
                          <li className="d-sm-flex align-items-md-center">
                            <em className="gg-calendar me-1" />{' '}
                            <span className="flex-shrink-0">
                              {t('text.ratingReview.bookingDate')}:
                            </span>
                            <span className="ms-sm-1 ms-0">
                              {moment(
                                state?.bookingDetails?.bookingScheduleDate
                              ).format('ddd')}
                              ,&nbsp;
                              {moment(
                                state?.bookingDetails?.bookingScheduleDate
                              ).format('LL')}{' '}
                              &nbsp;at&nbsp;
                              {moment(
                                state?.bookingDetails?.bookingScheduleDate
                              ).format('LT')}
                            </span>
                          </li>
                          {state?.bookingDetails.ServiceCategory?.isSalesQuote != 1 &&
                            <li className="d-sm-flex align-items-md-center">
                              {' '}
                              <em className="gg-discount me-1" />{' '}
                              <span>
                                {t('text.ratingReview.discountApplied')}:
                              </span>
                              {/* <span className="ms-sm-1 ms-0">
                                {state?.bookingDetails?.PromoCode?.discountType
                                  ? state?.bookingDetails?.PromoCode
                                    ?.discountType === 'percentage'
                                    ? `${parseFloat(
                                      state?.bookingDetails?.PromoCode
                                        ?.discountValue
                                    )?.toFixed(2)}%`
                                    : checkValidPrice(
                                      state?.bookingDetails?.PromoCode
                                        ?.discountValue *
                                      (state?.bookingDetails?.quantity || 1)
                                    )
                                  : 'No Promo Code Applied'}
                              </span> */}
                              <span className="ms-sm-1 ms-0">
                                {state?.bookingDetails?.promoCodeDiscount
                                ? checkValidPrice(
                                  state?.bookingDetails?.promoCodeDiscount
                                )
                                : 'No Promo Code Applied'}
                              </span>
                            </li>
                          }
                        </ul>
                      </div>
                      <div className="ratingPage_Booking_right">
                        <CommonButton
                          type="button"
                          variant="info"
                          className="btn btn-info mt-2 mt-sm-0"
                          onClick={handleGetReceipt}
                          disabled={state?.isReceiptLoading}
                          loading={state?.isReceiptLoading}
                        >
                          {t('text.ratingReview.getReceipt')}
                        </CommonButton>
                      </div>
                    </div>
                    <div className="ratingPage_Payment">
                      <div className="ratingPage_Payment_type">
                        <p>
                          {t('text.ratingReview.paymentType')}:{' '}
                          <span className="text-capitalize">
                            {state?.bookingDetails?.BookingDetail?.paymentMode}
                          </span>
                        </p>
                      </div>
                      <div className="ratingPage_Services">
                        <h4 className="ratingheading mb-0">
                          {t('text.ratingReview.services')}
                        </h4>
                        <div className="ratingPage_Services_inner d-flex justify-content-between align-items-end">
                          <div className="ratingPage_Services_maintenance">
                            <h5 className="ratingsubhead mb-2">
                              {state?.bookingDetails?.ServiceCategory?.name}{' '}
                              {state?.bookingDetails?.ServiceCategory
                                ?.servicePrice === 'paid' && (
                                  <>
                                    (
                                    {state?.bookingDetails?.ServiceCategory
                                      ?.price > 0
                                      ? checkValidPrice(
                                        state?.bookingDetails?.serviceAmount
                                      )
                                      : null}{' '}
                                    X {state?.bookingDetails?.quantity || 1})
                                  </>
                                )}
                            </h5>
                          </div>
                          <p className="ratingamount mb-0">
                            {state?.bookingDetails?.ServiceCategory
                              ?.servicePrice === 'paid'
                              ? checkValidPrice(
                                state?.bookingDetails?.serviceAmount *
                                (state?.bookingDetails?.quantity || 1)
                              )
                              : 'Free'}
                          </p>
                        </div>
                      </div>
                      <div className="ratingPage_Services">
                        <>
                          <h4 className="ratingheading mb-0">
                            {t('text.customerPayment.customerImage')}
                          </h4>
                          {state?.bookingDetails?.customerSignatureThumbUrl ? (
                            <div className="ratingPage_Services_image">
                              <ImageElement
                                previewSource={
                                  state?.bookingDetails
                                    ?.customerSignatureThumbUrl
                                }
                                alt="Customer Signature"
                              />
                            </div>
                          ) : (
                            <div className="ratingPage_Services_image">
                              <div className="pt-4">No Signature Found</div>
                            </div>
                          )}
                        </>
                        {/* New Quote Start Api remaining*/}
                        {state?.bookingDetails?.optionsList?.length &&
                          state?.bookingDetails?.optionsList?.map(
                            (quoteItem, index) => (
                              <div key={index}>
                                <div className="ratingPage_Services_inner d-flex justify-content-between">
                                  <div className="d-flex align-items-sm-start">
                                    <ImageElement
                                      previewSource={quoteItem?.image}
                                      className="me-2"
                                      alt="water-heater"
                                    />

                                    <div className="ratingPage_desc">
                                      <h5 className="ratingsubhead mb-1 d-flex align-items-center">
                                        {quoteItem?.brand} -{' '}
                                        {quoteItem?.quote_type}
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            // setState({
                                            //   ...state,
                                            //   modalHeading: quoteItem?.quote_type,
                                            //   showModal: 'requestDetails',
                                            //   modalKey: 'information',
                                            //   modalData: quoteItem,
                                            // });
                                          }}
                                        >
                                          <em className="gg-danger-circle ms-2 text-750" />
                                        </Link>
                                      </h5>
                                      {quoteItem?.series_name && (
                                        <p className="ratingamount mb-1 d-flex align-items-center">
                                          {quoteItem?.series_name}
                                        </p>
                                      )}
                                      {/* {quoteItem?.quote_description && (
                                        <p className="ratingamount mb-1 d-flex align-items-center">
                                          {quoteItem?.quote_description}
                                        </p>
                                      )} */}
                                      {quoteItem?.size_description && (
                                        <p className="ratingamount mb-1">
                                          <span className="ratingsubhead">
                                            {t(
                                              'text.requestViewDetails.sizeDescription'
                                            )}
                                          </span>
                                          {quoteItem?.size_description}
                                        </p>
                                      )}
                                      {/* {quoteItem?.quote_description && (
                                        <p className="ratingamount mb-1">
                                          <span className="ratingsubhead">
                                            {t(
                                              'text.requestViewDetails.quoteDescription'
                                            )}
                                          </span>
                                          {quoteItem?.quote_description}
                                        </p>
                                      )} */}
                                      {quoteItem?.system_btu_capacity && (
                                        <p className="ratingamount mb-1">
                                          <span className="ratingsubhead">
                                            {t(
                                              'text.requestViewDetails.capacity'
                                            )}
                                          </span>
                                          {quoteItem?.system_btu_capacity}
                                        </p>
                                      )}
                                      {quoteItem?.directional && (
                                        <p className="ratingamount mb-1">
                                          <span className="ratingsubhead">
                                            {t(
                                              'text.requestViewDetails.directional'
                                            )}
                                          </span>
                                          {quoteItem?.directional}
                                        </p>
                                      )}
                                      {quoteItem?.min_blower_drive && (
                                        <p className="ratingamount mb-1">
                                          <span className="ratingsubhead">
                                            {t(
                                              'text.requestViewDetails.minimumBlowerDrive'
                                            )}
                                          </span>
                                          {quoteItem?.min_blower_drive}
                                        </p>
                                      )}
                                      {quoteItem?.efficiency && (
                                        <p className="ratingamount mb-1">
                                          <span className="ratingsubhead">
                                            {t(
                                              'text.requestViewDetails.efficiency'
                                            )}
                                          </span>
                                          {quoteItem?.efficiency}
                                        </p>
                                      )}
                                      {quoteItem?.system_part_warranty && (
                                        <p className="ratingamount mb-1">
                                          <span className="ratingsubhead">
                                            {t(
                                              'text.requestViewDetails.partsWarranty'
                                            )}
                                          </span>{' '}
                                          {quoteItem?.system_part_warranty}
                                        </p>
                                      )}
                                      {quoteItem?.energy_guide_label && (
                                        <p className="ratingamount mb-1">
                                          <span className="ratingsubhead">
                                            {t(
                                              'text.requestViewDetails.energyGuideLabel'
                                            )}
                                          </span>
                                          {quoteItem?.energy_guide_label}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  {quoteItem?.admin_material_total_amount && (
                                    <div className="ratingPage_amount">
                                      <p className="ratingamount mb-1">
                                        ${' '}
                                        {quoteItem?.admin_material_total_amount}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )
                          )}
                        {/* New Quote End */}
                      </div>
                      {state?.bookingDetails?.quote?.length > 0 && (
                        <div className="ratingPage_Services">
                          <h4 className="ratingheading">Quote Option</h4>
                          {state?.bookingDetails?.quote?.length > 0
                            ? state?.bookingDetails?.quote?.map(
                              (quoteItem, index) => (
                                <div key={index}>
                                  <div className="ratingPage_Services_inner d-flex">
                                    <ImageElement
                                      previewSource={
                                        quoteItem?.image?.imageThumbUrl
                                      }
                                      className="me-2"
                                      alt="water-heater"
                                    />

                                    <div className="flex-grow-1">
                                      <div className="ratingPage_desc d-flex justify-content-between">
                                        <div>
                                          <h5 className="ratingsubhead mb-1 d-flex align-items-center">
                                            {quoteItem?.brand} -{' '}
                                            {quoteItem?.quote_type}
                                            <Link
                                              to="#"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setState({
                                                  ...state,
                                                  heading: quoteItem?.brand
                                                    ? `${quoteItem?.brand} ${quoteItem?.quote_type
                                                      ? `- ${quoteItem?.quote_type}`
                                                      : ''
                                                    }`
                                                    : '-',
                                                  showModal: 'requestDetails',
                                                  modalKey: 'information',
                                                  modalData: quoteItem,
                                                });
                                              }}
                                            >
                                              <em className="gg-danger-circle ms-2 text-750" />
                                            </Link>
                                          </h5>
                                          {quoteItem?.series_name && (
                                            <p className="ratingamount mb-1 d-flex align-items-center">
                                              {quoteItem?.series_name}
                                            </p>
                                          )}
                                          {/* {quoteItem?.quote_description && (
                                              <p className="ratingamount mb-1">
                                                {quoteItem?.quote_description}
                                              </p>
                                            )} */}
                                          {/* {quoteItem?.quote_description && (
                                              <p className="ratingamount mb-1">
                                                <span className="ratingsubhead">
                                                  {t(
                                                    'text.requestViewDetails.quoteDescription'
                                                  )}
                                                </span>
                                                {quoteItem?.quote_description}
                                              </p>
                                            )} */}
                                          {quoteItem?.system_btu_capacity && (
                                            <p className="ratingamount mb-1">
                                              <span className="ratingsubhead">
                                                {t(
                                                  'text.requestViewDetails.capacity'
                                                )}
                                              </span>
                                              {quoteItem?.system_btu_capacity}
                                            </p>
                                          )}
                                          {quoteItem?.size_description && (
                                            <p className="ratingamount mb-1">
                                              <span className="ratingsubhead">
                                                {t(
                                                  'text.requestViewDetails.sizeDescription'
                                                )}
                                              </span>
                                              {quoteItem?.size_description}
                                            </p>
                                          )}
                                          {quoteItem?.directional && (
                                            <p className="ratingamount mb-1">
                                              <span className="ratingsubhead">
                                                {t(
                                                  'text.requestViewDetails.directional'
                                                )}
                                              </span>
                                              {quoteItem?.directional}
                                            </p>
                                          )}
                                          {quoteItem?.min_blower_drive && (
                                            <p className="ratingamount mb-1">
                                              <span className="ratingsubhead">
                                                {t(
                                                  'text.requestViewDetails.minimumBlowerDrive'
                                                )}
                                              </span>
                                              {quoteItem?.min_blower_drive}
                                            </p>
                                          )}
                                          {quoteItem?.efficiency && (
                                            <p className="ratingamount mb-1">
                                              <span className="ratingsubhead">
                                                {t(
                                                  'text.requestViewDetails.efficiency'
                                                )}
                                              </span>
                                              {quoteItem?.efficiency}
                                            </p>
                                          )}
                                          {quoteItem?.system_part_warranty && (
                                            <p className="ratingamount mb-1">
                                              <span className="ratingsubhead">
                                                {t(
                                                  'text.requestViewDetails.partsWarranty'
                                                )}
                                              </span>{' '}
                                              {
                                                quoteItem?.system_part_warranty
                                              }
                                            </p>
                                          )}
                                          {quoteItem?.energy_guide_label && (
                                            <p className="ratingamount mb-1">
                                              <span className="ratingsubhead">
                                                {t(
                                                  'text.requestViewDetails.energyGuideLabel'
                                                )}
                                              </span>
                                              {quoteItem?.energy_guide_label}
                                            </p>
                                          )}
                                        </div>
                                        {quoteItem?.admin_material_total_amount && (
                                          <div className="ratingPage_amount">
                                            <p className="ratingamount mb-1">
                                              $&nbsp;
                                              {
                                                quoteItem?.admin_material_total_amount
                                              }
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                      <div>
                                        {quoteItem?.discount_list?.length >
                                          0 && (
                                            <div className="d-flex mb-1 align-items-center justify-content-between">
                                              <h5 className="ratingsubhead mb-0 d-flex align-items-center">
                                                {t(
                                                  'text.requestViewDetails.discountedPrice'
                                                )}
                                                <Link
                                                  to="#"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    setState({
                                                      ...state,
                                                      heading: t(
                                                        'text.requestViewDetails.discountS'
                                                      ),
                                                      showModal:
                                                        'requestDetails',
                                                      modalKey: 'discountPrice',
                                                      modalData: quoteItem,
                                                    });
                                                  }}
                                                >
                                                  <em className="gg-danger-circle ms-2 text-750" />
                                                </Link>
                                              </h5>
                                              <p className="ratingamount mb-1">
                                                {checkValidPrice(
                                                  parseFloat(
                                                    quoteItem?.admin_material_total_amount
                                                  ) -
                                                  totalCost(
                                                    quoteItem?.discount_list
                                                  )
                                                )}
                                              </p>
                                            </div>
                                          )}
                                        {quoteItem?.optionalItemForBooking
                                          ?.length > 0 && (
                                            <>
                                              <div className="ratingsubhead mb-1 pe-0">
                                                {t(
                                                  'text.requestViewDetails.options'
                                                )}
                                              </div>
                                              {quoteItem?.optionalItemForBooking?.map(
                                                (optionalItem, idx) => (
                                                  <div
                                                    key={idx}
                                                    className="d-flex mb-1 justify-content-between align-items-center"
                                                  >
                                                    <div className="ratingsubhead">
                                                      {optionalItem?.title ??
                                                        '-'}
                                                    </div>
                                                    <div className="ratingamount">
                                                      {checkValidPrice(
                                                        optionalItem?.cost
                                                      )}
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                            </>
                                          )}
                                        {state?.bookingDetails?.financeOption
                                          ?.paymentFactor > 0 && (
                                            <div className="d-flex text-nowrap justify-content-between align-items-center">
                                              <div className="ratingsubhead mb-0">
                                                {t(
                                                  'text.requestViewDetails.monthlyApprovedCredit'
                                                )}
                                              </div>

                                              <div className="ratingamount float-left ml-auto ratingPage_amount">
                                                {/* {checkValidPrice(
                                                  parseFloat(
                                                    (parseFloat(
                                                      quoteItem?.admin_material_total_amount
                                                    ) +
                                                      parseFloat(
                                                        quoteItem
                                                          ?.optionalItemForBooking
                                                          ?.length
                                                          ? quoteItem?.optionalItemForBooking
                                                            ?.map(
                                                              (e) => e?.cost
                                                            )
                                                            ?.reduce(
                                                              (prev, next) =>
                                                                prev + next
                                                            )
                                                          : 0
                                                      ) -
                                                      parseFloat(
                                                        totalCost(
                                                          quoteItem?.discount_list
                                                        )
                                                      )) /
                                                    100
                                                  ) *
                                                  parseFloat(
                                                    state?.bookingDetails
                                                      ?.financeOption
                                                      ?.paymentFactor
                                                  )
                                                )} */}
                                                {parseFloat(
                                                  quoteItem?.appliedDiscount
                                                ) <
                                                  parseFloat(
                                                    quoteItem?.admin_material_total_amount
                                                  )
                                                  ? checkValidPrice(
                                                    ((parseFloat(
                                                      quoteItem?.admin_material_total_amount
                                                    ) -
                                                      parseFloat(
                                                        quoteItem?.appliedDiscount
                                                      )) /
                                                      100) *
                                                    parseFloat(
                                                      quoteItem
                                                        ?.financeOption
                                                        ?.paymentFactor
                                                    )
                                                  )
                                                  : '$0.00'}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )
                            : null}
                        </div>
                      )}
                      {/* Equipment Start */}
                      {state?.bookingDetails?.otherEquipment?.length > 0 && (
                        <div className="ratingPage_Services">
                          <h4 className="ratingheading">Technician Options</h4>

                          {state?.bookingDetails?.otherEquipment?.map(
                            (val, index) => (
                              <>
                                <div className="ratingPage_Services_maintenance">
                                  <div
                                    key={val?.id}
                                    className="ratingPage_Services_inner d-flex justify-content-between "
                                  >
                                    <div className="d-flex align-items-sm-center">
                                      <ImageElement
                                        previewSource={val?.imageThumbUrl}
                                        className="me-2"
                                        alt="water-heater"
                                      />
                                      <div className="ratingPage_desc">
                                        <h5 className="ratingsubhead mb-1 d-flex align-items-center">
                                          {val?.title || ''}
                                        </h5>
                                        <p className="ratingamount mb-0 d-flex align-items-center">
                                          {val?.description || ''}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="ratingPage_amount">
                                      <p className="ratingamount mb-0">
                                        {checkValidPrice(val?.cost || '')}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {val?.otherEquipment?.length ? (
                                  <>
                                    <h5 className="ratingsubhead mb-0 mt-2">
                                      {t('text.requestViewDetails.optionsList')}
                                    </h5>

                                    {val?.otherEquipment?.map(
                                      (optionData, i) => (
                                        <>
                                          <div className="ratingPage_Services_inner d-flex justify-content-between ">
                                            <h5 className="ratingsubhead mb-0">
                                              {optionData?.title}
                                            </h5>
                                            <div className="ratingPage_amount">
                                              <p className="ratingamount mb-0">
                                                {checkValidPrice(
                                                  optionData?.cost
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </>
                                      )
                                    )}
                                  </>
                                ) : null}
                              </>
                            )
                          )}

                          {/* {state?.bookingDetails?.financeOption?.paymentFactor >
                            0 && (
                            <div className="ratingPage_Services_inner d-flex justify-content-between ">
                              <h5 className="ratingsubhead mb-0">
                                {t(
                                  'text.requestViewDetails.monthlyApprovedCredit'
                                )}
                              </h5>
                              <p className="ratingamount mb-0 ">
                                {checkValidPrice(
                                  parseFloat(
                                    (parseFloat(
                                      state?.bookingDetails.otherEquipment
                                        ?.length
                                        ? state?.bookingDetails.otherEquipment
                                            ?.map((e) => parseFloat(e?.cost))
                                            ?.reduce(
                                              (prev, next) => prev + next
                                            )
                                        : 0
                                    ) +
                                      parseFloat(
                                        state?.bookingDetails.otherEquipment
                                          ?.map((e) => e.otherEquipment)
                                          ?.flat()?.length
                                          ? state?.bookingDetails.otherEquipment
                                              ?.map((e) => e.otherEquipment)
                                              ?.flat()
                                              ?.map((i) => parseFloat(i?.cost))
                                              ?.reduce(
                                                (prev, next) => prev + next
                                              )
                                          : 0
                                      )) /
                                      100
                                  ) *
                                    parseFloat(
                                      state?.bookingDetails?.financeOption
                                        ?.paymentFactor
                                    )
                                )}
                              </p>
                            </div>
                          )} */}
                        </div>
                      )}

                      {/* quote amount display */}
                      {(state?.bookingDetails.isPaid === 0 && state?.bookingDetails.ServiceCategory?.isSalesQuote === 0) &&
                        <div className="ratingPage_Services_inner d-flex justify-content-between ">
                          <h5 className="ratingsubhead mb-0">
                            {t('text.requestViewDetails.quoteAmount')}
                          </h5>
                          <p className="ratingamount mb-0 ">
                            {state?.bookingDetails?.BookingQuote?.quoteAmount
                              ? checkValidPrice(
                                state?.bookingDetails?.BookingQuote?.quoteAmount
                              )
                              : '$0.00'}
                          </p>
                        </div>
                      }

                      {/* Line item listing Block */}
                      {state?.bookingDetails?.BookingLineItems?.length > 0 && (
                        <div className="ratingPage_Services">
                          <h4 className="ratingheading">
                            {t('text.ratingReview.lineItems')}
                          </h4>

                          {state?.bookingDetails?.BookingLineItems?.length >
                            0 ? (
                            state?.bookingDetails?.BookingLineItems?.map(
                              (item) => (
                                <div className="ratingPage_Services_inner d-flex justify-content-between align-items-start">
                                  <div className="d-sm-flex">
                                    <ImageElement
                                      previewSource={item?.imageThumbUrl}
                                      className="me-2 mb-2"
                                      alt="water-heater"
                                    />
                                    <div className="ratingPage_Services_items">
                                      <h5 className="ratingsubhead mb-xxl-2 mb-1">
                                        {item?.name}
                                      </h5>
                                      <p className="ratingpara mb-0">
                                        {item?.description}
                                      </p>
                                    </div>
                                  </div>
                                  <p className="ratingamount mb-0">
                                    ${item?.total}
                                  </p>
                                </div>
                              )
                            )
                          ) : (
                            <p className="ratingamount mb-0 mt-2">
                              {t('text.ratingReview.noItems')}
                            </p>
                          )}
                          {/* {state?.bookingDetails?.financeOption?.paymentFactor >
                            0 && (
                            <div className="ratingPage_Services_inner d-flex justify-content-between ">
                              <h5 className="ratingsubhead mb-0">
                                {t(
                                  'text.requestViewDetails.monthlyApprovedCredit'
                                )}
                              </h5>
                              <p className="ratingamount mb-0 ">
                                {checkValidPrice(
                                  parseFloat(
                                    (state?.bookingDetails?.BookingLineItems
                                      ?.length
                                      ? state?.bookingDetails?.BookingLineItems?.map(
                                          (e) => parseFloat(e?.total)
                                        )?.reduce((prev, next) => prev + next)
                                      : 0) / 100
                                  ) *
                                    parseFloat(
                                      state?.bookingDetails?.financeOption
                                        ?.paymentFactor
                                    )
                                )}
                              </p>
                            </div>
                          )} */}
                        </div>
                      )}

                      {/* Block For Display Total Cost   */}
                      <div className='ratingPage_Services_inner d-flex justify-content-between align-items-center mt-2'>
                        <h5 className="ratingsubhead mb-0">
                          {t('text.ratingReview.cost')}
                        </h5>
                        <p className="ratingsubhead mb-0 ratingPage_amount pe-0">
                          {state?.bookingDetails?.totalCost
                            ? checkValidPrice(
                              state?.bookingDetails?.totalCost
                            )
                            : '0'}
                        </p>
                      </div>

                      <div className="ratingPage_Services">
                        {
                          !(state?.bookingDetails?.BookingQuote?.isSalesQuotes) &&
                          state?.bookingDetails?.PromoCode &&
                          <div className="ratingPage_Services_inner d-flex justify-content-between align-items-center mt-0">
                            <h5 className="ratingsubhead mb-0">
                              {t('text.ratingReview.promoCodeDiscount')}
                              {':'}
                              <Link
                                to="#"
                                className="ms-1"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setState({
                                    ...state,
                                    heading:t('text.ratingReview.promoCodeDiscount'),
                                    showModal:'promoCodeInfo',
                                  });
                                }
                                }
                              >
                                <em className="gg-danger-circle ms-2 text-750" />
                              </Link>
                            </h5>
                            {/* <p className="ratingsubhead mb-0 ratingPage_amount pe-0">
                              {!state?.bookingDetails
                                ?.membershipBenefitAvailable ? (
                                <>
                                  {state?.bookingDetails?.PromoCode?.discountType
                                    ? state?.bookingDetails?.PromoCode
                                      ?.discountType === 'percentage'
                                      ? `${parseFloat(
                                        state?.bookingDetails?.PromoCode
                                          ?.discountValue
                                      ).toFixed(2)}%`
                                      : checkValidPrice(
                                        state?.bookingDetails?.PromoCode
                                          ?.discountValue *
                                        (state?.bookingDetails?.quantity || 1)
                                      )
                                    : '$ 0.00'}
                                </>
                              ) : Number(
                                state?.bookingDetails?.customerPromoCodeDiscount
                              ) ? (
                                checkValidPrice(
                                  state?.bookingDetails?.customerPromoCodeDiscount
                                )
                              ) : (
                                '$0.00'
                              )}
                            </p> */}
                            <p className="ratingsubhead mb-0 ratingPage_amount pe-0">
                              {state?.bookingDetails?.promoCodeDiscount
                                ? checkValidPrice(
                                  state?.bookingDetails?.promoCodeDiscount
                                )
                                : '0'}
                            </p>
                          </div>
                        }
                        <div className="ratingPage_Services_inner d-flex justify-content-between align-items-center mt-1">
                          <h5 className="ratingsubhead mb-0 mt-2">
                            {t('text.ratingReview.subTotal')}
                          </h5>
                          <p className="ratingsubhead mb-0 ratingPage_amount pe-0 mt-1">
                            {!state?.bookingDetails?.membershipBenefitAvailable
                              ? Number(state?.bookingDetails?.subTotal)
                                ? checkValidPrice(
                                  state?.bookingDetails?.subTotal
                                )
                                : '$0.00'
                              : Number(state?.bookingDetails?.customerSubTotal)
                                ? checkValidPrice(
                                  state?.bookingDetails?.customerSubTotal
                                )
                                : '$0.00'}
                          </p>
                        </div>
                        {state?.bookingDetails?.membershipBenefitAvailable && (
                          <div className="ratingPage_Services_inner d-flex justify-content-between align-items-center my-sm-3 my-2">
                            <h5 className="ratingsubhead mb-0 d-flex align-items-center">
                              {t('text.common.memberShipSavings')}{':'}
                              <Link
                                to="#"
                                className="ms-1"
                                onClick={() =>
                                  setShowModalMember({
                                    ...showModalMember,
                                    show: true,
                                    data: {
                                      lineItemMembershipBenefit:
                                        state?.bookingDetails
                                          ?.lineItemMembershipBenefit,
                                      serviceMembershipBenefit:
                                        state?.bookingDetails
                                          ?.membershipBenefit,
                                      membershipBenefitAvailable:
                                        state?.bookingDetails
                                          ?.membershipBenefitAvailable,
                                      membershipType: state?.bookingDetails
                                        ?.membershipType,
                                    },
                                  })
                                }
                              >
                                <em className="gg-danger-circle ms-2 text-750" />
                              </Link>
                            </h5>
                            <p className="ratingsubhead mb-0 ratingPage_amount pe-0">
                              {Number(
                                state?.bookingDetails
                                  ?.membershipBenefitAvailable
                              )
                                ? checkValidPrice(
                                  Number(
                                    state?.bookingDetails?.membershipBenefit
                                  ) +
                                  Number(
                                    state?.bookingDetails
                                      ?.lineItemMembershipBenefit
                                  )
                                )
                                : '$0.00'}
                            </p>
                          </div>
                        )}
                        {state?.bookingDetails?.discount > 0 && (
                          <div className="ratingPage_Services_inner d-flex justify-content-between align-items-center mt-0 my-sm-3 my-2">
                            <h5 className="ratingsubhead mb-0">
                              {state?.bookingDetails.ServiceCategory?.isSalesQuote === 1 ? 'Discount Applied' : 'Service Provider Discount'}
                              {state?.bookingDetails.ServiceCategory?.isSalesQuote === 1 &&
                                <Link
                                  to="#"
                                  className="ms-1"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setState({
                                      ...state,
                                      heading: t(
                                        'text.requestViewDetails.discount'
                                      ),
                                      showModal:
                                        'requestDetails',
                                      modalKey: 'discountApplied',
                                      modalData: compareDiscountApplied(),
                                    });
                                  }
                                  }
                                >
                                  <em className="gg-danger-circle ms-2 text-750" />
                                </Link>
                              }
                            </h5>
                            <p className="ratingsubhead mb-0 pe-0">
                              {checkValidPrice(state?.bookingDetails?.discount)}
                            </p>
                          </div>
                        )}

                        <div className="ratingPage_Services_inner d-flex justify-content-between align-items-center ">
                          <h5 className="ratingsubhead mb-0">
                            {t('text.ratingReview.total')}
                          </h5>
                          <p className="ratingsubhead mb-0 pe-0">
                            {Number(state?.bookingDetails?.total)
                              ? checkValidPrice(state?.bookingDetails?.total)
                              : '$0.00'}
                          </p>
                        </div>
                        {state?.bookingDetails?.financeOption?.paymentFactor >
                          0 && (
                            <div className="ratingPage_Services_inner d-flex justify-content-between ">
                              <h5 className="ratingsubhead mb-0">
                                {t(
                                  'text.requestViewDetails.monthlyApprovedCredit'
                                )}
                              </h5>
                              <p className="ratingamount mb-0 ">
                                {checkValidPrice(
                                  parseFloat(
                                    (state?.bookingDetails?.total > 0
                                      ? state?.bookingDetails?.total
                                      : 0) / 100
                                  ) *
                                  parseFloat(
                                    state?.bookingDetails?.financeOption
                                      ?.paymentFactor
                                  )
                                )}
                              </p>
                            </div>
                          )}
                      </div>

                      {Number(state?.remainingAmountDetails?.remainingAmount) >
                        0 &&
                        Number(state?.remainingAmountDetails?.total) >
                        Number(
                          state?.remainingAmountDetails?.remainingAmount
                        ) ? (
                        <div className="ratingPage_Services">
                          <div className="ratingPage_Services_inner d-flex justify-content-between align-items-center mt-0">
                            <h5 className="ratingsubhead mb-0">
                              Remaining Amount (By Split Payment)
                            </h5>
                            <p className="ratingsubhead mb-0 pe-0">
                              {checkValidPrice(
                                state?.remainingAmountDetails?.remainingAmount
                              )}
                            </p>
                          </div>
                        </div>
                      ) : null}
                      <div
                        className={`ratingPage_Services ${isRating ? '' : 'border-bottom-0'
                          }`}
                      >
                        <h5 className="ratingsubhead mb-2 pe-0">
                          {t('text.ratingReview.invoiceMessage')}
                        </h5>
                        <p className="ratingpara mb-0">
                          {state?.bookingDetails?.invoiceMessage
                            ? checkValidData(
                              state?.bookingDetails?.invoiceMessage
                            )
                            : 'No Invoice Message Found'}
                        </p>
                      </div>
                      {isRating && (
                        <div className="ratingPage_Services border-0 pb-0">
                          <h4 className="ratingheading text-center">
                            {t('text.ratingReview.ratingText')}
                          </h4>
                          <div className="rating d-flex justify-content-center">
                            <Rate
                              value={Number(state?.rate)}
                              allowHalf
                              onChange={(value) => handleChange('rate', value)}
                              disabled={
                                state?.bookingDetails?.Customer?.isRated === 1
                              }
                            />
                          </div>
                          <FormGroup className="form-group">
                            <TextArea
                              value={state?.comment}
                              onChange={(e) =>
                                handleChange('comment', e?.target?.value)
                              }
                              size="large"
                              placeholder="Enter Your Comment"
                              className="bg-white"
                              disabled={
                                state?.bookingDetails?.Customer?.isRated === 1
                              }
                            />
                            {console.log(
                              state?.bookingDetails?.BookingReviews[0]?.rating
                            )}
                          </FormGroup>
                        </div>
                      )}
                    </div>
                    <div
                      className={`d-flex justify-content-${isRating ? 'center' : 'end'
                        } mt-sm-4 mt-3`}
                    >
                      {isRating ? (
                        state?.bookingDetails?.Customer?.isRated === 0 && (
                          <CommonButton
                            onClick={handleSubmitRate}
                            className="ms-4 font-sm btn btn-primary min-200 btn-lg"
                            disabled={state?.isLoading}
                            loading={state?.isLoading}
                          >
                            {t('text.ratingReview.rate')}
                          </CommonButton>
                        )
                      ) : (
                        <div>
                          {state?.bookingDetails?.BookingDetail?.isSplitPayment === true && state?.bookingDetails?.remainingAmount === 0 ? null :
                            <CommonButton
                              disabled={Number(state?.bookingDetails?.total) <= 0}
                              onClick={() => {
                                navigate(
                                  generatePath(accessRoute.PAYMENT_OPTION.path, {
                                    id: location?.state || params?.id,
                                  }),
                                  {
                                    state: {
                                      bookingId: location?.state || params?.id,
                                      totalAmount: state?.bookingDetails?.BookingDetail?.isSplitPayment === false && state?.bookingDetails?.remainingAmount === 0
                                        ?
                                        state.remainingAmountDetails?.total
                                        : state.remainingAmountDetails?.remainingAmount
                                    },
                                  }
                                );
                              }}
                              className="ms-4 font-sm btn btn-primary min-200 btn-lg"
                            >
                              {t('text.ratingReview.payNow')}
                            </CommonButton>
                          }
                        </div>
                      )}
                    </div>
                    {Number(state.bookingDetails?.total) <= 0 && (
                      <div className="text-center mt-3">
                        Service Provider{' '}
                        {state?.bookingDetails?.Provider?.firstName}{' '}
                        {state?.bookingDetails?.Provider?.lastName} give you
                        100% discount
                      </div>
                    )}
                  </div>
                </div>
              ) : !state.globalLoading &&
                !Object.keys(state?.bookingDetails)?.length ? (
                <NoDataFound
                  source="no-data-found-img.svg"
                  text="No Data Found"
                  isCustomer={'/assets/images/admin/no-data-found-img.svg'}
                />
              ) : (
                <GlobalLoader />
              )}

              {!(userProfile?.Customer?.CompanyNca?.code) && (
                <div className="sideBar_bottom mt-3 ratingPage_bottom">
                  <p className="text-center mb-sm-3 mb-2">
                    {t('text.ratingReview.footerText')}
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleData('android_app_website_footer');
                      }}
                      className="me-2"
                    >
                      <ImageElement
                        source="google-play-logo.svg"
                        className="w-100 img-fluid"
                        alt="Google-play"
                      />
                    </Link>
                    <Link
                      className="mt-0"
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleData('iphone_app_website_footer');
                      }}
                    >
                      <ImageElement
                        source="app-store-logo.svg"
                        className="w-100 img-fluid"
                        alt="App-play"
                      />
                    </Link>
                  </div>
                </div>
              )}
            </Container>
          </div>
        </div>
      </div>

      {state.showModal && (
        <ModalWrapper
          closable={true}
          heading={state?.heading}
          show={state?.showModal}
          content={modalObj[state?.showModal]}
          onCancel={() => {
            if(state?.showModal === 'rateSuccess'){
              navigate(bookingHome.HOME.path);
            }
            setState({ ...state, showModal: '' })
          }}
          extraClassName={state?.showModal === 'decline' && 'modal-lg'}
          extraTitleClassName="align-items-start modal-title h4"
          size={state?.showModal === 'requestDetails' && 'lg'}
        ></ModalWrapper>
      )}

      <ModalWrapper
        onCancel={() =>
          setShowModalMember({
            show: false,
            data: {},
          })
        }
        heading={t('text.common.memberShipSavings')}
        show={showModalMember?.show}
        content={handleMemberShipData(showModalMember)}
      ></ModalWrapper>
    </>
  );
}

export default CustomerPayment;
