import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PageContainerWrapper,
  ServiceProviderStatusActivityFilterForm,
  TablePlus,
} from '../../../../components';
import useTable from '../../../../hooks/useTable.hook';
import {
  Columns,
  getProvidersActivityService,
} from '../../../../services/index.service';
import { logger, modalNotification } from '../../../../utils';
import { useEffect } from 'react';
import moment from 'moment';
import { selectDateData } from '../../../../services/Columns';
import { Table, Spin } from 'antd';
import { checkValidData } from '../../../../components/UIElements/Formatter';

function SpStatusAnalysisReport() {
  const { t } = useTranslation();
  const breadcrumb = Columns.serviceProviderActivityReportBreadcrumb();

  const [isSearch, setIsSearch] = useState(0);

  const [state, setState] = useState({
    loader: null,
  });

  const [filterValue, setFilter] = useState({
    onlineFromDate: moment().format('YYYY-MM-DD') || '',
    onlineToDate: moment().format('YYYY-MM-DD') || '',
    location: undefined,
    dateType: selectDateData[0]?.value || undefined,
  });

  const [values, setValues] = useState({
    onlineFromDate: '',
    onlineToDate: '',
    dateType: undefined,
    location: undefined,
  });
  const [totalTimingDetails, setTotalTimingDetails] = useState({});
  const [globalFilter, setGlobalFilter] = useState(false);

  const [tableHandler, tableProps] = useTable(
    getProvidersActivityService,
  );
  const {
    onFilterChange,
    onFilterResetChange,
    onTableSort,
  } = tableHandler;
  const {
    data,
    allData,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;

  const columns = Columns.serviceProviderActivityReportColumn({
    onSort: onTableSort,
    onFilterChange: onFilterChange,
    filter: filter,
    page,
    sizePerPage,
  });

  const onFilterSubmit = (values) => {
    try {
      if (!values?.onlineFromDate && !values?.onlineToDate) {
        modalNotification({
          type: 'error',
          message: t('text.common.selectDateFilter'),
        });
        return;
      }
      let val = {
        onlineFromDate: values?.onlineFromDate || '',
        onlineToDate: values?.onlineToDate || '',
        location: values?.location || '',
      };
      setFilter((prevState) => ({
        ...prevState,
        ...val,
      }));
      delete val?.dateType;

      setIsSearch(isSearch + 1);

      onFilterChange(val);
      setGlobalFilter(false);
    } catch (error) {
      logger(error);
    }
  };

  function onReset() {
    if (
      !values?.onlineFromDate &&
      !values?.onlineToDate
    ) {
      return;
    }
    const resetData = {
      onlineFromDate: '',
      onlineToDate: '',
      location: undefined,
      dateType: '',
    };
    setFilter({
      ...resetData,
    });
    setValues({
      ...resetData,
    });
    setIsSearch(isSearch + 1);
    onFilterChange(resetData);
    setGlobalFilter(false);
  }

  function onTableResetFilter() {
    const resetData = {
      onlineFromDate: '',
      onlineToDate: '',
      location: undefined,
      dateType: '',
    };
    setFilter({
      ...resetData,
    });
    setIsSearch(isSearch + 1);
    onFilterChange(resetData);
    onFilterResetChange();
  }

  const totalTimingDetailsColumn = [
    { dataIndex: 'service',
      title: t('text.report.totalOnlineTime'),
      render: (cell, row) => <b>{checkValidData(cell)}</b>
    },
    {
      dataIndex: 'timing',
      title: t('text.report.averageTimeSpentOnline'),
      render: (cell, row) => checkValidData(cell),
    },
  ];

  const getTotalTimingDetailsData = () => {
    const totalTimingDetailsColumnValues = [
      {
        id: '1.',
        timing: 0,
        service: t('text.report.totalOnlineTime'),
      },
      {
        id: '2.',
        timing: 0,
        service: t('text.report.averageTimeSpentOnline'),
      }
    ];
    if (totalTimingDetails?.totalOnlineTime) {
      const time = totalTimingDetails?.totalOnlineTime || {};
      let timeString = '';
      if (time.days) timeString += `${time.days} day${time.days > 1 ? 's' : ''} `;
      if (time.hours) timeString += `${time.hours} hour${time.hours > 1 ? 's' : ''} `;
      if (time.minutes) timeString += `${time.minutes} minute${time.minutes > 1 ? 's' : ''} `;
      if (time.seconds) timeString += `${time.seconds} second${time.seconds > 1 ? 's' : ''} `;

      totalTimingDetailsColumnValues[0].timing = timeString.trim() || '-';
    }
    if (totalTimingDetails?.averageTimeSpentOnline) {
      const time = totalTimingDetails?.averageTimeSpentOnline || {};
      let timeString = '';
      if (time.days) timeString += `${time.days} day${time.days > 1 ? 's' : ''} `;
      if (time.hours) timeString += `${time.hours} hour${time.hours > 1 ? 's' : ''} `;
      if (time.minutes) timeString += `${time.minutes} minute${time.minutes > 1 ? 's' : ''} `;
      if (time.seconds) timeString += `${time.seconds} second${time.seconds > 1 ? 's' : ''} `;

      totalTimingDetailsColumnValues[1].timing = timeString.trim() || '-';
    }
    return totalTimingDetailsColumnValues.map((col) => ({
      ...col,
      timing: `${col.timing}`,
    }));
  };


  useEffect(() => {
    if (values) {
      setFilter({
        ...filterValue,
        onlineFromDate:
          values?.onlineFromDate ||
          (values?.dateType && moment().format('YYYY-MM-DD')),
        onlineToDate:
          values.onlineToDate || (values?.dateType && moment().format('YYYY-MM-DD')),
        dateType: values?.dateType,
      });
    }
  }, [values]);

  useEffect(() => {
    if (data) {
      setTotalTimingDetails(allData?.data ?? {});
    }
  }, [data]);

  return (
    <>
      <PageContainerWrapper
        heading={t('text.report.providerActivityReportList')}
        breadcrumb={breadcrumb}
        extraClass="btn btn-primary d-block d-xl-none"
      >
        {/* <div className="">
          <div className="globalFilters">
            <div className="card ">
              <div className="card-inner">
                <div
                  className={`globalFilters_inner ${globalFilter ? 'filterToggle' : ''
                    }`}
                >
                  <ServiceProviderStatusActivityFilterForm
                    data={true}
                    onSubmit={onFilterSubmit}
                    onReset={onReset}
                    filterValue={filterValue}
                    setValues={setValues}
                    values={values}
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="tableScrollHide">
          <TablePlus
            hasLimit
            noOfPage={noOfPages}
            sizePerPage={sizePerPage}
            page={page}
            count={count}
            tableData={data}
            tableColumns={columns}
            selectRow={true}
            statusAction={false}
            showAction={false}
            tableLoader={isLoading}
            onFilterResetChange={onTableResetFilter}
            filter={filter}
            sortBy={sortBy}
            sortType={sortType}
            scrollTopPosition
          />
        </div>

        {state?.loader === true ? (
          <div className="text-center">
            <Spin />
          </div>
        ) : (
          <>
            <div className="nk-block">
              <div className="card">
                <div className="card-inner">
                  <div className="common-table">
                    <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                      <Table
                        pagination={false}
                        showHeader={false}
                        loading={isLoading}
                        rowKey="id"
                        columns={totalTimingDetailsColumn}
                        dataSource={
                          Object.values(totalTimingDetails)?.length > 0
                            ? getTotalTimingDetailsData()
                            : []
                        }
                      />{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </PageContainerWrapper>
    </>
  );
}

export default SpStatusAnalysisReport;
