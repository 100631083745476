import {
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import i18next from 'i18next';
import {
  ButtonTextFormatter,
  checkValidData,
  correctFormate,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import {
  dateFormatter,
  formatPhoneNumber,
  phoneNumberFormate,
  readMoreTextShow,
  showDateInBrowser,
} from '../../utils';
import { getFromToDateFilter } from '../../components/Filter/Common/date.filter';
import { accessRoute } from '../../routes/Dashboard';
import { firstLetterCaps, getFullName } from '../../utils/text.util';
import { TeamSetting } from '../TeamSetting';
import { CustomersService } from '../Customers';
import { accessRoute as homeInventoryRoute } from '../../routes/HomeInventory';
import { generatePaths } from './serviceManagement.service';
import {
  getBrandService,
  getModelService,
  getQuoteTypeService,
  getSerialNoService,
} from '../index.service';
import { DATE_TH_MONTH_YEAR } from '../../config';
import moment from 'moment';

export const homeInventoryBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.homeInventory.title'),
    },
  ];
};
export const installedEquipmentImages = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: homeInventoryRoute.HOME_INVENTORY.path,
      name: i18next.t('text.homeInventory.title'),
    },
    {
      path: '#',
      name: i18next.t('text.homeInventory.installedEquipmentImage'),
    },
  ];
};

const getSerialData = () => {
  return [
    {
      name: 'Test1',
      value: 'Test1',
    },
    {
      name: 'Test2',
      value: 'Test2',
    },
  ];
};
const getPartTitleData = () => {
  return [
    {
      name: 'All',
      id: 'all',
    },
    {
      name: 'Residential-Single-Family',
      id: 'residential_single_family',
    },
    {
      name: 'Residential-Multi-Family',
      id: 'residential_multi_Family',
    },
    {
      name: 'Commercial',
      id: 'commercial',
    },
  ];
};
const getTypePurchaseData = () => {
  return [
    {
      name: 'All',
      id: 'all',
    },
    {
      name: 'Replacement',
      id: 'replacement',
    },
    {
      name: 'Add-on',
      id: 'add_on',
    },
    {
      name: 'New',
      id: 'new',
    },
    {
      name: 'Existing Equipment',
      id: 'existing_equipment',
    },
  ];
};
const getSystemConditionData = () => {
  return [
    {
      name: 'All',
      id: 'all',
    },
    {
      name: 'New',
      id: 'new',
    },
    {
      name: 'Above Average',
      id: 'above_average',
    },
    {
      name: 'Average',
      id: 'average',
    },
    {
      name: 'Below Average',
      id: 'below_average',
    },
    {
      name: 'Poor',
      id: 'poor',
    },
  ];
};

export const HomeInventoryTableColumn = ({
  onFilterChange,
  filter = {},
  page,
  sizePerPage,
  userRole,
  filterObj,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'providerCompleted',
      title: i18next.t('text.homeInventory.providerCompleted'),
      headerClasses: 'sorting',
      render: (cell, row) =>
        getFullName(
          row?.Booking?.Provider?.firstName,
          row?.Booking?.Provider?.lastName
        ),
      ...getMultiSelectSearchFilter({
        dataIndex: 'providerCompleted',
        onFilterChange,
        name: i18next.t('text.homeInventory.provider'),
        list: [],
        mode: 'multiple',
        // scope: 'all',
        searchOrNot: true,
        extraQuary:
          userRole !== 'nca' ? { providerNca: 'Good Guys Admin' } : {},
        listKeys: { nameKey: ['firstName', 'lastName'], nameId: 'id' },
        listService: TeamSetting?.providerListService,
        filter,
      }),
    },
    {
      dataIndex: 'createdAt',
      filterDataIndex: ['dateEquipmentFromDateAdded', 'dateEquipmentToAdded'],
      title: i18next.t('text.homeInventory.dateEquipment'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell) => showDateInBrowser(cell),
      ...getFromToDateFilter({
        dataStartIndex: 'dateEquipmentFromDateAdded',
        dataEndIndex: 'dateEquipmentToAdded',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY-MM-DD',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'customerName',
      title: i18next.t('text.homeInventory.customerName'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell, row) =>
        getFullName(
          row?.Booking?.Customer?.firstName,
          row?.Booking?.Customer?.lastName,
          '',
          row?.Booking?.Customer?.CustomerMembership?.MembershipPlan?.name
        ),
      ...getMultiSelectSearchFilter({
        dataIndex: 'customerName',
        onFilterChange,
        name: i18next.t('text.homeInventory.customerNamePlaceholder'),
        list: [],
        mode: 'multiple',
        // scope: 'all',
        searchOrNot: true,
        extraQuary: {
          userCompany: 'Good Guys Admin',
          isFilter: 1
        },
        listKeys: { nameKey: ['firstName', 'lastName'], nameId: 'id' },
        listService: CustomersService?.CustomersListService,
        filter,
        addExtraObj: filterObj,
      }),
    },
    {
      dataIndex: 'customerPhoneNumber',
      title: i18next.t('text.homeInventory.customerPhoneNumber'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell, row) => {
        const formatNumber = phoneNumberFormate(
          row?.Booking?.Customer?.phoneNumber,
          row?.Booking?.Customer?.countryPhoneCode
        );
        return formatNumber ?? '-';
      },
      ...getInputSearchFilter({
        dataIndex: 'customerPhoneNumber',
        placeholder: 'customer phone number',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'customerEmail',
      title: i18next.t('text.homeInventory.customerEmail'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell, row) => checkValidData(row?.Booking?.Customer?.email),
      ...getInputSearchFilter({
        dataIndex: 'customerEmail',
        placeholder: i18next.t('text.homeInventory.customerEmailPlaceholder'),
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'quoteType',
      title: i18next.t('text.homeInventory.equipmentInstalled'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell) => checkValidData(cell),
      ...getMultiSelectSearchFilter({
        dataIndex: 'quoteType',
        onFilterChange,
        name: i18next.t('text.homeInventory.equipmentInstalledPlaceholder'),
        mode: 'multiple',
        list: [],
        listKeys: { nameKey: 'quoteType', nameId: 'quoteType' },
        listService: getQuoteTypeService,
        filter,
      }),
    },
    {
      dataIndex: 'brand',
      title: i18next.t('text.homeInventory.brand'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell) => checkValidData(cell),
      ...getMultiSelectSearchFilter({
        dataIndex: 'brand',
        onFilterChange,
        name: i18next.t('text.homeInventory.brandPlaceholder'),
        list: [],
        mode: 'multiple',
        listKeys: { nameKey: 'brand', nameId: 'brand' },
        listService: getBrandService,
        filter,
      }),
    },
    {
      dataIndex: 'model',
      title: i18next.t('text.homeInventory.modal'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell) => checkValidData(cell),
      // ...getMultiSelectSearchFilter({
      //   dataIndex: 'model',
      //   onFilterChange,
      //   name: i18next.t('text.homeInventory.modelPlaceholder'),
      //   list: [],
      //   mode: 'multiple',
      //   listKeys: { nameKey: 'model', nameId: 'model' },
      //   listService: getModelService,
      //   filter,
      // }),
      ...getInputSearchFilter({
        dataIndex: 'model',
        placeholder: i18next.t('text.homeInventory.modelPlaceholder'),
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'serialNo',
      filterDataIndex: ['serial'],
      title: i18next.t('text.homeInventory.serial'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell) => checkValidData(cell),
      ...getMultiSelectSearchFilter({
        dataIndex: 'serial',
        onFilterChange,
        name: i18next.t('text.homeInventory.serialPlaceholder'),
        list: [],
        mode: 'multiple',
        listKeys: { nameKey: 'serialNo', nameId: 'serialNo' },
        listService: getSerialNoService,
        filter,
      }),
    },
    {
      dataIndex: 'installDate',
      filterDataIndex: ['installationFromDate', 'installationToDate'],
      title: i18next.t('text.homeInventory.installationDate'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell) => checkValidData(cell),
      ...getFromToDateFilter({
        dataIndex: 'equipmentPickupDate',
        dataStartIndex: 'installationFromDate',
        dataEndIndex: 'installationToDate',
        placeholderStartDate: 'From year',
        placeholderEndDate: 'To year',
        dateFormat: 'YYYY',
        onFilterChange,
        picker: 'year',
        filter,
      }),
    },
    {
      dataIndex: 'applicationType',
      title: i18next.t('text.homeInventory.applicationType'),
      headerClasses: 'sorting',
      sorter: false,
      // render: (cell) => checkValidData(cell),
      render: (cell, row) => checkValidData(correctFormate(cell)),
      ...getMultiSelectSearchFilter({
        dataIndex: 'applicationType',
        onFilterChange,
        name: i18next.t('text.homeInventory.applicationTypePlaceholder'),
        list: [],
        mode: false,
        listService: getPartTitleData,
        filter,
      }),
    },
    {
      dataIndex: 'purchaseType',
      filterDataIndex: ['typeOfPurchase'],
      title: i18next.t('text.homeInventory.typeOfPurchase'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell) => checkValidData(correctFormate(cell)),
      ...getMultiSelectSearchFilter({
        dataIndex: 'typeOfPurchase',
        onFilterChange,
        name: i18next.t('text.homeInventory.typeOfPurchasePlaceholder'),
        list: [],
        mode: false,
        listService: getTypePurchaseData,
        filter,
      }),
    },
    {
      dataIndex: 'systemCondition',
      title: i18next.t('text.homeInventory.systemCondition'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell) => checkValidData(correctFormate(cell)),
      ...getMultiSelectSearchFilter({
        dataIndex: 'systemCondition',
        onFilterChange,
        name: i18next.t('text.homeInventory.systemConditionPlaceholder'),
        list: [],
        mode: false,
        listService: getSystemConditionData,
        filter,
      }),
    },
    {
      dataIndex: 'systemNotes',
      filterDataIndex: ['note'],
      title: i18next.t('text.homeInventory.systemNotes'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell) =>
        readMoreTextShow(
          checkValidData(firstLetterCaps(cell)),
          i18next.t('text.homeInventory.systemNotes'),
          20
        ),

      ...getInputSearchFilter({
        dataIndex: 'note',
        placeholder: 'system notes',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'homeInventoryEquipmentImages',
      title: i18next.t('text.homeInventory.linksToPhotosUploaded'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell, row) =>
        ButtonTextFormatter(
          generatePaths(homeInventoryRoute.HOME_INVENTORY_VIEW.path, row.id),
          'View Images'
        ),
    },
  ];
};
