import i18next from 'i18next';
import { EditGeneralForm } from '../../components';
import { accessRoute } from '../../routes/Dashboard';
import {
  editCompanySettingGeneralService,
  editCompanySettingPaymentService,
  editCompanySettingEmailService,
  editCompanySettingAppearanceService,
  editCompanySettingSocialMediaService,
  editCompanySettingAppSettingService,
  editCompanySettingAppInstallationSettingService,
  editCompanySettingAppearanceNCAService,
  editCompanySettingEmailNCAService,
  editCompanyReportAnIssueSettingService,
  editCompanyReportAnIssueNCAService,
  editCompanyShoppingCartService,
  editCompanyVoiceAIService,
  editCompanyShoppingCartNCAService,
} from '../index.service';

export const companySettingBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.companySetting.title'),
    },
  ];
};

export const companySettingApiList = (type) => {
  return {
    general: editCompanySettingGeneralService,
    email:
      type !== 'nca'
        ? editCompanySettingEmailService
        : editCompanySettingEmailNCAService,
    appearance:
      type !== 'nca'
        ? editCompanySettingAppearanceService
        : editCompanySettingAppearanceNCAService,
    payment: editCompanySettingPaymentService,
    socialMedia: editCompanySettingSocialMediaService,
    appSettings: editCompanySettingAppSettingService,
    installationSettings: editCompanySettingAppInstallationSettingService,
    ReportAnIssue:
      type !== 'nca'
        ? editCompanyReportAnIssueSettingService
        : editCompanyReportAnIssueNCAService,
    shoppingCart:
    type !== 'nca'
      ? editCompanyShoppingCartService
      : editCompanyShoppingCartNCAService,
    VoiceAI:editCompanyVoiceAIService,
  };
};

export const tabContent = (
  { formData, onSubmit, isLoading, isGetLoading },
  userType,
  defaultKey,
  isShoppingPermission
) => {
  const tabs = [
    {
      name: i18next.t('text.common.email'),
      key: 'email',
      content: (
        <EditGeneralForm
          formData={formData}
          isLoading={isLoading === 'SUBMIT'}
          onSubmit={onSubmit}
          initKey="email"
          isGetLoading={isGetLoading}
          userType={userType}
          defaultKey={defaultKey}
        />
      ),
    },
    {
      name: i18next.t('text.common.appearance'),
      key: 'appearance',
      content: (
        <EditGeneralForm
          formData={formData}
          isLoading={isLoading === 'SUBMIT'}
          onSubmit={onSubmit}
          initKey="appearance"
          isGetLoading={isGetLoading}
          userType={userType}
          defaultKey={defaultKey}
        />
      ),
    },
  ];
  if (userType === 'nca') {
    return [
      ...tabs,
      {
        name: i18next.t('text.common.reportAnIssue'),
        key: 'ReportAnIssue',
        content: (
          <EditGeneralForm
            formData={formData}
            isLoading={isLoading === 'SUBMIT'}
            onSubmit={onSubmit}
            initKey="ReportAnIssue"
            isGetLoading={isGetLoading}
            open={false}
            userType={userType}
            defaultKey={defaultKey}
          />
        ),
      },
      (isShoppingPermission && {
        name: i18next.t('text.common.shoppingCart'),
        key: 'shoppingCart',
        content: (
          <EditGeneralForm
            formData={formData}
            isLoading={isLoading === 'SUBMIT'}
            onSubmit={onSubmit}
            initKey="shoppingCart"
            isGetLoading={isGetLoading}
            open={false}
            userType={userType}
            defaultKey={defaultKey}
          />
        ),
      }),
    ];
  }
  return [
    {
      name: i18next.t('text.common.general'),
      key: 'general',
      content: (
        <EditGeneralForm
          formData={formData}
          isLoading={isLoading === 'SUBMIT'}
          onSubmit={onSubmit}
          initKey="general"
          isGetLoading={isGetLoading}
          userType={userType}
          defaultKey={defaultKey}
        />
      ),
    },
    ...tabs,
    // {
    //   name: 'Payment',
    //   key: 'payment',
    //   content: (
    //     <EditGeneralForm
    //       formData={formData}
    //       isLoading={isLoading === 'SUBMIT'}
    //       onSubmit={onSubmit}
    //       initKey="payment"
    //     />
    //   ),
    // },
    {
      name: i18next.t('text.common.socialMedia'),
      key: 'socialMedia',
      content: (
        <EditGeneralForm
          formData={formData}
          isLoading={isLoading === 'SUBMIT'}
          onSubmit={onSubmit}
          initKey="socialMedia"
          isGetLoading={isGetLoading}
          userType={userType}
          defaultKey={defaultKey}
        />
      ),
    },
    {
      name: i18next.t('text.common.appSettings'),
      key: 'appSettings',
      content: (
        <EditGeneralForm
          formData={formData}
          isLoading={isLoading === 'SUBMIT'}
          onSubmit={onSubmit}
          initKey="appSettings"
          isGetLoading={isGetLoading}
          userType={userType}
          defaultKey={defaultKey}
        />
      ),
    },
    {
      name: i18next.t('text.common.reportAnIssue'),
      key: 'ReportAnIssue',
      content: (
        <EditGeneralForm
          formData={formData}
          isLoading={isLoading === 'SUBMIT'}
          onSubmit={onSubmit}
          initKey="ReportAnIssue"
          isGetLoading={isGetLoading}
          open={false}
          userType={userType}
          defaultKey={defaultKey}
        />
      ),
    },
    {
      name: i18next.t('text.common.shoppingCart'),
      key: 'shoppingCart',
      content: (
        <EditGeneralForm
          formData={formData}
          isLoading={isLoading === 'SUBMIT'}
          onSubmit={onSubmit}
          initKey="shoppingCart"
          isGetLoading={isGetLoading}
          open={false}
          userType={userType}
          defaultKey={defaultKey}
        />
      ),
    },
    {
      name: i18next.t('text.common.voiceAI'),
      key: 'VoiceAI',
      content: (
        <EditGeneralForm
          formData={formData}
          isLoading={isLoading === 'SUBMIT'}
          onSubmit={onSubmit}
          initKey="VoiceAI"
          isGetLoading={isGetLoading}
          open={false}
          userType={userType}
          defaultKey={defaultKey}
        />
      ),
    },
    // {
    //   name: 'Installation Settings',
    //   key: 'installationSettings',
    //   content: (
    //     <EditGeneralForm
    //       formData={formData}
    //       isLoading={isLoading === 'SUBMIT'}
    //       onSubmit={onSubmit}
    //       initKey="installationSettings"
    //     />
    //   ),
    // },
  ];
};

export const distanceUnitList = () => {
  return [
    {
      id: 'Miles',
      name: 'Miles',
    },
  ];
};

export const paymentMode = () => {
  return [
    {
      name: 'Card',
      id: 'card',
    },
  ];
};

export const paymentMethod = () => {
  return [
    {
      name: 'Stripe',
      id: 'stripe',
    },
  ];
};

export const providersCabRequestData = () => {
  return [
    {
      name: 'All (COMPETITIVE ALGORITHM)',
      value: 'all',
    },
    {
      name: 'Distance (Nearest 1st Algorithm)',
      value: 'distance',
    },
    {
      name: 'Time (FIFO Algorithm)',
      value: 'time',
    },
  ];
};
