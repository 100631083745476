import {
  getColumnSelectSearchProps,
  getInputSearchFilter,
} from '../../components';
import i18next from 'i18next';
import { accessRoute } from '../../routes/Dashboard';
import { accessRoute as manageRoute } from '../../routes/ManageLocation/index';
import { accessRoute as ncaAccessRoute } from '../../routes/AdminSetting';
import {
  camelCaseFormatter,
  checkValidData,
  checkValidPrice,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { getLocationFor, getStatus } from '../index.service';

export const geoLocationTableColumn = ({
  Action,
  onFilterChange,
  filter,
  Toggle,
  page,
  userType,
  id,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'name',
      filterDataIndex: ['search'],
      title: i18next.t('text.manageLocation.locationName'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'search',
        placeholder: i18next.t('text.manageLocation.locationName'),
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'countryName',
      title: i18next.t('text.common.country'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidData(row?.Country?.name),
      ...getInputSearchFilter({
        dataIndex: 'countryName',
        placeholder: i18next.t('text.common.country'),
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'locationFor',
      title: i18next.t('text.manageLocation.locationFor'),
      headerClasses: 'sorting',
      className: 'text-capitalize',
      sorter: true,
      render: (cell, row) => camelCaseFormatter(row?.locationFor),
      ...getColumnSelectSearchProps({
        dataIndex: 'locationFor',
        name: 'location for',
        onFilterChange,
        filter,
        mode: false,
        list: getLocationFor(),
      }),
    },
    {
      dataIndex: 'pullPermitCost',
      title: i18next.t('text.manageLocation.pullPermit'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => checkValidPrice(row?.pullPermitCost),
      ...getInputSearchFilter({
        dataIndex: 'pullPermitCost',
        placeholder: i18next.t('text.manageLocation.pullPermit'),
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.common.status'),
      headerClasses: 'sorting',
      sorter: true,
      render: (cell, row) => {
        return <Toggle isDisabled = {(userType === 'nca' && row?.createdById === id) ? false : true} row={row} cell={cell} />;
      },
      ...getColumnSelectSearchProps({
        dataIndex: 'status',
        name: 'status',
        onFilterChange,
        filter,
        mode: false,
        list: getStatus(),
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.localization.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        if(userType === 'nca' && row?.createdById === id) {
          return <Action row={row} cell={cell} />;
        }else if(userType === 'nca') {
          return  null;
        }else{
          return <Action row={row} cell={cell} />;
        }
      },
    },
  ];
};
export const breadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.manageLocation.heading'),
    },
  ];
};

export const breadcrumbAdd = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: manageRoute.MANAGE_LOCATION.path,
      name: i18next.t('text.manageLocation.heading'),
    },
    {
      path: '#',
      name: i18next.t('text.manageLocation.addLocation'),
    },
  ];
};

export const breadcrumbEdit = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: manageRoute.MANAGE_LOCATION.path,
      name: i18next.t('text.manageLocation.heading'),
    },
    {
      path: '#',
      name: i18next.t('text.manageLocation.editLocation'),
    },
  ];
};

export const locationForData = () => {
  return [
    {
      name: 'Service Type',
      value: 'serviceType',
    },
  ];
};

export const locationFilterData = () => {
  return [
    {
      name: 'All',
      value: 'all',
    },
    {
      name: 'Service Type',
      value: 'vehicleType',
    },
  ];
};

export const ncaAddGeoLocationBreadcrumb = (url) => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: ncaAccessRoute.LIST_NCA.path,
      name: i18next.t('text.common.nca'),
    },
    {
      path: url,
      name: i18next.t('text.common.editNCA'),
    },
    {
      path: '#',
      name: i18next.t('text.manageLocation.addLocation'),
    },
  ];
};

export const ncaEditGeoLocationBreadcrumb = (url) => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: ncaAccessRoute.LIST_NCA.path,
      name: i18next.t('text.common.nca'),
    },
    {
      path: url,
      name: i18next.t('text.common.editNCA'),
    },
    {
      path: '#',
      name: i18next.t('text.manageLocation.editLocation'),
    },
  ];
};
