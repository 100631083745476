import React, { forwardRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ChatContext } from '../../../context/chat.context';

import { Image, Spin } from 'antd';

import { firstLetterCaps } from '../../../utils/text.util';
import { CommonButton, ImageElement } from '../../../components';
import { copyToClipBoard, modalNotification } from '../../../utils';
import { Dropdown } from 'react-bootstrap';
import { t } from 'i18next';
import Linkify from 'linkify-react';

const ChatMessageList = forwardRef(
  (
    {
      messages,
      messagesLength,
      handleDownloadMedia,
      hasMoreFetch,
      fetchMoreRoomMessageList,
      isMessageListLoading,
      isOpenViewMediaModal,
      setIsOpenViewMediaModal,
      selectedViewMedia,
      resetSelectedViewMedia,
      handleOpenVideoModal,
      isFetchMoreLoading,
      selectUserDetails,
      newMessages,
      socket,
    },
    ref
  ) => {
    const { userDetails, isChatLoading } = useContext(ChatContext);
    const handleMessageDoubleClick = async (e) => {
      if (e.detail === 2 || e.detail === 3) {
        try {
          const selection = document.getSelection();
          const selectionText = selection.toString().trim();
          navigator.clipboard.writeText(selectionText);
          // e.detail === 3
          // ? selectionText
          // : selection.anchorNode.data.slice(
          //     selection.baseOffset,
          //     selection.extentOffset
          //   )
          setTimeout(() => {
            modalNotification({
              type: 'info',
              message: 'Copied',
            });
          }, 500);
        } catch (error) {
          console.log('error', error);
        }
      }
    };
    const handleMessageOnClick = async (messageDetails) => {
      copyToClipBoard(messageDetails?.message);
    };
    useEffect(() => {
      if (selectUserDetails?.chatRoom?.id === newMessages?.roomId) {
        socket.emit('read-message', {
          userId: userDetails?.id,
          roomId: newMessages?.roomId,
        });
      }
    }, [selectUserDetails, newMessages]);

    return (
      <div
        className="nk-chat-panel"
        id="scrollableDiv"
        style={{
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
        ref={ref}
      >
        {messages?.length > 0 ? (
          <InfiniteScroll
            dataLength={messagesLength}
            inverse={true}
            hasMore={hasMoreFetch}
            loader={<Spin style={{ display: 'flex', margin: 'auto' }} />}
            scrollableTarget="scrollableDiv"
            next={() =>
              !isFetchMoreLoading && hasMoreFetch
                ? fetchMoreRoomMessageList()
                : null
            }
            style={{
              display: 'flex',
              flexDirection: 'column-reverse',
              overflow: 'hidden',
            }}
          >
            {isMessageListLoading && (
              <Spin
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: 'auto',
                }}
              />
            )}
            {messages?.map((messageItem, idx) =>
              messageItem?.type ? (
                <div key={idx} className="chat-sap">
                  <div className="chat-sap-meta">
                    <span>{moment(messageItem?.date).format('ll')}</span>
                  </div>
                </div>
              ) : messageItem?.fromId === userDetails?.id ? (
                messageItem?.messageType === 'media' ? (
                  <div key={idx} className="chat is-me">
                    <div className="chat-content text-right">
                      <div className="chat-bubbles">
                        {['png', 'jpg', 'jpeg', 'gif'].includes(
                          (messageItem?.message || messageItem?.mediaName)
                            ?.split?.('.')
                            .pop()
                        ) ? (
                          <div className="text-right">
                            <Image
                              src={messageItem?.mediaImageUrl}
                              alt={`${messageItem?.mediaType}${idx}`}
                              loading="lazy"
                            />
                          </div>
                        ) : (
                          <div
                            disabled={isChatLoading}
                            onClick={() =>
                              handleOpenVideoModal(messageItem?.mediaImageUrl)
                            }
                            className="chatVideoLink text-right"
                          >
                            <video src={messageItem?.mediaImageUrl}></video>
                            <em className="icon ni ni-play-circle-fill"></em>
                          </div>
                        )}
                      </div>
                      <ul className="chat-meta">
                        <li>
                          <span>
                            {moment(messageItem?.createdAt).format('LT')}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div key={idx} className="chat is-me">
                    <div className="chat-content">
                      <div
                        className="chat-bubbles"
                        onClick={(e) => handleMessageDoubleClick(e)}
                      >
                        <div className="chat-bubble align-items-start">
                          <div className="text-end">
                            <Dropdown
                              onClick={(e) => {}}
                              // ref={ref}
                              className="position-static"
                            >
                              <Dropdown.Toggle as="a" className="btn btn-icon">
                                <em className="icon ni ni-more-v" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                align="end"
                                size="sm"
                                className="wide-xs"
                              >
                                <ul className="link-list-plain">
                                  {' '}
                                  <li className="action_list">
                                    <CommonButton
                                      onClick={() =>
                                        handleMessageOnClick(messageItem)
                                      }
                                    >
                                      <em className="icon ni ni-copy" />
                                      {t('text.common.copy')}
                                    </CommonButton>
                                  </li>{' '}
                                </ul>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <p
                            style={{
                              wordBreak: 'break-word',
                              whiteSpace: 'pre-line',
                            }}
                            className="chat-msg"
                            // onDoubleClick={() =>
                            //   handleDoubleClick(messageItem?.message)
                            // }
                            // onClick={(e) => handleMessageDoubleClick(e)}
                            // dangerouslySetInnerHTML={{
                            //   __html: textLinkToLinkConverter(
                            //     messageItem?.message,
                            //     true
                            //   ),
                            // }}
                          >
                            {' '}
                            <Linkify options={{ target: 'blank' }}>
                              {messageItem?.message}
                            </Linkify>
                            {/* {messageItem?.message} */}
                          </p>
                        </div>
                      </div>
                      <ul className="chat-meta">
                        <li>
                          <span>
                            {moment(messageItem?.createdAt).format('LT')}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                )
              ) : (
                <>
                  {messageItem?.messageType === 'media' ? (
                    <div key={idx} className="chat is-you">
                      <div className="d-flex">
                        <div className="chat-avatar">
                          <div className="user-avatar">
                            <span>
                              {messageItem?.User?.firstName
                                ?.slice?.(0, 1)
                                ?.toUpperCase()}
                              {messageItem?.User?.lastName
                                ?.slice?.(0, 1)
                                ?.toUpperCase()}
                            </span>
                          </div>
                        </div>
                        <div className="chat-content text-right mx-1">
                          <div className="chat-bubbles">
                            {['png', 'jpg', 'jpeg', 'gif'].includes(
                              (messageItem?.message || messageItem?.mediaName)
                                ?.split?.('.')
                                .pop()
                            ) ? (
                              <div className="text-right">
                                <Image
                                  src={messageItem?.mediaImageUrl}
                                  alt={messageItem?.mediaType}
                                  loading="lazy"
                                />
                              </div>
                            ) : (
                              <div
                                disabled={isChatLoading}
                                onClick={() =>
                                  handleOpenVideoModal(
                                    messageItem?.mediaImageUrl
                                  )
                                }
                                className="chatVideoLink text-right"
                              >
                                <video src={messageItem?.mediaImageUrl}></video>
                                <em className="icon ni ni-play-circle-fill"></em>
                              </div>
                            )}
                          </div>
                          <ul className="chat-meta">
                            <li>
                              {firstLetterCaps(
                                messageItem?.User?.firstName ?? ''
                              )}
                            </li>
                            <li>
                              {moment(messageItem?.createdAt)?.format('lll')}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div key={idx} className="chat is-you">
                      <div className="chat-avatar">
                        <div className="user-avatar overflow-hidden">
                          {messageItem?.User?.profilePictureThumbUrl ? (
                            <ImageElement
                              previewSource={
                                messageItem?.User?.profilePictureThumbUrl
                              }
                              alt={`${messageItem?.User?.firstName}${idx}`}
                              isProfile={true}
                            />
                          ) : (
                            <span>
                              {messageItem?.User?.firstName
                                ?.slice?.(0, 1)
                                ?.toUpperCase()}
                              {messageItem?.User?.lastName
                                ?.slice?.(0, 1)
                                ?.toUpperCase()}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="chat-content">
                        <div
                          className="chat-bubbles"
                          onClick={(e) => handleMessageDoubleClick(e)}
                        >
                          <div className="chat-bubble">
                            <div
                              className="chat-msg"
                              style={{
                                wordBreak: 'break-word',
                                whiteSpace: 'pre-line',
                              }}
                            >
                              <div
                              // onClick={(e) => handleMessageDoubleClick(e)}
                              // dangerouslySetInnerHTML={{
                              //   __html: textLinkToLinkConverter(
                              //     messageItem?.message,
                              //     false
                              //   ),
                              // }}
                              />
                              <Linkify options={{ target: 'blank' }}>
                                {messageItem?.message}
                              </Linkify>
                            </div>
                            <div className="text-end">
                              <Dropdown
                                onClick={(e) => {}}
                                // ref={ref}
                                className="position-static"
                              >
                                <Dropdown.Toggle
                                  as="a"
                                  className="btn btn-icon"
                                >
                                  <em className="icon ni ni-more-v" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  align="end"
                                  size="sm"
                                  className="wide-xs"
                                >
                                  <ul className="link-list-plain">
                                    {' '}
                                    <li className="action_list">
                                      <CommonButton
                                        onClick={() =>
                                          handleMessageOnClick(messageItem)
                                        }
                                      >
                                        <em className="icon ni ni-copy" />
                                        {t('text.common.copy')}
                                      </CommonButton>
                                    </li>{' '}
                                  </ul>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                        <ul className="chat-meta">
                          <li>
                            {firstLetterCaps(
                              messageItem?.User?.firstName ?? ''
                            )}
                          </li>
                          <li>
                            {moment(messageItem?.createdAt).format('lll')}
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </>
              )
            )}
          </InfiniteScroll>
        ) : null}
      </div>
    );
  }
);

ChatMessageList.propTypes = {
  messages: PropTypes.array.isRequired,
  messagesLength: PropTypes.number,
  hasMoreFetch: PropTypes.bool,
  fetchMoreRoomMessageList: PropTypes.func,
  handleDownloadMedia: PropTypes.func,
  handleScrollToBottom: PropTypes.func,
};

ChatMessageList.displayName = 'ChatMessageList';

export default ChatMessageList;
