import {
  LoginRoute,
  ncaRegisterRoute,
  getLoginRoutes,
  getRegisterRoutes,
} from './Auth/index';
import CustomerLogin from './Auth/customer';
import {
  DashboardRoute,
  getChildrenRoutes as dashboardRoutes,
} from './Dashboard/index';
import {
  DashboardSubRoute,
  getChildrenRoutes as dashboardSubRoute,
} from './Dashboard/dashboardSub';
// import { getChildrenRoutes as connectRoutes } from './GoogleConnect';
// import { getChildrenRoutes as equipmentRoutes } from './EquipmentOrder';

import {
  CustomerRoute,
  getChildrenRoutes as customerRoutes,
} from './Customers';
import {
  CommonRoutes,
  getChildrenRoutes as commonRoutes,
} from './CommonRoutes';
import { getChildrenRoutes } from './CustomerApp/scheduleServiceProvider';
import {
  ManualBookingRoute,
  getChildrenRoutes as manualBooking,
} from './ManualBooking';

import {
  ServiceManagement,
  getChildrenRoutes as serviceManagement,
} from './ServiceManagement';

import {
  MarketingRoute,
  getChildrenRoutes as marketingRoutes,
} from './Marketing';

import {
  ManageLocation,
  getChildrenRoutes as locationRoutes,
} from './ManageLocation';
import {
  CompanySettingRoute,
  getChildrenRoutes as companySettingRoutes,
} from './CompanySetting';
import {
  ManageSlotRoute,
  getChildrenRoutes as manageSlotRoute,
} from './ManageSlot';

import { LogViewRoute, getChildrenRoutes as logRoutes } from './Logger';
import { getChildrenRoutes as scheduleBooking } from './ScheduledBooking';
import {
  WillCallRoute,
  getChildrenRoutes as willCallRoutes,
} from './WillCallList';
import {
  LessonListRoute,
  getChildrenRoutes as lessonListRoute,
} from './LessonList';

import {
  GoogleConnect,
  getChildrenRoutes as connectRoutes,
} from './GoogleConnect';
import {
  EquipmentOrderRoute,
  getChildrenRoutes as equipmentRoutes,
} from './EquipmentOrder';
// import {
//   CustomerRoute,
//   getChildrenRoutes as customerRoutes,
// } from './Customers';
import {
  HomeInventoryRoute,
  getChildrenRoutes as inventoryRoutes,
} from './HomeInventory';

import {
  VoiceRecordsRoute,
  getChildrenRoutes as voiceRecordsRoute,
} from './VoiceAIRecords';

import {
  ChatbotQuestionAnswerRecordRoute,
  getChildrenRoutes as chatbotQuestionAnswerRecordRoute,
} from './ChatbotQuestionAnswerRecord';

import { NewEquipmentRoutes } from './NewEquipment';
import {
  SuperAdminRoute,
  getChildrenRoutes as superAdminRoutes,
} from './AdminSetting';
import {
  AdminLayout,
  LoginLayout,
  CustomerLoginLayout,
  CustomerLayout,
  CustomerPublicLayout,
  StoreLoginLayout,
} from '../layouts/index.layout';
import {
  AdminProfileRoute,
  getChildrenRoutes as adminProfileRoute,
} from './AdminProfile';

import {
  TeamSettingRoute,
  getChildrenRoutes as teamSettingRoutes,
} from './TeamSetting';
import {
  PriceManagementRoute,
  getChildrenRoutes as priceManagementRoutes,
} from './PriceManagement';

import { TagsRoute, getChildrenRoutes as tagsRoutes } from './Tags';

import {
  NotificationRoute,
  getChildrenRoutes as notificationRoutes,
} from './Notification';

import {
  SiteStatisticsRoute,
  getChildrenRoutes as siteStatisticsRoute,
} from './SiteStatistics';

import {
  EmailSettingRoute,
  getChildrenRoutes as emailSettingRoutes,
} from './EmailSetting';

import { RequestsRoute, getChildrenRoutes as requestsRoutes } from './Request';
import { getChildrenRoutes as newEquipmentRoutes } from './NewEquipment';

import { getFlatRoutes } from '../helpers/index.helper';
import { Reports, getChildrenRoutes as reports } from './Reports';
import { Navigate, Outlet } from 'react-router-dom';
import { ChatRoute, getChildrenRoutes as chatRoute } from './Chat';
import {
  TruckOrdering,
  getChildrenRoutes as truckOrdering,
} from './TruckOrdering';

import {
  OperatingProcedureRoute,
  getChildrenRoutes as operatingProcedureRoutes,
} from './OperatingProcedure';
import {
  NcaProfileRoute,
  getChildrenRoutes as ncaProfileRoute,
} from './NcaProfile';
import {
  NcaLogReport,
  getChildrenRoutes as ncaLogReportRoute,
} from './NcaLogReport';
import {
  ProviderInvoiceUpdateRoute,
  getChildrenRoutes as providerInvoiceUpdateRoute,
} from './ProviderInvoiceUpdate';
// import {
//   MembershipRoute,
//   getChildrenRoutes as membershipRoute,
// } from './Membership';

import {
  ShoppingCartRoutes,
  getChildrenRoutes as shoppingCartRoutes,
} from './ShoppingCart';
// import {
//   MembershipRoute,
//   getChildrenRoutes as membershipRoute,
// } from './Membership';

/****************************************
 *****************Customer Route******************
 * ******************** */
import customerHomeRoutes from './CustomerApp/home';
import customerBookingRoutes from './CustomerApp/booking';
import customerBookAppointmentRoutes from './CustomerApp/bookingAppointment';
import customerFinancingRoutes from './CustomerApp/financing';
import customerPaymentRoutes from './CustomerApp/payment';
import customerSearchProviderRoutes from './CustomerApp/searchProvider';
import customerTipsOfMonthRoutes from './CustomerApp/tipsOfMonth';
import customerRatingReviewRoutes from './CustomerApp/reviewRating';
import customerAskExpertRoutes from './CustomerApp/askTheExpert';
import customerScheduleBookingRoutes from './CustomerApp/scheduleServiceProvider';
import { NCALoginLayout, NcaStepLayout } from '../layouts/ncaLogin';
import termsRoutes from './CustomerApp/terms';
import customerPayOnlineGuestRoutes from './CustomerApp/payOnlineGuest';

/****************************************
 *****************Store Route******************
 * ******************** */
import { StoreLayout } from '../layouts/store';
import StoreRoute from './Auth/store';
import {
  FooterRoute,
  StoreHomeRoute,
  getChildrenRoutes as StoreRouteList,
} from './StoreApp';
import StepsRoutes, {
  getRegisterRoutes as StepsRoutesList,
} from '../routes/Auth/ncaRegisterSteps';
import PageNotFound from '../views/PageNotFound';
import MarketingUnSubscribe from '../views/Marketing/MarketingTemplate/MarketingUnsubscribe';
import DetectOs from '../components/DetectOs';
// import { FooterRoutes, getChildrenRoutes as footerRoutes } from './StoreApp';

const customerSidebarRoutes = [
  ...customerHomeRoutes(),
  ...customerBookingRoutes(),
  // ...customerBookingRoutes(),
  ...customerBookAppointmentRoutes(),
  ...customerPaymentRoutes(),
  // ...customerPaymentRoutes(),
  ...customerFinancingRoutes(),
  ...customerSearchProviderRoutes(),
  ...customerRatingReviewRoutes(),
  // ...customerReviewRatingRoutes(),
  ...customerTipsOfMonthRoutes(),
  ...customerScheduleBookingRoutes(),
  ...customerAskExpertRoutes(),
  ...customerPayOnlineGuestRoutes(),
];

const storeRoutes = [...StoreRoute(), FooterRoute()];

export const routes = () => {
  return [
    // {
    //   path: '/',
    //   element: <Navigate to="/home" />,
    // },
    {
      path: '/download',
      element: <DetectOs />,
    },
    {
      element: <CustomerLoginLayout />,
      children: [...CustomerLogin()],
    },
    {
      element: <CustomerPublicLayout />,
      children: [...termsRoutes()],
    },
    {
      element: <CustomerLayout />,
      children: customerSidebarRoutes,
    },
    {
      element: <NCALoginLayout />,
      children: [...ncaRegisterRoute()],
    },
    {
      element: <NcaStepLayout />,
      children: [...StepsRoutes()],
    },
    {
      element: <StoreLayout />,
      children: [...storeRoutes],
    },
    {
      element: <StoreLoginLayout />,
      children: [...StoreHomeRoute()],
    },
    {
      element: <LoginLayout />,
      children: [...LoginRoute()],
    },
    // {
    //   element: <Outlet />,
    //   children: [...LogViewRoute()],
    // },
    {
      path: '/admin',
      isParent: true,
      belongsToSidebar: true,
      element: <AdminLayout />,
      children: [
        ...DashboardRoute(),
        ...DashboardSubRoute(),
        ...NcaProfileRoute(),
        ...NcaLogReport(),
        ...ProviderInvoiceUpdateRoute(),
        ...TruckOrdering(),
        ...HomeInventoryRoute(),
        ...NewEquipmentRoutes(),
        ...EquipmentOrderRoute(),
        ...GoogleConnect(),
        ...WillCallRoute(),
        ...OperatingProcedureRoute(),
        ...ChatRoute(),
        ...SiteStatisticsRoute(),
        ...ManualBookingRoute(),
        ...CustomerRoute(),
        ...ManageSlotRoute(),
        ...scheduleBooking(),
        ...ServiceManagement(),
        ...Reports(),
        ...TeamSettingRoute(),
        ...MarketingRoute(),
        ...CompanySettingRoute(),
        ...EmailSettingRoute(),
        ...ManageLocation(),
        ...TagsRoute(),
        ...PriceManagementRoute(),
        ...NotificationRoute(),
        ...RequestsRoute(),
        ...SuperAdminRoute(),
        ...ShoppingCartRoutes(),
        ...AdminProfileRoute(),
        ...CommonRoutes(),
        // ...MembershipRoute(),
        // ...EquipmentOrderRoute(),
        // ...CustomerRoute(),
        // ...ServiceManagement(),
        ...VoiceRecordsRoute(),
        ...ChatbotQuestionAnswerRecordRoute(),
        ...LessonListRoute(),
        ...LogViewRoute(),
      ],
    },

    {
      path: '/page-not-found',
      element: <PageNotFound />,
    },
    {
      path: '*',
      element: <PageNotFound />,
    },
    {
      path: '/marketing-unsubscribe/:userId/:recipientId',
      element: <MarketingUnSubscribe />,
    },
  ];
};

export const getSidebarItems = () => {
  try {
    return routes().find((e) => e?.isParent).children;
  } catch (err) {
    console.log(err);
  }
};

export const getCustomerSidebarItems = () => {
  try {
    return [...customerHomeRoutes()[0].children, ...customerSidebarRoutes];
  } catch (err) {
    console.log(err);
  }
};

export const getStoreRoutesList = () => {
  try {
    return [...storeRoutes];
  } catch (err) {
    console.log(err);
  }
};

/********Get Auth Route for redirection if user try to access public page on login state********** */
export const getDefaultAuthRoute = (user, isLoggedIn) => {
  if (isLoggedIn) {
    if (user === 'admin') {
      return '/admin/dashboard';
    } else if (user === 'customer') {
      return '/customer/home';
    } else if (user === 'store') {
      return '/store/my-profile';
    }
  } else {
    if (user === 'admin') {
      return '/login';
    } else if (user === 'customer') {
      const companyCode = localStorage.getItem('companyCode')
      if(companyCode && companyCode !== "undefined" && companyCode !== "null"){
        return `/customer?code=${companyCode}`;
      }
      return '/customer';
    } else if (user === 'store') {
      return '/store';
    }
  }
};

/********Get Non Auth Route for redirection if user try to access private page on non login state********** */
export const getDefaultPublicRoute = (user) => {
  if (user === 'store') {
    return '/store';
  }
  return '/login';
};

export const getCustomerDefaultPublicRoute = () => {
  return '/customer';
};

export const getLoginRoute = (user) => {
  if (user === 'admin') {
    return '/login';
  } else if (user === 'customer') {
    return '/customer';
  } else if (user === 'store') {
    return '/store';
  }
};

/*******Define all nested routes which have routes path********** */
export const routesList = () => {
  return [
    ...StepsRoutesList(),
    ...dashboardRoutes(),
    ...dashboardSubRoute(),
    ...truckOrdering(),
    ...inventoryRoutes(),
    ...newEquipmentRoutes(),
    ...equipmentRoutes(),
    ...connectRoutes(),
    ...willCallRoutes(),
    ...operatingProcedureRoutes(),
    ...chatRoute(),
    ...siteStatisticsRoute(),
    // ...manualBooking(),
    ...customerRoutes(),
    ...manageSlotRoute(),
    ...scheduleBooking(),
    ...serviceManagement(),
    ...reports(),
    ...teamSettingRoutes(),
    ...marketingRoutes(),
    ...companySettingRoutes(),
    ...emailSettingRoutes(),
    ...locationRoutes(),
    ...tagsRoutes(),
    ...priceManagementRoutes(),
    ...notificationRoutes(),
    ...requestsRoutes(),
    ...superAdminRoutes(),
    ...shoppingCartRoutes(),
    // ...equipmentRoutes(),
    ...manualBooking(),
    ...getLoginRoutes(),
    ...getRegisterRoutes(),
    // ...membershipRoute(),
    ...adminProfileRoute(),
    ...logRoutes(),
    ...StoreRouteList(),
    ...ncaProfileRoute(),
    ...ncaLogReportRoute(),
    ...providerInvoiceUpdateRoute(),

    ...lessonListRoute(),
    ...StoreRoute(),
    ...getChildrenRoutes(),
    ...commonRoutes(),
    ...voiceRecordsRoute(),
    ...chatbotQuestionAnswerRecordRoute(),
  ];
};

export const moduleRoutesList = () => {
  let adminRouteArr = {
    admin: [...LoginRoute()[0].children, ...LoginRoute()[1].children],
  };
  return adminRouteArr;
};

export const getCompletePathList = () => {
  return getFlatRoutes(routesList());
};

export const getCustomerCompletePathList = () => {
  return getFlatRoutes([
    ...getCustomerSidebarItems(),
    ...CustomerLogin(),
    ...termsRoutes(),
  ]);
};
