import i18next from 'i18next';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import {
  capitalizeFirstLetter,
  readMoreTextShow,
  showDateInBrowser,
} from '../../utils';
import { CommonButton, getInputSearchFilter, getMultiSelectSearchFilter } from '../../components';
import { getFromToDateFilter } from '../../components/Filter/Common/date.filter';
import { Select } from 'antd';

export const reportAnIssueBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: i18next.t('text.report.reportAnIssue'),
    },
  ];
};

const IssueTypes = () => {
  return [
    {
      value: 'app',
      label: 'App',
    },
    {
      value: 'equipment',
      label: 'Equipment',
    },
    {
      value: 'supply_house',
      label: 'Supply House',
    },
    {
      value: 'payment',
      label: 'Payment',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ];
};

const phoneTypes = () => {
  return [
    {
      value: 'android',
      label: 'Android',
    },
    {
      value: 'ios',
      label: 'Ios',
    },
  ];
};

const IssueStatus = () => {
  return [
    {
      value: 'pending',
      label: 'Reporting',
    },
    {
      value: 'resolved',
      label: 'Resolved',
    },
    {
      value: 'unresolved',
      label: 'Unresolved',
    },
  ];
};

const IssueCurrentStatus = () => {
  return [
    {
      value: 'resolved',
      label: 'Resolved',
    },
    {
      value: 'open',
      label: 'Open',
    },
    {
      value: 'on_hold',
      label: 'On Hold',
    },
    {
      value: 'pending_third_party',
      label: 'Pending Third Party',
    },
  ];
};

const PriorityLevel = () => {
  return [
    {
      value: 'lowest',
      label: 'Lowest',
    },
    {
      value: 'low',
      label: 'Low',
    },
    {
      value: 'medium',
      label: 'Medium',
    },
    {
      value: 'high',
      label: 'High',
    },
    {
      value: 'highest',
      label: 'Highest',
    },
  ];
};

export const reportAnIssueColumn = ({
  onSort,
  page,
  options,
  sizePerPage,
  onFilterChange,
  filter,
  adminNoteModal,
  Action,
  handleChangeOption,
  saveUpdates,
  cancelUpdates,
  isUserCanEdit,
  issueAssign
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'createdAt',
      filterDataIndex: ['reportedFromDate', 'reportedToDate'],
      title: i18next.t('text.report.dateReported'),
      sorter: false,
      render: (cell) => showDateInBrowser(cell),
      ...getFromToDateFilter({
        dataStartIndex: 'reportedFromDate',
        dataEndIndex: 'reportedToDate',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY-MM-DD',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'User.firstName',
      filterDataIndex: ['reporter'],
      title: i18next.t('text.report.reporterPerson'),
      render: (cell, row) => checkValidData(`${row?.User?.firstName ?? ''} ${row?.User?.lastName ?? ''}`),
      ...getInputSearchFilter({
        filterDataIndex: ['reporter'],
        dataIndex: 'reporter',
        placeholder: 'reporter',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'typeOfIssue',
      title: i18next.t('text.report.issueType'),
      render: (cell, row) => {
        if (row?.typeOfIssue === 'supply_house') {
          return (
            <>Supply House</>
          )
        } else {
          return (
            <>{capitalizeFirstLetter(checkValidData(row?.typeOfIssue))}</>
          )
        }
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'typeOfIssue',
        onFilterChange,
        name: 'issue type',
        listKeys: { nameKey: 'label', nameId: 'value' },
        mode: false,
        listService: IssueTypes,
        filter,
      }),
    },
    {
      dataIndex: 'typeOfPhone',
      title: i18next.t('text.report.typeOfPhone'),
      render: (cell, row) =>
        capitalizeFirstLetter(checkValidData(row?.typeOfPhone)),
      ...getMultiSelectSearchFilter({
        dataIndex: 'typeOfPhone',
        onFilterChange,
        name: 'phone type',
        listKeys: { nameKey: 'label', nameId: 'value' },
        mode: false,
        listService: phoneTypes,
        filter,
      }),
    },
    {
      dataIndex: 'typeOfModel',
      title: i18next.t('text.report.typeOfModel'),
      render: (cell, row) => checkValidData(row?.typeOfModel),
      ...getInputSearchFilter({
        dataIndex: 'typeOfModel',
        placeholder: 'model',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'EquipmentLocation?.name',
      filterDataIndex: ['supplyHouse'],
      title: i18next.t('text.report.supplyHouse'),
      render: (cell, row) =>
        checkValidData(row?.EquipmentLocation?.name),
      ...getInputSearchFilter({
        filterDataIndex: ['supplyHouse'],
        dataIndex: 'supplyHouse',
        placeholder: 'supply house',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'issueDetail',
      filterDataIndex: ['issueConcern'],
      title: i18next.t('text.report.issueConcern'),
      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(row?.issueDetail),
          i18next.t('text.report.issueConcern'),
          20
        ),
      ...getInputSearchFilter({
        filterDataIndex: ['issueConcern'],
        dataIndex: 'issueConcern',
        placeholder: 'issue concern',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'issueStatus',
      title: i18next.t('text.report.issueStatusOnDateReported'),
      render: (cell, row) => {
        if (row?.issueStatus === 'pending') {
          return (
            <>Reporting</>
          )
        } else {
          return (
            <>{capitalizeFirstLetter(checkValidData(row?.issueStatus))}</>
          )
        }
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'issueStatus',
        onFilterChange,
        name: 'issue status',
        listKeys: { nameKey: 'label', nameId: 'value' },
        mode: false,
        listService: IssueStatus,
        filter,
      }),
    },
    {
      dataIndex: 'resolvedIssueDetail',
      filterDataIndex: ['resolvedDetail'],
      title: i18next.t('text.report.resolutionSuggestion'),
      render: (cell, row) => readMoreTextShow(
        checkValidData(row?.issueStatus === 'resolved' ? row?.resolvedIssueDetail : row?.notResolveIssueDetail),
        i18next.t('text.report.resolutionSuggestion'),
        20
      ),
      ...getInputSearchFilter({
        dataIndex: 'resolvedDetail',
        filterDataIndex: ['resolvedDetail'],
        placeholder: 'resolution/suggestion',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'issueStatus',
      title: i18next.t('text.report.imageOrVideoLinks'),
      render: (_, row) =>
        row?.ReportIssueMedia?.length > 0 ? (
          <Action row={row} cell={'Links'} />
        ) : (
          '-'
        ),
    },
    {
      dataIndex: 'issueCreatedBy',
      filterDataIndex: ['personIncharge'],
      title: i18next.t('text.report.issueIncharge'),
      render: (cell, row) => {
        if (row?.personIncharge) {
          return (
            <>
              {row?.personIncharge && `${row?.personInchargeUser?.firstName ?? ''} ${row?.personInchargeUser?.lastName ?? ''}`}{' '}
              <CommonButton
                disabled={!isUserCanEdit}
                onClick={() => issueAssign(row)}
                className="btn btn-primary btn-sm"
              > Change
              </CommonButton>
            </>
          );
        } else {
          return (
            <CommonButton
              disabled={!isUserCanEdit}
              onClick={() => issueAssign(row)}
              className="btn btn-primary btn-sm"
            > Assign
            </CommonButton>
          );
        }
      },
      ...getInputSearchFilter({
        dataIndex: 'personIncharge',
        placeholder: 'incharge person',
        filterDataIndex: ['personIncharge'],
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'adminNote',
      title: i18next.t('text.report.adminNote'),
      sorter: false,
      render: (cell, row) =>
        <CommonButton
          className="btn btn-primary"
          onClick={() => {
            adminNoteModal(row?.id, cell);
          }}
        >

          {cell
            ? i18next.t('text.report.editNote')
            : i18next.t('text.report.addNote')}
        </CommonButton>
    },
    {
      dataIndex: 'priorityOption',
      title: i18next.t('text.report.priorityLevel'),
      sorter: false,
      render: (cell, row) => {
        return (
          <Select
            id="priorityOption"
            size="medium"
            extraClassName="form-control"
            disabled={!isUserCanEdit}
            name="priorityOption"
            value={
              options?.[row?.id]?.priorityOption
                ? options[row?.id].priorityOption
                : row?.priorityOption ?? '-'
            }
            onSelect={(e) => handleChangeOption('priorityOption', e, row?.id)}
            variant="standard"
            placeholder="Select Level"
            options={PriorityLevel().map((item) => ({
              label: item.label,
              value: item.value,
            }))}
          />
        );
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'priorityOption',
        onFilterChange,
        name: 'priority option',
        listKeys: { nameKey: 'label', nameId: 'value' },
        mode: false,
        listService: PriorityLevel,
        filter,
      }),
    },
    {
      dataIndex: 'currentStatus',
      title: i18next.t('text.report.issueCurrentStatus'),
      sorter: false,
      render: (cell, row) => {
        return (
          <Select
            id="currentStatus"
            size="medium"
            extraClassName="form-control"
            disabled={!isUserCanEdit}
            name="currentStatus"
            value={
              options?.[row?.id]?.currentStatus
                ? options[row?.id].currentStatus
                : row?.currentStatus ?? '-'
            }
            onSelect={(e) => handleChangeOption('currentStatus', e, row?.id)}
            variant="standard"
            placeholder="Select status"
            options={IssueCurrentStatus().map((item) => ({
              label: item.label,
              value: item.value,
            }))}
          />
        );
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'currentStatus',
        onFilterChange,
        name: 'current status',
        listKeys: { nameKey: 'label', nameId: 'value' },
        mode: 'multiple',
        listService: IssueCurrentStatus,
        filter,
      }),
    },
    {
      dataIndex: 'resolvedDate',
      filterDataIndex: ['resolvedFromDate', 'resolvedToDate'],
      title: i18next.t('text.report.dateResolved'),
      sorter: false,
      render: (cell, row) => {
        if (options?.[row?.id]) {
          return (
            <>
              <CommonButton
                onClick={() => saveUpdates(row?.id)}
                className="btn btn-primary btn-sm"
              >
                {i18next.t('text.common.save')}
              </CommonButton>
              <CommonButton
                onClick={() => cancelUpdates(row?.id)}
                className="btn btn-light btn-sm ms-1"
              >
                {i18next.t('text.common.cancel')}
              </CommonButton>
            </>
          );
        } else {
          return (
            <>{row?.resolvedDate ? showDateInBrowser(cell) : '-'} </>
          );
        }
      },
      // render: (cell,row) => row?.resolvedDate ? showDateInBrowser(cell) : '-',
      ...getFromToDateFilter({
        dataStartIndex: 'resolvedFromDate',
        dataEndIndex: 'resolvedToDate',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY-MM-DD',
        onFilterChange,
        filter,
      }),
    },
  ];
};
