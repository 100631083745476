import React, { useEffect, useState } from 'react';
import { generatePath, Link, useNavigate, useParams } from 'react-router-dom';
import {
  AddEditLocationForm,
  NoteFooter,
  PageContainerWrapper,
} from '../../../../components';
import AddEditNCAForm from '../../../../components/Forms/ManageLocation/addEditLocationNca.form';
import { formSubmit } from '../../../../hooks/useFormSubmit.hook';
import { getApiData } from '../../../../hooks/useGetApi.hook';
import { accessRoute } from '../../../../routes/ManageLocation';
import { accessRoute as NCARoute } from '../../../../routes/AdminSetting';
import {
  breadcrumbEdit,
  ncaEditGeoLocationBreadcrumb,
} from '../../../../services/Columns';
import { backToPage } from '../../../../utils';
import Map from '../../../../components/Map/index';
import { useTranslation } from 'react-i18next';
import { getGeneralSetting } from '../../../../services/Common/index.service';
import {
  editManageLocationService,
  ViewManageLocationService,
  editNCALocationService,
} from '../../../../services/GeoLocation';
import {
  GOOGLE_MAPS_API_KEY,
  GOOGLE_MAP_URL,
  MANAGE_LOCATION_VIDEO,
} from '../../../../config';
import { useSelector } from 'react-redux';
// import videoWrap from '../../../../assets/video/manage_location.mp4';

function EditGeoLocation({ editNca = false }) {
  let { id } = useParams();
  const { t } = useTranslation();
  const userType = useSelector((state) => state?.auth?.userData?.Role?.type);
  const [formData, setFormData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const googleMapApiKey = useSelector((state) => state?.googleMap?.apiKey);

  const url =
    editNca &&
    formData?.userId &&
    `${generatePath(NCARoute?.EDIT_NCA.path, {
      id: formData?.userId,
    })}?tab=geoFenceLocation`;

  const breadcrumbData = editNca
    ? ncaEditGeoLocationBreadcrumb(url)
    : breadcrumbEdit();
  const [state, setState] = useState({ mapKey: '', loading: true });
  const [location, setLocation] = useState({
    lat: null,
    lng: null,
    latLng: null,
  });
  const [address, setAddress] = useState(null);
  const [oldAddress, setOldAddress] = useState({ lat: null, lng: null });

  useEffect(() => {
    getApiKeys();
  }, []);

  async function getApiKeys() {
    try {
      const res = await getGeneralSetting({
        queryParams: { type: 'installation_setting' },
      });
      if (Array.isArray(res?.data?.rows)) {
        const mapKey = res?.data?.rows?.find(
          (e) => e?.key === 'google_server_key'
        );
        if (mapKey?.value) {
          setState({ ...state, mapKey: mapKey?.value, loading: false });
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getApiData({
      id,
      service: ViewManageLocationService,
      setData: setFormData,
    });
  }, [id]);

  const redirectUrl = !editNca ? accessRoute.MANAGE_LOCATION.path : url;

  useEffect(() => {
    if (formData?.latLong && formData?.latLong?.coordinates) {
      setOldAddress({
        lat: formData?.latLong?.coordinates[0]?.map((e) => e[0]).join(),
        lng: formData?.latLong?.coordinates[0]?.map((e) => e[1]).join(),
      });

      if (formData?.latLong) {
        setLocation({ ...location, latLng: formData?.latLong?.coordinates });
        setAddress(formData?.latLong);
      } else {
        setAddress(formData?.Country?.name);
      }
    }
  }, [formData]);

  async function onFormSubmit(values) {
    try {
      const payload = { ...values };

      if (Array.isArray(location?.latLng) && location?.latLng?.length > 1) {
        payload.latLong = [...location?.latLng];
      } else {
        payload.latLong = location?.latLng?.pop();
      }

      const res = await editNCALocationService({ values: payload, id });
      if (res?.success) {
        navigate(redirectUrl);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <PageContainerWrapper
        breadcrumb={breadcrumbData}
        btnArray={['extraButton']}
        extraBtnText={t('text.common.back')}
        extraBtnClass="btn btn-outline-light bg-white"
        onExtraButtonHandleShow={() => backToPage(navigate, redirectUrl)}
        heading={t('text.manageLocation.editLocation')}
      >
        <div className="nk-block">
          <div className="card">
            <div className="card-inner">
              <div className="row">
                <div className="col-lg-6">
                  {userType !== 'nca' ? (
                    <AddEditLocationForm
                      formData={formData}
                      onSubmit={(values) =>
                        formSubmit({
                          values: {
                            ...values,
                            userId: formData?.userId ?? null,
                            latLong:
                              Array.isArray(location?.latLng) &&
                              location?.latLng?.length > 1
                                ? [...location?.latLng]
                                : location?.latLng?.pop(),
                          },
                          service: editManageLocationService,
                          navigate,
                          redirectUrl,
                          setIsLoading,
                          id,
                        })
                      }
                      setAddress={setAddress}
                      isLoading={isLoading}
                      backToPage={() => backToPage(navigate, redirectUrl)}
                    />
                  ) : (
                    <AddEditNCAForm
                      formData={formData}
                      onSubmit={onFormSubmit}
                      setAddress={setAddress}
                      isLoading={isLoading}
                      backToPage={() => backToPage(navigate, redirectUrl)}
                    />
                  )}
                  <NoteFooter
                    title={`${t('text.common.notes')} :`}
                    card={false}
                    customIcon={true}
                  >
                    <li>
                      <img
                        src="/assets/images/admin/select.svg"
                        className="p-0"
                        alt="select"
                      />
                      {t('text.manageLocation.noteListOne')}
                    </li>
                    <li>
                      <img
                        src="/assets/images/admin/polygon.svg"
                        className="p-0"
                        alt="polygon"
                      />
                      {t('text.manageLocation.noteListTwo')}
                    </li>
                    <li>
                      {' '}
                      <img
                        src="/assets/images/admin/chart.svg"
                        className="p-0"
                        alt="lines"
                      />
                      {t('text.manageLocation.noteListThree')}
                    </li>
                  </NoteFooter>
                </div>
                {/* Pending map  */}
                <div className="col-lg-6 mt-3 mt-lg-0">
                  <p className="mb-0">
                    <b>
                      Draw Location Point Here In Map :
                      <span className="text-danger">*</span>
                    </b>
                  </p>
                  <p>
                    Please select the area by putting the points around it.
                    Please
                    <a
                      href={MANAGE_LOCATION_VIDEO}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {' '}
                      click here{' '}
                    </a>{' '}
                    to view how to select the area and add it.
                  </p>
                  <div className="card card-bordered w-100">
                    {false ? (
                      <>Loading Map</>
                    ) : (
                      <>
                        <Map
                          oldPolygon={oldAddress}
                          polygonBound={location}
                          address={address}
                          setPolygon={setLocation}
                          googleMapURL={GOOGLE_MAP_URL(
                            googleMapApiKey || GOOGLE_MAPS_API_KEY,
                            ['drawing', 'places']
                          )}
                          loadingElement={<div style={{ height: `100%` }} />}
                          containerElement={<div style={{ height: `480px` }} />}
                          mapElement={
                            <div style={{ height: `calc(100% - 62px)` }} />
                          }
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainerWrapper>
    </>
  );
}

export default EditGeoLocation;
