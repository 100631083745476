export const text = {
  dashboard: 'Dashboard',
  registeredUsers: 'Registered Users',
  heatmap: 'Heatmap',
  currentProviderStatus: 'Current Provider Status',
  emergencyServiceResponseTimeAverage:
    ' Emergency Service Response Time Average',
  shortestTime: 'Shortest Time',
  oneSecond: '06s',
  longestTime: 'Longest Time',
  activeInactiveProviders: 'Active / Inactive Providers',
  viewAll: 'View All',
  active: 'Active',
  inActive: 'Inactive',
  lastWeek: 'vs. last week',
  providerRatingAverage: 'Provider Rating Average',
  registeredProviders: ' Registered Providers',
  sales: 'Sales',
  lastYear: '(Last Year)',
  salesTotal: 'Sales Total',
  scheduledSales: 'Scheduled Sales',
  jobCount: 'Job Count',
  payment: 'Payment',
  invoiceTotal: 'Invoice Total',
  paymentMethod: 'Payment Method',
  percentage: 'Percentage',
  serviceCategory: 'Service Category',
  quantity: 'Quantity',
  serviceTypeBooked: 'Service Type Booked',
  averageTicket: 'Average Ticket',
  estimates: 'Estimates',
  providerSalesLeaders: ' Provider Sales Leaders',
  leader: 'Leader',
  invoiceAmount: 'Invoice Amount',
  home: 'Home',
  sno: 'S.No.',
  latestJobs: 'Latest Jobs',
  QANCA: 'QA NCA',
  HVAC: 'HVAC',
  averageTicketLeaderboard: 'Average Ticket Leaderboard',
  invoiceAmountJobCount: 'Invoice Amount/Job Count',
  promoCode: 'Promo Code',
  promoCodeName: 'Promo Code Name',
  availableProviders: 'Available Providers',
  superAdminSetting: 'Super Admin Setting',
  customerAppSetting: 'Customer App Setting',
  notificationTemplates: 'Notification Templates',
  noRecordFound: 'No Record Found',
  addCity: 'Add City',
  addCountry: 'Add Country',
  cancel: 'Cancel',
  addState: 'Add State',
  submit: 'Submit',
  add: 'Add',
  edit: 'Edit',
  countryName: 'Country Name',
  countryNamePlace: 'Enter country name',
  country: 'Country',
  utility: 'utility',
  localization: 'Localization',
  stateName: 'State Name',
  state: 'State',
  cityName: 'City Name',
  locationDeleteSuccess: 'Location successfully deleted',
  status: 'Status',
  selectStatus: ' Select Status',
  countryCode: 'Country Code',
  countryCodePlace: 'Enter country code ',
  stateCode: 'State Code',
  cityCode: 'City Code',
  countryPhoneCode: 'Country Phone Code',
  countryPhoneCodePlace: 'Enter country phone code',
  unit: 'Unit',
  unitPlace: 'Enter unit',
  banner: 'Banner',
  selectCountry: 'Select country',
  selectCity: 'Select city',
  selectState: 'Select state',
  emergencyNumber: 'Emergency number',
  emergencyNumberPlace: 'Enter emergency number',
  selectAction: 'Select Action',
  reset: 'Reset',
  filter: 'Filter',
  select: 'Select',
  show: 'Show',
  cgst: 'Cgst (IN %)',
  cgstPlace: 'Enter tax',
  serialNo: 'S.No.',
  action: 'Action',
  appText: 'App Text',
  id: 'Id',
  name: 'Name',
  fName: 'First Name',
  lName: 'Last Name',
  mobile: 'Mobile',
  password: 'Password',
  cpassword: 'Confirm Password',
  forgetPasswordHead: 'Forgot Password',
  address: 'Address',
  landmark: 'Landmark',
  city: 'City',
  zipCode: 'Zip Code',
  zipCodePlaceholder: 'Zip code',
  update: 'Update',
  signUp: 'Sign Up',
  signIn: 'Login',
  areYouSure: 'Are you sure',
  warning: 'warning',
  deleteThisLocations: 'You want to delete this locations',
  delete: 'Delete',
  back: 'Back',
  notes: 'Notes',
  saveNext: 'Save',
  editCity: 'Edit City',
  countryDeletedSuccessfully: 'Country Deleted Successfully',
  cityDeletedSuccessfully: 'City Deleted Successfully',
  stateDeletedSuccessfully: 'State Deleted Successfully',
  areYouSureDeleteCity: 'you want to delete this City',
  areYouSureDeleteCountry: 'you want to delete this Country',
  reYouSureDeleteCity: 'Are you sure to delete this city?',
  areYouSureDeleteBankOption: 'Are you sure to delete this bank option?',
  areYouSureDeleteState: 'you want to delete this State',
  EnglishTitle: 'English Purpose',
  content: 'English Body',
  image: 'Image',
  title: 'Title',
  bannerOrder: 'Banner Order',
  order: 'Order',
  months: 'Months',
  notificationTitle: 'Notification Purpose',
  notificationMessage: 'Notification Message',
  editHomeContent: 'Edit Home Content',
  homePageContent: 'Home Page Content',
  areYouSureDeleteCustomer: 'you want to delete this Customer',
  customerDeletedSuccessfully: 'Customer Deleted Successfully',
  gender: 'Gender',
  profilePicture: 'Profile picture',
  noOfMonth: 'Number of month',
  membership: 'Membership',
  phoneNumber: 'Phone Number',
  phoneNumberPlaceholder: 'Phone number',
  email: 'Email',
  selectMonth: 'Select month',
  selectMembership: 'Select membership',
  rating: 'Rating',
  registrationDate: 'Registration Date',
  fee: 'Fee',
  availability: 'Availability',
  unavailability: 'Unavailability',
  selectDate: 'Select Date',
  saveChanges: 'Save Changes',
  fromDate: 'From Date',
  toDate: 'To Date',
  price: 'Price',
  description: 'Description',
  enterYourLocation: 'Enter your location',
  otherMilesRadius: 'Enter Other Miles Radius',
  customerTag: 'Customer Tag',
  jobTag: 'Job Tag',
  customerNotes: 'Customer Notes',
  businessPhoneNumber: 'Business Phone Number',
  enterDescription: 'Enter description',
  enterPrice: 'Enter Price',
  enterName: 'Enter name',
  cost: 'Cost',
  displayOrder: 'Display Order',
  quoteType: 'Quote Type',
  selectDisplayOrder: 'Select Display Order',
  equalTo: 'Equal To',
  and: 'And',
  or: 'Or',
  greaterEqualTo: 'Greater Than Equal To',
  lessEqualTo: 'Less Than Equal To',
  amount: 'Amount',
  icon: 'Icon',
  subCategories: 'Sub Categories',
  location: 'Location',
  selectLocation: 'Select Location',
  category: 'Category',
  categoryDescription: 'Category Description',
  subCategory: 'Subcategory',
  areYouSureDelete: 'Are you sure to delete this?',
  selectSubCategory: 'Select Subcategory',
  subcategoryPrice: 'Subcategory Price',
  enterSubcategoryPrice: 'Enter subcategory price',
  subcategoryDescription: 'Subcategory Description',
  enterSubcategoryDescription: 'Enter subcategory description',
  logo: 'Logo',
  selectLogo: 'Select Logo',
  autoTag: 'Auto Tag',
  selectCustomerTag: 'Select Customer Tag',
  imageDimensionMessage: 'Please upload only png image size of 360px*360px.',
  notification: 'Notification',
  UserName: 'User Name',
  serviceType: 'Service Type',
  providerName: 'Provider Name',
  dateTime: 'Date & Time',
  subject: 'Subject',
  enterSubject: 'Enter subject',
  body: 'Body',
  enterBody: 'Enter body',
  purpose: 'Purpose ',
  enterPurpose: 'Enter purpose',
  companyName: 'Company Name',
  industryType: 'Industry Type',
  brand: 'Brand',
  directional: 'Directional',
  seriesName: 'Series Name',
  benefits: 'Benefits',
  imageName: 'Image Name',
  enterCompanyName: 'Enter company name',
  enterIndustryType: 'Enter industry type',
  enterBrand: 'Enter brand',
  enterDirectional: 'Enter directional',
  wifi: 'Wifi',
  enterWifi: 'Enter wifi',
  modelStatus: 'Model Status',
  enterModelStatus: 'Enter model status',
  manufacturerType: 'Manufacturer Type',
  enterManufacturerType: 'Enter manufacturer type',
  phase: 'Phase',
  enterPhase: 'Enter phase',
  enterSeriesName: 'Enter series name',
  soldIn: 'Sold in?',
  enterSoldIn: 'Enter sold in?',
  enterRegion: 'Enter region',
  region: 'Region',
  enterBenefits: 'Enter benefits',
  viewDetails: ' View Details',
  services: 'Services',
  bookingLog: 'Booking Log',
  date: 'Date',
  log: 'Log',
  scheduleBookingNo: ' Schedule Booking No',
  jobStatus: 'Job Status',
  provider: 'Provider',
  totalCost: 'Total Cost',
  lastUpdatedSave: 'Last Updated/Save',
  model: 'Model',
  close: 'Close',
  jobNo: 'Job No',
  yes: 'Yes',
  no: 'No',
  graph: 'Graph',
  user: 'User',
  addCustomer: 'Add Customer',
  doHaveAcc: 'Already have an account ?',
  iAgreeWith: 'I agree with',
  resetPassword: 'Reset Password',
  tnc: 'Terms and Conditions',
  login: 'Login',
  forgetPassword: 'Forgot Password?',
  bookOnline: 'Book Online',
  resetPasswordNote1: 'Submit Email or Mobile Number to reset your password.',
  resetPasswordNote2:
    "Looks like you are a returning customer or we have added your information in the past. Let's reset your password.",
  addressLine1: 'Address Line 1',
  addressLine2: 'Address Line 2',
  suiteNumber: 'Suite Number',
  save: 'Save',
  enterCost: 'Enter cost',
  startDate: 'Start Date',
  endDate: 'End Date',
  selectStartDate: 'Select start date',
  selectEndDate: 'Select end date',
  myProfile: 'My Profile',
  currentPassword: 'Current Password',
  oldPassword: 'Confirm Password',
  newPassword: 'New Password',
  apply: 'Apply',
  changePassword: 'Change Password',
  upload: 'Upload',
  imageOnlyMessgae: 'Please add either a JPEG file',
  addProvider: 'Add Provider',
  areYouSureDeleteOptionalItem: 'you want to delete this Optional Items',
  imageSizeExceedingMessage: 'File size should not be more than 5 mb',
  optionalItemDeletedSuccessfully: 'Quote optional item deleted successfully',
  siteStatics: 'Site Statistics',
  nca: 'NCA',
  roles: 'Roles',
  companyCode: 'Company Code',
  profileStatus: 'Profile Status',
  planStatus: 'Plan Status',
  paymentReport: 'Payment Report',
  viewEditDocument: 'View/Edit Documents',
  ncaLink: 'NCA Link',
  ncaName: 'NCA Name',
  numberOfficeStaff: 'Number Of Office Staff',
  numberFieldTechniciansInstallers: 'Number Of Field Technicians/Installers',
  editNca: 'Edit NCA',
  goLogReport: 'Go to Log Report',
  ncaDetails: 'NCA Details',
  ncaProfile: 'NCA Profile',
  setUpCompanyProfile: 'Set Up Company Profile',
  setUpCardDetails: 'Setup Card Details',
  setUpBusinessDetails: 'Setup Business Details',
  setUpBankingDetails: 'Setup Banking Details',
  sendEmail: 'Send Email',
  jobId: 'Job ID',
  amountCollected: 'Amount Collected',
  typePayment: 'Type of payment',
  transactionFee: 'Transaction Fee',
  totalPayAmount: 'Total Pay Amount',
  paymentStatus: 'Payment Status',
  paymentSettleStatus: 'Payment Settle Status',
  selectAll: 'Select All',
  paymentTransferStatus: 'Payment Transfer Status',
  document: 'Document',
  editNCA: 'Edit NCA',
  enterFirstName: 'Enter First Name',
  enterLastName: 'Enter Last Name',
  enterPhoneNumber: 'Enter Phone Number',
  enterEmail: 'Enter Email',
  companyAddress: 'Company Address',
  enterCompanyAddress: 'Enter company address',
  enterNumberOfficeStaff: 'Enter number of office staff',
  enterNumberFieldTechniciansInstallers:
    'Enter number of field technicians/installers',
  areaServed: 'Area Served (Enter zip codes or cities) ',
  enterAreaServed: "Seperate each with comma','",
  role: 'Role',
  enterRole: 'Enter Role',
  paymentMode: 'Payment Mode',
  enterPaymentMode: 'Enter Payment Mode',
  cardHolder: 'Card Holder',
  enterCardHolder: 'Enter CVV',
  cardNumber: 'Card Number',
  enterCardNumber: 'Enter card number',
  expMonth: 'Exp. Month',
  selectExpMonth: 'Select month',
  expYear: 'Exp. Year',
  selectExpYear: 'Select year',
  industry: 'Industry',
  ownershipType: 'Ownership Type',
  employerIdentification: 'Employer Identification',
  regBusinessAddress: 'Reg. Business Address',
  doingBusinessAs: 'Doing Business As',
  businessWebsite: 'Business Website',
  representativeOwnerCEO: 'Representative, Owner, CEO',
  dob: 'Date of birth',
  SSNLastFour: 'SSN last four',
  homeAddress: 'Home Address',
  businessTaxID: 'Business Tax ID',
  documentFront: 'Document Front',
  businessVatID: 'Business VAT ID',
  documentBack: 'Document Back',
  bankName: 'Bank Name',
  enterBankName: 'Enter bank name',
  bankAccountHolderName: 'Bank Account Holder Name',
  enterBankAccountHolderName: 'Enter bank account holder name',
  orderNo: 'Order No',
  orderDate: 'Order Date',
  partTitle: 'Part Title',
  partNumber: 'Part Number',
  partCost: 'Part Cost',
  adminOrder: 'Admin Order',
  supplyHouse: 'Supply House',
  removeParts: 'Remove Parts',
  orderParts: 'Order Parts',
  youSure: 'Are you sure',
  orderSelectedPart: 'You want to order selected part ?',
  removeSelectedPart: 'You want to remove selected part ?',
  totalPartsAmount: 'Total Parts Amount',
  customer: 'Customer',
  selectViewEditPlaceholder: 'Select View/Edit',
  locationPlaceholder: 'Select location',
  addExistingCategory: 'Add Existing Category',
  selectLocationPlaceholder: 'Select location',
  selectCategory: 'Select category',
  customerName: 'Customer Name',
  customerPhoneNumber: 'Customer Phone Number',
  emailOrMobile: 'Mobile Number / Email',
  addedBy: 'Added By ',
  oldPasswordText: 'Old Password',
  copy: 'Copy',
  youWantToSave: 'you want to save',
  cvv: 'CVV',
  note: 'Note',
  oneTimeFile:
    'Please fill the form carefully because the form cannot be updated after successful submission.',
  enterConfirmPassword: 'Enter confirm password',
  iAgree: 'I agree to the',
  termsService: 'Terms of service',
  privacyPolicy: 'Privacy policy',
  saveEndNext: 'Save & Next',
  createYourProfile: 'Create your profile',
  alreadyRegistered: 'Already Registered',
  setUpYourCompanyProfile: 'Set Up Your Company Profile  ',
  selectRole: 'Select role',
  selectDuration: 'Select duration',
  creditCardInfo: 'Credit Card Info',
  permissionThisRole: 'Permission for this role',
  skipCompleteLater: 'Skip & Complete Later',
  companyAdmin: 'Company Admin',
  companyAdminSubTitle:
    'Access the Good Guys panel using your email and password.',
  enterYourEmail: 'Enter your email address',
  enterYourPassword: 'Enter your password',
  forgotPassword: 'Forgot Password',
  notHaveAccount: `Don't have an account?`,
  companyAdministratorEmail: 'Company Administrator Email',
  setUpYourBankingDetails: 'Setup Your Banking Details',
  awesome: 'AWESOME',
  awesomeSubtitle:
    'You are well on your way. We are excited you have decided to signup. You will receive a confirmation email that confirms we have received your information.',
  thankYouNot: 'THANK YOU!',
  awesomeSubtitleTwo:
    'In the email you will have a link to click on to schedule a time and finalize your account.',
  Ok: 'Ok',
  search: 'Search',
  pageNotFound: 'Page Not Found',
  WeAreSorry: "We're sorry, the page you requested could not be found.",
  goBack: 'Please go back to the homepage.',
  backToHome: 'Back To Home',
  copyCompanyCode: 'COPY COMPANY CODE',
  copyLink: 'COPY LINK',
  admin: 'Admin',
  editSubAdmin: 'Edit SubAdmin',
  profile: 'Profile',
  pendingTitle: 'Pending Required Procedure',
  completed: 'Completed',
  NoDataFound: 'No data found.',
  deleteRecordTitle: 'Delete Records?',
  goToDashboard: 'Go To Dashboard',
  clickHere: 'Click Here',
  // startDate: 'From date',
  // endDate: 'To date',
  ncaAddressLine: 'Address Line ',
  businessVatId: 'Business VAT ID',
  businessTaxId: 'Business Tax ID',
  dateOfBirth: 'Date of birth',
  doingBusinessAss: 'Doing Business As',
  westfield: 'Westfield',
  businessDetails: 'Business Details',
  accountNumber: 'Account Number',
  bankLocation: 'Bank Location',
  acHolderName: 'A/C Holder Name',
  routingNumber: 'Routing Number',
  emailAddress: 'Email Address',
  bankDetails: 'Bank Details',
  bookNow: 'Book Now',
  enter: 'Enter',
  areYouSureBanner: 'you want to delete this Banner',
  recoverPassword: 'Recover Password',
  send: 'Send',
  enterPassword: 'Enter password',
  phone: 'Phone',
  enterPhone: 'Enter Phone',
  addAction: 'Tags Action',
  saveUploadedData: 'Save uploaded data',
  discardEndBack: 'Discard & Back',
  totalRecords: 'Total Records',
  recordInserted: 'Record Inserted',
  recordUpdated: 'Record Updated',
  ok: 'OK',
  importRecord: 'Import Records',
  goodGuysAdmin: 'Good Guys Admin',
  documentUpdatedSuccess: 'Document added successfully',
  documentUploadedSuccess: 'Document Uploaded successfully',
  deleteCard: 'you want to delete this card',
  subCategoriesCount: 'Sub Categories Count',
  serviceTypeCount: 'Service Type Count',
  JobCount: 'Job Count',
  averageTime: 'Average Time',
  providers: 'Providers',
  totalSales: 'Total Sales',
  avgTicket: 'Average Ticket',
  quoteMngEstimates: 'Quote Management - Estimates',
  locationName: 'Location Name',
  cash: 'Cash',
  credit: 'Credit Card',
  financing: 'Financing',
  check: 'Check',
  warranty: 'Warranty/No Charge',
  notPaid: 'Not Paid Request Payment',
  paid: 'Paid Request Payment',
  settledPay: 'Admin Settled Payment',
  adminAttention: 'Admin Attention Required',
  expired: 'Expired',
  accepted: 'Accepted',
  declined: 'Declined',
  open: 'Open',
  totalQuotes: 'Total Quotes',
  onlineProvider: 'Online Providers',
  onlineProviderActivity: 'Online Providers Activity',
  serviceProviderTotalTimeOnline: 'SP Total Time Online',
  emergencyRadius: 'Emergency Radius',
  leadLosing : 'Lead/Losing'
};
