import i18next from 'i18next';
import { accessRoute } from '../../routes/Dashboard';
import {
  checkValidData,
  getBooleanResponse,
  checkboxBooleanFormatter,
  checkboxDashFormatter,
  checkValidPrice,
  serialNumberFormatter,
  NAformatter,
  titleCaseFormatter,
} from '../../components/UIElements/Formatter';
import {
  getInputSearchFilter,
  getMultiSelectSearchFilter,
  getPriceFilter,
} from '../../components';
import {
  getSupplyHouseService,
  booleanYesNoList,
  LineItemsListService,
  getPartTitleItems,
  poOptions,
  paymentTypeOptions,
} from '../index.service';
import { dateFormatter } from '../../utils';
import { getFromToDateFilter } from '../../components/Filter/Common/date.filter';
import { firstLetterCaps, getFullName } from '../../utils/text.util';
import { providerListService } from '../TeamSetting/provider.service';
import { DATE_TH_MONTH_YEAR } from '../../config';

const priceCostFields = {
  fromOperatorField: 'partCostFromOperator',
  fromOperatorAmountField: 'partCostFromAmount',
  conditionalField: 'partCostCondition',
  toOperatorField: 'partCostToOperator',
  toOperatorAmountField: 'partCostToAmount',
};

export const selectDateData = [
  {
    name: 'Today',
    value: 'today',
  },
  {
    name: 'Yesterday',
    value: 'yesterday',
  },
  {
    name: 'Current Week',
    value: 'currentWeek',
  },
  {
    name: 'Previous Week',
    value: 'previousWeek',
  },
  {
    name: 'Current Month',
    value: 'currentMonth',
  },
  {
    name: 'Previous Month',
    value: 'previousMonth',
  },
  {
    name: 'Current Year',
    value: 'currentYear',
  },
  {
    name: 'Previous Year',
    value: 'previousYear',
  },
];
export const truckTableColumn = ({
  onFilterChange,
  onOrderCheck,
  onRemoveCheck,
  filter,
  page,
  sizePerPage,
  isUserCanEdit,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'orderId',
      title: i18next.t('text.common.orderNo'),
      sorter: true,
      render: (cell, row) =>
        checkValidData(
          row?.isAdminOrdered && !row?.isOrdered
            ? row?.createdByUser?.firstName && row?.createdByUser?.lastName
              ? `Added by admin (${firstLetterCaps(
                  row?.createdByUser?.firstName
                )} ${firstLetterCaps(row?.createdByUser?.lastName)} )`
              : '-'
            : cell
        ),
      ...getInputSearchFilter({
        dataIndex: 'orderId',
        placeholder: 'order number',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'updatedAt',
      title: i18next.t('text.common.orderDate'),
      filterDataIndex: ['fromDate', 'toDate'],
      sorter: true,
      render: (cell, row) => dateFormatter(cell, DATE_TH_MONTH_YEAR),
      ...getFromToDateFilter({
        dataStartIndex: 'fromDate',
        dataEndIndex: 'toDate',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY/MM/DD',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'providerName',
      title: i18next.t('text.common.providerName'),
      sorter: true,
      render: (cell, row) =>
        getFullName(row?.User?.firstName, row?.User?.lastName),
      // checkValidData(firstLetterCaps(row?.User?.firstName)),
      ...getMultiSelectSearchFilter({
        dataIndex: 'providerName',
        onFilterChange,
        name: 'provider name',
        list: [],
        // scope: 'all',
        listService: providerListService,
        listKeys: { nameKey: ['firstName', 'lastName'], nameId: 'id' },
        searchOrNot: true,
        filter,
      }),
    },
    {
      dataIndex: 'partName',
      title: i18next.t('text.common.partTitle'),
      sorter: true,
      render: (cell, row) =>
        checkValidData(
          row?.paymentType !== null
            ? 'PO Generated'
            : row?.LineItem?.name || row?.BookingLineItem?.LineItem?.name
        ),
      ...getMultiSelectSearchFilter({
        dataIndex: 'partName',
        onFilterChange,
        name: 'part title',
        list: [],
        scope: 'activeLineItem',
        listService: getPartTitleItems,
        listKeys: { nameKey: 'name', nameId: 'name' },
        filter,
        type: 'poGenerated',
      }),
    },
    {
      dataIndex: 'partNumber',
      title: i18next.t('text.common.partNumber'),
      sorter: true,
      render: (cell, row) =>
        checkValidData(
          row?.paymentType !== null
            ? 'PO Generated'
            : row?.LineItem?.partNumber ||
                row?.BookingLineItem?.LineItem?.partNumber
        ),
      ...getMultiSelectSearchFilter({
        dataIndex: 'partNumber',
        onFilterChange,
        name: 'part number',
        list: [],
        scope: 'activeLineItem',
        listService: LineItemsListService,
        listKeys: { nameKey: 'partNumber', nameId: 'partNumber' },
        filter,
        type: 'poGenerated',
      }),
    },
    {
      dataIndex: 'partCost',
      filterDataIndex: [
        'partCostCondition',
        'partCostFromAmount',
        'partCostFromOperator',
        'partCostToAmount',
        'partCostToOperator',
      ],
      title: i18next.t('text.common.partCost'),
      // sorter: true,
      render: (cell, row) =>
        checkValidPrice(
          row?.orderAmount ||
            row?.LineItem?.partCost ||
            row?.BookingLineItem?.LineItem?.partCost
        ),
      ...getPriceFilter({
        dataIndex: 'price',
        onFilterChange,
        fields: priceCostFields,
        filter,
      }),
    },
    {
      dataIndex: 'isOrdered',
      title: i18next.t('text.truckOrdering.order'),
      sorter: true,
      render: (cell, row) => getBooleanResponse(cell),
      ...getMultiSelectSearchFilter({
        dataIndex: 'isOrdered',
        onFilterChange,
        name: 'order',
        list: [],
        mode: false,
        listService: booleanYesNoList,
        filter,
      }),
    },
    {
      dataIndex: 'orderId',
      title: i18next.t('text.common.adminOrder'),
      render: (cell, row) =>
        isUserCanEdit
          ? checkboxBooleanFormatter({
              cell: row?.isAdminOrdered && !row?.isOrdered ? false : cell,
              text: row?.isAdminOrdered ? 'Yes' : 'No',
              action: onOrderCheck,
              id: row?.id,
            })
          : '-',
    },
    {
      dataIndex: 'supplyHouse',
      title: i18next.t('text.common.supplyHouse'),
      sorter: true,
      render: (cell, row) =>
        row?.EquipmentLocation?.name
          ? row?.EquipmentLocation?.name
          : i18next.t('text.common.noItem'),
      ...getMultiSelectSearchFilter({
        dataIndex: 'supplyHouse',
        onFilterChange,
        name: i18next.t('text.common.supplyHouse'),
        list: [],
        scope: 'activeEquipmentLocation',
        listService: getSupplyHouseService,
        listKeys: { nameKey: 'name', nameId: 'id' },
        filter,
      }),
    },
    {
      dataIndex: 'itemName',
      title: i18next.t('text.truckOrdering.poOption'),
      sorter: true,
      render: (cell, row) =>
        cell
          ? titleCaseFormatter(
              cell === 'tools_misc'
                ? cell?.replaceAll('_', ' / ')
                : cell?.replaceAll('_', ' ')
            )
          : NAformatter(cell),
      ...getMultiSelectSearchFilter({
        dataIndex: 'itemName',
        onFilterChange,
        name: i18next.t('text.truckOrdering.poOption'),
        list: [],
        mode: false,
        listService: poOptions,
        filter,
      }),
    },
    {
      dataIndex: 'paymentType',
      title: i18next.t('text.truckOrdering.paymentType'),
      sorter: true,
      render: (cell, row) =>
        cell
          ? titleCaseFormatter(cell?.replaceAll('_', ' '))
          : NAformatter(cell),
      ...getMultiSelectSearchFilter({
        dataIndex: 'paymentType',
        onFilterChange,
        name: i18next.t('text.truckOrdering.paymentType'),
        list: [],
        mode: false,
        listService: paymentTypeOptions,
        filter,
      }),
    },
    {
      dataIndex: 'orderId',
      title: i18next.t('text.common.removeParts'),
      render: (cell, row) =>
        isUserCanEdit
          ? checkboxDashFormatter({
              cell: row?.isAdminOrdered && !row?.isOrdered ? false : cell,
              action: onRemoveCheck,
              id: row?.id,
            })
          : '-',
    },
  ];
};

export const truckListBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.truckOrdering.title'),
    },
  ];
};
