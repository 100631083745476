import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AdminNote,
    GlobalLoader,
    ModalWrapper,
    PageContainerWrapper,
    TablePlus,
} from '../../../../components';
import useTable from '../../../../hooks/useTable.hook';
import useSubmit from '../../../../hooks/useSubmit.hook';
import {
    getIssueReportService,
    changePriorityAndStatusService,
    addAdminNoteDataService,
    issueAssignService,
    Columns,
    getActions,
} from '../../../../services/index.service';
import { modalNotification } from '../../../../utils';
import ReportedIssueMedia from '../View';
import { Link } from 'react-router-dom';
import { PermissionContext } from '../../../../context/permission.context';
import { useSelector } from 'react-redux';
import ReportedIssueAssign from '../Assign';

function ReportAnIssue() {
    const { t } = useTranslation();
    const breadcrumb = Columns.reportAnIssueBreadcrumb();
    const [noteData, setNoteData] = useState('')
    const userType = useSelector((state) => state?.auth?.userData?.Role?.type);
    const permissionContext = useContext(PermissionContext);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    useEffect(() => {
        setIsFirstLoad(false);
    },[])
    const extraQuery = isFirstLoad
        ? {
            ...(isFirstLoad && { currentStatus: ['open', 'on_hold', 'pending_third_party'] }),
        }
        : false;
    const [tableHandler, tableProps] = useTable(
        getIssueReportService,
        extraQuery
    );
    const [assignModalData, setAssignModalData] = useState({});
    const {
        onFilterChange,
        onTableSort,
        onFilterResetChange,
        onRefresh,
    } = tableHandler;
    const {
        data,
        isLoading,
        count,
        page,
        noOfPages,
        sizePerPage,
        filter,
        sortBy,
        sortType,
    } = tableProps;

    const [state, setState] = useState({
        isAlertVisible: false,
        loading: false,
        updateId: null,
        modal: null,
        options: {},
        selectedRows: [],
        noteId: null,
        showNote: false,
        headingText: 'Add',
    });

    const {
        loading,
        options,
        noteId,
        modal,
        selectedRows,
        showNote,
        headingText,
    } = state;
    const action = permissionContext;
    const isUserCanEdit = (action && action?.arr?.includes(getActions('CREATE').value)) || userType === 'admin';


    const adminNoteModal = (noteId, isNote) => {
        if (isNote) {
            setNoteData(isNote);
        }
        handleStateChange('noteId', noteId);
        handleStateChange('showNote', true);
        handleStateChange('headingText', isNote ? 'Edit' : 'Add');
    };

    const handleStateChange = (name, value) => {
        if (typeof name === 'string') {
            setState((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                ...name,
            }));
        }
    };

    function saveUpdates(id) {
        handleStateChange('updateId', id);
        handleStateChange('isAlertVisible', true);
    }

    async function handleChangeOption(name, value, id) {
        try {
            handleStateChange('loading', true);
            const body = {
                changetype: name === "currentStatus" ? "status" : "priority",
                value
            };
            const response = await changePriorityAndStatusService({
                body,
                id,
            });

            if (response?.success) {
                handleStateChange({ loading: false, isAlertVisible: false });
                onRefresh();
                modalNotification({
                    type: 'success',
                    message: response?.message,
                });
                handleStateChange('modal', '');
                return true;
            }
        } catch (error) {
            handleStateChange('loading', false);
            modalNotification({
                type: 'error',
                message: error?.message,
            });
        }
    }

    const actionFormatter = (options, data) => {
        return (
            <Link to="#" onClick={(e) => onAction(e, data)} className="text-primary">
                {options}
            </Link>
        );
    };

    function onAction(e, data) {
        e.preventDefault();
        setState({
            ...state,
            modal: 'media',
            selectedRows: data,
        });
    }
    const issueAssign = (data) => {
        setAssignModalData(data);
        handleStateChange('modal', 'assign');
    }
    function cancelUpdates(id) {
        let prevOptions = { ...options };
        delete prevOptions[id];
        handleStateChange('options', prevOptions);
    }
    const onCancel = () => {
        setNoteData('');
        handleStateChange('modal', '');
        handleStateChange('showNote', false);
        handleStateChange('noteId', null);
        renewState();
    };

    const [onSubmit, values, loader, renewState] = useSubmit({
        service: addAdminNoteDataService,
        id: noteId,
        afterSubmitActionType: 'close-modal',
        onModalCancel: onCancel,
        action: onRefresh,
    });

    const columns = Columns.reportAnIssueColumn({
        onSort: onTableSort,
        onFilterChange: onFilterChange,
        filter: filter,
        page,
        options: options,
        sizePerPage,
        Action: (props) => actionFormatter(props.cell, props.row),
        adminNoteModal,
        handleChangeOption,
        saveUpdates,
        cancelUpdates,
        isUserCanEdit,
        issueAssign
    });

    const onIssueAssignSubmit = async (values) => {
        try {
            handleStateChange('loading', true);
            const response = await issueAssignService({
                userId: values?.providerId ?? values?.subAdminId,
                id: assignModalData?.id,                
            });

            if (response?.success) {
                handleStateChange({ loading: false, isAlertVisible: false });
                onRefresh();
                modalNotification({
                    type: 'success',
                    message: response?.message,
                });
                handleStateChange('modal', '');
                return true;
            }
        } catch (error) {
            handleStateChange('loading', false);
            modalNotification({
                type: 'error',
                message: error?.message,
            });
        }
    }

    return (
        <>
            <PageContainerWrapper
                heading={t('text.report.reportAnIssue')}
                breadcrumb={breadcrumb}
                extraClass="btn btn-primary d-block d-xl-none"
            >
                <div className="tableScrollHide">
                    <TablePlus
                        hasLimit
                        noOfPage={noOfPages}
                        sizePerPage={sizePerPage}
                        page={page}
                        count={count}
                        tableData={data}
                        tableColumns={columns}
                        selectRow={true}
                        statusAction={false}
                        showAction={false}
                        tableLoader={isLoading}
                        filter={filter}
                        sortBy={sortBy}
                        onFilterResetChange={onFilterResetChange}
                        sortType={sortType}
                        scrollTopPosition
                    />
                </div>
            </PageContainerWrapper>

            <ModalWrapper
                onCancel={onCancel}
                extraClassName="modal-dialog-scrollable"
                content={
                    (loader === 'GET-DETAIL') ? (
                        <GlobalLoader />
                    ) : showNote && (
                        <AdminNote
                            formData={{adminNote:noteData}}
                            onSubmit={onSubmit}
                            onCancel={onCancel}
                            isLoading={loader === 'SUBMIT'}
                            isUserCanEdit={isUserCanEdit}
                        />
                    )
                }
                heading={
                    headingText === 'Add'
                        ? t('text.voiceRecords.addNote')
                        : t('text.voiceRecords.updateNote')
                }
                show={showNote}
            />

            <ModalWrapper
                onCancel={onCancel}
                content={
                    (loader === 'GET-DETAIL') ? (
                        <GlobalLoader />
                    ) :
                        modal === 'media' ?
                            <ReportedIssueMedia addModalHide={onCancel} selectedRows={selectedRows} /> :
                            modal === 'assign' ?
                                <ReportedIssueAssign
                                    formData={assignModalData}
                                    onCancel={onCancel}
                                    onSubmit={onIssueAssignSubmit}
                                /> :
                                null
                }
                heading={modal === 'media' ? t('text.report.reportedIssueMedia') : t('text.report.reportedIssueAssign')}
                show={modal === 'assign' || modal === 'media'}
            />
        </>
    );
}

export default ReportAnIssue;
