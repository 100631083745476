export const text = {
  noteListOne: 'Administrator module will list all NCA on this page.',
  noteListTwo: 'Administrator can Activate / Deactivate / Delete any NCA.',
  statusInfo:
    'If you want to notify to NCA to complete his profile click on below button to send profile reminder email.',
  ncaPaymentReport: 'NCA Payment Report ',
  documentOf: 'Document of',
  goLogReport: 'Go To Log Report',
  uploadCompanyLogo: 'Upload Company Logo',
  businessDetails: 'Business Details',
  whichRoleWouldYouLike: 'Which role would you like to use',
  WouldYouLikeToPay: 'Would you like to pay monthly?',
  creditCardSubTitle:
    'Save card on file for recurring payment. No charges will be  completed until your account is live.',
  goToNcaDashboard: 'Go To NCA Dashboard',
  termsOfUseUrl: 'Show url of term of use',
  privacyUrl: 'Show url of privacy policy',
  aboutUsUrl: 'Show url of about us',
  termsOfUse: 'Terms of use',
  privacyPolicy: 'Privacy policy',
  aboutUs: 'About us',
  pagePermission: 'Set page permission after logged in (show/hide)',
  alert: 'Would you like to deactivate your account?',
  alertNote:
    'Canceling your plan will lock all admin profiles, service providers and leave no access to any previous information. We recommend downgrading to a default plan to allow you access to past reporting. Once your account is deactivated you will no longer be able to access the past information again?',
  requiredEmail: 'Email is required',
  validEmail: 'Please enter valid email',
  defaultPlan: 'Are you sure want to move on default plan?',
  updatePlan: 'Are you sure want to update plan?',
  paymentPlan:
    'Are you sure want to update plan? Even if you update the plan, your bill will be generated according to your current plan instantly.',
  cancelPlan: 'Cancel Plan',
  defaultPlanBilling:
    'Even if you select the default plan, your bill will be generated according to your current plan instantly.',
  eliminateHistory:
    'Please confirm you want to eliminate your access to all history?',
  planInactive:
    'Are you sure you want to inactive the NCA ? By inactivating the NCA a bill will be generated for the NCA immediately.',
  statusInactive: 'Are you sure you want to inactive the NCA?',
  markAsSettled: 'Mark As Settled',
  totalCollected: 'Total Collected',
  totalFee: 'Total Fees',
  paidNca: 'Total paid to NCA',
};
