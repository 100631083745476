import React from 'react';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { months } from '../../../services/Register';

import { expireYearList } from '../../../utils';
import {
  FormWrapper,
  FormElementWrapper,
  TextInput,
  CommonButton,
  GlobalLoader,
  Select,
  CardComponent,
} from '../../index';
import { cardValidation, initCardValues } from './validation';

function SetupCardForm({ onSubmit, cardLoading, cardList, getCardList }) {
  const { t } = useTranslation();
  const monthsData = months();
  const yearData = expireYearList();
  // let editFormData = {
  //   ...formData?.UserCard,
  // };
  const [loading, setLoading] = useState(false);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  return (
    <div className="card">
      <div className="card-inner">
        <Row>
          <Col lg={6}>
            <FormWrapper
              row={true}
              enableReinitialize={true}
              initialValues={initCardValues({ currentYear, currentMonth })}
              // data={editFormData}
              onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
              validation={() => cardValidation()}
              extraClassName="g-3"
            >
              {(props) => (
                <>
                  <FormElementWrapper
                    md={6}
                    mandatory={true}
                    label={t('text.common.cardHolder')}
                    element={() => (
                      <TextInput
                        name="name"
                        placeholder={t('text.common.enterCardHolder')}
                        formik={props}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    mandatory={true}
                    label={t('text.common.cardNumber')}
                    element={() => (
                      <TextInput
                        name="cardNumber"
                        placeholder={t('text.common.enterCardNumber')}
                        formik={props}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    lg={4}
                    mandatory={true}
                    label={t('text.common.expMonth')}
                    element={() => (
                      <Select
                        name="expMonth"
                        placeholder={t('text.common.selectExpMonth')}
                        options={monthsData}
                        showSearch
                        formik={props}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    lg={4}
                    mandatory={true}
                    label={t('text.common.expYear')}
                    element={() => (
                      <Select
                        name="expYear"
                        placeholder={t('text.common.selectExpYear')}
                        options={yearData}
                        showSearch
                        formik={props}
                      />
                    )}
                  />
                  <FormElementWrapper
                    md={6}
                    lg={4}
                    mandatory={true}
                    label={t('text.common.cvv')}
                    element={() => (
                      <TextInput
                        name="cvv"
                        placeholder={'Enter cvv'}
                        formik={props}
                      />
                    )}
                  />

                  <FormElementWrapper
                    md={12}
                    className="text-end"
                    element={() => (
                      <>
                        <CommonButton
                          type="submit"
                          className="btn btn-primary"
                          disabled={cardLoading}
                          loading={cardLoading}
                        >
                          {t('text.common.saveChanges')}
                        </CommonButton>
                      </>
                    )}
                  />
                </>
              )}
            </FormWrapper>
          </Col>
          <Col lg={6}>
            <div className="ncaCardList">
              {loading ? (
                <GlobalLoader />
              ) : (
                <ul>
                  <CardComponent
                    cardDetail={cardList}
                    getCardList={getCardList}
                    loading={loading}
                    setLoading={setLoading}
                  />
                </ul>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default SetupCardForm;
