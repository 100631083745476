import i18next from 'i18next';
import React, { useState } from 'react';
import { ImagePreviewFormatter, VideoPreviewFormatter } from '../../../../components/UIElements/Formatter';
import { CommonButton } from '../../../../components';

export default function ReportedIssueMedia({ addModalHide, selectedRows }) {
    const [imageError, setImageError] = useState(false);
    return (
        <div>
            <div className="row g-3">
                <div className="col-12">
                    <div className="d-flex flex-wrap">
                        {selectedRows?.ReportIssueMedia?.length > 0
                            ? selectedRows?.ReportIssueMedia?.map(
                                (item, itemIdx) => (
                                    <div
                                        key={itemIdx}
                                        className="imgPreviewBox overflow-hidden m-1"
                                    >
                                        {item?.attachmentType === 'image' && (
                                            <ImagePreviewFormatter
                                                path={item?.attachmentUrl}
                                                text="Image"
                                                setImageError={setImageError}
                                                imageError={imageError}
                                            />
                                        )}
                                        {item?.attachmentType === 'video' && (
                                            <VideoPreviewFormatter path={item?.attachmentUrl} text="Video" />
                                        )}
                                    </div>
                                )
                            )
                            : '-'}
                    </div>
                </div>
            </div>
            <div className="text-end mt-2">
                <CommonButton className="btn btn-light" onClick={() => addModalHide()}>
                    {i18next.t('text.common.close')}
                </CommonButton>
            </div>
        </div>
    );
}
