export const REPORT_ENDPOINT = {
  getUserReview: {
    url: '/v1/admin/customer-review',
    method: 'GET',
  },
  getProviderReview: {
    url: '/v1/admin/provider-review',
    method: 'GET',
  },
  getSubLabour: (bookingId) => ({
    url: `/v1/admin/sub-labour/${bookingId}`,
    method: 'GET',
  }),
  getCreatePurchase: {
    url: `/v1/admin/sub-labour`,
    method: 'POST',
  },
  getProviderInvoiceReportDetailsById: (bookingId) => ({
    url: `/v1/admin/provider-invoice-report/${bookingId}`,
    method: 'GET',
  }),
  deleteSubLabor: {
    url: `/v1/admin/sub-labour`,
    method: 'DELETE',
  },
  getProviderPaymentReport: {
    url: '/v1/provider/payment-report',
    method: 'GET',
  },
  getProviderPaymentReportTotalSublaborPay: (providerUserId) => ({
    url: `/v1/provider/sub-labour/${providerUserId}`,
    method: 'GET',
  }),
  getInvoicePayDetailsById: (bookingId) => ({
    url: `/v1/admin/invoice-pay-detail/${bookingId}`,
    method: 'GET',
  }),
  postBookingPayment: {
    url: `/v1/admin/booking/pay-now`,
    method: 'POST',
  },
  getPublicInvoicePayDetailsById: (bookingId) => ({
    url: `/v1/admin/public/invoice-pay-detail/${bookingId}`,
    method: 'GET',
  }),
  getPaymentReportExcel: {
    url: 'v1/admin/payment-report-excel',
    method: 'GET',
  },
  getProvidersActivity: {
    url: '/v1/provider/online-activity',
    method: 'GET',
  },
};
