import { Outlet } from 'react-router-dom';
import {
  AddEditProduct,
  Category,
  CouponList,
  OrderInvoice,
  OrderManagement,
  ProductBulkUpload,
  ProductManagementList,
  ProductViewImages,
  VariantManagementList,
} from '../../views/ShopingCart';

import { getActions } from '../../services/index.service';
// import SubCategoryList from '../../views/ShopingCart/ManageCategory/SubCategory/List';

export default function route() {
  return [
    {
      private: true,
      name: 'Shopping Cart',
      key: 'shopping',
      belongsToSidebar: true,
      icon: (
        <span className="nk-menu-icon">
          <em className="icon ni ni-wallet-fill" />
        </span>
      ),
      moduleKeys: [
        accessRoute.SHOPPING_CART_CATEGORY.moduleKey,
        accessRoute.VARIANTS_LIST.moduleKey,
        accessRoute.PRODUCTS_LIST.moduleKey,
        accessRoute.COUPON_MANAGEMENT_LIST.moduleKey,
        accessRoute.ORDER_MANAGEMENT_LIST.moduleKey,
      ],
      element: <Outlet />,
      submenu: true,
      children: [
        {
          private: true,
          name: 'Manage Category',
          key: 'shopping.shopping-Categrory',
          belongsToSidebar: true,
          moduleKeys: [accessRoute.SHOPPING_CART_CATEGORY.moduleKey],
          element: <Outlet />,
          submenu: true,
          children: [
            {
              private: true,
              name: accessRoute.SHOPPING_CART_CATEGORY.name,
              path: accessRoute.SHOPPING_CART_CATEGORY.path,
              key: 'shopping.shopping-Categrory.category',
              belongsToSidebar: true,
              element: <Category />,
              ...accessRoute.SHOPPING_CART_CATEGORY,
            },
          ],
        },
        {
          private: true,
          name: accessRoute.VARIANTS_LIST.name,
          key: 'shopping.varinatManagement',
          path: accessRoute.VARIANTS_LIST.path,
          moduleKeys: accessRoute.VARIANTS_LIST.moduleKey,
          belongsToSidebar: true,
          element: <VariantManagementList />,
          ...accessRoute.VARIANTS_LIST,
        },
        {
          private: true,
          name: accessRoute.PRODUCTS_LIST.name,
          key: 'shopping.productManagement',
          path: accessRoute.PRODUCTS_LIST.path,
          moduleKeys: accessRoute.PRODUCTS_LIST.moduleKey,
          belongsToSidebar: true,
          element: <ProductManagementList />,
          ...accessRoute.PRODUCTS_LIST,
        },
        {
          private: true,
          name: accessRoute.PRODUCTS_ADD.name,
          key: 'shoppingCart.products',
          path: accessRoute.PRODUCTS_ADD.path,
          belongsToSidebar: false,
          element: <AddEditProduct />,
          ...accessRoute.PRODUCTS_ADD,
        },
        {
          private: true,
          name: accessRoute.PRODUCTS_EDIT.name,
          key: 'shoppingCart.products',
          path: accessRoute.PRODUCTS_EDIT.path,
          belongsToSidebar: false,
          element: <AddEditProduct />,
          ...accessRoute.PRODUCTS_EDIT,
        },
        {
          private: true,
          name: accessRoute.BULK_UPLOAD_IMAGE.name,
          key: 'shoppingCart.products',
          path: accessRoute.BULK_UPLOAD_IMAGE.path,
          belongsToSidebar: false,
          element: <ProductBulkUpload />,
        },
        {
          private: true,
          name: accessRoute.BULK_UPLOAD_IMAGE_VIEW.name,
          key: 'shoppingCart.products',
          path: accessRoute.BULK_UPLOAD_IMAGE_VIEW.path,
          belongsToSidebar: false,
          element: <ProductViewImages />,
          ...accessRoute.BULK_UPLOAD_IMAGE_VIEW,
        },
        {
          private: true,
          name: accessRoute.COUPON_MANAGEMENT_LIST.name,
          key: 'shopping.couponManagement',
          path: accessRoute.COUPON_MANAGEMENT_LIST.path,
          moduleKeys: accessRoute.COUPON_MANAGEMENT_LIST.moduleKey,
          belongsToSidebar: true,
          element: <CouponList />,
          ...accessRoute.COUPON_MANAGEMENT_LIST,
        },
        {
          private: true,
          name: accessRoute.ORDER_MANAGEMENT_LIST.name,
          key: 'shopping.orderManagement',
          path: accessRoute.ORDER_MANAGEMENT_LIST.path,
          moduleKeys: accessRoute.ORDER_MANAGEMENT_LIST.moduleKey,
          belongsToSidebar: true,
          element: <OrderManagement />,
          ...accessRoute.ORDER_MANAGEMENT_LIST,
        },
        {
          private: true,
          name: accessRoute.ORDER_MANAGEMENT_VIEW.name,
          key: 'shoppingCart.orders',
          path: accessRoute.ORDER_MANAGEMENT_VIEW.path,
          moduleKeys: accessRoute.ORDER_MANAGEMENT_VIEW.moduleKey,
          belongsToSidebar: false,
          element: <OrderInvoice />,
          ...accessRoute.ORDER_MANAGEMENT_VIEW,
        },
      ],
    },
  ];
}

export const accessRoute = {
  SHOPPING_CART_CATEGORY: {
    path: '/admin/category-management',
    name: 'Category',
    key: 'shopping.shopping-Categrory.category',
    action: getActions('LIST').value,
    moduleKey: 'shopping-category',
    for: ['admin', 'subadmin','nca'],
    order: 71,
  },
  SHOPPING_CART_SUB_CATEGORY: {
    path: '/admin/subcategory-management',
    name: 'Sub Category',
  },
  VARIANTS_LIST: {
    path: '/admin/variant-management',
    name: 'Variants Management',
    key: 'shopping.variants-management',
    action: getActions('LIST').value,
    moduleKey: 'variants-management',
    for: ['admin', 'subadmin','nca'],
    order: 72,
  },
  PRODUCTS_LIST: {
    path: '/admin/product-management',
    name: 'Product Management',
    key: 'shopping.product-management',
    action: getActions('LIST').value,
    moduleKey: 'product-management',
    for: ['admin', 'subadmin','nca'],
    order: 73,
  },
  PRODUCTS_ADD: {
    path: '/admin/product-management/add',
    name: 'Add Product',
    moduleKey: 'product-management',
    action: getActions('CREATE').value,
    for: ['admin', 'subadmin','nca'],
    key: 'shopping.product-management',
  },
  PRODUCTS_EDIT: {
    path: '/admin/product-management/edit/:id',
    name: 'Edit Product',
    action: getActions('EDIT').value,
    moduleKey: 'product-management',
    for: ['admin', 'subadmin','nca'],
    key: 'shopping.product-management',
  },
  COUPON_MANAGEMENT_LIST: {
    path: '/admin/coupon-management',
    name: 'Coupon Management',
    key: 'shopping.coupon-management',
    for: ['admin', 'subadmin','nca'],
    action: getActions('LIST').value,
    moduleKey: 'coupon-management',
    order: 74,
  },
  ORDER_MANAGEMENT_LIST: {
    path: '/admin/order-management',
    name: 'Order Management',
    for: ['admin', 'subadmin','nca'],
    key: 'shopping.order-management',
    action: getActions('LIST').value,
    moduleKey: 'order-management',
    order: 75,
  },
  BULK_UPLOAD_IMAGE: {
    path: '/admin/product/bulk-upload',
    name: 'Product Bulk Upload',
    moduleKey: 'product-management',
    key: 'shopping.product-management',
  },
  BULK_UPLOAD_IMAGE_VIEW: {
    path: '/admin/product/bulk-upload-view',
    name: 'Product Bulk Upload View',
    moduleKey: 'product-management',
    action: getActions('LIST').value,
    key: 'shopping.product-management',
  },
  ORDER_MANAGEMENT_VIEW: {
    path: '/admin/order-management/invoice/:id',
    name: 'Order Management Invoice',
    key: 'shopping.order-management' ,
    action: getActions('VIEW').value,
    moduleKey: 'order-management',

  },
};

/******Return should be list of children routes**** */
export const getChildrenRoutes = () => {
  return [...route()[0]?.children];
};
