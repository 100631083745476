import loadable from '@loadable/component';
import FullPageLoader from '../../components/UIElements/Loader/fullPageLoader';

export const ListCity = loadable(
  () => import('./Utility/Localization/City/List'),
  {
    fallback: <FullPageLoader />,
  }
);

export const ListState = loadable(
  () => import('./Utility/Localization/State/List'),
  {
    fallback: <FullPageLoader />,
  }
);

export const ListCountry = loadable(
  () => import('./Utility/Localization/Country/List'),
  {
    fallback: <FullPageLoader />,
  }
);

export const ListFaq = loadable(() => import('./Utility/Faq/List'), {
  fallback: <FullPageLoader />,
});

export const EditPage = loadable(() => import('./Utility/Pages/Edit'), {
  fallback: <FullPageLoader />,
});
export const ListPage = loadable(() => import('./Utility/Pages/List'), {
  fallback: <FullPageLoader />,
});

export const EditNCA = loadable(() => import('./NCA/Edit'), {
  fallback: <FullPageLoader />,
});
export const ListNCA = loadable(() => import('./NCA/List'), {
  fallback: <FullPageLoader />,
});

export const ListIndustry = loadable(() => import('./Industry/List'), {
  fallback: <FullPageLoader />,
});

export const EditHomepagePage = loadable(
  () => import('./Utility/EditHomePage/Edit'),
  {
    fallback: <FullPageLoader />,
  }
);
export const ListHomepagePage = loadable(
  () => import('./Utility/EditHomePage/List'),
  {
    fallback: <FullPageLoader />,
  }
);

export const SendNotification = loadable(
  () => import('./Utility/PushNotification'),
  {
    fallback: <FullPageLoader />,
  }
);

export const Banner = loadable(
  () => import('./CustomerAppSetting/Banner/View'),
  {
    fallback: <FullPageLoader />,
  }
);

export const Information = loadable(
  () => import('./AppText/Information/View'),
  {
    fallback: <FullPageLoader />,
  }
);
export const Financing = loadable(() => import('./AppText/Financing/View'), {
  fallback: <FullPageLoader />,
});
export const Tips = loadable(() => import('./AppText/Tips/View'), {
  fallback: <FullPageLoader />,
});
export const QuoteInformation = loadable(
  () => import('./AppText/QuoteInformation/View'),
  {
    fallback: <FullPageLoader />,
  }
);
export const LogReports = loadable(() => import('./NCA/LogsReports'), {
  fallback: <FullPageLoader />,
});
export const NcaPaymentReport = loadable(() => import('./NCA/PaymentReport'), {
  fallback: <FullPageLoader />,
});
