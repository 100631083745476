import i18next from 'i18next';
import { accessRoute } from '../../routes/Dashboard';
import { generatePath } from 'react-router-dom';
import * as yup from 'yup';

import {
  // CheckBox,
  CommonButton,
  FormElementWrapper,
  FormWrapper,
  getInputSearchFilter,
  getMultiSelectSearchFilter,
  getPriceFilter,
  TextInput,
} from '../../components';
import { accessRoute as reportRoute } from '../../routes/Reports';
import { accessRoute as providerInvoiceRoute } from '../../routes/ProviderInvoiceUpdate';
import { accessRoute as commonAccessRoutes } from '../../routes/CommonRoutes';
import {
  ButtonFormatter,
  ButtonTextFormatter,
  checkValidData,
  checkValidPrice,
  serialNumberFormatter,
  // serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { firstLetterCaps, getFullName, splitLetter } from '../../utils/text.util';
import {
  convertMinDotSecToMinutes,
  dateFormatter,
  minuteIntoHHMMSS,
  minutesToTime,
  readMoreTextShow,
  showDateInBrowser,
} from '../../utils';
import { Checkbox, Form, Input, Select } from 'antd';
import { capitalizeFirstLetter } from '../../utils';
import { generatePaths } from './serviceManagement.service';
import moment from 'moment';
import { providerListService } from '../TeamSetting/provider.service';
import { CustomersService } from '../Customers';

const getFilterListData = (value) => {
  return value.map((item) => ({
    name: `${item?.firstName ? item?.firstName : ''} ${
      item?.lastName ? item?.lastName : ''
    } - (${item?.email ? item?.email : ''})`,
    id: item?.id,
  }));
};

const getPaymentMethodData = () => {
  return [
    {
      name: 'Cash',
      id: 'cash',
    },
    {
      name: 'Card',
      id: 'card',
    },
    {
      name: 'Check',
      id: 'check',
    },
    {
      name: 'Financing',
      id: 'finance',
    },
    {
      name: 'Request',
      id: 'request',
    },
    {
      name: 'Warranty/No Charge',
      id: 'warranty',
    },
    {
      name: 'Admin Attention Required',
      id: 'admin',
    },
  ];
};
const getMembershipTypeData = () => {
  return [
    {
      name: 'New Purchased',
      id: 'new_purchased',
    },
    {
      name: 'Renewed',
      id: 'renewed',
    },
    {
      name: 'Existing',
      id: 'existing',
    },
    {
      name: 'New',
      id: 'new',
    },
    {
      name: 'No',
      id: 'no',
    },
  ];
};
const getPaymentTypeData = () => {
  return [
    {
      name: 'Full Payment',
      id: 0,
    },
    {
      name: 'Split Payment',
      id: 1,
    },
  ];
};
const getProviderPaymentStatusData = () => {
  return [
    {
      name: 'Settled',
      id: 'settelled',
    },
    {
      name: 'Unsettled',
      id: 'unsettelled',
    },
  ];
};
const getPaymentTransferStatusData = () => {
  return [
    {
      name: 'Pending',
      id: 'pending',
    },
    {
      name: 'Transfered',
      id: 'tranfered',
    },
  ];
};

function reduceAddTotalAmount(data, text) {
  let sum = 0;
  let filterData = data?.SubLabors?.filter((item) => item?.type === text);
  sum = filterData
    ?.map((item) => item?.totalAmount)
    ?.reduce((pre, cur) => parseFloat(pre) + parseFloat(cur), 0);
  return sum;
}

export const PaymentReportServices = () => {
  return [
    {
      id: 1,
      provider: 'jorge neon',
      user: 'Shane Watson',
      jobDate: '15th November, 2022 06:35 PM',
      location: 'Vijay Nagar, Indore',
      totalFees: '$ 5,400.00',
      platformFees: '-',
      promoCodeDiscount: '$ 0.00	',
      providerDiscountAmount: '$0.00',
      purchaseOrderTotal: '',
      sublaborTotalFees: '',
      returnedPO: '',
      providerPayAmount: '$ 4,700.00	',
      jobStatus: 'Finished',
      jobStartTime: '15th November, 2022 06:35:59	',
      jobEndTime: '16th November, 2022 03:15:54',
      jobCompletionTime: '20h 39m 55s',
      paymentMethod: 'Request',
      jobPaymentType: 'Full Payment',
      providerPaymentStatus: 'Unsettled',
      paymentTransferStatus: '',
    },
    {
      id: 2,
      provider: 'jorge neon',
      user: 'Shane Watson',
      jobDate: '17th November, 2022 04:16 PM',
      location: 'Vijay Nagar, Indore',
      totalFees: '$ 99.95',
      platformFees: '-',
      promoCodeDiscount: '$ 0.00	',
      providerDiscountAmount: '$0.00',
      purchaseOrderTotal: '',
      sublaborTotalFees: '',
      returnedPO: '',
      providerPayAmount: '$ 4,700.00	',
      jobStatus: 'Finished',
      jobStartTime: '15th November, 2022 06:35:59	',
      jobEndTime: '16th November, 2022 03:15:54',
      jobCompletionTime: '20h 39m 55s',
      paymentMethod: 'Request',
      jobPaymentType: 'Full Payment',
      providerPaymentStatus: 'Unsettled',
      paymentTransferStatus: '',
    },
    {
      id: 3,
      provider: 'jorge neon',
      user: 'Shane Watson',
      jobDate: '19th November, 2022 06:35 PM',
      location: 'Vijay Nagar, Indore',
      totalFees: '$ 5,400.00',
      platformFees: '-',
      promoCodeDiscount: '$ 0.00	',
      providerDiscountAmount: '$0.00',
      purchaseOrderTotal: '',
      sublaborTotalFees: '',
      returnedPO: '',
      providerPayAmount: '$ 4,700.00	',
      jobStatus: 'Finished',
      jobStartTime: '15th November, 2022 06:35:59	',
      jobEndTime: '16th November, 2022 03:15:54',
      jobCompletionTime: '20h 39m 55s',
      paymentMethod: 'Request',
      jobPaymentType: 'Full Payment',
      providerPaymentStatus: 'Unsettled',
      paymentTransferStatus: '',
    },
    {
      id: 4,
      provider: 'jorge neon',
      user: 'Shane Watson',
      jobDate: '19th November, 2022 06:35 PM',
      location: 'Vijay Nagar, Indore',
      totalFees: '$ 5,400.00',
      platformFees: '-',
      promoCodeDiscount: '$ 0.00	',
      providerDiscountAmount: '',
      purchaseOrderTotal: '$5.00',
      sublaborTotalFees: '$ 500.00',
      returnedPO: '$ 200.00',
      providerPayAmount: '$ 4,700.00	',
      jobStatus: 'Finished',
      jobStartTime: '15th November, 2022 06:35:59	',
      jobEndTime: '16th November, 2022 03:15:54',
      jobCompletionTime: '20h 39m 55s',
      paymentMethod: 'Cash',
      jobPaymentType: 'Split Payment',
      providerPaymentStatus: 'Settled',
      paymentTransferStatus: 'Transfered',
    },
  ];
};

export const adminData = () => {
  return [
    {
      id: 1,
      jobNumber: '75833147',
      providerName: 'Ios Pro',
      serviceType: 'QA & CATEGORY - Free Sch no quote',
      servicePrice: '$0.00	',
      discount: '$10',
      paymentTransferStatus: 'Pending',
    },
  ];
};

function handleKey(e) {
  const ASCIICode = e.which ? e.which : e.keyCode;
  const keyCode = e.charCode;
  if (
    (ASCIICode > 31 && ASCIICode > 57) ||
    keyCode === 43 ||
    keyCode === 42 ||
    keyCode === 45 ||
    keyCode === 47 ||
    keyCode === 33 ||
    keyCode === 35 ||
    keyCode === 36 ||
    keyCode === 37 ||
    keyCode === 38 ||
    keyCode === 44 ||
    keyCode === 40 ||
    keyCode === 41 ||
    keyCode === 39 ||
    keyCode === 34 ||
    keyCode === 32
  ) {
    e.preventDefault();
  }
}
const discountValidation = () => {
  return yup.object().shape({
    discount: yup.string().required(i18next.t('validation.common.discount')),
  });
};
export const columns = ({
  onSort,
  onFilterChange,
  refForm,
  setDiscount,
  splitPaymentModalShow,
  page = 1,
  sizePerPage = 10,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'jobNumber',
      title: i18next.t('text.report.jobNumber'),
      // headerClasses: 'sorting',
      // sorter: true,
      onSort: (field, order) => onSort('jobNumber', order),
      render: (cell, row) => checkValidData(row?.bookingNo),
    },
    {
      dataIndex: 'providerName',
      title: 'Provider Name',
      // headerClasses: 'sorting',
      // sorter: true,
      onSort: (field, order) => onSort('providerName', order),
      render: (cell, row) =>
        checkValidData(
          getFullName(row?.Provider?.firstName, null, row?.Provider?.lastName)
        ),
    },
    {
      dataIndex: 'serviceType',
      title: 'Service Type',
      // headerClasses: 'sorting',
      // sorter: true,
      onSort: (field, order) => onSort('serviceType', order),
      render: (cell, row) =>
        checkValidData(
          `${firstLetterCaps(
            row?.ServiceCategory?.serviceType === 'same_day'
              ? 'Same Day Service'
              : 'Scheduled Service'
          )} ${firstLetterCaps(
            row?.ServiceCategory?.servicePrice
          )} ${firstLetterCaps(row?.ServiceCategory?.name)}`
        ),
    },
    {
      dataIndex: 'servicePrice',
      title: 'Service Price',
      // headerClasses: 'sorting',
      // sorter: true,
      onSort: (field, order) => onSort('servicePrice', order),
      render: (cell, row) => checkValidPrice(row?.ServiceCategory?.price),
    },
    {
      dataIndex: 'discount',
      title: 'Discount',
      // headerClasses: 'sorting',
      // sorter: true,
      render: (cell, row) => {
        let InitValues = {
          discount: row?.discount || '',
        };
        return (
          <>
            <FormWrapper
              onSubmit={(e) => {}}
              enableReinitialize={true}
              initialValues={InitValues}
              row={true}
              extraClassName="g-3"
              innerRef={refForm}
              validation={discountValidation}
            >
              {(props) => (
                <>
                  {setDiscount(props.values.discount)}
                  <FormElementWrapper
                    md={12}
                    element={(formik) => (
                      <>
                        <div className="d-flex align-items-center">
                          <span className="me-1">$</span>
                          <TextInput
                            id="discount"
                            className="form-control"
                            name="discount"
                            // disabled={staffId}
                            variant="standard"
                            onKeyPress={(e) => handleKey(e)}
                            min="0"
                            inputmode="numeric"
                            placeholder="Enter discount"
                            formik={props}
                            // setFieldValue={props.handleChange}
                          />
                        </div>
                        {props?.errors?.discount && (
                          <div className="ant-form-item-explain-error">
                            {props?.errors?.discount}
                          </div>
                        )}
                      </>
                    )}
                  />
                </>
              )}
            </FormWrapper>
          </>
        );
      },
    },
    {
      dataIndex: 'paymentTransferStatus',
      title: 'Payment Transfer Status',
      text: 'Payment Transfer Status',
      // headerClasses: 'sorting',
      // sorter: true,
      onSort: (field, order) => onSort('paymentTransferStatus', order),
      render: (cell, row) =>
        row?.SettledPayments?.length > 0 ? 'Completed' : 'Pending',
      // checkValidData(firstLetterCaps(row?.bookingStatus)),
    },
  ];
};

// const ButtonFormatter = (url, text) => {
//   return (
//     <>
//       <Link to={url} className="">
//         {text}
//       </Link>
//     </>
//   );
// };

export const paymentReportBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.report.heading'),
    },
  ];
};
export const paymentReportBookingLogBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: reportRoute.PAYMENT_REPORT.path,
      name: i18next.t('text.report.heading'),
    },
    {
      path: '#',
      name: i18next.t('text.serviceManagement.bookingLog'),
    },
  ];
};
export const jobProviderDiscountBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: reportRoute.PAYMENT_REPORT.path,
      name: i18next.t('text.report.heading'),
    },
    {
      path: '#',
      name: i18next.t('text.report.providerDiscountHeading'),
    },
  ];
};
export const purchaseOrderBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: reportRoute.PAYMENT_REPORT.path,
      name: i18next.t('text.report.heading'),
    },
    {
      path: '#',
      name: i18next.t('text.report.purchaseOrderAdd'),
    },
  ];
};

// BreadCrumb
export const subLabureBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: reportRoute.PAYMENT_REPORT.path,
      name: i18next.t('text.report.heading'),
    },
    {
      path: '#',
      name: i18next.t('text.report.SubLaborPayAdd'),
    },
  ];
};

// Return Amount
export const returnAmountBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: reportRoute.PAYMENT_REPORT.path,
      name: i18next.t('text.report.heading'),
    },
    {
      path: '#',
      // name: i18next.t('text.report.purchaseOrderAdd'),
      name: 'Return Amount Details',
    },
  ];
};
// Payment Methods Bread Crumb
export const paymentMethodsBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: reportRoute.PAYMENT_REPORT.path,
      name: i18next.t('text.report.heading'),
    },
    {
      path: '#',
      name: i18next.t('text.report.paymentMethodHeader'),
    },
  ];
};
//

// Bread Crumb
export const invoiceBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: reportRoute.PAYMENT_REPORT.path,
      name: i18next.t('text.report.heading'),
    },
    {
      path: '#',
      name: i18next.t('text.report.invoiceHeading'),
    },
  ];
};

export const splitPaymentBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: reportRoute.PAYMENT_REPORT.path,
      name: i18next.t('text.report.heading'),
    },
    {
      path: '#',
      name: i18next.t('text.report.splitPayment'),
    },
  ];
};
// Split Column
export const splitPaymentColumn = ({
  onSort,
  onFilterChange,
  filter = {},
  page,
  sizePerPage,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      onSort: (field, order) => onSort('id', order),
    },
    {
      dataIndex: 'Booking.bookingNo',
      title: i18next.t('text.common.jobNo'),
      headerClasses: 'sorting',
      onSort: (field, order) => onSort('jobNumber', order),
      sorter: (a, b) => {
        return a?.Booking?.bookingNo < b?.Booking?.bookingNo
          ? -1
          : a?.Booking?.bookingNo > b?.Booking?.bookingNo
          ? 1
          : 0;
      },
      render: (cell, row) => checkValidData(row?.Booking?.bookingNo),
    },
    {
      dataIndex: 'providerName',
      title: i18next.t('text.common.providerName'),
      headerClasses: 'sorting',
      onSort: (field, order) => onSort('providerName', order),
      sorter: (a, b) => {
        return a?.Booking?.Provider?.firstName < b?.Booking?.Provider?.firstName
          ? -1
          : a?.Booking?.Provider?.firstName > b?.Booking?.Provider?.firstName
          ? 1
          : 0;
      },
      render: (cell, row) =>
        getFullName(
          row?.Booking?.Provider?.firstName,
          row?.Booking?.Provider?.lastName
        ),
    },
    {
      dataIndex: 'customerName',
      title: i18next.t('text.common.customerName'),
      headerClasses: 'sorting',
      onSort: (field, order) => onSort('customerName', order),
      sorter: (a, b) => {
        return a?.Booking?.Customer?.firstName < b?.Booking?.Customer?.firstName
          ? -1
          : a?.Booking?.Customer?.firstName > b?.Booking?.Customer?.firstName
          ? 1
          : 0;
      },
      render: (cell, row) =>
        checkValidData(
          getFullName(
            row?.Booking?.Customer?.firstName,
            row?.Booking?.Customer?.lastName
          )
        ),
    },
    {
      dataIndex: 'User.Role.name',
      title: i18next.t('text.report.paidBy'),
      headerClasses: 'sorting',
      // onSort: (field, order) => onSort('paidBy', order),
      // sorter: (a, b) => {
      //   return a?.row?.Booking?.Customer?.firstName <
      //     b?.row?.Booking?.Customer?.firstName
      //     ? -1
      //     : a?.row?.Booking?.Customer?.firstName >
      //       b?.row?.Booking?.Customer?.firstName
      //     ? 1
      //     : 0;
      // },
      render: (cell, row) => 
        row?.User?.Role?.type === 'nca' ?
          checkValidData('admin') : row?.User?.Role?.type === 'subadmin' ? checkValidData('admin') : 
            checkValidData(row?.User?.Role?.name),
    },
    {
      dataIndex: 'totalAmount',
      title: i18next.t('text.report.jobTotalAmount'),
      headerClasses: 'sorting',
      sorter: (a, b) => {
        return a?.totalAmount < b?.totalAmount
          ? -1
          : a?.totalAmount > b?.totalAmount
          ? 1
          : 0;
      },
      render: (cell, row) => checkValidPrice(cell),
      onSort: (field, order) => onSort('jobTotalAmount', order),
    },
    {
      dataIndex: 'paidAmount',
      title: i18next.t('text.report.splitPayments'),
      headerClasses: 'sorting',
      sorter: (a, b) => {
        return a?.paidAmount < b?.paidAmount
          ? -1
          : a?.paidAmount > b?.paidAmount
          ? 1
          : 0;
      },
      render: (cell, row) => checkValidPrice(cell),
      onSort: (field, order) => onSort('splitPayment', order),
    },
    {
      dataIndex: 'paymentMode',
      title: i18next.t('text.report.paymentMethod'),
      headerClasses: 'sorting',
      sorter: (a, b) => {
        return a?.paymentMode < b?.paymentMode
          ? -1
          : a?.paymentMode > b?.paymentMode
          ? 1
          : 0;
      },
      render: (cell, row) => checkValidData(cell),
      onSort: (field, order) => onSort('paymentMethod', order),
    },
    {
      dataIndex: 'bankName',
      title: i18next.t('text.report.bankName'),
      headerClasses: 'sorting',
      sorter: (a, b) => {
        return a?.JSON?.parse(a?.paymentDetails)?.bankName <
          b?.JSON?.parse(b?.paymentDetails)?.bankName
          ? -1
          : a?.JSON?.parse(a?.paymentDetails)?.bankName >
            b?.JSON?.parse(b?.paymentDetails)?.bankName
          ? 1
          : 0;
      },
      render: (cell, row) =>
        checkValidData(JSON.parse(row?.paymentDetails)?.bankName),
      onSort: (field, order) => onSort('bankName', order),
    },
    {
      dataIndex: 'financingNumber',
      title: i18next.t('text.report.checkOrFinancingNum'),
      headerClasses: 'sorting',
      sorter: (a, b) => {
        return checkValidData(a?.JSON?.parse(a?.paymentDetails)?.checkNumber) <
          checkValidData(b?.JSON?.parse(b?.paymentDetails)?.checkNumber)
          ? -1
          : checkValidData(a?.JSON?.parse(a?.paymentDetails)?.checkNumber) >
            checkValidData(b?.JSON?.parse(b?.paymentDetails)?.checkNumber)
          ? 1
          : 0;
      },
      render: (cell, row) =>
        checkValidData(
          JSON.parse(row?.paymentDetails)?.financingNumber ||
            JSON.parse(row?.paymentDetails)?.checkNumber
        ),
      onSort: (field, order) => onSort('CheckFinancingNo', order),
    },
    {
      dataIndex: 'Booking.BookingDetail.paymentStatus',
      title: i18next.t('text.report.jobPaymentStatus'),
      headerClasses: 'sorting',
      sorter: (a, b) => {
        return a?.Booking?.BookingDetail?.paymentStatus <
          b?.Booking?.BookingDetail?.paymentStatus
          ? -1
          : a?.Booking?.BookingDetail?.paymentStatus >
            b?.Booking?.BookingDetail?.paymentStatus
          ? 1
          : 0;
      },
      render: (cell, row) =>
        row?.Booking?.BookingDetail?.paymentStatus === 'complete'
          ? 'Completed'
          : 'Pending',
      // row?.SettledPayments?.length > 0 ? 'Completed' : 'Pending',
      // checkValidData(row?.Booking?.BookingDetail?.paymentStatus),

      onSort: (field, order) => onSort('jobPaymentStatus', order),
    },
  ];
};

export const splitPaymentData = () => {
  return [
    {
      id: 1,
      jobNumber: '75833147',
      providerName: 'jorge neon',
      customerName: 'Shane Watson',
      paidBy: 'Provider',
      jobTotalAmount: '$5400.00',
      splitPayment: '$400.00',
      paymentMethod: 'Card',
      bankName: '-',
      CheckFinancingNo: '-',
      jobPaymentStatus: 'Complete',
    },
    {
      id: 2,
      jobNumber: '75833147',
      providerName: 'jorge neon',
      customerName: 'Shane Watson',
      paidBy: 'Provider',
      jobTotalAmount: '$5400.00',
      splitPayment: '$400.00',
      paymentMethod: 'Card',
      bankName: '-',
      CheckFinancingNo: '-',
      jobPaymentStatus: 'Complete',
    },
  ];
};

// Provider Invoice Update Details
export const providerInvoiceBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: reportRoute.PAYMENT_REPORT.path,
      name: i18next.t('text.report.heading'),
    },
    {
      path: '#',
      name: i18next.t('text.report.providerInvoice'),
    },
  ];
};

// Provider Invoice Column
export const providerInvoiceColumn = ({ onSort, page, sizePerPage }) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (_, __, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'providerName',
      title: i18next.t('text.common.providerName'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('providerName', order),
      render: (_, row) =>
        row?.Booking?.Provider?.firstName
          ? `${capitalizeFirstLetter(
              row.Booking.Provider.firstName
            )} ${capitalizeFirstLetter(row?.Booking?.Provider?.lastName ?? '')}`
          : '-',
    },
    {
      dataIndex: 'serviceType',
      title: i18next.t('text.common.serviceType'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('providerName', order),
      render: (_, row) =>
        row?.Booking?.ServiceType?.name
          ? capitalizeFirstLetter(row?.Booking?.ServiceType?.name)
          : '-',
    },
    {
      dataIndex: 'servicePrice',
      title: i18next.t('text.common.servicePrice'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('servicePrice', order),
      render: (_, row) => checkValidPrice(row?.Booking?.ServiceCategory?.price),
    },
    {
      dataIndex: 'type',
      title: i18next.t('text.report.purchaseSubLabor'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('purchaseOrderSubLaborReturned', order),
      render: (_, row) => {
        if (row?.type === 'returned') {
          return 'Returned Amount';
        } else if (row?.type === 'sublabor') {
          return 'Sub-Labor Pay';
        } else {
          return 'Purchase Order';
        }
      },
    },
    {
      dataIndex: 'name',
      title: i18next.t('text.report.purchaseSubLaborName'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('name', order),
      render: (_, row) => (row?.name ? capitalizeFirstLetter(row.name) : '-'),
    },
    {
      dataIndex: 'total',
      title: i18next.t('text.common.total'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('total', order),
      render: (_, row) => checkValidPrice(row?.totalAmount),
    },
    {
      dataIndex: 'fee',
      title: i18next.t('text.report.goodGuyFee'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('fee', order),
      render: (_, row) =>
        checkValidPrice(row?.Booking?.BookingDetail?.goodGuyFee),
    },
    {
      dataIndex: 'earnedAmount',
      title: i18next.t('text.report.earnedAmount'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('earnedAmount', order),
      render: (_, row) =>
        checkValidPrice(row?.Booking?.BookingDetail?.earnedAmount),
    },
    {
      dataIndex: 'dateTime',
      title: i18next.t('text.report.dateTimes'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('dateTime', order),
      render: (_, row) =>
        row?.createdAt ? moment(row?.createdAt).format('llll') : '-',
    },
  ];
};
// Provider Invice Data
// export const providerInvoiceData = () => {
//   return [
//     {
//       id: 1,
//       providerName: 'jorge neon',
//       serviceType: 'QA & CATEGORY - Free Sch no quote',
//       servicePrice: '$0.00',
//       purchaseOrderSubLaborReturned: 'Purchase Order',
//       name: 'trgj86433',
//       total: '$ 400.00',
//       goodGuyFee: '$0.00',
//       earnedAmount: '	$4700.00',
//       dateTime: 'Wed, November 16, 2022 04:20 AM',
//     },
//     {
//       id: 2,
//       providerName: 'jorge neon',
//       serviceType: 'QA & CATEGORY - Free Sch no quote',
//       servicePrice: '$0.00',
//       purchaseOrderSubLaborReturned: 'Returned Amount',
//       name: 'flu7544',
//       total: '$ 400.00',
//       goodGuyFee: '$0.00',
//       earnedAmount: '	$4700.00',
//       dateTime: 'Wed, November 16, 2022 04:20 AM',
//     },
//     {
//       id: 3,
//       providerName: 'jorge neon',
//       serviceType: 'QA & CATEGORY - Free Sch no quote',
//       servicePrice: '$0.00',
//       purchaseOrderSubLaborReturned: 'Sub-Labor Pay',
//       name: 'testing',
//       total: '$ 400.00',
//       goodGuyFee: '$0.00',
//       earnedAmount: '	$4700.00',
//       dateTime: 'Wed, November 16, 2022 04:20 AM',
//     },
//   ];
// };
const totalFeesFields = {
  fromOperatorField: 'totalFeesFirstOperator',
  fromOperatorAmountField: 'totalFeesFirstAmount',
  conditionalField: 'totalFeesConditional',
  toOperatorField: 'totalFeesSecondOperator',
  toOperatorAmountField: 'totalFeeSecondAmount',
};

const platformFeesFields = {
  fromOperatorField: 'platformFeesFirstOperator',
  fromOperatorAmountField: 'platformFeesFirstAmount',
  conditionalField: 'platformFeesConditional',
  toOperatorField: 'platformFeesSecondOperator',
  toOperatorAmountField: 'platformFeeSecondAmount',
};
const subLaborFields = {
  fromOperatorField: 'sublaborFirstOperator ',
  fromOperatorAmountField: 'sublaborFirstAmount',
  conditionalField: 'sublaborConditional',
  toOperatorField: 'sublaborSecondOperator',
  toOperatorAmountField: 'sublaborSecondAmount',
};
const returnedPOFields = {
  fromOperatorField: 'returnedPoFirstOperator ',
  fromOperatorAmountField: 'returnedPoFirstAmount',
  conditionalField: 'returnedPoConditional',
  toOperatorField: 'returnedPoSecondOperator',
  toOperatorAmountField: 'returnedPoSecondAmount',
};
const promoCodeFields = {
  fromOperatorField: 'promoCodeFirstOperator ',
  fromOperatorAmountField: 'promoCodeFirstAmount',
  conditionalField: 'promoCodeConditional',
  toOperatorField: 'promoCodeSecondOperator',
  toOperatorAmountField: 'promoCodeSecondAmount',
};
const discountrFields = {
  fromOperatorField: 'discountFirstOperator ',
  fromOperatorAmountField: 'discountFirstAmount',
  conditionalField: 'discountConditional',
  toOperatorField: 'discountSecondOperator',
  toOperatorAmountField: 'discountSecondAmount',
};
const providerPayAmountFields = {
  fromOperatorField: 'providerPayFirstOperator ',
  fromOperatorAmountField: 'providerPayFirstAmount',
  conditionalField: 'providerPayConditional',
  toOperatorField: 'providerPaySecondOperator',
  toOperatorAmountField: 'providerPaySecondAmount',
};
const purchaseOrderFields = {
  fromOperatorField: 'purchaseOrderFirstOperator ',
  fromOperatorAmountField: 'purchaseOrderFirstAmount',
  conditionalField: 'purchaseOrderConditional',
  toOperatorField: 'purchaseOrderSecondOperator',
  toOperatorAmountField: 'purchaseOrderSecondAmount',
};
// temporary keys
const planDiscountFields = {
  fromOperatorField: 'planDiscountFirstOperator ',
  fromOperatorAmountField: 'planDiscountFirstAmount',
  conditionalField: 'planDiscountConditional',
  toOperatorField: 'planDiscountSecondOperator',
  toOperatorAmountField: 'planDiscountSecondAmount',
};
export const PaymentReportTableColumn = ({
  onFilterChange,
  InvoicePage,
  filter = {},
  paymentReportModals,
  setIsOpenModal,
  page,
  sizePerPage,
  // providerListData,
  // customerListData,
  setRowData,
  isUserCanEdit,
  isNCAUser,
  onCheckAllChange,
  defaultFilter,
  filterObj,
  settledCheck,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      export: true,
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      width: 70,
    },
    {
      dataIndex: 'bookingNo',
      title: i18next.t('text.report.jobNumber'),
      exportName: i18next.t('text.report.jobNumberExport'),
      headerClasses: 'sorting',
      // sorter: true,
      render: (cell, row) =>
        ButtonTextFormatter(
          generatePath(reportRoute.BOOKING_VIEW.path, {
            id: row?.id,
            viewQuote:
              row?.ServiceCategory?.isSalesQuote === 1
                ? 'isViewQuote'
                : 'isNoViewQuote',
          }),
          cell,
          false,
          {},
          '_blank'
        ),
      ...getInputSearchFilter({
        dataIndex: 'bookingNo',
        placeholder: 'job number',
        onFilterChange,
        filter,
      }),
      width: 150,
    },
    {
      dataIndex: 'providerName',
      dataField: 'Provider.firstName',
      filterDataIndex: ['providerUserId'],
      title: i18next.t('text.report.provider'),
      headerClasses: 'sorting',
      sorter: true,
      export: true,
      exportName: i18next.t('text.report.providerName'),
      exportFormatter: (_, row) =>
        getFullName(row?.Provider?.firstName, row?.Provider?.lastName),
      render: (_, row) =>
        row?.Provider?.firstName
          ? getFullName(row?.Provider?.firstName, row?.Provider?.lastName)
          : '-',
      // ...getMultiSelectSearchFilter({
      //   dataIndex: 'providerUserId',
      //   onFilterChange,
      //   name: 'provider name',
      //   list: [],
      //   mode: false,
      //   listService: () => getFilterListData(providerListData),
      //   filter,
      // }),
      ...getMultiSelectSearchFilter({
        dataIndex: 'providerUserId',
        onFilterChange,
        name: 'provider name',
        mode: 'multiple',
        // scope: 'all',
        listService: providerListService,
        searchOrNot: true,
        filter,
        listKeys: { nameKey: ['firstName', 'lastName'], nameId: 'id' },
        // defaultFilter,
        addExtraObj: filterObj,
        // options: getFilterListData(providerListData),
      }),
      width: 220,
    },
    {
      dataIndex: 'userName',
      dataField: 'Customer.firstName',
      filterDataIndex: ['customerUserId'],
      title: i18next.t('text.report.user'),
      headerClasses: 'sorting',
      sorter: true,
      render: (_, row) =>
        row?.Customer?.firstName
          ? getFullName(
              row?.Customer?.firstName,
              row?.Customer?.lastName,
              '',
              row?.Customer?.CustomerMembership?.MembershipPlan?.name
              // 101,
              // 'percentage'
            )
          : '-',
      exportName: i18next.t('text.report.userName'),
      export: true,
      exportFormatter: (cell, row) =>
        getFullName(
          row?.Customer?.firstName,
          row?.Customer?.lastName,
          '',
          row?.Customer?.CustomerMembership?.MembershipPlan?.name
          // 101,
          // 'percentage'
        ),
      ...getMultiSelectSearchFilter({
        dataIndex: 'customerUserId',
        onFilterChange,
        name: 'user name',
        list: [],
        mode: 'multiple',
        addExtraObj: filterObj,
        // scope: 'all',
        listKeys: { nameKey: ['firstName', 'lastName'], nameId: 'id' },
        listService: CustomersService?.CustomersListService,
        searchOrNot: true,
        filter,
        extraQuary: { isFilter: 1 },
      }),
      width: 220,
    },
    {
      dataIndex: 'bookingScheduleDate',
      title: i18next.t('text.report.jobsDate'),
      headerClasses: 'sorting',
      // sorter: true,
      exportName: i18next.t('text.report.jobsDate'),
      export: true,
      exportFormatter: (cell, row) =>
        checkValidData(showDateInBrowser(row?.bookingScheduleDate)),
      render: (cell, row) =>
        row?.bookingScheduleDate
          ? checkValidData(showDateInBrowser(row?.bookingScheduleDate))
          : '-',
      width: 220,
    },
    {
      dataIndex: 'jobLocationAddress',
      title: i18next.t('text.report.location'),
      headerClasses: 'sorting',
      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(row?.jobLocationAddress),
          i18next.t('text.report.location'),
          25
        ),
      width: 300,
    },
    // {
    //   customerPaid: 'Customer Paid',
    //   dataIndex: 'customerPaid',
    //   headerClasses: 'sorting',
    //   title: i18next.t('text.serviceManagement.customerPaid'),
    //   sorter: true,
    //   // onSort: (field, order) => onSort('customerPaid', order),
    //   render: (cell, row) => checkValidPrice(0),
    //   ...getInputSearchFilter({
    //     dataIndex: 'customerPaid',
    //     placeholder: 'customer paid',
    //     onFilterChange,
    //     filter,
    //   }),
    //   width: 250,
    // },
    {
      dataIndex: 'membershipPlanType',
      filterDataIndex: ['membershipPlanType'],
      title: i18next.t('text.common.membershipType'),
      headerClasses: 'sorting',
      // sorter: true,
      ...getMultiSelectSearchFilter({
        dataIndex: 'membershipPlanType',
        onFilterChange,
        name: 'membership type',
        list: [],
        mode: false,
        listService: getMembershipTypeData,
        filter,
      }),
      export: true,
      exportName: i18next.t('text.report.membershipType'),
      exportFormatter: (cell, row) => row?.membershipPlanType,
      render: (cell, row) => 
        checkValidData(splitLetter(row?.membershipPlanType)),
      width: 220,
    },
    {
      dataIndex: 'customerPaid',
      // filterDataIndex: ['customerPaid'],
      title: i18next.t('text.common.customerPaid'),
      headerClasses: 'sorting',
      // sorter: true,
      exportName: i18next.t('text.common.customerPaid'),
      // export: true,
      exportFormatter: (cell, row) => checkValidPrice(row?.customerPaid),
      render: (cell, row) => checkValidPrice(row?.customerPaid),
      // ...getPriceFilter({
      //   dataIndex: 'totalFees',
      //   onFilterChange,
      //   fields: totalFeesFields,
      //   filter,
      //   dataIndexKey: 'filterColumn',
      // }),
      // ...getColumnSelectAmountProps(),
      width: 150,
    },
    {
      dataIndex: 'memberShipPlan',
      // filterDataIndex: ['customerPaid'],
      title: i18next.t('text.common.memberPlan'),
      headerClasses: 'sorting',
      // sorter: true,
      exportName: i18next.t('text.common.memberPlan'),
      // export: true,
      exportFormatter: (cell, row) =>
        checkValidData(row?.CustomerMembership?.MembershipPlan?.name),
      render: (cell, row) =>
        checkValidData(row?.CustomerMembership?.MembershipPlan?.name),
      // ...getInputSearchFilter({
      //   dataIndex: 'memberShipPlan',
      //   placeholder:'membership plan',
      //   onFilterChange,
      //   filter,
      // }),
      // ...getColumnSelectAmountProps(),
      width: 220,
    },
    {
      dataIndex: 'total',
      filterDataIndex: ['totalFeesConditional'],
      title: i18next.t('text.report.atotalFees'),
      headerClasses: 'sorting',
      // sorter: true,
      exportName: i18next.t('text.report.atotalFees'),
      export: true,
      exportFormatter: (cell, row) => checkValidPrice(row?.total),
      render: (cell, row) => checkValidPrice(row?.total),

      ...getPriceFilter({
        dataIndex: 'totalFees',
        onFilterChange,
        fields: totalFeesFields,
        filter,
        dataIndexKey: 'filterColumn',
      }),
      // ...getColumnSelectAmountProps(),
      width: 150,
    },
    {
      dataField: 'BookingDetail.platformFees',
      filterDataIndex: ['platformFeesConditional'],
      title: i18next.t('text.report.bPlatformFees'),
      headerClasses: 'sorting',
      // sorter: true,
      // ...getColumnSelectAmountProps(),
      export: true,
      exportName: i18next.t('text.report.bPlatformFees'),
      exportFormatter: (cell, row) =>
        checkValidPrice(row?.BookingDetail?.platformFees),
      render: (cell, row) => checkValidPrice(row?.BookingDetail?.platformFees),
      ...getPriceFilter({
        dataIndex: 'platformFees',
        onFilterChange,
        fields: platformFeesFields,
        filter,
        dataIndexKey: 'filterColumn',
      }),
      width: 160,
    },
    {
      dataIndex: 'promoCodeDiscount',
      filterDataIndex: ['promoCodeConditional'],
      title: i18next.t('text.report.ePromoCodeDiscount'),
      headerClasses: 'sorting',
      export: true,
      exportName: i18next.t('text.report.ePromoCodeDiscount'),
      exportFormatter: (cell, row) => checkValidPrice(row?.promoCodeDiscount),
      render: (cell, row) => checkValidPrice(row?.promoCodeDiscount),
      // sorter: true,
      // ...getColumnSelectAmountProps(),
      ...getPriceFilter({
        dataIndex: 'promoCodeDiscount',
        onFilterChange,
        fields: promoCodeFields,
        filter,
        dataIndexKey: 'filterColumn',
      }),
      width: 230,
    },
    {
      dataIndex: 'discount',
      filterDataIndex: ['discountConditional'],
      title: i18next.t('text.report.dJobProviderDiscountAmount'),
      headerClasses: 'sorting',
      export: true,
      exportName: i18next.t('text.report.dJobProviderDiscountAmountExport'),
      exportFormatter: (cell, row) => checkValidPrice(row?.discount),
      ...getPriceFilter({
        dataIndex: 'jobProviderDiscountAmount',
        onFilterChange,
        fields: discountrFields,
        filter,

        dataIndexKey: 'filterColumn',
      }),
      render: (cell, row) => {
        return row?.BookingDetail?.providerPaymentStatus === 'settelled' ? (
          checkValidPrice(row?.discount)
        ) : (
          <>
            {checkValidPrice(row?.discount)} <br />{' '}
            <CommonButton
              disabled={!isUserCanEdit}
              // onClick={() => workInProgressToast()}
              onClick={() => {
                setIsOpenModal(paymentReportModals.jobProviderDiscountModal);
                setRowData(row);
              }}
              className="btn btn-primary btn-sm"
            >
              {i18next.t('text.report.addEdit')}
            </CommonButton>
          </>
        );
      },
      width: 280,
    },
    {
      dataField: 'BookingDetail.purchaseAmount',
      filterDataIndex: ['purchaseOrderConditional'],
      title: i18next.t('text.report.ePurchaseOrderTotal'),
      headerClasses: 'sorting',
      export: true,
      exportName: i18next.t('text.report.ePurchaseOrderTotal'),
      exportFormatter: (cell, row) =>
        checkValidPrice(reduceAddTotalAmount(row, 'purchase')),

      ...getPriceFilter({
        dataIndex: 'purchaseOrderTotal',
        onFilterChange,
        fields: purchaseOrderFields,
        filter,
        dataIndexKey: 'filterColumn',
      }),
      render: (cell, row) => {
        return row?.BookingDetail?.providerPaymentStatus === 'settelled' ? (
          <>
            {reduceAddTotalAmount(row, 'purchase') === 0
              ? checkValidPrice(reduceAddTotalAmount(row, 'purchase'))
              : checkValidData(
                  checkValidPrice(reduceAddTotalAmount(row, 'purchase'))
                )}
            <br />{' '}
            {reduceAddTotalAmount(row, 'purchase') !== 0 && (
              <CommonButton
                onClick={() => {
                  setIsOpenModal(paymentReportModals.purchaseOrderTotalModal);
                  setRowData(row);
                }}
                disabled={
                  !isUserCanEdit
                    ? reduceAddTotalAmount(row, 'purchase') > 0
                      ? false
                      : true
                    : false
                }
                className="btn btn-primary btn-sm"
              >
                {i18next.t('text.report.viewDetails')}
              </CommonButton>
            )}
          </>
        ) : (
          <>
            {reduceAddTotalAmount(row, 'purchase') === 0
              ? checkValidPrice(reduceAddTotalAmount(row, 'purchase'))
              : checkValidData(
                  checkValidPrice(reduceAddTotalAmount(row, 'purchase'))
                )}
            <br />{' '}
            <CommonButton
              onClick={() => {
                setIsOpenModal(paymentReportModals.purchaseOrderTotalModal);
                setRowData(row);
              }}
              disabled={
                !isUserCanEdit
                  ? reduceAddTotalAmount(row, 'purchase') > 0
                    ? false
                    : true
                  : false
              }
              className="btn btn-primary btn-sm"
            >
              {reduceAddTotalAmount(row, 'purchase') > 0
                ? i18next.t('text.report.viewDetails')
                : i18next.t('text.report.addEdit')}
            </CommonButton>
          </>
        );
      },
      width: 230,
    },
    {
      dataField: 'BookingDetail.sublaborAmount',
      filterDataIndex: ['sublaborConditional'],
      title: i18next.t('text.report.fSubLaborTotalFees'),
      headerClasses: 'sorting',
      export: true,
      exportKey: 'BookingDetail',
      exportName: i18next.t('text.report.fSubLaborTotalFees'),
      exportFormatter: (cell, row) =>
        checkValidPrice(row?.BookingDetail?.sublaborAmount),
      // exportName: 'Hello there',

      ...getPriceFilter({
        dataIndex: 'sublaborTotalFees',
        onFilterChange,
        fields: subLaborFields,
        filter,
        dataIndexKey: 'filterColumn',
      }),
      render: (cell, row) => {
        return row?.BookingDetail?.providerPaymentStatus === 'settelled' ? (
          <>
            {reduceAddTotalAmount(row, 'sublabor') === 0
              ? checkValidPrice(reduceAddTotalAmount(row, 'sublabor'))
              : checkValidData(
                  checkValidPrice(reduceAddTotalAmount(row, 'sublabor'))
                )}
            <br />{' '}
            {reduceAddTotalAmount(row, 'sublabor') !== 0 && (
              <CommonButton
                onClick={() => {
                  setIsOpenModal(paymentReportModals.sublaborTotalFeesModal);
                  setRowData(row);
                }}
                disabled={
                  !isUserCanEdit
                    ? reduceAddTotalAmount(row, 'sublabor') > 0
                      ? false
                      : true
                    : false
                }
                className="btn btn-primary btn-sm"
              >
                {i18next.t('text.report.viewDetails')}
              </CommonButton>
            )}
          </>
        ) : (
          <>
            {reduceAddTotalAmount(row, 'sublabor') === 0
              ? checkValidPrice(reduceAddTotalAmount(row, 'sublabor'))
              : checkValidData(
                  checkValidPrice(reduceAddTotalAmount(row, 'sublabor'))
                )}
            <br />{' '}
            <CommonButton
              onClick={() => {
                setIsOpenModal(paymentReportModals.sublaborTotalFeesModal);
                setRowData(row);
              }}
              disabled={
                !isUserCanEdit
                  ? reduceAddTotalAmount(row, 'sublabor') > 0
                    ? false
                    : true
                  : false
              }
              className="btn btn-primary btn-sm"
            >
              {reduceAddTotalAmount(row, 'sublabor') > 0
                ? i18next.t('text.report.viewDetails')
                : i18next.t('text.report.addEdit')}
            </CommonButton>
          </>
        );
      },
      width: 220,
    },
    {
      dataIndex: 'returnedPo',
      filterDataIndex: ['returnedPoConditional'],
      title: i18next.t('text.report.gReturnedPO'),
      headerClasses: 'sorting',
      export: true,
      exportName: i18next.t('text.report.gReturnedPO'),
      exportFormatter: (cell, row) => checkValidPrice(row?.returnedAmount),
      ...getPriceFilter({
        dataIndex: 'returnedPo',
        onFilterChange,
        fields: returnedPOFields,
        filter,
        dataIndexKey: 'filterColumn',
      }),
      render: (cell, row) => {
        return row?.BookingDetail?.providerPaymentStatus === 'settelled' ? (
          <>
            {reduceAddTotalAmount(row, 'returned') === 0
              ? checkValidPrice(reduceAddTotalAmount(row, 'returned'))
              : checkValidData(
                  checkValidPrice(reduceAddTotalAmount(row, 'returned'))
                )}
            <br />{' '}
            {reduceAddTotalAmount(row, 'returned') !== 0 && (
              <CommonButton
                onClick={() => {
                  setIsOpenModal(paymentReportModals.returnedPoModal);
                  setRowData(row);
                }}
                disabled={
                  !isUserCanEdit
                    ? reduceAddTotalAmount(row, 'returned') > 0
                      ? false
                      : true
                    : false
                }
                className="btn btn-primary btn-sm"
              >
                {i18next.t('text.report.viewDetails')}
              </CommonButton>
            )}
          </>
        ) : (
          <>
            {reduceAddTotalAmount(row, 'returned') === 0
              ? checkValidPrice(reduceAddTotalAmount(row, 'returned'))
              : checkValidData(
                  checkValidPrice(reduceAddTotalAmount(row, 'returned'))
                )}
            <br />{' '}
            <CommonButton
              onClick={() => {
                setIsOpenModal(paymentReportModals.returnedPoModal);
                setRowData(row);
              }}
              disabled={
                !isUserCanEdit
                  ? reduceAddTotalAmount(row, 'returned') > 0
                    ? false
                    : true
                  : false
              }
              className="btn btn-primary btn-sm"
            >
              {reduceAddTotalAmount(row, 'returned') > 0
                ? i18next.t('text.report.viewDetails')
                : i18next.t('text.report.addEdit')}
            </CommonButton>
          </>
        );
      },
      width: 210,
    },
    // comment plan discount column

    // {
    //   dataField: 'BookingDetail.planDiscount',
    //   filterDataIndex: ['planDiscount'],
    //   title: i18next.t('text.report.hPlanDiscount'),
    //   headerClasses: 'sorting',
    //   export: true,
    //   exportName: i18next.t('text.report.hPlanDiscount'),
    //   exportFormatter: (cell, row) => checkValidPrice(''),
    //   render: (cell, row) => checkValidPrice(''),
    //   ...getPriceFilter({
    //     dataIndex: 'planDiscount',
    //     onFilterChange,
    //     fields: planDiscountFields,
    //     filter,
    //     dataIndexKey: 'filterColumn',
    //   }),
    //   width: 290,
    // },
    {
      dataField: 'BookingDetail.providerPayAmount',
      filterDataIndex: ['providerPayConditional'],
      title: i18next.t('text.report.providerPayAmount'),
      headerClasses: 'sorting',
      export: true,
      exportName: i18next.t('text.report.providerPayAmountExport'),
      exportFormatter: (cell, row) =>
        checkValidPrice(row?.BookingDetail?.providerPayAmount, 'USD'),
      render: (cell, row) =>
        checkValidPrice(row?.BookingDetail?.providerPayAmount),
      ...getPriceFilter({
        dataIndex: 'providerPayAmount',
        onFilterChange,
        fields: providerPayAmountFields,
        filter,
        dataIndexKey: 'filterColumn',
      }),
      width: 290,
    },

    {
      dataField: 'BookingDetail.serviceStatus',
      title: i18next.t('text.report.jobStatus'),
      headerClasses: 'sorting',
      // sorter: true,
      export: true,
      exportName: i18next.t('text.report.jobStatus'),
      exportFormatter: (cell, row) =>
        checkValidData(firstLetterCaps(row?.BookingDetail?.serviceStatus)),
      render: (cell, row) =>
        checkValidData(firstLetterCaps(row?.BookingDetail?.serviceStatus)),
      width: 150,
    },
    {
      dataField: 'BookingDetail.serviceStartAt',
      title: i18next.t('text.report.jobStartTime'),
      headerClasses: 'sorting',
      // sorter: true,
      export: true,
      exportName: i18next.t('text.report.jobStartTimeExport'),
      exportFormatter: (cell, row) =>
        row?.BookingDetail?.serviceStartAt
          ? checkValidData(
              dateFormatter(
                row?.BookingDetail.serviceStartAt,
                'Do-MMMM-YYYY hh:mm:ss'
              )
            )
          : '-',
      render: (cell, row) =>
        row?.BookingDetail?.serviceStartAt
          ? checkValidData(
              dateFormatter(
                row?.BookingDetail.serviceStartAt,
                'Do-MMMM-YYYY hh:mm:ss'
              )
            )
          : '-',
      width: 220,
    },
    {
      dataField: 'BookingDetail.serviceEndAt',
      title: i18next.t('text.report.jobEndTime'),
      headerClasses: 'sorting',
      // sorter: true,
      export: true,
      exportName: i18next.t('text.report.jobEndTimeExport'),
      exportFormatter: (cell, row) =>
        row?.BookingDetail?.serviceEndAt
          ? checkValidData(
              dateFormatter(
                row?.BookingDetail.serviceEndAt,
                'Do-MMMM-YYYY hh:mm:ss'
              )
            )
          : '-',
      render: (cell, row) =>
        row?.BookingDetail?.serviceEndAt
          ? checkValidData(
              dateFormatter(
                row?.BookingDetail.serviceEndAt,
                'Do-MMMM-YYYY hh:mm:ss'
              )
            )
          : '-',
      width: 220,
    },
    {
      dataIndex: 'jobTime',
      title: i18next.t('text.report.jobCompletionTime'),
      headerClasses: 'sorting',
      export: true,
      render: (_, row) => {
        if (
          row?.BookingDetail?.serviceDuration &&
          parseFloat(row?.BookingDetail?.serviceDuration)
        ) {
          return minuteIntoHHMMSS(row?.BookingDetail?.serviceDuration);
        } else {
          return '-';
        }
      },
      exportName: i18next.t('text.report.jobCompletionTimeExport'),
      exportFormatter: (_, row) => {
        if (
          row?.BookingDetail?.serviceDuration &&
          parseFloat(row?.BookingDetail?.serviceDuration)
        ) {
          return minuteIntoHHMMSS(row?.BookingDetail?.serviceDuration);
        } else {
          return '';
        }
      },
      width: 220,
    },
    {
      dataField: 'BookingDetail.paymentMode',
      filterDataIndex: ['paymentMethod'],
      title: i18next.t('text.report.paymentMethod'),
      headerClasses: 'sorting',
      // sorter: true,
      export: true,
      exportName: i18next.t('text.report.paymentMethod'),
      exportFormatter: (cell, row) =>
        checkValidData(row?.BookingDetail.paymentMode),
      // exportFormatter: (cell, row) =>
      //   checkValidData(firstLetterCaps(row?.BookingDetail.paymentMode)),
      ...getMultiSelectSearchFilter({
        dataIndex: 'paymentMethod',
        onFilterChange,
        name: 'payment method',
        list: [],
        mode: 'multiple',
        listService: getPaymentMethodData,
        filter,
      }),

      render: (cell, row) => {
        let bookingLatestPayments =
          row?.BookingPayments?.length &&
          row?.BookingPayments.sort((a, b) => {
            return b.id - a.id;
          });

        let paymentDetails =
          bookingLatestPayments &&
          bookingLatestPayments[0]?.paymentDetails &&
          JSON.parse(bookingLatestPayments[0]?.paymentDetails);

        let adminRequestMode = row?.BookingDetail?.paymentMode;

        let paymentMode =
          bookingLatestPayments &&
          bookingLatestPayments[0] &&
          bookingLatestPayments[0]?.paymentMode;

        let adminModeWarranty = row?.BookingDetail?.adminMode;
        let adminReason =
          bookingLatestPayments &&
          bookingLatestPayments.find(
            (e) => e?.paymentMode === 'admin' || e?.paymentMode === 'warranty'
          )?.paymentDetails &&
          JSON.parse(
            bookingLatestPayments.find(
              (e) => e?.paymentMode === 'admin' || e?.paymentMode === 'warranty'
            )?.paymentDetails
          )?.reason;

        return (
          <>
            {(paymentDetails?.authorizedUser === 'admin' &&
              adminRequestMode !== 'admin' &&
              adminRequestMode !== 'cash') ||
            (adminRequestMode === 'request' &&
              (!paymentDetails?.authorizedUser ||
                adminModeWarranty === 'pending')) ? (
              <>
                Request
                <br />
                <CommonButton
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    setIsOpenModal({
                      adminModal: 'adminRequest',
                      adminPaymentMode: paymentMode
                        ? adminRequestMode === 'request' &&
                          adminModeWarranty === 'pending'
                          ? false
                          : paymentMode
                        : adminModeWarranty === 'warranty' ||
                          adminModeWarranty === 'waive'
                        ? adminModeWarranty
                        : false,

                      adminPaymentDetails: paymentDetails,
                    });
                    setRowData(row);
                  }}
                  disabled={
                    !isUserCanEdit &&
                    (paymentMode
                      ? false
                      : adminModeWarranty === 'warranty' ||
                        adminModeWarranty === 'waive'
                      ? false
                      : true)
                  }
                >
                  View Option
                </CommonButton>
              </>
            ) : paymentDetails?.authorizedUser === 'provider' ? (
              bookingLatestPayments[0]?.paymentMode === 'cash' ||
              bookingLatestPayments[0]?.paymentMode === 'card' ? (
                firstLetterCaps(bookingLatestPayments[0]?.paymentMode)
              ) : bookingLatestPayments[0]?.paymentMode === 'check' ||
                bookingLatestPayments[0]?.paymentMode === 'finance' ? (
                <>
                  {bookingLatestPayments[0]?.paymentMode === 'finance'
                    ? 'Financing'
                    : firstLetterCaps(bookingLatestPayments[0]?.paymentMode)}
                  <br />
                  <CommonButton
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                      setIsOpenModal({
                        adminModal: 'adminRequest',
                        adminPaymentMode: paymentMode,
                        adminPaymentDetails: paymentDetails,
                        authorizedUser: 'provider',
                      });
                      setRowData(row);
                    }}
                  >
                    View Details
                  </CommonButton>
                </>
              ) : (
                <>
                  Admin
                  <br />
                  <CommonButton
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                      setIsOpenModal({
                        adminModal: 'adminRequest',
                        adminPaymentDetails: paymentDetails,
                        authorizedUser: 'adminAttention',
                        adminPaymentMode: paymentMode
                          ? paymentMode === 'admin' &&
                            adminModeWarranty === 'pending'
                            ? paymentMode
                            : adminModeWarranty
                          : adminModeWarranty === 'warranty' ||
                            adminModeWarranty === 'waive'
                          ? adminModeWarranty
                          : false,
                        bookingLatestPayments: bookingLatestPayments,
                      });
                      setRowData(row);
                    }}
                    disabled={!isUserCanEdit && paymentMode === 'admin'}
                  >
                    View Reason
                  </CommonButton>
                </>
              )
            ) : (
              <>
                {paymentDetails?.authorizedUser === 'customer' ? (
                  firstLetterCaps(paymentMode)
                ) : (
                  <>
                    {paymentMode === 'warranty'
                      ? firstLetterCaps(paymentMode)
                      : 'Admin'}

                    <br />
                    <CommonButton
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        setIsOpenModal({
                          adminModal: 'adminRequest',
                          adminPaymentDetails: {
                            ...paymentDetails,
                            ...(adminReason && { reason: adminReason }),
                          },
                          authorizedUser: 'adminAttention',
                          adminPaymentMode: paymentMode
                            ? paymentMode === 'admin' &&
                              adminModeWarranty === 'pending'
                              ? paymentMode
                              : adminModeWarranty
                            : adminModeWarranty === 'warranty' ||
                              adminModeWarranty === 'waive'
                            ? adminModeWarranty
                            : false,
                          bookingLatestPayments: bookingLatestPayments,
                        });
                        setRowData(row);
                      }}
                      disabled={!isUserCanEdit && paymentMode === 'admin'}
                    >
                      View Reason
                    </CommonButton>
                  </>
                )}
              </>
            )}
          </>
        );
      },
      width: 220,
    },
    {
      dataField: 'BookingDetail.bookingId',
      filterDataIndex: ['jobPaymentType'],
      title: i18next.t('text.report.jobPaymentType'),
      headerClasses: 'sorting',
      // sorter: true,
      ...getMultiSelectSearchFilter({
        dataIndex: 'jobPaymentType',
        onFilterChange,
        name: 'job payment type',
        list: [],
        mode: false,
        listService: getPaymentTypeData,
        filter,
      }),
      export: true,
      exportName: firstLetterCaps(i18next.t('text.report.jobPaymentType')),
      exportFormatter: (cell, row) =>
        row?.BookingDetail?.isSplitPayment === true
          ? i18next.t('text.report.splitPayment')
          : i18next.t('text.report.fullPayment'),

      render: (cell, row) => {
        let bookingLatestPayments =
          row?.BookingPayments?.length &&
          row?.BookingPayments.sort((a, b) => {
            return b.id - a.id;
          });

        // let authorizedUser =
        //   bookingLatestPayments &&
        //   bookingLatestPayments
        //     .map((e) => JSON.parse(e?.paymentDetails))
        //     .find((z) => z?.authorizedUser === 'provider')?.authorizedUser;
        if (
          row?.BookingDetail?.isSplitPayment === true
          // || authorizedUser === 'provider'
        ) {
          return (
            <>
              {i18next.t('text.report.splitPayment')} <br />
              <CommonButton
                // onClick={() => workInProgressToast()}
                onClick={() => {
                  setIsOpenModal(paymentReportModals.splitPaymentDetailsModal);
                  setRowData(row);
                }}
                className="btn btn-primary btn-sm"
              >
                {i18next.t('text.report.splitPayment')}
              </CommonButton>
            </>
          );
        } else {
          return <> {i18next.t('text.report.fullPayment')}</>;
        }
      },
      width: 200,
    },
    ...(isNCAUser
      ? [
          {
            dataIndex: 'paymentSettledByAdmin',
            title: i18next.t('text.report.paymentSettledByAdmin'),
            headerClasses: 'sorting',
            // sorter: true,
            ...getMultiSelectSearchFilter({
              dataIndex: 'paymentSettledByAdmin',
              onFilterChange,
              name: 'payment settled by admin',
              list: [],
              mode: false,
              listService: getProviderPaymentStatusData,
              filter,
            }),
            render: (cell, row) =>
              row?.BookingDetail?.providerPaymentStatus
                ? firstLetterCaps(
                    row?.BookingDetail?.providerPaymentStatus === 'unsettelled'
                      ? 'unsettled'
                      : 'settled'
                  )
                : '-',
            width: 250,
          },
        ]
      : [{}]),
    {
      dataField: 'BookingDetail.providerPaymentStatus',
      filterDataIndex: ['providerPaymentStatus'],
      title: i18next.t('text.report.providerPaymentStatus'),
      headerClasses: 'sorting',
      // sorter: true,
      ...getMultiSelectSearchFilter({
        dataIndex: 'providerPaymentStatus',
        onFilterChange,
        name: 'provider payment status',
        list: [],
        mode: false,
        listService: getProviderPaymentStatusData,
        filter,
      }),
      // firstLetterCaps(row?.BookingDetail?.providerPaymentStatus)
      export: true,
      exportName: i18next.t('text.report.providerPaymentStatus'),
      exportFormatter: (cell, row) =>
        row?.BookingDetail?.providerPaymentStatus
          ? firstLetterCaps(row?.BookingDetail?.providerPaymentStatus)
          : '-',
      render: (cell, row) => (
        <>
          {row?.BookingDetail?.providerPaymentStatus
            ? row?.BookingDetail?.providerPaymentStatus === 'unsettelled'
              ? 'Unsettled'
              : 'Settled'
            : '-'}{' '}
          {row?.SubLabors?.length > 0 &&
            ButtonTextFormatter(
              generatePaths(
                providerInvoiceRoute.PROVIDER_INVOICE_UPDATE.path,
                row?.id
              ),
              '[View Updated Invoice Details]'
            )}
        </>
      ),
      width: 290,
    },
    // Table.SELECTION_COLUMN,
    {
      dataIndex: 'selectAll',
      title: (e) => {
        return (
          <>
            {i18next.t('text.report.selectAll')}{' '}
            {isUserCanEdit && settledCheck && (
              <Checkbox
                value="all"
                className="ms-1"
                onChange={onCheckAllChange}
              />
            )}
          </>
        );
      },
      headerClasses: 'sorting',
      // sorter: true,
      render: (cell, row) => {
        if (cell === 'View Invoice') {
          return (
            <>
              <CommonButton
                onClick={(e) => InvoicePage(e)}
                className="btn btn-primary btn-sm"
              >
                {i18next.t('text.report.viewInvoice')}
              </CommonButton>
            </>
          );
        } else {
          if (cell !== '') {
            return (
              <>
                {cell}
                <div className="d-flex align-items-center">
                  {isUserCanEdit &&
                    row?.BookingDetail?.providerPaymentStatus ===
                      'unsettelled' &&
                    row?.BookingDetail?.paymentStatus === 'complete' && (
                      <Checkbox
                        value={row?.bookingNo}
                        onChange={onCheckAllChange}
                        className="me-1"
                      />
                    )}
                  {ButtonFormatter(
                    generatePath(commonAccessRoutes.INVOICE.path, {
                      viewInvoice: 'invoice',
                      routePath: 'payment-report',
                      id: row?.id,
                    }),
                    'View Invoice',
                    '_blank'
                  )}

                  {/* <CommonButton
                    // onClick={() => workInProgressToast()}
                    onClick={() => InvoicePage(row?.id)}
                    className="btn btn-primary btn-sm ms-1"
                  >
                    {i18next.t('text.report.viewInvoice')}
                  </CommonButton> */}
                </div>
              </>
            );
          }
        }
      },
      width: 200,
    },
    {
      dataIndex: 'SettledPayments',
      filterDataIndex: ['paymentTransferStatus'],
      title: i18next.t('text.report.paymentTransferStatus'),
      headerClasses: 'sorting',
      // sorter: true,
      ...getMultiSelectSearchFilter({
        dataIndex: 'paymentTransferStatus',
        onFilterChange,
        name: 'Payment Transfer Status',
        list: [],
        mode: false,
        listService: getPaymentTransferStatusData,
        filter,
      }),
      // ...getColumnSelectSearchProps('status', getPaymentTransferStatusData()),
      export: true,
      exportName: i18next.t('text.report.paymentTransferStatus'),
      exportFormatter: (cell, row) => row?.paymentTransferStatus,
      render: (cell, row) =>
        checkValidData(
          firstLetterCaps(
            row?.paymentTransferStatus === 'tranfered' && 'Transfered'
          )
        ),
      width: 220,
    },
  ].filter((value) => Object.values(value).length > 0);
};

// Purchase Order list Column and Data

export const purchaseOrderColumn = ({
  onSort,
  filter = {},
  selectedRows,
  setValue,
  subLaborColumn = false,
  providerName,
  serviceType,
  status,
  bookingNo,
  servicePrice,
  providerDiscountData,
  page,
  sizePerPage,
  form,
  selectRow,
  providerData,
  checkBoxIDs,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.sno'),
      sort: true,
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      // onSort: (field, order) => onSort('name', order),
    },
    {
      dataIndex: 'bookingNo',
      title: i18next.t('text.common.jobNo'),
      render: (cell, row) => providerDiscountData?.bookingNo,
      onSort: (field, order) => onSort('Job Number', order),
      sorter: (a, b) => {
        return a?.providerDiscountData?.bookingNo <
          b?.providerDiscountData?.bookingNo
          ? -1
          : a?.providerDiscountData?.bookingNo >
            b?.providerDiscountData?.bookingNo
          ? 1
          : 0;
      },
    },
    {
      dataIndex: 'providerName',
      title: i18next.t('text.common.providerName'),
      render: (cell, row) =>
        getFullName(
          providerDiscountData?.Provider?.firstName,
          providerDiscountData?.Provider?.lastName
        ),
      onSort: (field, order) => onSort('Provider Number', order),
      sorter: (a, b) => {
        return a?.providerDiscountData?.Provider?.firstName <
          b?.providerDiscountData?.Provider?.firstName
          ? -1
          : a?.providerDiscountData?.Provider?.firstName >
            b?.providerDiscountData?.Provider?.firstName
          ? 1
          : 0;
      },
    },
    {
      dataIndex: 'serviceType',
      title: i18next.t('text.common.serviceType'),
      headerClasses: 'sorting',
      render: (cell, row) =>
        checkValidData(
          `${providerDiscountData?.ServiceCategory?.name}(${firstLetterCaps(
            providerDiscountData?.ServiceCategory?.servicePrice
          )} - ${firstLetterCaps(
            providerDiscountData?.ServiceCategory?.serviceType?.replace(
              /_/g,
              ' '
            )
          )})`
        ),
      sorter: (a, b) => {
        return a?.providerDiscountData?.ServiceType <
          b?.providerDiscountData?.ServiceType
          ? -1
          : a?.providerDiscountData?.ServiceType >
            b?.providerDiscountData?.ServiceType
          ? 1
          : 0;
      },
    },
    {
      dataIndex: 'servicePrice',
      title: i18next.t('text.common.servicePrice'),
      headerClasses: 'sorting',
      render: (cell, row) =>
        checkValidPrice(providerDiscountData?.ServiceCategory?.price),
      sorter: (a, b) => {
        return a?.providerDiscountData?.ServiceCategory?.price <
          b?.providerDiscountData?.ServiceCategory?.price
          ? -1
          : a?.providerDiscountData?.ServiceCategory?.price >
            b?.providerDiscountData?.ServiceCategory?.price
          ? 1
          : 0;
      },
    },
    {
      dataIndex: 'name',
      title: i18next.t('text.common.name'),
      headerClasses: 'sorting',
      sorter: (a, b) => {
        return a?.name < b?.name ? -1 : a?.name > b?.name ? 1 : 0;
      },
      render: (cell, row, index) => {
        if (selectedRows?.length > 0 || checkBoxIDs?.length > 0) {
          if (
            selectedRows.includes(row?.id) ||
            checkBoxIDs?.includes(row?.id)
          ) {
            return (
              <>
                <Form.Item
                  initialValue={row?.id}
                  name={`${row?.id}-id`}
                  className="d-none"
                />
                {subLaborColumn && (
                  <Form.Item
                    className="d-none"
                    initialValue={row?.providerUserId}
                    name={`${row?.id}-providerUserId`}
                  />
                )}
                <Form.Item
                  name={`${row?.id}-name`}
                  initialValue={row?.name || undefined}
                  className="ms-1"
                  rules={[
                    {
                      required: selectRow?.includes(row?.id) && true,
                      message: 'Please enter name',
                    },
                  ]}
                >
                  {subLaborColumn ? (
                    <Select
                      name="account"
                      style={{
                        minWidth: '120px',
                      }}
                      placeholder="Please Select"
                      options={providerData?.map((item) => {
                        return {
                          value: item?.id,
                          label: getFullName(
                            item?.firstName,
                            null,
                            item?.lastName
                          ),
                        };
                      })}
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return (
                          option?.label &&
                          ('' + option?.label)
                            ?.toLowerCase()
                            ?.indexOf(input?.toLowerCase()) >= 0
                        );
                      }}
                      showSearch
                      getPopupContainer={(trigger) => trigger.parentElement}
                    />
                  ) : (
                    <Input
                      className="form-control"
                      data-ms-editor="true"
                      onChange={(e) => {
                        setValue([{ id: row?.id, name: e.target.value }]);
                      }}
                      type="text"
                    />
                  )}
                </Form.Item>
              </>
            );
          } else {
            return subLaborColumn
              ? getFullName(row?.labor?.firstName, row?.labor?.lastName)
              : row?.name;
          }
        } else {
          return subLaborColumn
            ? getFullName(row?.labor?.firstName, row?.labor?.lastName)
            : row?.name;
        }
      },
    },
    {
      dataIndex: 'totalAmount',
      title: i18next.t('text.report.totalFees'),
      headerClasses: 'sorting',
      sorter: (a, b) => {
        return a?.totalAmount < b?.totalAmount
          ? -1
          : a?.totalAmount > b?.totalAmount
          ? 1
          : 0;
      },
      render: (cell, row, text, record, index) => {
        if (selectedRows?.length > 0 || checkBoxIDs?.length > 0) {
          if (
            selectedRows.includes(row?.id) ||
            checkBoxIDs?.includes(row?.id)
          ) {
            form.setFieldsValue({
              id: row?.id,
            });
            return (
              <>
                <Form.Item
                  className="d-none"
                  initialValue={row?.id}
                  name={`${row?.id}-id`}
                />

                <div className="d-flex align-items-center">
                  $
                  <Form.Item
                    name={`${row?.id}-totalFees`}
                    initialValue={row?.totalAmount}
                    className="ms-1"
                    rules={[
                      {
                        required: selectRow?.includes(row?.id) && true,
                        message: 'Please enter amount',
                      },
                    ]}
                  >
                    <Input
                      className="form-control"
                      data-ms-editor="true"
                      // onChange={(e) => {
                      //   setValue();
                      //   // produce((draft) => {
                      //   //   draft[index].totalFees = e;
                      //   // })
                      // }}
                      onChange={(e) => {
                        setValue([{ id: row?.id, name: e.target.value }]);
                      }}
                      onKeyPress={(e) => handleKey(e)}
                      min="0"
                      inputmode="numeric"
                      type="text"
                    />
                  </Form.Item>
                </div>
              </>
            );
          } else {
            return row?.totalAmount && `${'$'}${row?.totalAmount}`;
          }
        } else {
          return row?.totalAmount && `${'$'}${row?.totalAmount}`;
        }
      },
    },
    {
      dataIndex: 'bookingStatus',
      title: i18next.t('text.common.paymentTransferStatus'),
      headerClasses: 'sorting',

      render: (cell, row) =>
        row?.SettledPayments?.length > 0
          ? 'Transfered '
          : providerDiscountData?.paymentTransferStatus === 'tranfered'
          ? 'Transfered '
          : 'Pending',
      // row?.SettledPayments?.length > 0 ? 'Completed' : 'Pending',
      // checkValidData(
      //   firstLetterCaps(providerDiscountData?.BookingDetail?.paymentStatus)
      // ),
      sorter: (a, b) => {
        return a?.providerDiscountData?.bookingStatus <
          b?.providerDiscountData?.bookingStatus
          ? -1
          : a?.totalAmount > b?.totalAmount
          ? 1
          : 0;
      },
    },
  ];
};

export const purchaseOrderData = () => {
  return [
    {
      id: 1,
      jobNumber: '75833147',
      providerName: 'jorge neon',
      serviceType: 'QA & CATEGORY - Free Sch no quote',
      servicePrice: '$0.00	',
      name: 'trgj86433',
      totalFees: '400.00',
      paymentTransferStatus: 'Pending',
    },
    {
      id: 2,
      jobNumber: '75833147',
      providerName: 'jorge neon',
      serviceType: 'QA & CATEGORY - Free Sch no quote',
      servicePrice: '$0.00	',
      name: 'trgj86433',
      totalFees: '400.00',
      paymentTransferStatus: 'Pending',
    },
  ];
};
