import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FormWrapper,
    FormElementWrapper,
    Select,
    CommonButton,
} from '../../../../components';
import { valueMapper } from '../../../../helpers/schema.helper';
import { TeamSetting } from '../../../../services/index.service';
import { useSelector } from 'react-redux';
import { providerListService } from '../../../../services/TeamSetting/provider.service';

const initValues = (data) => {
    const initVal = {
        providerId: null,
        subAdminId: null,
    };
    return valueMapper(initVal, data);
};

function ReportedIssueAssign({
    onSubmit,
    formData,
    loading,
    onCancel,
    submitText = 'Assign',
}) {
    const { t } = useTranslation();
    const userType = useSelector((state) => state.auth.userData?.Role?.type);
    const [error, setError] = useState('');

    if (formData) {
        formData.providerId = formData?.personInchargeUser?.Role.type === 'public' && formData?.personIncharge;
        formData.subAdminId = formData?.personInchargeUser?.Role.type === 'subadmin' && formData?.personIncharge;
    }

    const handleChangeSelect = (value, formik, name) => {
        setError('');
        if (name === "providerId") {
            formik.setFieldValue('subAdminId', null);
        } else if (name === "subAdminId") {
            formik.setFieldValue('providerId', null);
        }
    }

    return (
        <FormWrapper
            row={true}
            onSubmit={(params) => {
                if (params.providerId || params.subAdminId) {
                    setError('');
                    onSubmit(params);
                } else {
                    setError("Please choose the issue assignee");
                }
            }}
            enableReinitialize={true}
            initialValues={initValues({ ...(formData) })}
            data={formData}
            extraClassName="g-3"
        >
            {(props) => (
                <>
                    <FormElementWrapper
                        md={6}
                        label={t('text.report.selectProvider')}
                        element={() => (
                            <Select
                                showSearch
                                searchOrNot={true}
                                onItemChange={(value, formik) => handleChangeSelect(value, formik, "providerId")}
                                name="providerId"
                                scope="activeUser"
                                placeholder={t('text.report.selectServiceProvider')}
                                formik={props}
                                listService={providerListService}
                                listKeys={{
                                    nameKey: 'firstName',
                                    lastNameKey: 'lastName',
                                    nameId: 'id',
                                }}
                            />
                        )}
                    />
                    {userType !== 'nca' && <FormElementWrapper
                        md={6}
                        label={t('text.report.selectSubAdmin')}
                        element={() => (
                            <Select
                                name="subAdminId"
                                placeholder={t('text.report.selectSubAdmin')}
                                onItemChange={(value, formik) => handleChangeSelect(value, formik, "subAdminId")}
                                formik={props}
                                extraQuary={{ showAll: 1, status: 'active' }}
                                listService={TeamSetting.listAdminService}
                                listKeys={{
                                    nameKey: 'firstName',
                                    lastNameKey: 'lastName',
                                    nameId: 'id',
                                }}
                            />
                        )}
                    />}
                    {
                        error && (
                            <div className="text-danger">{error}</div>
                        )
                    }
                    <FormElementWrapper
                        md={12}
                        className="text-end"
                        element={() => (
                            <>
                                <CommonButton
                                    type="submit"
                                    loading={loading === 'SUBMIT'}
                                    className="btn btn-primary me-1"
                                >
                                    {submitText}
                                </CommonButton>
                                <CommonButton onClick={onCancel} className="btn btn-secondary">
                                    {t('text.common.cancel')}
                                </CommonButton>
                            </>
                        )}
                    />
                </>
            )}
        </FormWrapper>
    );
}

export default ReportedIssueAssign;
