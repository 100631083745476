import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EditBankingDetailsForm, GlobalLoader } from '../../components';
import {
  bankDetailsService,
  putNcaBankDetailService,
} from '../../services/index.service';

import { logger, modalNotification } from '../../utils';
import { firstLetterCaps } from '../../utils/text.util';
import { useDispatch } from 'react-redux';
import { updateUserData } from '../../redux/slices/auth/authSlice';
import { useSelector } from 'react-redux';

function SetupBankingDetails({ defaultKey }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.userData);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState();
  const [loader, setLoader] = useState(false);

  async function onSubmit(values) {
    setIsLoading(true);
    try {
      let bodyData = { ...values };
      const response = await putNcaBankDetailService({ bodyData });
      const { success, message } = response;
      if (success) {
        modalNotification({
          type: 'success',
          message,
        });
        let data = { ...user, profileStatus: 'Complete' };  // updating the profile status of the nca user 
        dispatch(updateUserData(data));
        getBankingDetails();
      }
    } catch (error) {
      logger(error);
    }
    setIsLoading(false);
  }

  const getBankingDetails = async () => {
    try {
      setLoader(true);
      const response = await bankDetailsService();
      const { success, data } = response;
      if (success) {
        setFormData(data?.dataValues);
      }
    } catch (error) {
      logger(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    if (defaultKey === 'bankingDetails') {
      getBankingDetails();
    }
  }, [defaultKey]);

  if (formData && Object.keys(formData).length > 0) {
    return loader ? (
      <GlobalLoader />
    ) : (
      <div className="nk-block">
        <div className="card">
          <div className="card-inner">
            <BankDetailCard data={formData} />
          </div>
        </div>
      </div>
    );
  }

  return loader ? (
    <GlobalLoader />
  ) : (
    <div className="wide-md mx-auto">
      <EditBankingDetailsForm
        onSubmit={onSubmit}
        isLoading={isLoading}
        formData={formData}
      />
    </div>
  );
}

export default SetupBankingDetails;

const BankDetailCard = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <div>
          <div className="ncaBusinessDetails">
            <h5 className="title mb-3">{t('text.common.bankDetails')}</h5>
            <div className="divider sm pt-3">
              <Row>
                <Col md={6}>
                  <ul className="list-unstyled mb-0">
                    <li>
                      <label className="sub-text">
                        {t('text.provider.bankName')}
                      </label>
                      {firstLetterCaps(data?.bankName)}
                    </li>
                    <li>
                      <label className="sub-text">
                        {t('text.provider.bankHolderName')}
                      </label>
                      {data?.bankAccountHolderName || '-'}
                    </li>
                    <li>
                      <label className="sub-text">
                        {t('text.provider.accountNumber')}
                      </label>
                      {data?.accountNumber || '-'}
                    </li>
                  </ul>
                </Col>
                <Col md={6}>
                  <ul className="list-unstyled">
                    <li>
                      <label className="sub-text">
                        {t('text.provider.routingNumber')}
                      </label>{' '}
                      {data?.bankAccountRoutingNumber || '-'}
                    </li>
                    <li>
                      <label className="sub-text">
                        {t('text.provider.bankLocation')}
                      </label>{' '}
                      {data?.bankLocation || '-'}
                    </li>
                    <li>
                      <label className="sub-text">
                        {t('text.common.email')}
                      </label>{' '}
                      {data?.email || '-'}
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
