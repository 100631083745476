// import { useState } from "react";

import { Button } from 'antd';
import moment from 'moment';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';
import { IMAGE_URL } from '../../../config';
import {
  capitalizeFirstLetter,
  dateFormatter,
  navigateWithParam,
} from '../../../utils';
import { CommonButton } from '../Button';
import i18next from 'i18next';
import { Image } from 'antd';
import React from 'react';
import { CheckBox } from '../index.element';
import { accessRoute } from '../../../routes/GoogleConnect';
import { Regex } from '../../../helpers/regex.helper';

export const checkValidData = (data) => {
  if (data === 0) {
    data = '0';
  }
  return data || '-';
};

export const checkValidCount = (data) => {
  return <>{data || 0}</>;
};

export const checkValidPrice = (
  data,
  isCurrencySymbol = true,
  currencyType
) => {
  const currencyFormat = Intl.NumberFormat(
    'en-US',
    isCurrencySymbol && {
      style: 'currency',
      currency: currencyType ?? 'USD',
    }
  );
  // if (data && data > 0) {
  //   const value = data.toString().split(".")
  //   return value?.length === 2
  //     ? currencyFormat.format(`${value[0]}.${value[1].slice(0, 2)}`)
  //     : currencyFormat.format(data)
  // } else {
  //   return currencyFormat.format(0);
  // }
  return data && data > 0
    ? currencyFormat.format(data)
    : currencyFormat.format(0);
};

export const checkValidDiscountType = (discountType, data) => {
  return data && discountType === 'percentage'
    ? `${parseFloat(data)}%`
    : checkValidPrice(data);
};

export const getResponse = (data) => {
  return <>{data === 0 ? `No` : 'Yes'}</>;
};

export const getBooleanResponse = (data) => {
  return data ? 'Yes' : `No`;
};

export const cssUrls = (data) => {
  return `${IMAGE_URL}/${data}`;
};
export const textFormatter = (data) => {
  return data && data?.charAt(0)?.toUpperCase() + data.slice(1);
};
export const totalTimeDifference = (createdAt, updatedAt) => {
  let startTime = moment(createdAt);
  let endTime = moment(updatedAt);
  let difference = startTime.from(endTime);

  return difference;
};

export const timeFormatter = ({ durationHours, durationMinutes }) => {
  return (
    <>
      {durationHours
        ? `${durationHours}``${'h'}``${' '}``${durationMinutes}``${'mins'}`
        : ''}
    </>
  );
};

export function commasFormatter(data) {
  return data.join(', ');
}
export const bookingDetailsFormatter = (treatment) => {
  return (
    <>
      <Link to="#">
        <u>{treatment?.title}</u>
      </Link>
      <div className="text-600 font-md">{treatment?.postcode}</div>
    </>
  );
};
export const serviceProviderFormatter = (userDetails) => {
  return <>{userDetails?.full_name}</>;
};
export const dateFormatWithMonth = 'DD MMM YYYY';

export const dateTimeFormatter = (date, time) => {
  return `${dateFormatter(date)} ${time}`;
};

export const ToFixedPrice = (data) => {
  if (data) return parseFloat(Number(data).toFixed(2));
  else return parseFloat(0).toFixed(2);
};

export const showTagsFormatter = (BookingTags, tagType) => {
  return BookingTags?.map(
    (tagData) =>
      tagData?.tagType === tagType && (
        <span className="badge badge-xs bg-gray me-1 mb-1">{tagData?.tag}</span>
      )
  );
};
export function TimeFormatterWithHour({ hours, mins }) {
  return <> {`${hours || 0}h ${mins || 0}mins`}</>;
}

export const placeFormatter = (data) => {
  return (
    <>
      {data || 0} {`${`Places left`}`}
    </>
  );
};

export const iconFormatter = (iconName, url) => {
  return (
    <>
      <div className="text-center">
        <Link to={url}>
          <em className={iconName} style={{ fontSize: '30px' }} />
        </Link>
      </div>
    </>
  );
};

export const ButtonFormatter = (
  url,
  text,
  target = '_self',
  state = { key: '', value: {} },
  alignCenterBtn = true
) => {
  return (
    <>
      <div className={`${alignCenterBtn ? 'text-center' : ''}`}>
        <Link
          to={url}
          onClick={() =>
            state ? localStorage.setItem(state.key, state.value) : null
          }
          target={target}
          className="btn btn-primary"
        >
          {text}
        </Link>
      </div>
    </>
  );
};

export const ButtonTextFormatter = (
  url,
  text,
  google,
  data = {},
  target = '_self'
) => {
  return (
    <>
      {google ? (
        <Link
          to={url}
          onClick={(e) => {
            e.preventDefault();
            navigateWithParam(data, url, accessRoute?.GOOGLE_GMB_REVIEW?.path);
          }}
          className="btn btn-primary btn-sm"
        >
          {`View (${text || 0})`}
        </Link>
      ) : (
        <Link to={url} className="" target={target}>
          {text}
        </Link>
      )}
    </>
  );
};

export const ModalOpenFormatterTask = (url, text) => {
  return (
    <>
      <Link to="#" onClick={url} className="text-primary">
        {text}
      </Link>
    </>
  );
};

export const getServiceName = (data, { callingServiceName }) => {
  return data ? `Yes` : `No`;
};

export const dangerouslySetInnerHTML = ({ data, extraClassName = '' }) => {
  return (
    (
      <div
        className={extraClassName}
        dangerouslySetInnerHTML={{ __html: data }}
      />
    ) || '-'
  );
};

export const textWithHeadingFormatter = ({ heading, text }) => {
  return (
    <div className="postDescription">
      <b>{heading} </b> {text}
    </div>
  );
};

export const badgeFormatter = (status) => {
  return (
    <>
      <Badge bg={status === 'yes' ? 'success' : 'danger'}>
        {status === 'yes' ? 'Available' : 'Assigned'}
      </Badge>
    </>
  );
};
export const badgeQuoteFormatter = (id) => {
  return (
    <>
      <Badge bg={id === null ? 'success' : 'danger'}>
        {id === null ? 'Available' : 'Assigned'}
      </Badge>
    </>
  );
};

export const imageNameFormatter = (data) => {
  let getIndex = data?.lastIndexOf('/') + 1;
  let filename = data?.substr(getIndex);
  return filename || '-';
};

export const logoFormatter = (path, text) => {
  return (
    <>
      {path ? (
        <div className="table-img">
          {/* <img src={path} alt="img" /> */}
          <Image width={200} src={path} alt="img" />
        </div>
      ) : (
        text
      )}
    </>
  );
};

export default function index() {
  return <div></div>;
}

function onError({ currentTarget }) {
  try {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src = '/assets/images/admin/img_default-placeholder.png';
  } catch (err) {
    return currentTarget;
  }
}

export function ImagePreviewFormatter({
  path,
  text,
  setImageError,
  imageError,
}) {
  return (
    <>
      {path ? (
          <Image
            src={
              imageError
                ? '/assets/images/admin/img_default-placeholder.png'
                : path
            }
            alt="img"
            onError={() => {
              setImageError(true);
              return onError();
            }}
          />
      ) : (
        text
      )}
    </>
  );
}

export function VideoPreviewFormatter({ path, text }) {
  return (
    <>
      {path ? (
        <video width="100%" height="100%" controls>
          <source src={path} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        text
      )}
    </>
  );
}

export const costWithPercentFormatter = ({ cell, row }) => {
  return <>{row?.type === 'flat' ? `${checkValidPrice(cell)}` : `${cell}%`}</>;
};

export const addSubFormatter = ({ cell, row }) => {
  return <>{row?.adjustmentForSeason === '+' ? `+${cell}` : `-${cell}`}</>;
};

export const cancelFormatter = ({
  status,
  BookingCancelModal,
  handleShow,
  providerStatus,
  cancelReason,
  id,
  isUserCanEdit = true,
}) => {
  return (
    <>
      {status !== 'declined' && status !== 'canceled' ? (
        <>
          {capitalizeFirstLetter(status)}
          {(status === 'accepted' || status === 'pending') && (
            <>
              <br />
              <CommonButton
                onClick={() => handleShow(id)}
                className="btn btn-primary"
                disabled={!isUserCanEdit}
              >
                {i18next.t('text.common.cancelBooking')}
              </CommonButton>
            </>
          )}
        </>
      ) : !providerStatus ? (
        status === 'canceled' ? (
          'Canceled By Provider'
        ) : (
          <>
            Declined
            <br />
            <CommonButton
              onClick={() => BookingCancelModal('declinedRemove', cancelReason)}
              className="btn btn-light btn-sm"
              disabled={!isUserCanEdit}
            >
              {i18next.t('text.scheduleBooking.cancelReason')}
            </CommonButton>
          </>
        )
      ) : (
        <>
          {status === 'canceled'
            ? providerStatus === 'provider'
              ? 'Canceled By Provider'
              : providerStatus === 'customer'
              ? 'Canceled By User'
              : 'Canceled By Admin'
            : '	Declined'}

          <br />
          <CommonButton
            onClick={() => BookingCancelModal(providerStatus, cancelReason)}
            className="btn btn-light btn-sm"
          >
            {i18next.t('text.scheduleBooking.cancelReason')}
          </CommonButton>
        </>
      )}
    </>
  );
};
export const badgeCompleteFormatter = (status) => {
  return (
    <>
      <Badge bg={status === 'Complete' ? 'success' : 'danger'}>
        {status === 'Complete' ? 'Complete' : 'Incomplete'}
      </Badge>
    </>
  );
};

export const viewButtonFormatter = (data) => {
  let element;
  if (data.text !== '') {
    element = (
      <Link to={data.path} className="btn btn-primary btn-sm">
        {' '}
        {'view'}
      </Link>
    );
  } else {
    element = '---';
  }
  return element;
};

export const sendQuoteButtonFormatter = (data) => {
  let element;
  if (data.text !== '') {
    element = (
      <Link to={data.path} className="btn btn-primary btn-sm">
        {' '}
        {'Send Quote'}
      </Link>
    );
  } else {
    element = '---';
  }
  return element;
};

export const ModalOpenFormatter = ({ text, action, flag }) => {
  if (flag) {
    return (
      <Link
        to="#"
        onClick={(e) => {
          e.preventDefault();
          action();
        }}
      >
        {text}
      </Link>
    );
  }
  return (
    <>
      <Button type="text" onClick={action} className="text-primary">
        {text}
      </Button>
    </>
  );
};

export const checkboxBooleanFormatter = ({ cell, action, text, id }) => {
  return <>{cell ? text : <CheckBox onChange={(e) => action(e, id)} />}</>;
};

export const checkboxDashFormatter = ({ cell, action, id }) => {
  return <>{cell ? ' -' : <CheckBox onChange={(e) => action(e, id)} />}</>;
};
export const deletedItemIcon = () => {
  return <span className="badge rounded-pill bg-danger">Deleted</span>;
};

export const serialNumberFormatter = ({ page, sizePerPage, index }) => {
  return (page - 1) * sizePerPage + index + 1;
};
export const getActiveCancelledResponse = (data) => {
  return data ? 'Active' : `Cancelled`;
};

export const logoImageFormatter = (firstName, lastName) => {
  let fName = firstName.substring(0, 1);
  let lName = lastName.substring(0, 1);
  return (
    <div className="user-avatar bg-secondary">
      <span>{`${fName.toUpperCase()}${lName.toUpperCase()}`}</span>
    </div>
  );
};

export const percentFormatter = ({ cell }) => {
  return <>{cell ? `${parseFloat(cell).toFixed()}%` : '-'} </>;
};
export const linkFormatter = (onHandleShow, text, id, nca, userType) => {
  return (
    <Link
      to="#"
      onClick={(e) => {
        e.preventDefault();
        id ? onHandleShow(id, nca, userType) : onHandleShow();
      }}
    >
      {text}
    </Link>
  );
};

export const ButtonFormatterWithZero = (
  url,
  text,
  extraText = false,
  extraClass = '',
  target = '',
  shouldDisable = false
) => {
  return (
    <>
      <div className={extraClass}>
        {shouldDisable ? (
          <CommonButton
            disabled={shouldDisable}
            to={url}
            target={target && target}
            className="btn btn-primary"
          >
            {text}
            {extraText > 0 ? (
              <span className="badge ms-1 badge-circle align-items-center">
                {extraText}
              </span>
            ) : (
              <span className="badge ms-1 badge-circle align-items-center">
                0
              </span>
            )}
          </CommonButton>
        ) : (
          <Link to={url} target={target && target} className="btn btn-primary">
            {text}
            {extraText > 0 ? (
              <span className="badge ms-1 badge-circle align-items-center">
                {extraText}
              </span>
            ) : (
              <span className="badge ms-1 badge-circle align-items-center">
                0
              </span>
            )}
          </Link>
        )}
      </div>
    </>
  );
};

export function handleKey(e) {
  let ASCIICode = e.keyCode;
  if (
    ASCIICode === 37 ||
    ASCIICode === 38 ||
    ASCIICode === 39 ||
    ASCIICode === 40 ||
    ASCIICode === 69 ||
    ASCIICode === 46 ||
    ASCIICode === 107 ||
    ASCIICode === 109
  ) {
    e.preventDefault();
  }
}

export const numberToBoolean = (data) => {
  return data ? 'active' : `inactive`;
};

export const numberToBooleanApposite = (data) => {
  return data ? 'Inactive' : `Active`;
};

export function starRate(rate) {
  const getRating = (rate) => {
    let rateNumber = '';
    if (rate === 'ONE') {
      rateNumber = 1;
      return rateNumber;
    } else if (rate === 'TWO') {
      rateNumber = 2;
      return rateNumber;
    } else if (rate === 'THREE') {
      rateNumber = 3;
      return rateNumber;
    } else if (rate === 'FOUR') {
      rateNumber = 4;
      return rateNumber;
    } else if (rate === 'FIVE') {
      rateNumber = 5;
      return rateNumber;
    }
  };
  return (
    <>
      <div className="reviewSec_item_rating">
        {[...new Array(5)].map((item, i) => {
          if (i + 1 <= getRating(rate)) {
            return <em className="ni ni-star-fill text-primary" />;
          }
          return <em className="ni ni-star text-primary" />;
        })}
      </div>
    </>
  );
}

export const disabledFutureDate = (current) => {
  return current > moment();
};
export const disabledPastAndFutureDate = (current, startDate = moment()) => {
  return (
    (current && current < moment(startDate, 'YYYY-MM-DD')) || current > moment()
  );
};

export const bookingType = (type) => {
  if (type === 'emergency') {
    return 'NOW';
  } else {
    return 'SCHEDULE';
  }
};

export const camelCaseFormatter = (str) => {
  return str ? str?.replace(/([A-Z])/g, ' $1') : '-';
};

export const capitalizeFirstLetterString = (string) => {
  const str = string.charAt(0).toUpperCase() + string.slice(1);
  return str;
};
let timeObj = {
  'an hour': '1 hours',
  'a day': '1 day',
  'a minute': '1 minutes',
  'a few seconds': '5 seconds',
};
export const totalTimeCount = (min) => {
  let totalTime = '';
  if (min) {
    let minute = min * 1000;
    let duration = moment.duration(minute);
    let durationC = moment.duration(duration, 'days');
    if (Object?.keys(timeObj)?.includes(durationC.humanize())) {
      totalTime = timeObj[durationC.humanize()];
    } else {
      totalTime = durationC.humanize();
    }
    if (totalTime === '5 seconds') {
      totalTime = `${min} minutes`;
    }
  } else {
    totalTime = '-';
  }

  return totalTime;
};

export const correctFormate = (data) => {
  return (
    <>
      {data
        ? data
            .replaceAll('_', '-')
            .split('-')
            .map((e) => e.charAt(0).toUpperCase() + e.slice(1))
            .join('-')
        : '-'}
    </>
  );
};

export const mintToHourConverter = (mint) => {
  const duration = moment.duration(mint, 'minutes');

  const h = duration?.hours();

  const m = duration?.minutes();

  const s = duration?.seconds();

  return h > 0
    ? `${h}h ${m}m ${s}s`
    : m > 0
    ? `${m}m ${s}s`
    : s > 0
    ? `${s}s`
    : '-';
};

export const titleCaseFormatter = (data) => {
  return data
    ? data
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : '-';
};

export const NAformatter = (data) => {
  return data ? data : 'N/A';
};

// export const textLinkToLinkConverter = (message, sender = false) => {
//   return message.replace(Regex.urlRegexLink, (match) => {
//     console.log('match', match);
//     if (match)
//       return `<a class="${
//         sender ? 'text-white' : ''
//       } text-decoration-underline" href="${match}" id="firstOne" target="_blank" rel="noopener noreferrer">${match}</a>`;
//   });
// };

export const textLinkToLinkConverter = (message, sender = false) => {
  return message.replace(Regex.urlRegexLink, (match) => {
    const absoluteUrl =
      match.startsWith('http://') || match.startsWith('https://')
        ? match
        : `http://${match}`;

    return `<a class="${
      sender ? 'text-white' : ''
    } text-decoration-underline" href="${absoluteUrl}" id="firstOne" target="_blank" rel="noopener noreferrer">${match}</a>`;
  });
};
