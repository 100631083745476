import i18next, { t } from 'i18next';
import { accessRoute } from '../../routes/Dashboard';
import {
  CommonButton,
  getInputSearchFilter,
  getMultiSelectSearchFilter,
  getPriceFilter,
} from '../../components';
import {
  ButtonFormatter,
  ButtonTextFormatter,
  checkValidData,
  checkValidPrice,
} from '../../components/UIElements/Formatter';
import { generatePath } from 'react-router-dom';
import moment from 'moment';

import { providerListService } from '../TeamSetting/provider.service';
import { CustomersService } from '../Customers';
import { capitalizeFirstLetter, showDateInBrowser } from '../../utils';
import { firstLetterCaps, getFullName } from '../../utils/text.util';
import { accessRoute as serviceManagementRoutes } from '../../routes/ServiceManagement';
import { accessRoute as commonAccessRoutes } from '../../routes/CommonRoutes';
import { generatePaths } from './serviceManagement.service';
import { accessRoute as teamSettingRoute } from '../../routes/TeamSetting';
import { accessRoute as reportRoute } from '../../routes/Reports';

export const quoteMngEstimatesBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.dashboard.quoteMngEstimates'),
    },
  ];
};

const getJobStatusData = () => {
  return [
    {
      name: 'Accepted',
      id: 'accepted',
    },
    {
      name: 'Open',
      id: 'pending',
    },
    {
      name: 'Declined',
      id: 'declined',
    },
    {
      name: 'Expired',
      id: 'expired',
    },
  ];
};

const totalCostFields = {
  fromOperatorField: 'firstOperator',
  fromOperatorAmountField: 'firstAmount',
  conditionalField: 'conditional',
  toOperatorField: 'secondOperator',
  toOperatorAmountField: 'secondAmount',
};

export const estimatesTableColumn = ({
  onFilterChange,
  filter = {},
  onSort,
  handleSendQuote,
  sendQuoteBookingId,
}) => {
  return [
    {
      dataIndex: 'bookingNo',
      title: i18next.t('text.serviceManagement.booking'),
      render: (cell, row) =>
        ButtonTextFormatter(
          generatePath(serviceManagementRoutes.BOOKING_VIEW.path, {
            id: row?.id,
            viewQuote:
              row?.BookingQuote?.submitQuote === 'submitted'
                ? 'isViewQuote'
                : 'isNoViewQuote',
          }),
          cell,
          false,
          {},
          '_blank'
        ),
      ...getInputSearchFilter({
        dataIndex: 'bookingNo',
        placeholder: i18next.t('text.serviceManagement.booking'),
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'Address.addressLine1',
      exportName: 'Location',
      title: i18next.t('text.quote.locationName'),
      headerClasses: 'sorting',
      sorter: false,
      exportFormatter: (_, row) => checkValidData(row?.Address?.addressLine1),
      render: (_, row) => checkValidData(row?.Address?.addressLine1),
    },
    {
      dataIndex: 'bookingScheduleDate',
      title: i18next.t('text.serviceManagement.jobDate'),
      headerClasses: 'sorting',
      sorter: false,
      exportFormatter: (cell) => showDateInBrowser(cell),
      render: (_, row) =>
        row?.createdAt
          ? moment(row?.bookingScheduleDate).format('ddd, MMM D, YYYY h:mm A')
          : '-',
    },
    {
      dataIndex: 'Provider.firstName',
      filterDataIndex: ['providerUserId'],
      exportName: 'Provider',
      title: i18next.t('text.dashboard.provider'),
      headerClasses: 'sorting',
      sorter: false,
      render: (_, row) =>
        row?.Provider?.firstName ? (
          ButtonTextFormatter(
            generatePaths(
              teamSettingRoute.EDIT_PROVIDER.path,
              row.providerUserId
            ),
            row?.Provider?.firstName
              ? getFullName(row?.Provider?.firstName, row?.Provider?.lastName)
              : '-'
          )
        ) : (
          <>-</>
        ),
      exportFormatter: (_, row) =>
        row?.Provider?.firstName ? (
          getFullName(row?.Provider?.firstName, row?.Provider?.lastName)
        ) : (
          <>-</>
        ),
      ...getMultiSelectSearchFilter({
        dataIndex: 'providerUserId',
        onFilterChange,
        filter: filter,
        name: t('text.common.provider'),
        list: [],
        mode: 'multiple',
        // scope: 'all',
        searchOrNot: true,
        listKeys: { nameKey: ['firstName', 'lastName'], nameId: 'id' },
        listService: providerListService,
      }),
    },
    {
      dataIndex: 'Customer.firstName',
      filterDataIndex: ['customerUserId'],
      exportName: 'Customer',
      title: i18next.t('text.dashboard.user'),
      headerClasses: 'sorting',
      sorter: false,
      exportFormatter: (_, row) =>
        row?.Customer?.firstName ? (
          getFullName(
            row?.Customer?.firstName,
            row?.Customer?.lastName,
            ''
            // add customer plan with amount temporary commented with temporary key
            // 'plan name',
            // 101,
            // 'percentage'
          )
        ) : (
          <>-</>
        ),
      render: (_, row) =>
        ButtonTextFormatter(
          generatePaths(reportRoute.PAYMENT_REPORT.path),
          getFullName(
            row?.Customer?.firstName,
            row?.Customer?.lastName,
            ''
            // add customer plan with amount temporary commented with temporary key
            // 'plan name',
            // 101,
            // 'percentage'
          ),
          false,
          {},
          '_blank'
        ),
      ...getMultiSelectSearchFilter({
        dataIndex: 'customerUserId',
        onFilterChange,
        filter,
        name: 'User',
        list: [],
        mode: 'multiple',
        // scope: 'all',
        searchOrNot: true,
        listKeys: { nameKey: ['firstName', 'lastName'], nameId: 'id' },
        listService: CustomersService?.CustomersListService,
        extraQuary: { isFilter: 1 },
      }),
    },
    {
      dataIndex: 'total',
      filterDataIndex: ['price'],
      title: i18next.t('text.serviceManagement.jobAmount'),
      headerClasses: 'sorting',
      sorter: false,
      exportFormatter: (cell) => checkValidPrice(cell),
      render: (cell) => checkValidPrice(cell),
      ...getPriceFilter({
        dataIndex: 'price',
        onFilterChange,
        fields: totalCostFields,
        filter,
        dataIndexKey: 'filterColumn',
      }),
    },
    {
      dataIndex: 'ServiceCategory.name',
      filterDataIndex: ['serviceType'],
      exportName: 'Type',
      title: i18next.t('text.serviceManagement.type'),
      headerClasses: 'sorting',
      sorter: false,
      exportFormatter: (_, row) =>
        checkValidData(
          `${row?.ServiceCategory?.name}(${row?.ServiceCategory?.servicePrice} - ${row?.ServiceCategory?.serviceType})`
        ),
      render: (_, row) =>
        row?.ServiceCategory?.name ? (
          <div className="text-capitalize">
            {checkValidData(
              `${row?.ServiceCategory?.name}(${firstLetterCaps(
                row?.ServiceCategory?.servicePrice
              )} - ${firstLetterCaps(
                row?.ServiceCategory?.serviceType?.replace(/_/g, ' ')
              )})`
            )}
          </div>
        ) : (
          <>-</>
        ),
    },
    {
      dataIndex: 'quoteDetails',
      title: i18next.t('text.serviceManagement.quote'),
      // headerClasses: 'sorting',
      // sorter: true,
      onSort: (_, order) => onSort('quoteDetail', order),
      render: (_, row) => {
        if (row?.BookingQuote?.submitQuote === 'submitted') {
          let path = row?.BookingQuote?.isSalesQuotes
            ? generatePath(commonAccessRoutes.INVOICE.path, {
                viewInvoice: 'quote',
                routePath: 'service-management',
                id: row?.id,
              })
            : generatePath(
                serviceManagementRoutes.SERVICE_MANAGEMENT_QUOTE.path,
                { id: row?.id }
              );
          return ButtonFormatter(path, 'View Quote', '_blank');
        } else {
          return <>-</>;
        }
      },
    },
    {
      dataIndex: 'bookingStatus',
      filterDataIndex: ['status'],
      title: i18next.t('text.common.quoteStatus'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (_, order) => onSort('bookingStatus', order),
      render: (cell, row) =>
        row?.quoteStatus === 'pending'
          ? 'Open'
          : row?.quoteStatus
          ? capitalizeFirstLetter(row?.quoteStatus)
          : '-',
      ...getMultiSelectSearchFilter({
        dataIndex: 'status',
        onFilterChange,
        filter,
        name: 'Status',
        list: [],
        mode: 'multiple',
        listService: getJobStatusData,
      }),
    },
    {
      dataIndex: 'sendQuote',
      title: i18next.t('text.serviceManagement.sendQuote'),
      render: (_, row) => {
        if (
          row?.BookingQuote?.submitQuote === 'submitted' &&
          row?.BookingQuote?.customerStatus === 'pending'
        ) {
          return (
            <>
              <CommonButton
                disabled={sendQuoteBookingId}
                loading={row?.id === sendQuoteBookingId}
                onClick={() => handleSendQuote(row?.id)}
                className="btn btn-primary btn-sm"
              >
                {i18next.t('text.serviceManagement.sendQuote')}&nbsp;
                {`${checkValidPrice(row?.BookingQuote?.quoteAmount)}`}
              </CommonButton>
            </>
          );
        } else {
          return <>-</>;
        }
      },
    },
  ];
};
