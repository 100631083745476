export const NOTIFICATION_TEMPLATE_ENDPOINT = {
    getNotificationTemplate: {
      url: '/v1/admin/notification-template',
      method: 'GET',
    },
    updateNotificationId:(id)=>({
      url: `/v1/admin/notification-template/${id}`,
      method: 'PUT'
    }),
    getNotificationById: (id) => ({
      url: `/v1/admin/notification-template/${id}`,
      method: 'GET'
    }),
  };
  