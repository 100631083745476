import { Outlet } from 'react-router-dom';
import { getActions } from '../../services/index.service';
import Document from '../../views/AdminSetting/NCA/Document';
import EditNCA from '../../views/AdminSetting/NCA/Edit';
import AddNCACategory from '../../views/AdminSetting/NCA/Edit/addNCACategory';
import AddNCAGeoFence from '../../views/AdminSetting/NCA/Edit/addNCAGeoFence';
import AddNCASubCategory from '../../views/AdminSetting/NCA/Edit/addNCASubCategory';
import EditNCACategory from '../../views/AdminSetting/NCA/Edit/editNCACategory';
import EditNCAGeoFence from '../../views/AdminSetting/NCA/Edit/editNCAGeoFence';
import EditNCASubCategory from '../../views/AdminSetting/NCA/Edit/editNCASubCategory';
import ViewNCAServiceType from '../../views/AdminSetting/NCA/Edit/viewNCAServiceType';
import ViewNCASubCategory from '../../views/AdminSetting/NCA/Edit/viewNCASubCategory';
import EditHomePage from '../../views/AdminSetting/Utility/EditHomePage/Edit';
import ListHomePage from '../../views/AdminSetting/Utility/EditHomePage/List';
import {
  EditPage,
  ListCity,
  ListCountry,
  ListFaq,
  ListPage,
  ListState,
  SendNotification,
  Banner,
  ListIndustry,
  Information,
  Financing,
  Tips,
  QuoteInformation,
  ListNCA,
  LogReports,
  NcaPaymentReport,
} from '../../views/index.view';

export default function route() {
  return [
    {
      // path: accessRoute.DASHBOARD.path,
      private: true,
      name: 'Super Admin Settings',
      key: 'admin',
      belongsToSidebar: true,
      submenu: true,
      icon: accessRoute.LIST_CITY.icon,
      moduleKeys: [
        accessRoute.LIST_CITY.moduleKey,
        accessRoute.LIST_STATE.moduleKey,
        accessRoute.LIST_COUNTRY.moduleKey,
        accessRoute.LIST_PAGE.moduleKey,
        accessRoute.LIST_HOMEPAGE.moduleKey,
        accessRoute.LIST_FAQ.moduleKey,
        accessRoute.Important_Info.moduleKey,
        accessRoute.Financing.moduleKey,
        accessRoute.Tips.moduleKey,
        accessRoute.Quote_Info.moduleKey,
        accessRoute.Banner_View.moduleKey,
        accessRoute.LIST_INDUSTRY.moduleKey,
        accessRoute.SEND_NOTIFICATION.moduleKey,
        accessRoute.LIST_NCA.moduleKey,
      ],
      element: <Outlet />,
      children: [
        {
          private: true,
          name: 'Utility',
          key: 'admin.utility',
          belongsToSidebar: true,
          // icon: accessRoute.DASHBOARD.icon,
          element: <Outlet />,
          moduleKeys: [
            accessRoute.LIST_PAGE.moduleKey,
            accessRoute.LIST_HOMEPAGE.moduleKey,
            accessRoute.LIST_FAQ.moduleKey,
            accessRoute.LIST_CITY.moduleKey,
            accessRoute.LIST_STATE.moduleKey,
            accessRoute.LIST_COUNTRY.moduleKey,
            accessRoute.SEND_NOTIFICATION.moduleKey,
          ],
          submenu: true,
          children: [
            {
              private: true,
              name: 'Localization',
              key: 'admin.utility.localization',
              belongsToSidebar: true,
              // icon: accessRoute.DASHBOARD.icon,
              element: <Outlet />,
              moduleKeys: [
                accessRoute.LIST_CITY.moduleKey,
                accessRoute.LIST_STATE.moduleKey,
                accessRoute.LIST_COUNTRY.moduleKey,
              ],
              submenu: true,
              children: [
                {
                  private: true,
                  name: accessRoute.LIST_COUNTRY.name,
                  key: accessRoute.LIST_COUNTRY.key,
                  path: accessRoute.LIST_COUNTRY.path,
                  belongsToSidebar: true,
                  icon: accessRoute.LIST_COUNTRY.icon,
                  element: <ListCountry />,
                  ...accessRoute.LIST_COUNTRY,
                },

                {
                  private: true,
                  name: accessRoute.LIST_STATE.name,
                  key: accessRoute.LIST_STATE.key,
                  path: accessRoute.LIST_STATE.path,
                  belongsToSidebar: true,
                  icon: accessRoute.LIST_STATE.icon,
                  element: <ListState />,
                  ...accessRoute.LIST_STATE,
                },
                {
                  private: true,
                  name: accessRoute.LIST_CITY.name,
                  key: accessRoute.LIST_CITY.key,
                  path: accessRoute.LIST_CITY.path,
                  belongsToSidebar: true,
                  icon: accessRoute.LIST_CITY.icon,
                  element: <ListCity />,
                  ...accessRoute.LIST_CITY,
                },
              ],
            },
            {
              private: true,
              path: accessRoute.LIST_PAGE.path,
              name: 'Pages',
              key: accessRoute.LIST_PAGE.key,
              belongsToSidebar: true,
              // icon: accessRoute.LIST_PAGE.icon,  accessRoute.LIST_PAGE.moduleKey,
              element: <ListPage />,
              ...accessRoute.LIST_PAGE,
            },
            {
              private: true,
              path: accessRoute.LIST_PAGE_EDIT.path,
              name: 'edit-page',
              key: accessRoute.LIST_PAGE.key,
              belongsToSidebar: false,
              // icon: accessRoute.LIST_PAGE_EDIT.icon,
              element: <EditPage />,
              ...accessRoute.LIST_PAGE_EDIT,
            },
            {
              private: true,
              path: accessRoute.LIST_HOMEPAGE.path,
              name: 'Edit Home Page',
              key: accessRoute.LIST_HOMEPAGE.key,
              belongsToSidebar: true,
              icon: accessRoute.LIST_HOMEPAGE.icon,
              element: <ListHomePage />,
              ...accessRoute.LIST_HOMEPAGE,
            },
            {
              private: true,
              path: accessRoute.EDIT_HOMEPAGE.path,
              name: 'Edit Home Page',
              key: accessRoute.EDIT_HOMEPAGE.key,
              belongsToSidebar: false,
              // icon: accessRoute.EDIT_HOMEPAGE.icon,
              element: <EditHomePage />,
              ...accessRoute.EDIT_HOMEPAGE,
            },
            {
              private: true,
              path: accessRoute.LIST_FAQ.path,
              name: 'Faq',
              key: accessRoute.LIST_FAQ.key,
              belongsToSidebar: true,
              lable: 'Faq',
              icon: accessRoute.LIST_FAQ.icon,
              element: <ListFaq />,
              ...accessRoute.LIST_FAQ,
            },
            {
              private: true,
              path: accessRoute.SEND_NOTIFICATION.path,
              name: 'Send Push-Notification',
              key: accessRoute.SEND_NOTIFICATION.key,
              belongsToSidebar: true,
              icon: accessRoute.SEND_NOTIFICATION.icon,
              element: <SendNotification />,
              ...accessRoute.SEND_NOTIFICATION,
            },
          ],
        },
        {
          private: true,
          name: 'App Text',
          key: 'admin.appText',
          belongsToSidebar: true,
          // icon: accessRoute.DASHBOARD.icon,
          moduleKeys: [
            accessRoute.Important_Info.moduleKey,
            accessRoute.Financing.moduleKey,
            accessRoute.Tips.moduleKey,
            accessRoute.Quote_Info.moduleKey,
          ],
          element: <Outlet />,
          submenu: true,
          children: [
            {
              private: true,
              path: accessRoute.Important_Info.path,
              name: 'Information',
              key: accessRoute.Important_Info.key,
              belongsToSidebar: true,
              // icon: accessRoute.Important_Info.icon,
              element: <Information />,
              ...accessRoute.Important_Info,
            },
            {
              private: true,
              path: accessRoute.Financing.path,
              name: 'Financing',
              key: accessRoute.Financing.key,
              belongsToSidebar: true,
              icon: accessRoute.Financing.icon,
              element: <Financing />,
              ...accessRoute.Financing,
            },
            {
              private: true,
              path: accessRoute.Tips.path,
              name: 'Tips',
              key: accessRoute.Tips.key,
              belongsToSidebar: true,
              icon: accessRoute.Tips.icon,
              element: <Tips />,
              ...accessRoute.Tips,
            },
            {
              private: true,
              path: accessRoute.Quote_Info.path,
              name: 'Quote Information',
              key: accessRoute.Quote_Info.key,
              belongsToSidebar: true,
              icon: accessRoute.Quote_Info.icon,
              element: <QuoteInformation />,
              ...accessRoute.Quote_Info,
            },
          ],
        },
        {
          private: true,
          name: 'Customer App Settings',
          key: 'admin.customerappsettings',
          belongsToSidebar: true,
          // icon: accessRoute.DASHBOARD.icon,
          moduleKeys: [
            accessRoute.Banner_View.moduleKey,
          ],
          element: <Outlet />,
          submenu: true,
          children: [
            {
              private: true,
              path: accessRoute.Banner_View.path,
              name: 'Banner',
              key: accessRoute.Banner_View.key,
              belongsToSidebar: true,
              icon: accessRoute.Banner_View.icon,
              element: <Banner />,
              ...accessRoute.Banner_View,
            },
          ],
        },
        {
          private: true,
          path: accessRoute.LIST_NCA.path,
          name: accessRoute.LIST_NCA.name,
          key: accessRoute.LIST_NCA.key,
          belongsToSidebar: true,
          element: <ListNCA />,
          ...accessRoute.LIST_NCA,
        },
        {
          private: true,
          path: accessRoute.LIST_INDUSTRY.path,
          name: 'Industry',
          key: accessRoute.LIST_INDUSTRY.key,
          belongsToSidebar: true,
          element: <ListIndustry />,
          ...accessRoute.LIST_INDUSTRY,
        },

        {
          private: true,
          path: accessRoute.EDIT_NCA.path,
          name: accessRoute.EDIT_NCA.name,
          key: accessRoute.EDIT_NCA.key,
          belongsToSidebar: false,
          element: <EditNCA />,
        },
        {
          private: true,
          path: accessRoute.NCA_ADD_GEO_FENCE.path,
          name: accessRoute.NCA_ADD_GEO_FENCE.name,
          key: accessRoute.NCA_ADD_GEO_FENCE.key,
          belongsToSidebar: false,
          element: <AddNCAGeoFence />,
        },
        {
          private: true,
          path: `${accessRoute.NCA_EDIT_GEO_FENCE.path}/:id`,
          name: accessRoute.NCA_EDIT_GEO_FENCE.name,
          key: accessRoute.NCA_EDIT_GEO_FENCE.key,
          belongsToSidebar: false,
          element: <EditNCAGeoFence />,
        },
        {
          private: true,
          path: accessRoute.NCA_ADD_CATEGORY.path,
          name: accessRoute.NCA_ADD_CATEGORY.name,
          key: accessRoute.NCA_ADD_CATEGORY.key,
          belongsToSidebar: false,
          element: <AddNCACategory />,
        },
        {
          private: true,
          path: accessRoute.NCA_EDIT_CATEGORY.path,
          name: accessRoute.NCA_EDIT_CATEGORY.name,
          key: accessRoute.NCA_EDIT_CATEGORY.key,
          belongsToSidebar: false,
          element: <EditNCACategory />,
        },
        {
          private: true,
          path: accessRoute.NCA_VIEW_SUB_CATEGORY.path,
          name: accessRoute.NCA_VIEW_SUB_CATEGORY.name,
          key: accessRoute.NCA_VIEW_SUB_CATEGORY.key,
          belongsToSidebar: false,
          element: <ViewNCASubCategory />,
        },
        {
          private: true,
          path: accessRoute.NCA_ADD_SUB_CATEGORY.path,
          name: accessRoute.NCA_ADD_SUB_CATEGORY.name,
          key: accessRoute.NCA_ADD_SUB_CATEGORY.key,
          belongsToSidebar: false,
          element: <AddNCASubCategory />,
        },
        {
          private: true,
          path: accessRoute.NCA_EDIT_SUB_CATEGORY.path,
          name: accessRoute.NCA_EDIT_SUB_CATEGORY.name,
          key: accessRoute.NCA_EDIT_SUB_CATEGORY.key,
          belongsToSidebar: false,
          element: <EditNCASubCategory />,
        },
        {
          private: true,
          path: accessRoute.NCA_SERVICE_TYPE.path,
          name: accessRoute.NCA_SERVICE_TYPE.name,
          key: accessRoute.NCA_SERVICE_TYPE.key,
          belongsToSidebar: false,
          element: <ViewNCAServiceType />,
        },
        {
          private: true,
          path: accessRoute.LIST_NCA_DOCUMENT.path,
          name: accessRoute.LIST_NCA_DOCUMENT.name,
          key: accessRoute.LIST_NCA_DOCUMENT.key,
          belongsToSidebar: false,
          element: <Document />,
        },
        {
          private: true,
          path: accessRoute.LOG_REPORTS.path,
          name: accessRoute.LOG_REPORTS.name,
          key: accessRoute.LOG_REPORTS.key,
          belongsToSidebar: false,
          element: <LogReports />,
        },
        {
          private: true,
          path: accessRoute.LIST_NCA_PAYMENT_REPORT.path,
          name: accessRoute.LIST_NCA_PAYMENT_REPORT.name,
          key: accessRoute.LIST_NCA_PAYMENT_REPORT.key,
          belongsToSidebar: false,
          element: <NcaPaymentReport />,
        },
      ],
    },
  ];
}

export const accessRoute = {
  LIST_CITY: {
    path: '/admin/city',
    name: 'City',
    key: 'admin.utility.localization.city',
    moduleKey: 'city',
    for: ['admin', 'subadmin'],
    action: getActions('LIST').value,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-account-setting-fill" />
      </span>
    ),
    order: 21,
  },
  LIST_STATE: {
    path: '/admin/state',
    name: 'State',
    moduleKey: 'state',
    for: ['admin', 'subadmin'],
    action: getActions('LIST').value,
    key: 'admin.utility.localization.state',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
    order: 20,
  },
  LIST_COUNTRY: {
    path: '/admin/country',
    name: 'Country',
    action: getActions('LIST').value,
    moduleKey: 'country',
    for: ['admin', 'subadmin'],
    key: 'admin.utility.localization.country',
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-grid-alt" />
      </span>
    ),
    order: 19,
  },
  LIST_FAQ: {
    path: '/admin/faq',
    name: 'Faq',
    moduleKey: 'faq',
    for: ['admin', 'subadmin'],
    action: getActions('LIST').value,
    key: 'admin.utility.faq',
    order: 24,
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // ),
  },
  LIST_PAGE: {
    path: '/admin/page',
    name: 'Pages',
    action: getActions('LIST').value,
    moduleKey: 'pages',
    for: ['admin', 'subadmin'],
    key: 'admin.utility.page',
    order: 22,
  },
  LIST_PAGE_EDIT: {
    path: '/admin/page/edit/:id',
    key: 'admin.utility.page',
    moduleKey: 'pages',
    action: getActions('EDIT').value,
    name: 'Edit-Page',
  },
  LIST_INDUSTRY: {
    path: '/admin/industry',
    key: 'admin.industry',
    moduleKey: 'industry',
    for: ['admin', 'subadmin'],
    action: getActions('LIST').value,
    order: 49,
  },
  LIST_HOMEPAGE: {
    path: '/admin/homepage',
    key: 'admin.utility.homepage',
    moduleKey: 'edit-home-page',
    for: ['admin', 'subadmin'],
    action: getActions('LIST').value,
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // ),
    order: 23,
  },
  EDIT_HOMEPAGE: {
    path: '/admin/edit-homepage',
    key: 'admin.utility.edit-homepage',
    moduleKey: 'edit-home-page',
    for: ['admin', 'subadmin'],
    action: getActions('EDIT').value,
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // ),
  },
  SEND_NOTIFICATION: {
    path: '/admin/notification',
    key: 'admin.utility.notification',
    moduleKey: 'send-notifications',
    for: ['admin', 'subadmin'],
    action: getActions('LIST').value,
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // ),
    order: 25,
  },
  Banner_View: {
    path: '/admin/banner',
    key: 'admin.customerappsettings.banner',
    moduleKey: 'banner',
    for: ['admin', 'subadmin'],
    action: getActions('LIST').value,
    order: 18,
  },
  Important_Info: {
    path: '/admin/important-info',
    key: 'admin.appText.important-info',
    moduleKey: 'information',
    for: ['admin', 'subadmin'],
    action: getActions('LIST').value,
    order: 26,
  },
  Financing: {
    path: '/admin/financing',
    key: 'admin.appText.financing',
    moduleKey: 'financing',
    for: ['admin', 'subadmin'],
    action: getActions('LIST').value,
    order: 27,
  },
  Tips: {
    path: '/admin/tips',
    key: 'admin.appText.tips',
    moduleKey: 'tips',
    for: ['admin', 'subadmin'],
    action: getActions('LIST').value,
    order: 28,
  },
  Quote_Info: {
    path: '/admin/quote-info',
    key: 'admin.appText.quote-info',
    moduleKey: 'quote-information',
    for: ['admin', 'subadmin'],
    action: getActions('LIST').value,
    order: 29,
  },
  LIST_NCA_PAYMENT_REPORT: {
    path: '/admin/nca/payment-report/:id',
    name: 'NCA Payment Report',
    key: 'nca',
    moduleKey: 'nca',
    for: ['admin', 'subadmin'],
    action: 'view',
  },
  LIST_NCA_DOCUMENT: {
    path: '/admin/nca/documents/:id',
    name: 'NCA Documents',
    key: 'nca',
    for: ['admin', 'subadmin'],
    moduleKey: 'nca',
    action: 'edit',
  },
  LIST_NCA: {
    path: '/admin/nca',
    name: 'NCA',
    for: ['admin', 'subadmin'],
    key: 'admin.nca',
    moduleKey: 'nca',
    action: getActions('LIST').value,
    order: 50,
  },
  EDIT_NCA: {
    path: '/admin/nca/edit/:id',
    name: 'NCA Edit',
    key: 'nca',
    for: ['admin', 'subadmin'],
    moduleKey: 'nca',
    action: 'edit',
  },
  NCA_ADD_GEO_FENCE: {
    path: '/admin/nca/manage-location/add/:id',
    name: 'Add NCA Location',
    for: ['admin', 'subadmin'],
    key: 'nca',
  },
  NCA_EDIT_GEO_FENCE: {
    path: '/admin/nca/manage-location/edit',
    name: 'Edit NCA Location',
    for: ['admin', 'subadmin'],
    key: 'nca',
  },
  NCA_ADD_CATEGORY: {
    path: '/admin/nca/service-category/add/:id',
    name: 'Add NCA Category',
    for: ['admin', 'subadmin'],
    key: 'nca',
  },
  NCA_EDIT_CATEGORY: {
    path: '/admin/nca/service-category/edit/:id/:userId',
    name: 'Edit NCA Location',
    for: ['admin', 'subadmin'],
    key: 'nca',
  },
  NCA_VIEW_SUB_CATEGORY: {
    path: '/admin/nca/subservice-category/:id/:userId',
    name: 'View NCA Sub Service Category',
    for: ['admin', 'subadmin'],
    key: 'nca',
  },
  NCA_ADD_SUB_CATEGORY: {
    path: '/admin/nca/subservice-category/add/:id/:userId',
    name: 'Add NCA Sub Service Category',
    for: ['admin', 'subadmin'],
    key: 'nca',
  },
  NCA_EDIT_SUB_CATEGORY: {
    path: '/admin/nca/subservice-category/edit/:subcategoryId/:id/:userId',
    name: 'Edit NCA Sub Service Category',
    for: ['admin', 'subadmin'],
    key: 'nca',
  },
  NCA_SERVICE_TYPE: {
    path: '/admin/nca/services-type/:userId',
    name: 'NCA Service Type',
    for: ['admin', 'subadmin'],
    key: 'nca',
  },
  LOG_REPORTS: {
    path: '/admin/nca_log_report/:id',
    name: 'Edit NCA Sub Service Category',
    key: 'nca_log',
    moduleKey: 'nca',
    action: getActions('EDIT'),
  },
};
/******Return should be list of children routes**** */
export const getChildrenRoutes = () => {
  return [...route()[0]?.children];
};
