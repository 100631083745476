import {
  CommonButton,
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import i18next from 'i18next';
import {
  ButtonTextFormatter,
  checkValidPrice,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { getFromToDateFilter } from '../../components/Filter/Common/date.filter';
import {
  dateFormatter,
  phoneNumberFormate,
  showDateInBrowser,
} from '../../utils';
import { accessRoute as dashboardRoute } from '../../routes/Dashboard';
import { accessRoute } from '../../routes/NewEquipment';
import { firstLetterCaps, getFullName } from '../../utils/text.util';
import moment from 'moment';
import { providerListService } from '../TeamSetting/provider.service';
import { CustomersService } from '../Customers';
import { getQuoteTypeListService } from '../index.service';
import { Select } from 'antd';
import { generatePaths } from './serviceManagement.service';
import { DATE_TH_MONTH_YEAR } from '../../config';
export const newEquipmentBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.equipmentInstall.title'),
    },
  ];
};
export const newEquipmentBookingLogBreadcrumb = () => {
  return [
    {
      path: dashboardRoute?.DASHBOARD?.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: accessRoute?.NEW_EQUIPMENT?.path,
      name: i18next.t('text.equipmentInstall.title'),
    },
    {
      path: '#',
      name: i18next.t('text.serviceManagement.bookingLog'),
    },
  ];
};

const getJobStatusData = () => {
  return [
    {
      name: 'In Progress',
      value: 'onGoing',
    },
    {
      name: 'Finished',
      value: 'completed',
    },
    {
      name: 'Canceled',
      value: 'cancelled',
    },
  ];
};
const getPermitStatusFilterOption = () => {
  return [
    {
      name: 'Needs Attention',
      value: 'needs_attention',
    },
    {
      name: 'Requested',
      value: 'requested',
    },
    {
      name: 'Completed',
      value: 'completed',
    },
    {
      name: 'N/A',
      value: 'na',
    },
  ];
};
const getWarrantyRegisteredData = () => {
  return [
    {
      name: 'Needs Attention',
      value: 'needs_attention',
    },
    {
      name: 'Submitted for Processing',
      value: 'submitted_for_processing',
    },
    {
      name: 'Warranty Completed',
      value: 'warranty_completed',
    },
    {
      name: 'N/A',
      value: 'na',
    },
  ];
};
const getLaborWarrantyPurchasedData = () => {
  return [
    {
      name: 'No',
      value: 'no',
    },
    {
      name: 'Yes - (1 year)',
      value: 'yes1yr',
    },
    {
      name: 'Yes - (5 year)',
      value: 'yes5yr',
    },
    {
      name: 'Yes - (10 year)',
      value: 'yes10yr',
    },
    {
      name: 'Yes - (See Invoice)',
      value: 'yesSeeInv',
    },
  ];
};

const PermitStatus = [
  {
    value: 'needs_attention',
    label: 'Needs Attention',
  },
  {
    value: 'requested',
    label: 'Requested',
  },
  {
    value: 'completed',
    label: 'Completed',
  },
  {
    value: 'na',
    label: 'N/A',
  },
];

export const NewEquipmentInstallTableColumn = ({
  saveUpdates,
  cancelUpdates,
  onFilterChange,
  filter = {},
  Action,
  page,
  sizePerPage,
  options,
  handleChangeOption,
  isUserCanEdit,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (_, __, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (_, __, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'jobNumber',
      title: i18next.t('text.common.jobNo'),
      headerClasses: 'sorting',
      // sorter: true,
      render: (_, row) =>
        ButtonTextFormatter(
          generatePaths(accessRoute.BOOKING_LOG.path, row?.bookingId),
          row?.Booking?.bookingNo ? row?.Booking?.bookingNo : '-',
          false,
          {},
          '_blank'
        ),
      ...getInputSearchFilter({
        dataIndex: 'jobNumber',
        placeholder: 'job number',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'jobStatus',
      title: i18next.t('text.common.jobStatus'),
      headerClasses: 'sorting',
      // sorter: true,
      render: (_, row) => {
        if (row?.Booking?.jobStatus === 'completed') {
          return 'Finished';
        } else if (row?.Booking?.jobStatus === 'active') {
          return 'In Progress';
        } else if (
          row?.Booking?.jobStatus === 'canceled' ||
          row?.Booking?.jobStatus === 'declined'
        ) {
          return 'Cancelled';
        } else {
          return firstLetterCaps(row?.Booking?.jobStatus) || '-';
        }
      },
      // row?.Booking?.BookingDetail?.serviceStatus
      //   ? firstLetterCaps(
      //       row?.Booking?.BookingDetail?.serviceStatus === 'start'
      //         ? 'Ongoing'
      //         : row?.Booking?.BookingDetail?.serviceStatus
      //     )
      //   : '-',
      ...getMultiSelectSearchFilter({
        dataIndex: 'jobStatus',
        onFilterChange,
        name: 'job status',
        list: [],
        mode: false,
        listService: getJobStatusData,
        listKeys: { nameKey: 'name', nameId: 'value' },
        filter,
        isValue: true,
      }),
    },
    {
      dataIndex: 'jobDate',
      filterDataIndex: ['jobToDate', 'jobFromDate'],
      title: i18next.t('text.serviceManagement.jobDate'),
      headerClasses: 'sorting',
      // sorter: true,
      // render: (cell, _) => showDateInBrowser(cell),
      render: (cell, row) =>
        showDateInBrowser(row?.Booking?.bookingScheduleDate),
      // ...getColumnSelectDateProps('date'),
      ...getFromToDateFilter({
        dataStartIndex: 'jobFromDate',
        dataEndIndex: 'jobToDate',
        placeholderStartDate: 'Select date',
        placeholderEndDate: 'Select date',
        onFilterChange,
        dateFormat: 'YYYY-MM-DD',
        filter,
      }),
    },
    {
      dataIndex: 'provider',
      filterDataIndex: ['providerUserId'],
      title: i18next.t('text.common.provider'),
      headerClasses: 'sorting',
      render: (_, row) =>
        getFullName(
          row?.Booking?.Provider?.firstName ?? '',
          row?.Booking?.Provider?.lastName ?? ''
        ),
      ...getMultiSelectSearchFilter({
        dataIndex: 'providerUserId',
        onFilterChange,
        name: 'provider name',
        list: [],
        mode: 'multiple',
        // scope: 'activeUser',
        searchOrNot: true,
        listKeys: { nameKey: ['firstName', 'lastName'], nameId: 'id' },

        listService: providerListService,
        filter,
      }),
    },
    {
      dataIndex: 'customerName',
      filterDataIndex: ['customerUserId'],
      title: i18next.t('text.common.customerName'),
      headerClasses: 'sorting',
      // sorter: true,
      // onSort: (field, order) => onSort('jobStatus', order),
      render: (_, row) =>
        row?.Booking?.Customer?.firstName || row?.Booking?.Customer?.lastName
          ? getFullName(
              row?.Booking?.Customer?.firstName,
              row?.Booking?.Customer?.lastName,
              '',
              row?.Booking?.Customer?.CustomerMembership?.MembershipPlan?.name
            )
          : '-',
      ...getMultiSelectSearchFilter({
        dataIndex: 'customerUserId',
        onFilterChange,
        name: 'customer name',
        list: [],
        mode: 'multiple',
        // scope: 'activeUser',
        searchOrNot: true,
        listKeys: { nameKey: ['firstName', 'lastName'], nameId: 'id' },
        listService: CustomersService?.CustomersListService,
        filter,
        extraQuary: { isFilter: 1 },
      }),
    },
    {
      dataIndex: 'customerNumber',
      filterDataIndex: ['customerPhoneNumber'],
      title: i18next.t('text.common.customerPhoneNumber'),
      headerClasses: 'sorting',
      // sorter: true,
      render: (_, row) => {
        const formatNumber = phoneNumberFormate(
          row?.Booking?.Customer?.phoneNumber,
          row?.Booking?.Customer?.countryPhoneCode
        );
        return formatNumber ?? '-';
      },
      ...getInputSearchFilter({
        dataIndex: 'customerPhoneNumber',
        placeholder: 'customer phone number',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'customerEmail',
      title: i18next.t('text.homeInventory.customerEmail'),
      headerClasses: 'sorting',
      // sorter: true,
      render: (_, row) => row?.Booking?.Customer?.email ?? '-',
      ...getInputSearchFilter({
        dataIndex: 'customerEmail',
        placeholder: 'customer email',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'customerAddress',
      filterDataIndex: ['location'],
      title: i18next.t('text.homeInventory.customerAddress'),
      headerClasses: 'sorting',
      // sorter: true,
      render: (_, row) =>
        row?.Booking?.Address?.addressLine1
          ? row?.Booking?.Address?.addressLine1
          : '-',
      ...getInputSearchFilter({
        dataIndex: 'location',
        placeholder: 'customer address',
        onFilterChange,
        filter,
      }),
      // ...getColumnSearchProps('customer address'),
    },
    {
      dataIndex: 'totalCost',
      title: i18next.t('text.equipmentInstall.totalCost'),
      headerClasses: 'sorting',
      // sorter: true,
      render: (_, row) => checkValidPrice(row?.Booking?.total),
    },
    {
      dataIndex: 'equipmentInstalled',
      title: i18next.t('text.equipmentInstall.equipmentInstall'),
      headerClasses: 'sorting',
      // sorter: true,
      render: (_, row) =>
        row?.quoteType ? (
          <Action row={row} cell={firstLetterCaps(row?.quoteType)} />
        ) : (
          '-'
        ),
      // ModalOpenFormatter(equipmentViewModal, row?.equipmentInstalled),
      ...getMultiSelectSearchFilter({
        dataIndex: 'equipmentInstalled',
        onFilterChange,
        name: 'equipment installed',
        list: [],
        listService: getQuoteTypeListService,
        listKeys: { nameKey: 'quoteType', nameId: 'quoteType' },
        filter,
      }),
    },
    {
      dataIndex: 'permitLocation',
      title: i18next.t('text.equipmentInstall.permitLocation'),
      headerClasses: 'sorting',
      // sorter: true,
      render: (_, row) =>
        row?.Booking?.BookingQuote?.Location
          ? firstLetterCaps(row?.Booking?.BookingQuote?.Location?.name ?? '')
          : '-',
    },
    {
      dataIndex: 'permitStatus',
      title: i18next.t('text.equipmentInstall.permitStatus'),
      headerClasses: 'sorting',
      // sorter: true,
      ...getMultiSelectSearchFilter({
        dataIndex: 'permitStatus',
        onFilterChange,
        name: 'Permit Status',
        list: [],
        mode: false,
        listKeys: { nameKey: 'name', nameId: 'value' },
        listService: getPermitStatusFilterOption,
        filter,
        isValue: true,
      }),
      render: (_, row) => {
        return (
          <Select
            id="permitStatus"
            size="medium"
            extraClassName="form-control"
            name="statusId"
            disabled={!isUserCanEdit}
            value={
              options?.[row?.id]?.permitStatus
                ? options[row?.id].permitStatus
                : row?.permitStatus ?? 'Needs Attention'
            }
            onSelect={(e) => handleChangeOption('permitStatus', e, row?.id)}
            variant="standard"
            placeholder="Select status"
            options={PermitStatus}
          />
        );
      },
    },
    {
      dataIndex: 'warrantyRegistered',
      title: i18next.t('text.equipmentInstall.warrantyRegistered'),
      headerClasses: 'sorting',
      // sorter: true,
      ...getMultiSelectSearchFilter({
        dataIndex: 'warrantyRegistered',
        onFilterChange,
        name: 'warranty registered',
        list: [],
        mode: false,
        listKeys: { nameKey: 'name', nameId: 'value' },
        listService: getWarrantyRegisteredData,
        filter,
        isValue: true,
      }),

      render: (_, row) => {
        return (
          <Select
            id="warrantyRegistered"
            size="medium"
            extraClassName="form-control"
            name="warrantyRegistered"
            disabled={!isUserCanEdit}
            value={
              options?.[row?.id]?.warrantyRegistered
                ? options[row?.id]?.warrantyRegistered
                : row?.warrantyRegistered ?? 'Needs Attention'
            }
            onSelect={(e) =>
              handleChangeOption('warrantyRegistered', e, row?.id)
            }
            variant="standard"
            placeholder="Select options"
            options={getWarrantyRegisteredData()?.map((item) => ({
              label: item?.name,
              value: item?.value,
            }))}
          />
        );
      },
    },
    {
      dataIndex: 'laborWarrantyPurchased',
      filterDataIndex: ['laborWarranty'],
      title: i18next.t('text.equipmentInstall.laborWarranty'),
      headerClasses: 'sorting',
      // sorter: true,
      ...getMultiSelectSearchFilter({
        dataIndex: 'laborWarranty',
        onFilterChange,
        name: 'labor warranty purchased',
        list: [],
        mode: false,
        listKeys: { nameKey: 'name', nameId: 'value' },
        listService: getLaborWarrantyPurchasedData,
        filter,
        isValue: true,
      }),

      render: (_, row) => {
        return (
          <Select
            id="laborWarrantyPurchased"
            size="medium"
            extraClassName="form-control"
            name="laborWarrantyPurchased"
            disabled={!isUserCanEdit}
            value={
              options?.[row?.id]?.laborWarrantyPurchased
                ? options[row?.id]?.laborWarrantyPurchased
                : row?.laborWarrantyPurchased ?? ''
            }
            onSelect={(e) =>
              handleChangeOption('laborWarrantyPurchased', e, row?.id)
            }
            variant="standard"
            placeholder="Select options"
            options={getLaborWarrantyPurchasedData()?.map((item) => ({
              label: item?.name,
              value: item?.value,
            }))}
          />
        );
      },
    },
    {
      dataIndex: 'lastUpdatedSave',
      title: i18next.t('text.equipmentInstall.lastUpdatedAdSave'),
      render: (_, row) => {
        const prevDetails = {
          permitStatus: row?.permitStatus ?? '',
          laborWarrantyPurchased: row?.laborWarrantyPurchased ?? '',
          warrantyRegistered: row?.warrantyRegistered ?? '',
        };
        const updateDetails = {
          ...prevDetails,
          ...options?.[row?.id],
        };
        const isUpdate =
          JSON.stringify(prevDetails) !== JSON.stringify(updateDetails);
        if (options?.[row?.id] && isUpdate) {
          return (
            <>
              <CommonButton
                onClick={() => saveUpdates(row?.id)}
                className="btn btn-primary btn-sm"
              >
                {i18next.t('text.common.save')}
              </CommonButton>
              <CommonButton
                onClick={() => cancelUpdates(row?.id)}
                className="btn btn-light btn-sm ms-1"
              >
                {i18next.t('text.common.cancel')}
              </CommonButton>
            </>
          );
        } else {
          return (
            <>
              {row?.adminUpdatedByUser && row?.adminUpdatedByUser?.Role?.name
                ? `Admin ${getFullName(
                    row?.adminUpdatedByUser?.firstName ?? '',
                    row?.adminUpdatedByUser?.lastName ?? ''
                  )} Saved ${moment(row?.updatedAt).format('L')}`
                : row?.updatedAt
                ? moment(row?.updatedAt).format('llll')
                : null}
            </>
          );
        }
      },
    },
  ];
};
