export const REPORT_AN_ISSUE_ENDPOINT = {
  getIssueReportService: {
    url: '/v1/admin/report-an-issue',
    method: 'GET',
  },
  addAdminNote: (id) => ({
    url: `v1/admim/add-report-note/${id}`,
    method: 'PUT',
  }),
  changePriorityAndStatus: (id) => ({
    url: `v1/admim/change-priority-status/${id}`,
    method: 'PUT',
  }),
  issueAssign: (id,userId) => ({
    url: `v1/admim/add-person-incharge/${id}/${userId}`,
    method: 'PUT',
  }),
};
