import { MARKETING_TEMPLATE_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';
import { STORE_ENDPOINT } from '../../apiEndpoints/index.endpoint';

export const addMarketingEmailService = async ({ body }) => {
  try {
    const payload = {
      ...MARKETING_TEMPLATE_ENDPOINT.addMarketingEmail,
      bodyData: body,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const editMarketingEmailService = async ({ body, id, queryParams={} }) => {
  try {
    const payload = {
      ...MARKETING_TEMPLATE_ENDPOINT.editMarketingEmail(id),
      bodyData: body,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const listMarketingEmailService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...MARKETING_TEMPLATE_ENDPOINT.getMarketingEmailList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const updateMarketingEmailStatusService = async ({
  marketingEmailId,
  status,
}) => {
  try {
    const payload = {
      ...MARKETING_TEMPLATE_ENDPOINT.updateMarketingEmailStatus(
        marketingEmailId
      ),
      bodyData: { status },
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getMarketingReviewService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...STORE_ENDPOINT?.getMarketingReviewList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getUnsubscribeUserListService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...STORE_ENDPOINT?.getUnsubscribeUserList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const addSmsTemplateService = async ({ body }) => {
  try {
    const payload = {
      ...MARKETING_TEMPLATE_ENDPOINT?.addSmsTemplate,
      bodyData: body,
    };
    return await APIRequest(payload);
  } catch (err) {
    console.log(err);
  }
};

export const editSmsTemplateService = async ({ body, id, queryParams={} }) => {
  try {
    const payload = {
      ...MARKETING_TEMPLATE_ENDPOINT?.editSmsTemplate(id),
      bodyData: body,
      queryParams
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const deleteUnsubscribeUserService = async ({ bodyData }) => {
  try {
    const payload = {
      ...STORE_ENDPOINT.deleteUnsubscribeUser(bodyData?.id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateApproveDeclineStatusService = async ({
  resourceId,
  status,
}) => {
  try {
    const payload = {
      ...STORE_ENDPOINT.updateApproveDeclineStatus(resourceId),
      bodyData: { status },
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const updateMarketingSmsStatusService = async ({
  marketingSmsId,
  status,
}) => {
  console.log('  marketingSmsId,status,', marketingSmsId, status);
  try {
    const payload = {
      ...MARKETING_TEMPLATE_ENDPOINT.updateSMSStatusId(marketingSmsId),
      bodyData: { status },
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getMessageReportListService = async ({ queryParams }) => {
  let { id, ...restQuery } = queryParams;

  try {
    const payload = {
      ...MARKETING_TEMPLATE_ENDPOINT?.getMessageReportList(id),
      queryParams: restQuery,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getTagFilterService = async (id) => {
  try {
    const payload = {
      ...MARKETING_TEMPLATE_ENDPOINT?.getTagFilterList(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
export const getProviderNameFilterService = async (id) => {
  try {
    const payload = {
      ...MARKETING_TEMPLATE_ENDPOINT?.getProviderNameFilterList(id),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};
