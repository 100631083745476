import React, { useState, useEffect } from 'react';
import { useRef } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import {
  Breadcrumb,
  CommonButton,
  GlobalLoader,
  ImageElement,
  ModalWrapper,
  NoDataFound,
} from '../../../components';
import {
  addQuoteService,
  getBookingDetailsById,
  getQuoteOptionListService,
  putCustomerBookingApprove,
  quoteDecline,
} from '../../../services/CustomerApp/index.service';
import { Columns } from '../../../services/index.service';
import {
  generateStarRating,
  logger,
  modalNotification,
  setLocalStorage,
  arrayEqual,
  handlePrint,
} from '../../../utils';

import ApproveModal from './ApproveModal';
import DeclineModal from './DeclineModal';
import axios from 'axios';
import { COMMON_ENDPOINT } from '../../../apiEndpoints/index.endpoint';
import { firstLetterCaps } from '../../../utils/text.util';
import { Link } from 'react-router-dom';
import { Checkbox } from 'antd';
import { checkValidPrice } from '../../../components/UIElements/Formatter';
import RequestDetailsModal from './requestDetailsModal';
import { useSelector } from 'react-redux';
import { accessRoute } from '../../../routes/CustomerApp/booking';
import moment from 'moment';

function RequestDetails({ isGuest = false, guestRefToken = false }) {
  // const regexDashRemove = /[0-9]/g;

  // last digit remove
  const regexDashRemove = /\d$/;

  const navigate = useNavigate();
  const userType = useSelector((state) => state?.auth?.userData?.Role?.type);
  const companyCode = useSelector((state) => state?.auth?.unAuthUserData?.Provider?.Provider?.companyCode ?? null);
  const { bookingId } = useParams();

  const data = {};
  const { t } = useTranslation();
  const breadcrumb = Columns.requestViewDetailsBreadcrumb();
  const location = useLocation();
  const [checkAll, setCheckAll] = useState({});
  const [lineCheckAll, setLineCheckAll] = useState(false);
  const [equipmentCheckAll, setEquipmentCheckAll] = useState(false);

  const [spDiscount, setSpDiscount] = useState(0);
  const [optionTotalAmount, setOptionTotalAmount] = useState(0);

  const [quotePrice, setQuotePrice] = useState({});
  const [quoteOptionPrice, setQuoteOptionPrice] = useState({});

  const [quoteDiscountedPrice, setQuoteDiscountedPrice] = useState({});

  const [isDeclineBeforeNext, setIsDeclineBeforeNext] = useState(true);

  const [guestToken, setGuestToken] = useState('');

  const [quoteTotalAmount, setQuoteTotalAmount] = useState(0);
  const [memberShipDiscount, setMemberShipDiscount] = useState(0);

  const [state, setState] = useState({
    isLoading: false,
    details: [],
    showModal: '',
    modalHeading: '',
    reason: '',
    isSigned: false,
    customerSignature: '',
    editQuote: false,

    quoteData: [],
    quoteAllId: {},
    optionAllId: {},
    optionalItemIdObj: {},
    quoteOrderIdObj: {},
    lineItemIdArray: [],
    lineItemAllId: [],
    adminAddedlineItems: [],
    adminAddedCheckedlineItemsAmount: 0,

    allQuoteCost: {},
    allQuoteMembershipCost: {},
    allOptionQuoteCost: {},
    allOptionCost: {},
    allLineItemCost: {},
    lineItemTotalCost: 0,
    quoteTotalCost: 0,
    selectQuoteMembershipCost: 0,
    optionalTotalCost: 0,
    equipmentIdArray: {},
    equipmentAllIdArray: [],
    equipmentItemTotalCost: 0,
    allEquipmentItemCost: {},
    modalKey: '',
    modalData: '',
    monthlyTotalCostObj: {},
    monthlyTotalCost: 0,
    oldQuoteLineTotalCost: 0,
    discountObj: {},
    totalDiscount: 0,
    rebateObj: {},
    quoteSelectID: [],
    discountQuoteObj: {},
    discountQuoteListObj: {},
    monthlyEquipmentTotalCost: 0,
    monthlyEquipmentTotalCostObj: {},
    monthlyLineItemTotalCost: 0,
    monthlyLineItemTotalCostObj: {},
    equipmentAllIdObj: {},
    equipmentAllSelectedIdArray: [],
    equOptionalItemIdObj: {},
    equOptionAllId: {},
    equOrderIdObj: {},
    allEquOptionCost: {},
    equOptionalTotalCost: 0,
    equAllOptionQuoteCost: {},
    allCheckEquOptionalIdObj: {},
    allCheckEquOptionalTotalCost: 0,
    allQuoteDiscountedCost: {},
    isCheckBox: true,
    equOptionDecline: false,
  });

  let {
    editQuote,
    adminAddedlineItems,
    adminAddedCheckedlineItemsAmount,
    details,
    quoteData,
    optionalItemIdObj,
    quoteOrderIdObj,
    quoteAllId,
    optionAllId,
    lineItemIdArray,
    lineItemAllId,
    allQuoteCost,
    allQuoteMembershipCost,
    allOptionQuoteCost,
    allOptionCost,
    allLineItemCost,
    lineItemTotalCost,
    quoteTotalCost,
    selectQuoteMembershipCost,
    optionalTotalCost,
    equipmentIdArray,
    equipmentAllIdArray,
    equipmentItemTotalCost,
    allEquipmentItemCost,
    monthlyTotalCostObj,
    monthlyTotalCost,
    oldQuoteLineTotalCost,
    discountObj,
    totalDiscount,
    rebateObj,
    quoteSelectID,
    discountQuoteObj,
    discountQuoteListObj,
    monthlyEquipmentTotalCost,
    monthlyEquipmentTotalCostObj,
    monthlyLineItemTotalCost,
    monthlyLineItemTotalCostObj,
    equipmentAllIdObj,
    equipmentAllSelectedIdArray,
    equOptionalItemIdObj,
    equOptionAllId,
    equOrderIdObj,
    allEquOptionCost,
    equOptionalTotalCost,
    equAllOptionQuoteCost,
    allCheckEquOptionalIdObj,
    allCheckEquOptionalTotalCost,
    allQuoteDiscountedCost,
    isCheckBox,
    equOptionDecline,
  } = state;

  let sigPad = useRef();

  const handleClose = () => {
    setState({
      ...state,
      showModal: '',
    });
  };

  const getRequestViewDetails = async (e, status) => {
    try {
      setState({
        ...state,
        isLoading: true,
      });
      let obj = {};
      const response = await getBookingDetailsById(
        location?.state?.bookingId || bookingId,
        isGuest
      );
      if (response?.success) {
        obj = {
          ...obj,
          details: response?.data ?? [],
          isLoading: false,
        };
      }

      // for quote
      const quoteResponse = await getQuoteOptionListService(
        location?.state?.bookingId || bookingId,
        false,
        isGuest
      );
      if (quoteResponse?.success) {
        setGuestToken(quoteResponse?.data?.booking?.bookingToken);
        if (
          (quoteResponse?.data?.bookingQuote?.providerStatus === 'accepted' ||
            quoteResponse?.data?.bookingQuote?.providerStatus === 'declined') &&
          quoteResponse?.data?.bookingQuote?.approvedBy !== 'customer'
        ) {
          let path = generatePath(
            accessRoute.REQUEST_APPROVE_VIEW_DETAILS.path,
            {
              bookingId: bookingId,
            }
          );

          if (isGuest) {
            modalNotification({
              type: 'error',
              message:
                quoteResponse?.data?.bookingQuote?.providerStatus === 'accepted'
                  ? 'Your quote is already accepted by provider'
                  : 'Your quote is already declined by provider',
            });
            navigate('/');
          } else {
            navigate(path);
          }
        } else {
          if (e === 'editNextQuote') {
            return;
          }
        }
        /*{ Condition for diplaying data of new quote after customer approve quote (in case if sp update the quote page should show updated data)} */
        if (
          quoteResponse?.data?.bookingQuote?.approvedBy === 'customer' &&
          quoteResponse?.data?.bookingQuote?.customerStatus === 'accepted' &&
          quoteResponse?.data?.bookingQuote?.providerStatus === 'pending' &&
          quoteResponse?.data?.bookingQuote?.isSalesQuotes
        ) {
          let equipmentIdArray = [];
          equipmentIdArray = response?.data?.otherEquipment?.map((item) => item?.id);

          // Block for other equipment
          if (quoteResponse?.data?.otherEquipment && quoteResponse?.data?.otherEquipment?.length > 0) {
            quoteResponse.data.otherEquipment = quoteResponse?.data?.otherEquipment?.map((item) => {
              if (equipmentIdArray?.includes(item?.id)) {
                item.checked = 1;
              }
              return item;
            })
          }

          // Block for line items
          if (quoteResponse?.data?.lineItems && quoteResponse?.data?.lineItems?.length > 0) {
            quoteResponse.data.lineItems = quoteResponse?.data?.lineItems?.map((item) => {
              return {
                ...item,
                checked: 1
              }
            })
          }

          // Block for options
          if (quoteResponse?.data?.optionsList && Object.keys(quoteResponse?.data?.optionsList)?.length > 0) {
            Object.keys(quoteResponse?.data?.optionsList).forEach((key) => {
              quoteResponse.data.optionsList[key] = quoteResponse.data.optionsList[key]?.map((item) => {
                if (item?.optionalItemForBooking) {
                  item.optionalItemForBooking = item?.optionalItemForBooking?.map((item2) => ({
                    ...item2,
                    checked: 1,
                  }));
                }
                item.is_customer_checked = 1;
                return item;
              });
            });
          }
        }

        let quoteIObj = {};
        let optionalIObj = {};
        let totalQuoteCostObj = {};
        let totalQuoteMembershipCostObj = {};
        let totalOptionQuoteCostObj = {};
        let totalOptionQuoteCost = [];
        let monthlyCostObj = {};
        let allDiscountQuoteObj = {};
        let rebateQuoteObj = {};
        let discountQuoteTypeObj = {};
        let discountQuoteListTypeObj = {};
        let totalDiscountedQuoteCostObj = {};
        let quoteDiscountAmount = 0;
        let spTotalDiscount =
          quoteResponse?.data?.discountList?.length &&
          quoteResponse?.data?.discountList?.filter((e) => !e?.bookingQuoteId)
            ?.length &&
          quoteResponse?.data?.discountList
            ?.filter((e) => !e?.bookingQuoteId)
            .map((item) => item?.cost)
            ?.reduce(
              (accumulator, currentValue) =>
                Number(accumulator) + Number(currentValue)
            );

        let memberShipTotalDiscount =
          quoteResponse?.data?.booking &&
          quoteResponse?.data?.booking?.membershipBenefitAvailable &&
          Number(quoteResponse?.data?.booking?.membershipBenefit) +
          Number(quoteResponse?.data?.booking?.lineItemMembershipBenefit);

        setSpDiscount(spTotalDiscount || 0);
        setMemberShipDiscount(memberShipTotalDiscount || 0);
        quoteResponse?.data?.optionsList &&
          Object.keys(quoteResponse?.data?.optionsList)?.length &&
          Object.keys(quoteResponse?.data?.optionsList).forEach((key) => {
            quoteResponse?.data?.optionsList[key].forEach((item, i) => {
              quoteIObj = {
                ...quoteIObj,
                [`${key}${i}`]: [item?.id],
              };

              optionalIObj = {
                ...optionalIObj,
                [`${key}${i}`]: (
                  item?.optionalItem || item?.optionalItemForBooking
                )?.map((id) => id?.id),
              };

              // quoteDiscountAmount =
              //   item?.discount_list?.length &&
              //   item?.discount_list.filter(
              //     (e) =>
              //       e?.discountType === 'flat' &&
              //       parseFloat(item?.admin_material_total_amount) <
              //         parseFloat(e?.cost)
              //   )?.length
              //     ? 0
              //     : item?.discount_list.map((e) =>
              //         e?.discountType === 'flat'
              //           ? parseFloat(e?.cost)
              //           : (parseFloat(item?.admin_material_total_amount) /
              //               100) *
              //             parseFloat(e?.cost)
              //       )?.length &&
              //       item?.discount_list
              //         .map((e) =>
              //           e?.discountType === 'flat'
              //             ? parseFloat(e?.cost) <
              //               parseFloat(item?.admin_material_total_amount)
              //               ? parseFloat(item?.admin_material_total_amount) -
              //                 parseFloat(e?.cost)
              //               : item?.admin_material_total_amount
              //             : parseFloat(item?.admin_material_total_amount) -
              //               (parseFloat(item?.admin_material_total_amount) /
              //                 100) *
              //                 e?.cost
              //         )
              //         ?.reduce(
              //           (accumulator, currentValue) =>
              //             Number(accumulator) + Number(currentValue)
              //         );

              quoteDiscountAmount =
                parseFloat(item?.appliedDiscount) > 0
                  ? parseFloat(item?.admin_material_total_amount) -
                  parseFloat(item?.appliedDiscount)
                  : parseFloat(item?.admin_material_total_amount);

              totalQuoteCostObj = {
                ...totalQuoteCostObj,
                [item?.id]: item?.admin_material_total_amount,
              };
              totalQuoteMembershipCostObj = {
                ...totalQuoteMembershipCostObj,
                [item?.id]: item?.membershipBenefitAvailable ? item?.membershipBenefitAmount : {},
              };

              totalDiscountedQuoteCostObj = {
                ...totalDiscountedQuoteCostObj,
                [item?.id]: quoteDiscountAmount,
              };

              (item?.optionalItem || item?.optionalItemForBooking).forEach(
                (option) => {
                  const index = totalOptionQuoteCost.findIndex(
                    (e) => e?.key === key
                  );
                  const restData = {
                    ...totalOptionQuoteCost[index],
                    [option?.id]: option?.cost,
                    key,
                  };
                  if (index > -1) totalOptionQuoteCost[index] = restData;
                  else
                    totalOptionQuoteCost.push({
                      [option?.id]: option?.cost,
                      key,
                    });
                }
              );

              monthlyCostObj = {
                ...monthlyCostObj,
                [item?.id]: item?.monthlyPayment,
              };
              let quoteDiscount =
                item?.discount_list?.length &&
                  item?.discount_list.filter(
                    (e) =>
                      e?.discountType === 'flat' &&
                      parseFloat(item?.admin_material_total_amount) <
                      parseFloat(e?.cost)
                  )?.length
                  ? parseFloat(item?.admin_material_total_amount)
                  : item?.discount_list.map((e) =>
                    e?.discountType === 'flat'
                      ? parseFloat(e?.cost)
                      : (parseFloat(item?.admin_material_total_amount) /
                        100) *
                      parseFloat(e?.cost)
                  )?.length &&
                  item?.discount_list
                    .map((e) =>
                      e?.discountType === 'flat'
                        ? parseFloat(e?.cost) <
                          parseFloat(item?.admin_material_total_amount)
                          ? parseFloat(e?.cost)
                          : parseFloat(item?.admin_material_total_amount)
                        : (parseFloat(item?.admin_material_total_amount) /
                          100) *
                        parseFloat(e?.cost)
                    )
                    ?.reduce(
                      (accumulator, currentValue) =>
                        Number(accumulator) + Number(currentValue)
                    );
              allDiscountQuoteObj = {
                ...allDiscountQuoteObj,
                [item?.id]: quoteDiscount,
              };

              discountQuoteTypeObj = {
                ...discountQuoteTypeObj,
                [key]: item?.discount,
              };

              rebateQuoteObj = {
                ...rebateQuoteObj,
                [item?.id]: item?.rebate_list?.length ? item?.rebate_list : [],
              };

              discountQuoteListTypeObj = {
                ...discountQuoteListTypeObj,
                [item?.id]: item?.discount_list?.length ? item : [],
              };
            });
          });

        let lineItemIdArray =
          quoteResponse?.data?.lineItems?.length &&
          quoteResponse?.data?.lineItems.map((e) => e?.id);

        let equipmentAllIdArray =
          quoteResponse?.data?.otherEquipment &&
          quoteResponse?.data?.otherEquipment.map((e) => e?.id);

        let equipmentSelectedCost =
          quoteResponse?.data?.otherEquipment
            .filter((e) => e?.checked === 1)
            .map((item) => item?.cost)?.length &&
          quoteResponse?.data?.otherEquipment
            .filter((e) => e?.checked === 1)
            .map((item) => item?.cost)
            ?.reduce(
              (accumulator, currentValue) =>
                Number(accumulator) + Number(currentValue)
            );

        let equipmentSelectedIdArray =
          quoteResponse?.data?.otherEquipment &&
          quoteResponse?.data?.otherEquipment
            .filter((e) => e?.checked === 1)
            .map((e) => e?.id);

        let selectedEquipmentID = {};
        equipmentSelectedIdArray.forEach((e) => {
          selectedEquipmentID = {
            ...selectedEquipmentID,
            [e]: [e],
          };
        });

        let allEquipmentTotalCost =
          quoteResponse?.data?.otherEquipment?.length &&
          quoteResponse?.data?.otherEquipment
            ?.map((item) => item?.cost)
            ?.reduce(
              (accumulator, currentValue) =>
                Number(accumulator) + Number(currentValue)
            );

        let equipmentAllCost = {};
        let equipmentAllMonthlyCost = {};
        quoteResponse?.data?.otherEquipment.forEach((equipment) => {
          equipmentAllCost = {
            ...equipmentAllCost,
            [equipment?.id]: equipment?.cost,
          };
          if (quoteResponse?.data?.financeOption?.paymentFactor > 0) {
            equipmentAllMonthlyCost = {
              ...equipmentAllMonthlyCost,

              [equipment?.id]:
                Number(equipment?.cost) /
                quoteResponse?.data?.financeOption?.paymentFactor,
            };
          }
        });

        let monthlySelectedEquipmentTotalCost =
          quoteResponse?.data?.otherEquipment.filter((e) => e?.checked === 1)
            ?.length &&
          quoteResponse?.data?.otherEquipment
            .filter((e) => e?.checked === 1)
            .map(
              (e) =>
                Number(e?.cost) /
                quoteResponse?.data?.financeOption?.paymentFactor
            )
            .reduce(
              (accumulator, currentValue) =>
                Number(accumulator) + Number(currentValue)
            );

        // Equipment option start
        let equOptionalIdObj = {};
        let equOptionAllIdObj = {};
        let equOptionCost = {};
        let allEquOptionalTotalCost = 0;
        let totalEquOptionCost = [];
        let totalEquOptionCostObj = {};

        let allEquOptionalIdObj = {};
        quoteResponse?.data?.otherEquipment.forEach((item) => {
          equOptionalIdObj = {
            ...equOptionalIdObj,
            [item?.id]: item?.otherEquipment?.length
              ? item?.otherEquipment
                .filter((e) => e?.checked === 1)
                .map((e) => e?.id)
              : [],
          };

          allEquOptionalIdObj = {
            ...allEquOptionalIdObj,
            [item?.id]: item?.otherEquipment?.length
              ? item?.otherEquipment.map((e) => e?.id)
              : [],
          };

          equOptionAllIdObj = {
            ...equOptionAllIdObj,
            [item?.id]: item?.otherEquipment?.length
              ? item?.otherEquipment.map((e) => e?.id)
              : [],
          };

          equOptionCost = {
            ...equOptionCost,
            [item?.id]: item?.otherEquipment?.length
              ? item?.otherEquipment
                .filter((e) => e?.checked === 1)
                .map((e) => e?.cost)
              : 0,
          };

          allEquOptionalTotalCost = item?.otherEquipment?.length
            ? item?.otherEquipment
              .map((e) => e?.cost)
              ?.reduce(
                (accumulator, currentValue) =>
                  Number(accumulator) + Number(currentValue)
              ) + allEquOptionalTotalCost
            : 0 + allEquOptionalTotalCost;

          item?.otherEquipment?.length &&
            item?.otherEquipment.map((option) => {
              totalEquOptionCostObj = {
                ...totalEquOptionCostObj,
                [option?.id]: option?.cost,
                key: item?.id,
              };
            });
          totalEquOptionCost.push(totalEquOptionCostObj);
        });

        let equipmentIdObj = {};
        let equIdObj = {};
        quoteResponse?.data?.otherEquipment &&
          quoteResponse?.data?.otherEquipment.forEach((e) => {
            equipmentIdObj = {
              ...equipmentIdObj,
              [e?.id]: [e?.id],
            };
            if (e?.checked === 1) {
              equIdObj = {
                ...equIdObj,
                [e?.id]: [e?.id],
              };
            }
          });

        let equipmentCheckArray = quoteResponse?.data?.otherEquipment?.length
          ? quoteResponse?.data?.otherEquipment.map((e) => (e?.checked ? 1 : 0))
          : [];

        obj = {
          ...obj,
          editQuote: equipmentCheckArray.includes(1),
          quoteData: quoteResponse?.data ?? [],
          optionAllId: optionalIObj,
          quoteAllId: quoteIObj,
          lineItemAllId: lineItemIdArray,
          allQuoteCost: totalQuoteCostObj,
          allQuoteMembershipCost: totalQuoteMembershipCostObj,
          allOptionQuoteCost: totalOptionQuoteCost,
          equipmentAllIdArray: equipmentAllIdArray,
          equipmentAllIdObj: equipmentIdObj,
          equOptionAllId: equOptionAllIdObj,
          equipmentIdArray: selectedEquipmentID || {},
          equipmentAllSelectedIdArray: equipmentSelectedIdArray || [],
          equOptionalItemIdObj: equOptionalIdObj,
          monthlyTotalCostObj: monthlyCostObj,
          discountObj: allDiscountQuoteObj,
          rebateObj: rebateQuoteObj,
          discountQuoteObj: discountQuoteTypeObj,
          equipmentItemTotalCost:
            quoteResponse?.data?.booking?.bookingStatus === 'accepted' ||
              quoteResponse?.data?.booking?.bookingStatus === 'canceled' ||
              quoteResponse?.data?.booking?.bookingStatus === 'declined'
              ? equipmentSelectedCost
              : allEquipmentTotalCost,
          allEquipmentItemCost: equipmentAllCost,
          discountQuoteListObj: discountQuoteListTypeObj,
          monthlyEquipmentTotalCostObj: equipmentAllMonthlyCost,
          monthlyEquipmentTotalCost: monthlySelectedEquipmentTotalCost,
          equOrderIdObj: equIdObj,
          allEquOptionCost: equOptionCost,
          equOptionalTotalCost:
            quoteResponse?.data?.booking?.bookingStatus === 'accepted' ||
              quoteResponse?.data?.booking?.bookingStatus === 'canceled' ||
              quoteResponse?.data?.booking?.bookingStatus === 'declined'
              ? Object.keys(equOptionCost)?.length &&
                Object.keys(equOptionCost)
                  .map((e) => equOptionCost[e])
                  ?.flat().length
                ? Object.keys(equOptionCost)
                  .map((e) => equOptionCost[e])
                  .flat()
                  ?.reduce(
                    (accumulator, currentValue) =>
                      parseFloat(accumulator) + parseFloat(currentValue)
                  )
                : 0
              : allEquOptionalTotalCost,

          equAllOptionQuoteCost: totalEquOptionCost,
          allCheckEquOptionalIdObj: allEquOptionalIdObj,
          allCheckEquOptionalTotalCost: allEquOptionalTotalCost,
          allQuoteDiscountedCost: totalDiscountedQuoteCostObj,
        };

        let checkAllEquipmentId = arrayEqual(
          equipmentAllIdArray,
          equipmentSelectedIdArray
        );
        setEquipmentCheckAll(checkAllEquipmentId);

        if (
          quoteResponse?.data?.booking?.ServiceCategory?.isSalesQuote === 1 &&
          Object.keys(quoteResponse?.data?.optionsList)?.length
        ) {
          let quoteCheckArray =
            Object.keys(quoteResponse?.data?.optionsList)?.length &&
            Object.keys(quoteResponse?.data?.optionsList)
              .map((e) => quoteResponse?.data?.optionsList[e])
              .flat()
              .map((i) => i?.is_customer_checked);

          let quoteIdObj = {};
          let quoteIdArray = [];

          let optionalIdObj = {};
          let optionCost = {};
          let quoteSelectCost = 0;
          let quoteSelectMembershipCost = 0;
          let allSelectedId = {};

          let allQuoteTotalCost = 0;
          let allQuoteMembershipTotalCost = 0;

          let allOptionalTotalCost = 0;
          let monthlyPaymentSelectedCost = 0;
          let totalQuoteSelectedCost = 0;

          Object.keys(quoteResponse?.data?.optionsList).forEach((key) => {
            quoteResponse?.data?.optionsList[key].forEach((item, i) => {
              allSelectedId = {
                ...allSelectedId,
                // [key.replace(regexDashRemove, '').trim()]:
                [key]: item?.is_customer_checked ? true : false,
              };

              allQuoteTotalCost =
                Number(item?.admin_material_total_amount) + allQuoteTotalCost;

              allQuoteMembershipTotalCost = item?.membershipBenefitAvailable ?
                Number(item?.membershipBenefitAmount) + allQuoteMembershipTotalCost : 0;

              allOptionalTotalCost =
                item?.optionalItem?.length ||
                  item?.optionalItemForBooking?.length
                  ? (item?.optionalItem || item?.optionalItemForBooking)
                    ?.map((e) => e?.cost)
                    ?.reduce(
                      (accumulator, currentValue) =>
                        Number(accumulator) + Number(currentValue)
                    )
                  : 0 + allOptionalTotalCost;

              // if (
              //   item?.is_customer_checked ||
              //   quoteResponse?.data?.booking?.bookingStatus === 'canceled' ||
              //   quoteResponse?.data?.bookingQuote?.providerStatus === 'accepted'
              // ) {

              if (item?.is_customer_checked) {
                quoteIdObj = {
                  ...quoteIdObj,
                  [`${key}${i}`]: [item?.id],
                };

                quoteIdArray = [...quoteIdArray, Number([item?.id].join())];

                quoteSelectCost =
                  item?.is_customer_checked &&
                  Number(item?.admin_material_total_amount) + quoteSelectCost;
                quoteSelectMembershipCost =
                  item?.is_customer_checked &&
                  Number(item?.membershipBenefitAmount) + quoteSelectMembershipCost;
                let optionalId = (
                  item?.optionalItem || item?.optionalItemForBooking
                )
                  .filter((e) => e?.checked === 1)
                  .map((id) => id?.id);

                let optionalCost =
                  (item?.optionalItem || item?.optionalItemForBooking).filter(
                    (e) => e?.checked === 1
                  )?.length &&
                  (item?.optionalItem || item?.optionalItemForBooking)
                    .filter((e) => e?.checked === 1)
                    .map((id) => id?.cost)
                    ?.reduce(
                      (accumulator, currentValue) =>
                        Number(accumulator) + Number(currentValue)
                    );

                optionalIdObj = {
                  ...optionalIdObj,
                  [`${key}${i}`]: optionalId,
                };

                optionCost = {
                  ...optionCost,
                  [`${key}${i}`]: optionalCost,
                };

                monthlyPaymentSelectedCost =
                  Number(item?.monthlyPayment) + monthlyPaymentSelectedCost;

                totalQuoteSelectedCost =
                  Number(item?.appliedDiscount) + totalQuoteSelectedCost;
              }
            });
          });
          let checkEditQuote = [...quoteCheckArray];

          obj = {
            ...obj,
            editQuote: checkEditQuote?.includes(1),
            quoteOrderIdObj: quoteIdObj,
            optionalItemIdObj: optionalIdObj,
            allOptionCost: optionCost,
            // optionalTotalCost:
            //   quoteResponse?.data?.booking?.bookingStatus === 'accepted'
            //     ? Object.keys(optionCost)?.length
            //       ? Object.keys(optionCost)
            //           .map((e) => optionCost[e])
            //           ?.reduce(
            //             (accumulator, currentValue) =>
            //               Number(accumulator) + Number(currentValue)
            //           )
            //       : 0
            //     : allOptionalTotalCost,
            optionalTotalCost: 0,
            quoteTotalCost:
              quoteResponse?.data?.booking?.bookingStatus === 'accepted' ||
                quoteResponse?.data?.booking?.bookingStatus === 'canceled' ||
                quoteResponse?.data?.booking?.bookingStatus === 'declined'
                ? quoteSelectCost
                : allQuoteTotalCost,

            selectQuoteMembershipCost:
              quoteResponse?.data?.booking?.bookingStatus === 'accepted' ||
                quoteResponse?.data?.booking?.bookingStatus === 'canceled' ||
                quoteResponse?.data?.booking?.bookingStatus === 'declined'
                ? quoteSelectMembershipCost
                : allQuoteMembershipTotalCost,

            quoteSelectID: quoteIdArray,
            monthlyTotalCost: monthlyPaymentSelectedCost,
            totalDiscount: totalQuoteSelectedCost,
          };

          setCheckAll(allSelectedId);
        }

        if (quoteResponse?.data?.booking?.ServiceCategory?.isSalesQuote === 0) {
          setIsDeclineBeforeNext(false);
          obj = {
            ...obj,
            editQuote: true,
          };
        }

        if (quoteResponse?.data?.lineItems?.length) {
          let lineItemSelectedId =
            quoteResponse?.data?.lineItems?.length &&
            quoteResponse?.data?.lineItems
              .filter((e) => e?.checked === 1)
              .map((id) => id?.id);

          let lineItemSelectedCost =
            quoteResponse?.data?.lineItems?.length &&
            quoteResponse?.data?.lineItems
              .filter((e) => e?.checked === 1)
              .map((id) => id?.unitPrice)?.length &&
            quoteResponse?.data?.lineItems
              .filter((e) => e?.checked === 1)
              .map((id) => id?.unitPrice * (id?.qty || 1))
              ?.reduce(
                (accumulator, currentValue) =>
                  Number(accumulator) + Number(currentValue)
              );

          let lineItemAllCost = {};
          let lineItemAllMonthlyCost = {};
          (
            quoteResponse?.data?.lineItems?.length &&
            quoteResponse?.data?.lineItems
          ).forEach((lineItem) => {
            lineItemAllCost = {
              ...lineItemAllCost,
              [lineItem?.id]:
                Number(lineItem?.unitPrice) * (Number(lineItem?.qty) || 1),
            };

            if (quoteResponse?.data?.financeOption?.paymentFactor > 0) {
              lineItemAllMonthlyCost = {
                ...lineItemAllMonthlyCost,
                [lineItem?.id]:
                  (parseFloat(lineItem?.unitPrice) / 100) *
                  quoteResponse?.data?.financeOption?.paymentFactor,
              };
            }
          });

          let monthlySelectedLineItemTotalCost =
            quoteResponse?.data?.lineItems?.length &&
            quoteResponse?.data?.lineItems.filter((e) => e?.checked === true)
              ?.length &&
            quoteResponse?.data?.lineItems
              .filter((e) => e?.checked === true)
              .map(
                (e) =>
                  (parseFloat(e?.unitPrice) / 100) *
                  quoteResponse?.data?.financeOption?.paymentFactor
              )
              .reduce(
                (accumulator, currentValue) =>
                  parseFloat(accumulator) + parseFloat(currentValue)
              );

          let allLineItemTotalCost =
            quoteResponse?.data?.lineItems?.length &&
            quoteResponse?.data?.lineItems
              .map((id) => (Number(id?.qty) || 1) * parseFloat(id?.unitPrice))
              ?.reduce(
                (accumulator, currentValue) =>
                  parseFloat(accumulator) + parseFloat(currentValue)
              );

          let oldQuoteLineAllCost =
            quoteResponse?.data?.lineItems?.length &&
            quoteResponse?.data?.lineItems
              .map((item) => item?.unitPrice)
              ?.reduce(
                (accumulator, currentValue) =>
                  Number(accumulator) + Number(currentValue)
              );
          let adminAddedlineItemsArr = [];
          if (quoteResponse?.data?.booking?.BookingLineItems?.length > 0) {
            adminAddedlineItemsArr =
              quoteResponse?.data?.booking?.BookingLineItems?.length &&
              quoteResponse?.data?.booking?.BookingLineItems
                .filter((e) => e?.lineItemId != null)
          }
          // for get checked admin added line item amount
          let adminAddedLineItemAmount = 0;
          lineItemSelectedId?.length &&
            lineItemSelectedId?.map((e) => {
              adminAddedlineItemsArr?.length &&
                adminAddedlineItemsArr?.map((item) => {
                  if (item?.id === e) {
                    adminAddedLineItemAmount = adminAddedLineItemAmount + Number(item?.total)
                  }
                })
            })
          obj = {
            ...obj,

            ...(!obj?.editQuote && {
              editQuote:
                quoteResponse?.data?.booking?.bookingStatus === 'canceled' ||
                  quoteResponse?.data?.booking?.bookingStatus === 'declined' ||
                  quoteResponse?.data?.bookingQuote?.providerStatus ===
                  'declined' ||
                  quoteResponse?.data?.bookingQuote?.providerStatus ===
                  'canceled' ||
                  quoteResponse?.data?.bookingQuote?.customerStatus ===
                  'declined' ||
                  quoteResponse?.data?.bookingQuote?.customerStatus === 'canceled'
                  ? lineItemSelectedId?.length
                    ? true
                    : false
                  : false,
            }),
            adminAddedlineItems: adminAddedlineItemsArr ? adminAddedlineItemsArr : [],
            adminAddedCheckedlineItemsAmount: adminAddedLineItemAmount,
            lineItemIdArray: lineItemSelectedId,
            allLineItemCost: lineItemAllCost,
            lineItemTotalCost:
              quoteResponse?.data?.booking?.bookingStatus === 'accepted' ||
                quoteResponse?.data?.booking?.bookingStatus === 'canceled' ||
                quoteResponse?.data?.booking?.bookingStatus === 'declined'
                ? lineItemSelectedCost
                : allLineItemTotalCost,
            oldQuoteLineTotalCost: oldQuoteLineAllCost,
            monthlyLineItemTotalCostObj: lineItemAllMonthlyCost,
            monthlyLineItemTotalCost: monthlySelectedLineItemTotalCost,
          };

          let checkAllId = arrayEqual(lineItemIdArray, lineItemSelectedId);
          setLineCheckAll(checkAllId);
        }
      }
      if (
        quoteResponse?.data?.booking?.bookingStatus === 'canceled' ||
        quoteResponse?.data?.booking?.bookingStatus === 'declined' ||
        quoteResponse?.data?.bookingQuote?.providerStatus === 'accepted'
      ) {
        obj = {
          ...obj,
          isCheckBox: false,
        };
      }

      setState({
        ...state,
        ...obj,
        ...(e === 'approve' && {
          isLoading: false,
          showModal: '',
          editQuote: true,
          // approved: false,
        }),
        ...(e === 'decline' && {
          isLoading: false,
          showModal: '',
          editQuote: true,
        }),
        ...(status === 'equOptionDecline' && {
          equOptionDecline: true,
        }),
      });
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
    }
  };

  useEffect(() => {
    getRequestViewDetails();
  }, []);

  const handleDeclineQuote = async () => {
    let isQuote =
      Object.keys(equipmentIdArray)?.length || lineItemIdArray?.length;

    let isLineItem =
      Object.keys(equipmentIdArray)?.length ||
      (Object.keys(quoteOrderIdObj)?.length &&
        Object.keys(quoteOrderIdObj)
          ?.map((e) => quoteOrderIdObj[e])
          .flat()?.length);

    let isOtherEquipment =
      lineItemIdArray?.length ||
      (Object.keys(quoteOrderIdObj)?.length &&
        Object.keys(quoteOrderIdObj)
          ?.map((e) => quoteOrderIdObj[e])
          .flat()?.length);

    let quoteIdLength =
      quoteOrderIdObj &&
      Object.keys(quoteOrderIdObj)
        .map((e) => quoteOrderIdObj[e].join())
        .filter((i) => i);

    let optionIdLength =
      optionalItemIdObj &&
      Object.keys(optionalItemIdObj).length &&
      Object.keys(optionalItemIdObj)
        .map((e) => optionalItemIdObj[e])
        .flat()?.length;

    let checkEquipmentEmpty =
      equipmentIdArray &&
      Object.keys(equipmentIdArray)
        .map((e) => equipmentIdArray[e])
        .flat();

    let checkEquipmentOptionEmpty =
      Object.keys(equOptionalItemIdObj)?.length &&
      Object.keys(equOptionalItemIdObj)
        ?.map((e) => equOptionalItemIdObj[e])
        .flat()?.length;

    if (quoteIdLength?.length === 0 && optionIdLength) {
      modalNotification({
        type: 'error',
        message: 'Please select at least one quote item',
      });
      return false;
    } else if (checkEquipmentEmpty?.length === 0 && checkEquipmentOptionEmpty) {
      modalNotification({
        type: 'error',
        message: 'Please select at least one technician options',
      });
      return false;
    }

    try {
      let obj = {};
      let optionList = [];

      if (
        (Object.keys(quoteOrderIdObj)?.length &&
          Object.keys(quoteOrderIdObj)
            ?.map((e) => quoteOrderIdObj[e])
            .flat()?.length) ||
        (isQuote < 1 && quoteAllId && Object.keys(quoteAllId)?.length)
      ) {
        (
          (Object.keys(quoteOrderIdObj)?.length &&
            Object.keys(quoteOrderIdObj)
              ?.map((e) => quoteOrderIdObj[e])
              .flat()?.length &&
            Object.keys(quoteOrderIdObj)) ||
          (isQuote < 1 &&
            quoteAllId &&
            Object.keys(quoteAllId)?.length &&
            Object.keys(quoteAllId))
        ).forEach((quoteItem) => {
          obj = {
            quoteOrderId:
              Object.keys(quoteOrderIdObj)?.length &&
                Object.keys(quoteOrderIdObj)
                  ?.map((e) => quoteOrderIdObj[e])
                  .flat()?.length
                ? quoteOrderIdObj[quoteItem].join()
                : quoteAllId[quoteItem].join(),

            optionalItemId:
              optionalItemIdObj[quoteItem] &&
                optionalItemIdObj[quoteItem]?.length
                ? optionalItemIdObj[quoteItem]
                : quoteOrderIdObj &&
                  !Object.keys(quoteOrderIdObj)
                    ?.map((e) => quoteOrderIdObj[e])
                    .flat()?.length &&
                  optionAllId[quoteItem]?.length
                  ? optionAllId[quoteItem]
                  : [],
          };
          optionList.push(obj);
        });
      }

      let otherEquipmentAllSelectedId = Object.keys(equipmentIdArray)?.length
        ? Object.keys(equipmentIdArray)
          ?.map((e) => equipmentIdArray[e])
          .flat()
        : isOtherEquipment < 1
          ? equipmentAllIdArray
          : [];

      let otherEquipmentOptionAllSelectedId =
        Object.keys(equOptionalItemIdObj)?.length &&
          Object.keys(equOptionalItemIdObj)
            ?.map((e) => equOptionalItemIdObj[e])
            .flat()?.length
          ? Object.keys(equOptionalItemIdObj)
            ?.map((e) => equOptionalItemIdObj[e])
            ?.flat()
          : isOtherEquipment < 1 && Object.keys(equipmentIdArray)?.length < 1
            ? Object.keys(equOptionAllId)
              ?.map((e) => equOptionAllId[e])
              ?.flat()
            : [];

      let bodyData = {
        optionList,
        lineItems: lineItemIdArray?.length
          ? lineItemIdArray
          : isLineItem < 1
            ? lineItemAllId
            : [],

        otherEquipment: [
          ...otherEquipmentAllSelectedId,
          ...otherEquipmentOptionAllSelectedId,
        ],
      };

      let res = await addQuoteService({
        bookingId: location?.state?.bookingId || bookingId,
        bodyData,
        isGuest,
        guestToken,
      });
      if (res?.success) {
        getRequestViewDetails('decline', 'equOptionDecline');
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditApprove = async () => {
    try {
      // for quote
      const quoteResponse = await getQuoteOptionListService(
        location?.state?.bookingId || bookingId,
        false,
        isGuest
      );
      if (quoteResponse?.success) {
        if (
          quoteResponse?.data?.bookingQuote?.providerStatus === 'accepted' ||
          quoteResponse?.data?.bookingQuote?.providerStatus === 'declined'
        ) {
          modalNotification({
            type: 'error',
            message:
              quoteResponse?.data?.bookingQuote?.providerStatus === 'accepted'
                ? 'Your quote is already approved by technician'
                : 'Your quote is already decline by technician',
          });
          let path = generatePath(
            accessRoute.REQUEST_APPROVE_VIEW_DETAILS.path,
            {
              bookingId: bookingId,
            }
          );
          navigate(path);
          return true;
        } else {
          return false;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmitDecline = async (reason) => {
    if (!reason) {
      modalNotification({
        type: 'error',
        message: 'Please submit decline reason.',
      });
      return;
    }
    let approveStatus = await handleEditApprove();
    if (approveStatus) {
      return;
    }
    try {
      setState({
        ...state,
        isLoading: true,
      });

      if (isDeclineBeforeNext) {
        let declineStatus = await handleDeclineQuote();
        if (!declineStatus) {
          setState({
            ...state,
            isLoading: false,
            showModal: '',
          });
          return;
        }
      }

      const response = await quoteDecline({
        bookingId: location?.state?.bookingId || bookingId,
        declineReason: reason,
        isGuest,
        guestToken,
      });
      if (response?.success) {
        modalNotification({ type: 'success', message: response?.message });
        if (editQuote) {
          getRequestViewDetails('decline');
        }
      } else {
        if (editQuote) {
          getRequestViewDetails('decline');
        }
      }
    } catch (error) {
      logger(error);
      setState({
        ...state,
        isLoading: false,
      });
    }
  };

  const handleSubmitApprove = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    try {
      let approveStatus = await handleEditApprove();
      if (approveStatus) {
        return;
      }
      let res = await putCustomerBookingApprove({
        customerSignature: state?.customerSignature,
        bookingId: location?.state?.bookingId || bookingId,
        isGuest,
        guestToken,
        guestRefToken,
      });
      if (res?.success) {
        getRequestViewDetails('approve');
        modalNotification({
          type: 'success',
          message: 'Quote Approve Successfully',
        });
      } else {
        getRequestViewDetails('approve');
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
    }
  };
  const modalObj = {
    decline: (
      <DeclineModal
        handleClose={handleClose}
        handleSubmit={(reason) => handleSubmitDecline(reason)}
        isLoading={state?.isLoading}
      />
    ),
    approve: (
      <ApproveModal
        handleClose={handleClose}
        handleSubmit={handleSubmitApprove}
        isLoading={state?.isLoading}
      />
    ),

    requestDetails: (
      <RequestDetailsModal
        handleClose={handleClose}
        handleSubmit={handleSubmitApprove}
        isLoading={state?.isLoading}
        type={state?.modalKey}
        modalData={state?.modalData}
      />
    ),
    memberShip: (
      <>
        {state?.modalData?.membershipBenefitAvailable && (
          <div className="listService">
            {(state?.modalData?.membershipType === "service_category" || state?.modalData?.membershipType === "combo") &&
              <div className="listService_items d-flex justify-content-between">
                {quoteData?.booking?.ServiceCategory?.isSalesQuote === 1 ? t('text.memberShip.quoteDiscount') : t('text.common.serviceDiscount')}
                <span>
                  {checkValidPrice(
                    state?.modalData?.serviceMembershipBenefit
                  )}
                </span>
              </div>
            }
            {(state?.modalData?.membershipType === "quote") &&
              <div className="listService_items d-flex justify-content-between">
                {t('text.common.quoteMembershipDiscount')}
                <span>
                  {checkValidPrice(
                    state?.modalData?.serviceMembershipBenefit
                  )}
                </span>
              </div>
            }
            {(state?.modalData?.membershipType === "line_item" || state?.modalData?.membershipType === "combo") &&
              <div className="listService_items d-flex justify-content-between">
                {t('text.common.lineItemDiscount')}
                <span>
                  {checkValidPrice(
                    state?.modalData?.lineItemMembershipBenefit
                  )}
                </span>
              </div>
            }
          </div>
        )}
      </>
    ),
    promoCodeInfo: (
      <>
        <p>{t('text.ratingReview.promoCodeInfoIconDetails')}</p>
      </>
    )
  };

  const isEmpty = () => {
    if (sigPad.current?.isEmpty && sigPad.current?.isEmpty()) {
      return true;
    } else {
      return false;
    }
  };

  const handleSignature = async () => {
    let url = COMMON_ENDPOINT.uploadPublicImageFile('image', 'user');
    const signatureBase64 = sigPad.current
      .getTrimmedCanvas()
      .toDataURL('image/png');

    function dataURLtoFile(base64, filename) {
      var arr = base64.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = window.atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }
    let signatureImageFile = dataURLtoFile(signatureBase64, 'signature.png');

    let formData = new FormData();
    formData.append('file', signatureImageFile);

    let { data } = await axios.post(url?.url, formData, {
      headers: {
        accept: 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data`,
      },
    });
    if (data?.success) {
      setState({
        ...state,
        customerSignature: data?.data?.basePath,
      });
    }
  };

  const handleNext = async (status) => {
    if (status === 'edit') {
      setState({
        ...state,
        editQuote: false,
      });
      setLocalStorage({
        keyName: 'optionList',
        data: {
          quoteOrderIdObj: {},
          optionalItemIdObj: {},
          lineItemIdArray: [],
        },
      });
      setIsDeclineBeforeNext(true);
      return;
    }

    let approveStatus = await handleEditApprove();
    if (approveStatus) {
      return;
    }

    setIsDeclineBeforeNext(false);
    let quoteIdLength =
      quoteOrderIdObj &&
      Object.keys(quoteOrderIdObj)
        .map((e) => quoteOrderIdObj[e].join())
        .filter((i) => i);

    let checkEquipmentEmpty =
      Object.keys(equipmentIdArray).length &&
      Object.keys(equipmentIdArray)
        .map((e) => equipmentIdArray[e])
        .flat();

    if (quoteIdLength?.length === 0 && checkEquipmentEmpty === 0) {
      modalNotification({
        type: 'error',
        message: 'Please select at least one quote item',
      });
      return;
    }
    setState({
      ...state,
      isLoading: true,
    });
    try {
      let obj = {};
      let optionList = [];

      quoteOrderIdObj &&
        Object.keys(quoteOrderIdObj).forEach((quoteItem) => {
          obj = {
            quoteOrderId: quoteOrderIdObj[quoteItem].join(),
            optionalItemId:
              optionalItemIdObj[quoteItem] &&
                optionalItemIdObj[quoteItem]?.length
                ? optionalItemIdObj[quoteItem]
                : [],
          };
          optionList.push(obj);
        });

      let otherEquipmentAllSelectedId = Object.keys(equipmentIdArray)?.length
        ? Object.keys(equipmentIdArray)
          ?.map((e) => equipmentIdArray[e])
          .flat()
        : [];

      let selectedOptionItem = equOptionalItemIdObj;

      let optionObj = {};
      Object.keys(selectedOptionItem)?.length &&
        Object.keys(selectedOptionItem)?.forEach((e) => {
          if (otherEquipmentAllSelectedId.includes(Number(e))) {
            optionObj = {
              [e]: selectedOptionItem[e],
            };
          }
        });

      let otherEquipmentOptionAllSelectedId = Object.keys(optionObj)?.length
        ? Object.keys(optionObj)
          ?.map((e) => optionObj[e])
          ?.flat()
        : [];

      let bodyData = {
        optionList,
        lineItems: lineItemIdArray,
        otherEquipment: [
          ...otherEquipmentAllSelectedId,
          ...otherEquipmentOptionAllSelectedId,
        ],
      };

      let res = await addQuoteService({
        bookingId: location?.state?.bookingId || bookingId,
        bodyData,
        isGuest,
        guestRefToken,
      });
      if (res?.success) {
        // modalNotification({
        //   type: 'success',
        //   message: 'Quote item selected successfully',
        // });
        setState({
          ...state,
          editQuote: true,
          isLoading: false,
          equOptionalItemIdObj: optionObj,
          equipmentAllSelectedIdArray: bodyData?.otherEquipment,
        });
        getRequestViewDetails();
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
    }
  };

  // for quote id

  let quoteIdObj = quoteOrderIdObj;
  let quoteAddCost = quoteTotalCost;
  let quoteMembershipAddCost = selectQuoteMembershipCost;
  let monthlyAddCost = monthlyTotalCost;
  let quoteDiscount = totalDiscount;

  const handleQuoteChange = (checkedValues, key) => {
    quoteIdObj = {
      ...quoteIdObj,
      [key]: checkedValues,
    };

    let itemName = '';
    let quoteAllIdObj = '';
    quoteAllId &&
      Object.keys(quoteAllId)?.length &&
      Object.keys(quoteAllId)
        .map((e) => e)
        .forEach((item) => {
          if (itemName === item.replace(regexDashRemove, '').trim()) {
            quoteAllIdObj = {
              ...quoteAllIdObj,
              [item.replace(regexDashRemove, '').trim()]: [
                ...quoteAllIdObj[item.replace(regexDashRemove, '').trim()],
                ...quoteAllId[item],
              ],
            };
          } else {
            quoteAllIdObj = {
              ...quoteAllIdObj,
              [item.replace(regexDashRemove, '').trim()]: quoteAllId[item],
            };
          }
          itemName = item.replace(regexDashRemove, '').trim();
        });

    let checkedItemName = '';
    let checkedQuoteIdObj = '';
    quoteIdObj &&
      Object.keys(quoteIdObj)?.length &&
      Object.keys(quoteIdObj)
        .map((e) => e)
        .forEach((item) => {
          if (checkedItemName === item.replace(regexDashRemove, '').trim()) {
            checkedQuoteIdObj = {
              ...checkedQuoteIdObj,
              [item.replace(regexDashRemove, '').trim()]: [
                ...checkedQuoteIdObj[item.replace(regexDashRemove, '').trim()],
                ...quoteIdObj[item],
              ],
            };
          } else {
            checkedQuoteIdObj = {
              ...checkedQuoteIdObj,
              [item.replace(regexDashRemove, '').trim()]: quoteIdObj[item],
            };
          }
          checkedItemName = item.replace(regexDashRemove, '').trim();
        });

    let checkAllQuoteId = arrayEqual(
      quoteAllIdObj[key.replace(regexDashRemove, '').trim()],
      checkedQuoteIdObj[key.replace(regexDashRemove, '').trim()]
    );

    let itemOptionName = '';
    let quoteOptionAllIdObj = '';
    optionAllId &&
      Object.keys(optionAllId)?.length &&
      Object.keys(optionAllId)
        .map((e) => e)
        .forEach((item) => {
          if (itemOptionName === item.replace(regexDashRemove, '')) {
            quoteOptionAllIdObj = {
              ...quoteOptionAllIdObj,
              [item.replace(regexDashRemove, '')]: [
                ...quoteOptionAllIdObj[item.replace(regexDashRemove, '')],
                ...optionAllId[item],
              ],
            };
          } else {
            quoteOptionAllIdObj = {
              ...quoteOptionAllIdObj,
              [item.replace(regexDashRemove, '')]: optionAllId[item],
            };
          }
          itemOptionName = item.replace(regexDashRemove, '');
        });

    let checkedOptionItemName = '';
    let checkedQuoteOptionIdObj = '';
    optionalItemIdObj &&
      Object.keys(optionalItemIdObj)?.length &&
      Object.keys(optionalItemIdObj)
        .map((e) => e)
        .forEach((item) => {
          if (checkedOptionItemName === item.replace(regexDashRemove, '')) {
            checkedQuoteOptionIdObj = {
              ...checkedQuoteOptionIdObj,
              [item.replace(regexDashRemove, '')]: [
                ...checkedQuoteOptionIdObj[item.replace(regexDashRemove, '')],
                ...optionalItemIdObj[item],
              ],
            };
          } else {
            checkedQuoteOptionIdObj = {
              ...checkedQuoteOptionIdObj,
              [item.replace(regexDashRemove, '')]: optionalItemIdObj[item],
            };
          }
          checkedOptionItemName = item.replace(regexDashRemove, '');
        });

    let checkAllOptionId =
      optionAllId && optionAllId[key].length
        ? arrayEqual(
          quoteOptionAllIdObj[key.replace(regexDashRemove, '')],
          checkedQuoteOptionIdObj[key.replace(regexDashRemove, '')]
        )
        : true;

    setCheckAll({
      ...checkAll,
      [key.replace(regexDashRemove, '').trim()]:
        checkAllQuoteId && checkAllOptionId ? true : false,
    });

    quoteMembershipAddCost = Object.keys(quoteIdObj).length
      ? Object.keys(quoteIdObj)
        .map((e) => quoteIdObj[e].join() || 0)
        .filter((z) => z)
        .map((i) => allQuoteMembershipCost[i])?.length &&
      Object.keys(quoteIdObj)
        .map((e) => quoteIdObj[e].join() || 0)
        .filter((z) => z)
        .map((i) => allQuoteMembershipCost[i])
        ?.reduce(
          (accumulator, currentValue) =>
            Number(accumulator) + Number(currentValue)
        )
      : 0;
    quoteAddCost = Object.keys(quoteIdObj).length
      ? Object.keys(quoteIdObj)
        .map((e) => quoteIdObj[e].join() || 0)
        .filter((z) => z)
        .map((i) => allQuoteCost[i])?.length &&
      Object.keys(quoteIdObj)
        .map((e) => quoteIdObj[e].join() || 0)
        .filter((z) => z)
        .map((i) => allQuoteCost[i])
        ?.reduce(
          (accumulator, currentValue) =>
            Number(accumulator) + Number(currentValue)
        )
      : 0;

    monthlyAddCost = Object.keys(quoteIdObj).length
      ? Object.keys(quoteIdObj)
        .map((e) => quoteIdObj[e].join() || 0)
        .filter((z) => z)
        .map((i) => monthlyTotalCostObj[i])?.length &&
      Object.keys(quoteIdObj)
        .map((e) => quoteIdObj[e].join() || 0)
        .filter((z) => z)
        .map((i) => monthlyTotalCostObj[i])
        ?.reduce(
          (accumulator, currentValue) =>
            Number(accumulator) + Number(currentValue)
        )
      : 0;

    quoteDiscount = Object.keys(quoteIdObj).length
      ? Object.keys(quoteIdObj)
        .map((e) => quoteIdObj[e].join() || 0)
        .filter((z) => z)
        .map((i) => discountObj[i])?.length &&
      Object.keys(quoteIdObj)
        .map((e) => quoteIdObj[e].join() || 0)
        .filter((z) => z)
        .map((i) => discountObj[i])
        ?.reduce(
          (accumulator, currentValue) =>
            Number(accumulator) + Number(currentValue)
        )
      : 0;

    let quoteSelectIDArr =
      quoteIdObj &&
      Object.keys(quoteIdObj)?.length &&
      Object.keys(quoteIdObj)
        .map((e) => quoteIdObj[e])
        .flat();

    setState({
      ...state,
      quoteOrderIdObj: quoteIdObj,
      quoteTotalCost: quoteAddCost,
      selectQuoteMembershipCost: typeof quoteMembershipAddCost === 'object' ? Object.keys(quoteMembershipAddCost).length > 0 ? quoteMembershipAddCost : 0 : quoteMembershipAddCost,
      monthlyTotalCost: monthlyAddCost,
      totalDiscount: quoteDiscount,
      quoteSelectID: quoteSelectIDArr,
    });
  };

  // Compare quote and optional id
  const handleCompareId = ({ idObj, key }) => {
    let itemName = '';
    let checkedIdObj = '';
    idObj &&
      Object.keys(idObj)?.length &&
      Object.keys(idObj)
        .map((e) => e)
        .forEach((item) => {
          if (itemName === item.replace(regexDashRemove, '')) {
            checkedIdObj = {
              ...checkedIdObj,
              [item.replace(regexDashRemove, '')]: checkedIdObj[
                item.replace(regexDashRemove, '')
              ]
                ? [
                  ...checkedIdObj[item.replace(regexDashRemove, '')],
                  ...idObj[item],
                ]
                : [...idObj[item]],
            };
          } else {
            checkedIdObj = {
              ...checkedIdObj,
              [item.replace(regexDashRemove, '')]: idObj[item],
            };
          }
          itemName = item.replace(regexDashRemove, '');
        });

    return checkedIdObj;
  };

  // for optional id
  let optionalId = optionalItemIdObj;
  let optionCost = allOptionCost;
  let optionAddCost = 0;
  const handleOptionalChange = (checkedValues, key) => {
    let allQuoteId = handleCompareId({ idObj: quoteAllId, key });
    let checkedQuoteId = handleCompareId({ idObj: quoteIdObj, key });

    let checkAllQuoteId = arrayEqual(
      allQuoteId[key.replace(regexDashRemove, '')],
      checkedQuoteId[key.replace(regexDashRemove, '')]
    );

    optionalId = {
      ...optionalId,
      [key]: checkedValues,
    };

    let optionalAllId = handleCompareId({ idObj: optionAllId, key });
    let checkedOptionalId = handleCompareId({ idObj: optionalId, key });

    let checkAllOptionId = arrayEqual(
      optionalAllId[key.replace(regexDashRemove, '')],
      checkedOptionalId[key.replace(regexDashRemove, '')]
    );

    setCheckAll({
      ...checkAll,
      [key.replace(regexDashRemove, '')]:
        checkAllQuoteId && checkAllOptionId ? true : false,
    });

    // For total optional amount
    let totalAmount = 0;

    allOptionQuoteCost?.forEach((allOption) => {
      Object.keys(optionalId)?.length &&
        Object.keys(optionalId).forEach((selectOption) => {
          if (allOption?.key === selectOption.replace(/\d$/, '')) {
            totalAmount =
              optionalId[selectOption]?.length &&
              optionalId[selectOption]
                .map((e) => allOption[e])
                ?.reduce(
                  (accumulator, currentValue) =>
                    Number(accumulator) + Number(currentValue)
                );

            optionCost = {
              ...optionCost,
              [selectOption]: totalAmount,
            };
          }
        });
    });

    optionAddCost = Object.keys(optionCost)
      .map((e) => optionCost[e])
      ?.reduce(
        (accumulator, currentValue) =>
          Number(accumulator) + Number(currentValue)
      );

    setState({
      ...state,
      optionalItemIdObj: optionalId,
      allOptionCost: optionCost,
      optionalTotalCost: optionAddCost,
    });
  };

  // For Quote all check
  let allQuoteCheck = checkAll;
  let allCheckOptionCost = allOptionCost;

  let optionalAmount = optionalTotalCost;

  const onQuoteCheckAllChange = (e, key, optionStatus) => {
    allQuoteCheck = {
      ...allQuoteCheck,
      [key]: e?.target?.checked,
      // [key.replace(regexDashRemove, '').trim()]: e?.target?.checked,
    };

    setCheckAll(allQuoteCheck);
    let selectQuoteId = {};

    optionStatus?.length &&
      optionStatus.forEach((item, i) => {
        selectQuoteId = {
          ...selectQuoteId,
          [`${key}${i}`]: e?.target?.checked ? [item?.id] : [],
        };
      });

    let quoteAllCheckId = {
      ...quoteOrderIdObj,
      ...selectQuoteId,
    };
    let totalQuoteMembershipCost =
      Object.keys(quoteAllCheckId)
        .map((e) =>
          Array.isArray(quoteAllCheckId[e])
            ? quoteAllCheckId[e].join()
            : quoteAllCheckId[e]
        )
        .filter((i) => i)
        .map((z) => allQuoteMembershipCost[z])?.length &&
      Object.keys(quoteAllCheckId)
        .map((e) =>
          Array.isArray(quoteAllCheckId[e])
            ? quoteAllCheckId[e].join()
            : quoteAllCheckId[e]
        )
        .filter((i) => i)
        .map((z) => allQuoteMembershipCost[z])
        ?.reduce(
          (accumulator, currentValue) =>
            Number(accumulator) + Number(currentValue)
        );
    let totalQuoteCost =
      Object.keys(quoteAllCheckId)
        .map((e) =>
          Array.isArray(quoteAllCheckId[e])
            ? quoteAllCheckId[e].join()
            : quoteAllCheckId[e]
        )
        .filter((i) => i)
        .map((z) => allQuoteCost[z])?.length &&
      Object.keys(quoteAllCheckId)
        .map((e) =>
          Array.isArray(quoteAllCheckId[e])
            ? quoteAllCheckId[e].join()
            : quoteAllCheckId[e]
        )
        .filter((i) => i)
        .map((z) => allQuoteCost[z])
        ?.reduce(
          (accumulator, currentValue) =>
            Number(accumulator) + Number(currentValue)
        );

    let optionalAllCheckId = {};
    optionAllId &&
      Object.keys(optionAllId)?.length &&
      Object.keys(optionAllId).forEach((item) => {
        if (item.replace(regexDashRemove, '') === key) {
          optionalAllCheckId = {
            ...optionalAllCheckId,
            [item]: e?.target?.checked ? optionAllId[item] : [],
          };
        }
      });

    // not using optionalAmount
    allOptionQuoteCost.forEach((item, i) => {
      if (
        optionalAllCheckId &&
        Object.keys(optionalAllCheckId)?.length &&
        Object.keys(optionalAllCheckId)
          .map((e) => e?.replace(regexDashRemove, ''))
          .includes(item?.key) &&
        Object.keys(optionalAllCheckId).map((e) => optionalAllCheckId[e])
          ?.length &&
        Object.keys(optionalAllCheckId)
          .map((e) => optionalAllCheckId[e])
          .flat()?.length
      ) {
        optionalAmount =
          optionalAmount +
          parseFloat(
            Object.keys(item)
              .filter((e) => e !== 'key')
              .map((z) => item[z])
              ?.reduce(
                (accumulator, currentValue) =>
                  parseFloat(accumulator) + parseFloat(currentValue)
              )
          );
      }
    });

    let totalMonthlyCost =
      Object.keys(quoteAllCheckId)
        .map((e) =>
          Array.isArray(quoteAllCheckId[e])
            ? quoteAllCheckId[e].join()
            : quoteAllCheckId[e]
        )
        .filter((i) => i)
        .map((z) => monthlyTotalCostObj[z])?.length &&
      Object.keys(quoteAllCheckId)
        .map((e) =>
          Array.isArray(quoteAllCheckId[e])
            ? quoteAllCheckId[e].join()
            : quoteAllCheckId[e]
        )
        .filter((i) => i)
        .map((z) => monthlyTotalCostObj[z])
        ?.reduce(
          (accumulator, currentValue) =>
            Number(accumulator) + Number(currentValue)
        );

    let allQuoteDiscount =
      Object.keys(quoteAllCheckId)
        .map((e) =>
          Array.isArray(quoteAllCheckId[e])
            ? quoteAllCheckId[e].join()
            : quoteAllCheckId[e]
        )
        .filter((i) => i)
        .map((z) => discountObj[z])?.length &&
      Object.keys(quoteAllCheckId)
        .map((e) =>
          Array.isArray(quoteAllCheckId[e])
            ? quoteAllCheckId[e].join()
            : quoteAllCheckId[e]
        )
        .filter((i) => i)
        .map((z) => discountObj[z])
        ?.reduce(
          (accumulator, currentValue) =>
            Number(accumulator) + Number(currentValue)
        );

    let allQuoteObj = e?.target?.checked
      ? {
        ...quoteOrderIdObj,
        [key]: quoteAllId[key],
      }
      : {
        ...quoteOrderIdObj,
        [key]: [],
      };

    let allOptionalIdObj = {
      ...state?.optionalItemIdObj,
      ...optionalAllCheckId,
    };
    let totalAmount = 0;

    allOptionQuoteCost?.forEach((allOption) => {
      Object.keys(allOptionalIdObj)?.length &&
        Object.keys(allOptionalIdObj).forEach((selectOption) => {
          if (allOption?.key === selectOption.replace(regexDashRemove, '')) {
            totalAmount =
              allOptionalIdObj[selectOption]?.length &&
              allOptionalIdObj[selectOption]
                .map((e) => allOption[e])
                ?.reduce(
                  (accumulator, currentValue) =>
                    Number(accumulator) + Number(currentValue)
                );

            allCheckOptionCost = {
              ...allCheckOptionCost,
              [selectOption]: totalAmount,
            };
          }
        });
    });

    let quoteSelectIDArr =
      quoteAllCheckId &&
      Object.keys(quoteAllCheckId)?.length &&
      Object.keys(quoteAllCheckId)
        .map((e) => quoteAllCheckId[e])
        .flat();

    setState({
      ...state,
      quoteOrderIdObj: quoteAllCheckId,
      optionalItemIdObj: { ...state?.optionalItemIdObj, ...optionalAllCheckId },
      quoteTotalCost: totalQuoteCost,
      selectQuoteMembershipCost: typeof totalQuoteMembershipCost === 'object' ? Object.keys(totalQuoteMembershipCost).length > 0 ? totalQuoteMembershipCost : 0 : totalQuoteMembershipCost,
      allOptionCost: { ...allCheckOptionCost },
      optionalTotalCost: optionalAmount,
      monthlyTotalCost: totalMonthlyCost,
      totalDiscount: allQuoteDiscount,
      quoteSelectID: quoteSelectIDArr,
    });
  };

  // for Line Item id
  let monthlyAddLineItemCost = monthlyLineItemTotalCost;
  const handleLineItemChange = (checkedValues) => {
    let checkAllId = arrayEqual(lineItemAllId, checkedValues);
    setLineCheckAll(checkAllId);

    // for get checked admin added line item amount
    let adminAddedLineItemAmount = 0;
    checkedValues?.length &&
      checkedValues?.map((e) => {
        adminAddedlineItems?.length &&
          adminAddedlineItems?.map((item) => {
            if (item?.id === e) {
              adminAddedLineItemAmount = adminAddedLineItemAmount + Number(item?.total)
            }
          })
      })

    let totalCost =
      checkedValues?.length &&
      checkedValues
        .map((e) => allLineItemCost[e])
        ?.reduce(
          (accumulator, currentValue) =>
            Number(accumulator) + Number(currentValue)
        );

    monthlyAddLineItemCost =
      checkedValues?.length &&
      checkedValues
        .map((e) => monthlyLineItemTotalCostObj[e])
        ?.reduce(
          (accumulator, currentValue) =>
            Number(accumulator) + Number(currentValue)
        );

    setState({
      ...state,
      lineItemIdArray: checkedValues,
      adminAddedCheckedlineItemsAmount: adminAddedLineItemAmount,
      lineItemTotalCost: totalCost,
      monthlyLineItemTotalCost: monthlyAddLineItemCost,
    });
  };

  // for All Line Item
  const onLineItemCheckAllChange = (e) => {
    setLineCheckAll(e?.target?.checked);

    let totalCost =
      Object.keys(allLineItemCost)?.length &&
      Object.keys(allLineItemCost)
        .map((e) => allLineItemCost[e])
        ?.reduce(
          (accumulator, currentValue) =>
            Number(accumulator) + Number(currentValue)
        );

    let getAllSelectedLineItem = e?.target?.checked ? lineItemAllId : [];

    // for get checked admin added line item amount
    let adminAddedLineItemAmount = 0;
    getAllSelectedLineItem?.length &&
      getAllSelectedLineItem?.map((e) => {
        adminAddedlineItems?.length &&
          adminAddedlineItems?.map((item) => {
            if (item?.id === e) {
              adminAddedLineItemAmount = adminAddedLineItemAmount + Number(item?.total)
            }
          })
      })

    let monthlyAllAddEquipmentCost =
      getAllSelectedLineItem?.length &&
      getAllSelectedLineItem
        .map((e) => monthlyLineItemTotalCostObj[e])
        ?.reduce(
          (accumulator, currentValue) =>
            Number(accumulator) + Number(currentValue)
        );

    setState({
      ...state,
      adminAddedCheckedlineItemsAmount: adminAddedLineItemAmount,
      lineItemIdArray: e?.target?.checked ? lineItemAllId : [],
      lineItemTotalCost: e?.target?.checked ? totalCost : 0,
      monthlyLineItemTotalCost: monthlyAllAddEquipmentCost,
    });
  };

  // for all new equipment item
  let monthlyAddEquipmentCost = monthlyEquipmentTotalCost;
  let equipmentIdObj = equipmentIdArray;

  const handleEquipmentItemChange = (checkedValues, key) => {
    equipmentIdObj = {
      ...equipmentIdObj,
      [key]: checkedValues,
    };

    let allCheckedID =
      Object.keys(equipmentIdObj)?.length &&
      Object.keys(equipmentIdObj)
        ?.map((e) => equipmentIdObj[e])
        .flat();

    let checkAllId = arrayEqual(equipmentAllIdArray, allCheckedID);

    let checkAllOptionId = arrayEqual(
      typeof equOptionAllId === 'object' &&
      Object.values(equOptionAllId).flat(),
      typeof equOptionalItemIdObj === 'object' &&
      Object.values(equOptionalItemIdObj).flat()
    );
    setEquipmentCheckAll(checkAllId && checkAllOptionId);

    let totalCost =
      allCheckedID?.length &&
      allCheckedID
        .map((e) => allEquipmentItemCost[e])
        ?.reduce(
          (accumulator, currentValue) =>
            Number(accumulator) + Number(currentValue)
        );

    monthlyAddEquipmentCost =
      allCheckedID?.length &&
      allCheckedID
        .map((e) => monthlyEquipmentTotalCostObj[e])
        ?.reduce(
          (accumulator, currentValue) =>
            Number(accumulator) + Number(currentValue)
        );

    setState({
      ...state,
      equipmentIdArray: equipmentIdObj,
      equipmentItemTotalCost: totalCost,
      monthlyEquipmentTotalCost: monthlyAddEquipmentCost,
    });
  };

  const onNewEquipmentCheckAllChange = (e, key) => {
    setEquipmentCheckAll(e?.target?.checked);

    let totalCost =
      Object.keys(allEquipmentItemCost)?.length &&
      Object.keys(allEquipmentItemCost)
        .map((e) => allEquipmentItemCost[e])
        ?.reduce(
          (accumulator, currentValue) =>
            Number(accumulator) + Number(currentValue)
        );
    let getAllSelectedEquip = e?.target?.checked ? equipmentAllIdArray : [];

    let monthlyAllAddEquipmentCost =
      getAllSelectedEquip?.length &&
      getAllSelectedEquip
        .map((e) => monthlyEquipmentTotalCostObj[e])
        ?.reduce(
          (accumulator, currentValue) =>
            Number(accumulator) + Number(currentValue)
        );

    setState({
      ...state,
      equipmentIdArray: e?.target?.checked ? equipmentAllIdObj : {},
      equipmentItemTotalCost: e?.target?.checked ? totalCost : 0,
      monthlyEquipmentTotalCost: monthlyAllAddEquipmentCost,
      equOptionalItemIdObj: e?.target?.checked ? allCheckEquOptionalIdObj : {},
      equOptionalTotalCost: e?.target?.checked
        ? allCheckEquOptionalTotalCost
        : 0,
    });
  };

  // for optional id
  let equOptionalId = equOptionalItemIdObj;
  let equOptionCost = allEquOptionCost;
  let equOptionAddCost = 0;

  const handleEquOptionalChange = (checkedValues, key) => {
    equOptionalId = {
      ...equOptionalId,
      [key]: checkedValues,
    };

    let allCheckedID =
      Object.keys(equipmentIdArray)?.length &&
      Object.keys(equipmentIdArray)
        ?.map((e) => equipmentIdArray[e])
        .flat();

    let checkAllEquId = arrayEqual(equipmentAllIdArray, allCheckedID);
    let checkAllOptionId = arrayEqual(
      typeof equOptionAllId === 'object' &&
      Object.values(equOptionAllId).flat(),
      typeof equOptionalId === 'object' && Object.values(equOptionalId).flat()
    );

    setEquipmentCheckAll(checkAllEquId && checkAllOptionId);

    // For total optional amount
    let totalAmount = 0;
    equAllOptionQuoteCost?.forEach((allOption) => {
      Object.keys(equOptionalId)?.length &&
        Object.keys(equOptionalId).forEach((selectOption) => {
          if (allOption?.key === Number(selectOption)) {
            totalAmount =
              equOptionalId[Number(selectOption)]?.length &&
              equOptionalId[Number(selectOption)]
                .map((e) => allOption[e])
                ?.reduce(
                  (accumulator, currentValue) =>
                    Number(accumulator) + Number(currentValue)
                );

            equOptionCost = {
              ...equOptionCost,
              [Number(selectOption)]: Number(totalAmount),
            };
          }
        });
    });

    equOptionAddCost = Object.keys(equOptionCost)
      .map((e) => equOptionCost[e])
      ?.reduce(
        (accumulator, currentValue) =>
          Number(accumulator) + Number(currentValue)
      );

    setState({
      ...state,
      equOptionalItemIdObj: equOptionalId,
      allEquOptionCost: equOptionCost,
      equOptionalTotalCost: equOptionAddCost,
    });
  };

  const compareDiscountApplied = () => {
    let res = [];
    Object.keys(discountQuoteListObj).map((e) => {
      discountQuoteListObj[e]?.discount_list.map((element, index) => {
        res = [
          ...res,
          {
            ...element,
            admin_material_total_amount:
              discountQuoteListObj[e]?.admin_material_total_amount,
          }
        ]
      })
    });
    return res;
  };

  // For Quote Price
  let priceObj = quotePrice;
  let priceQuoteDiscountedObj = quoteDiscountedPrice;
  let itemName = '';
  const handlePrice = () => {
    quoteOrderIdObj &&
      Object.keys(quoteOrderIdObj)?.length &&
      Object.keys(quoteOrderIdObj)
        .map((e) => e)
        .forEach((item) => {
          if (itemName === item.replace(regexDashRemove, '').trim()) {
            priceObj = {
              ...priceObj,
              [item.replace(regexDashRemove, '').trim()]:
                (parseFloat(allQuoteCost[quoteOrderIdObj[item]]) || 0) +
                (parseFloat(
                  priceObj[item.replace(regexDashRemove, '').trim()]
                ) || 0),
            };

            priceQuoteDiscountedObj = {
              ...priceQuoteDiscountedObj,
              [item.replace(regexDashRemove, '').trim()]:
                (parseFloat(allQuoteDiscountedCost[quoteOrderIdObj[item]]) ||
                  0) +
                (parseFloat(
                  priceQuoteDiscountedObj[
                  item.replace(regexDashRemove, '').trim()
                  ]
                ) || 0),
            };
          } else {
            priceObj = {
              ...priceObj,
              [item.replace(regexDashRemove, '').trim()]:
                allQuoteCost[quoteOrderIdObj[item]] || 0,
            };

            priceQuoteDiscountedObj = {
              ...priceQuoteDiscountedObj,
              [item.replace(regexDashRemove, '').trim()]:
                allQuoteDiscountedCost[quoteOrderIdObj[item]] || 0,
            };
          }
          itemName = item.replace(regexDashRemove, '').trim();
        });
    setQuotePrice(priceObj);
    setQuoteDiscountedPrice(priceQuoteDiscountedObj);
  };

  // For Quote option Price
  let priceOptionObj = quoteOptionPrice;
  let itemOptionName = '';
  const handleOptionPrice = () => {
    allOptionCost &&
      Object.keys(allOptionCost)?.length &&
      Object.keys(allOptionCost)
        .map((e) => e)
        .forEach((item) => {
          if (itemOptionName === item.replace(regexDashRemove, '')) {
            priceOptionObj = {
              ...priceOptionObj,
              [item.replace(regexDashRemove, '')]:
                (parseFloat(allOptionCost[item]) || 0) +
                (parseFloat(
                  priceOptionObj[item.replace(regexDashRemove, '')]
                ) || 0),
            };
          } else {
            priceOptionObj = {
              ...priceOptionObj,
              [item.replace(regexDashRemove, '')]: allOptionCost[item] || 0,
            };
          }
          itemOptionName = item.replace(regexDashRemove, '');
        });

    let optionTotalAmountArr =
      Object.keys(priceOptionObj)?.length &&
      Object.keys(priceOptionObj)
        .map((e) => priceOptionObj[e])
        .reduce(
          (accumulator, currentValue) =>
            Number(accumulator) + Number(currentValue)
        );
    setOptionTotalAmount(optionTotalAmountArr);
    setQuoteOptionPrice(priceOptionObj);
  };

  const checkQuoteExpire = () => {
    return moment(quoteData?.bookingQuote?.createdAt)
      .add(quoteData?.bookingQuote?.quoteExpiredAt, 'd')
      .isSameOrBefore();
  };

  const expireMessage = () => {
    let expireDays = moment().diff(
      moment(quoteData?.bookingQuote?.createdAt).add(
        quoteData?.bookingQuote?.quoteExpiredAt,
        'd'
      ),
      'days'
    );
    let providerName = firstLetterCaps(details?.Provider?.firstName ?? '');
    let providerPhoneNumber = details?.Provider?.phoneNumber;
    return `Quote is older than 30 days . Please contact ${providerName} at ${providerPhoneNumber} to confirm quote details `;
  };

  useEffect(() => {
    handlePrice();
  }, [quoteOrderIdObj]);

  useEffect(() => {
    handleOptionPrice();
  }, [allOptionCost]);

  useEffect(() => {
    let total =
      (parseFloat(lineItemTotalCost) || 0) +
      (parseFloat(quoteTotalCost) || 0) +
      (parseFloat(equipmentItemTotalCost) || 0) +
      (parseFloat(optionTotalAmount) || 0) +
      (parseFloat(equOptionalTotalCost) || 0) -
      ((parseFloat(totalDiscount) || 0) + parseFloat(spDiscount) || 0);

    setQuoteTotalAmount(total);
  }, [
    lineItemTotalCost,
    quoteTotalCost,
    equipmentItemTotalCost,
    optionTotalAmount,
    equOptionalTotalCost,
    totalDiscount,
    spDiscount,
  ]);

  return (
    <>
      <div className="main-content ratingPage requestviewdetails">
        <div className="pageContent">
          <div className="pageContent_wide">
            <Container className="container-lg">
              {(isGuest && companyCode) ?
                null
                : <Breadcrumb
                  title={t('text.requestViewDetails.title')}
                  breadcrumb={breadcrumb}
                  type="customer"
                />}
              {!state?.isLoading && Object.keys(details)?.length > 0 ? (
                <>
                  <div
                    id="quoteWrapper"
                    className="ratingPage_Box br-10 p-30 bg-white"
                  >
                    <div className="ratingPage_profile mb-4">
                      <div className="ratingPage_profile_content text-center">
                        <ImageElement
                          previewSource={
                            details?.Provider?.profilePictureThumbUrl
                          }
                          source="view-details.png"
                          alt="view-details"
                        />
                        <h2 className="mb-0">
                          {firstLetterCaps(details?.Provider?.firstName ?? '')}
                          &nbsp;
                          {firstLetterCaps(details?.Provider?.lastName ?? '')}
                        </h2>
                        <p className="mb-0">
                          <ul className="list-unstyled d-flex justify-content-center mt-1 starRating mb-0">
                            {generateStarRating(
                              details?.Provider?.avgRating ?? 0
                            )}
                          </ul>
                        </p>
                      </div>
                    </div>

                    <div className="ratingPage_Payment ">
                      <div className="ratingPage_Services border-bottom-0 p-0">
                        <div className="ratingPage_Services_maintenance">
                          <h5 className="ratingsubhead mb-2">
                            {t('text.requestViewDetails.servicesDetails')}
                          </h5>
                          <p className="ratingpara mb-2">
                            {
                              details?.ServiceCategory?.ServiceCategoryDetails
                                ?.name
                            }
                          </p>
                          <div className="d-flex justify-content-between ">
                            <h5 className="ratingsubhead mb-2">
                              {details?.ServiceCategory?.name}
                            </h5>
                          </div>
                        </div>
                      </div>
                      {quoteData?.booking?.ServiceCategory?.isSalesQuote === 0 &&
                        <div className="ratingPage_Services_inner d-flex justify-content-between ">
                          <h5 className="ratingsubhead mb-0">
                            {t('text.requestViewDetails.quoteAmount')}
                          </h5>
                          <p className="ratingamount mb-0 ">
                            {quoteData?.bookingQuote?.quoteAmount
                              ? checkValidPrice(
                                quoteData?.bookingQuote?.quoteAmount
                              )
                              : '$0.00'}
                          </p>
                        </div>
                      }
                      {/* Edit quote Start  */}
                      <div
                        className={`ratingPage_Services pt-0 border-bottom-0 ${quoteData?.booking?.ServiceCategory?.isSalesQuote ===
                          0
                          ? 'p-0'
                          : ''
                          }`}
                      >
                        {quoteData?.booking?.ServiceCategory?.isSalesQuote ===
                          1 && (
                            <div className="ratingPage_Services pt-0">
                              <div className="ratingPage_Services_maintenance">
                                {quoteData?.optionsList &&
                                  !editQuote &&
                                  Object.keys(quoteData?.optionsList)?.length ? (
                                  <h4 className="ratingheading">
                                    {t('text.requestViewDetails.pleaseSelect')}
                                  </h4>
                                ) : null}

                                {quoteData?.optionsList &&
                                  Object.keys(quoteData?.optionsList)?.length
                                  ? Object.keys(quoteData?.optionsList).map(
                                    (item, i) => (
                                      <>
                                        {quoteData?.booking?.bookingStatus ===
                                          'accepted' &&
                                          (!editQuote ||
                                            (quoteOrderIdObj &&
                                              Object.keys(quoteOrderIdObj)?.length
                                              ? Object.keys(quoteOrderIdObj)
                                                .map((e) =>
                                                  e
                                                    .replace(
                                                      regexDashRemove,
                                                      ''
                                                    )
                                                    .trim()
                                                )
                                                .includes(item)
                                              : false)) && (
                                            <div className="ratingPage_Services_inner d-flex justify-content-between">
                                              <h5 className="ratingsubhead mb-0">
                                                {!editQuote && isCheckBox && (
                                                  <Checkbox
                                                    // checked={
                                                    //   checkAll[
                                                    //     item
                                                    //       .replace(
                                                    //         regexDashRemove,
                                                    //         ''
                                                    //       )
                                                    //       .trim()
                                                    //   ]
                                                    // }

                                                    checked={checkAll[item]}
                                                    onChange={(e) =>
                                                      onQuoteCheckAllChange(
                                                        e,
                                                        item,
                                                        quoteData
                                                          ?.optionsList?.[item]
                                                      )
                                                    }
                                                  ></Checkbox>
                                                )}{' '}
                                                Select All {item} Options
                                              </h5>
                                            </div>
                                          )}

                                        {quoteData?.optionsList?.[item].map(
                                          (quoteItem, index) =>
                                            (!editQuote
                                              ? true
                                              : quoteOrderIdObj &&
                                              quoteOrderIdObj.hasOwnProperty(
                                                `${item}${index}`
                                              ) &&
                                              quoteOrderIdObj[
                                              `${item}${index}`
                                              ] &&
                                              quoteOrderIdObj[
                                                `${item}${index}`
                                              ].includes(quoteItem?.id)) && (
                                              <>
                                                <Checkbox.Group
                                                  style={{
                                                    width: '100%',
                                                  }}
                                                  key={quoteItem?.id}
                                                  value={
                                                    quoteOrderIdObj[
                                                    `${item}${index}`
                                                    ]
                                                  }
                                                  onChange={(e) =>
                                                    handleQuoteChange(
                                                      e,
                                                      `${item}${index}`
                                                    )
                                                  }
                                                >
                                                  <div
                                                    key={index}
                                                    className="ratingPage_Services_inner d-flex justify-content-between"
                                                  >
                                                    <div className="d-flex align-items-sm-start">
                                                      {!editQuote &&
                                                        isCheckBox && (
                                                          <Checkbox
                                                            value={
                                                              quoteItem?.id
                                                            }
                                                          >
                                                            {' '}
                                                          </Checkbox>
                                                        )}
                                                      <ImageElement
                                                        previewSource={
                                                          quoteItem?.image
                                                            ?.imageThumbUrl
                                                        }
                                                        className="me-2"
                                                        alt="water-heater"
                                                      />
                                                      <div className="ratingPage_desc">
                                                        <h5 className="ratingsubhead mb-1 d-flex align-items-center">
                                                          {quoteItem?.brand} -{' '}
                                                          {
                                                            quoteItem?.quote_type
                                                          }
                                                          <Link
                                                            to="#"
                                                            onClick={(e) => {
                                                              e?.preventDefault();
                                                              setState({
                                                                ...state,
                                                                modalHeading:
                                                                  quoteItem?.quote_type,
                                                                showModal:
                                                                  'requestDetails',
                                                                modalKey:
                                                                  'information',
                                                                modalData:
                                                                  quoteItem,
                                                              });
                                                            }}
                                                          >
                                                            <em className="gg-danger-circle ms-2 text-750" />
                                                          </Link>
                                                        </h5>
                                                        {quoteItem?.series_name && (
                                                          <p className="ratingamount mb-1 d-flex align-items-center">
                                                            {
                                                              quoteItem?.series_name
                                                            }
                                                          </p>
                                                        )}

                                                        {/* {quoteItem?.quote_description && (
                                                          <p className="ratingamount mb-1 d-flex align-items-center">
                                                            {
                                                              quoteItem?.quote_description
                                                            }
                                                          </p>
                                                        )} */}
                                                        {quoteItem?.size_description && (
                                                          <p className="ratingamount mb-1">
                                                            <span className="ratingsubhead">
                                                              {t(
                                                                'text.requestViewDetails.sizeDescription'
                                                              )}
                                                            </span>
                                                            {
                                                              quoteItem?.size_description
                                                            }
                                                          </p>
                                                        )}
                                                        {/* {quoteItem?.quote_description && (
                                                          <p className="ratingamount mb-1">
                                                            <span className="ratingsubhead">
                                                              {t(
                                                                'text.requestViewDetails.quoteDescription'
                                                              )}
                                                            </span>
                                                            {
                                                              quoteItem?.quote_description
                                                            }
                                                          </p>
                                                        )} */}
                                                        {quoteItem?.system_btu_capacity && (
                                                          <p className="ratingamount mb-1">
                                                            <span className="ratingsubhead">
                                                              {t(
                                                                'text.requestViewDetails.capacity'
                                                              )}
                                                            </span>
                                                            {
                                                              quoteItem?.system_btu_capacity
                                                            }
                                                          </p>
                                                        )}
                                                        {quoteItem?.directional && (
                                                          <p className="ratingamount mb-1">
                                                            <span className="ratingsubhead">
                                                              {t(
                                                                'text.requestViewDetails.directional'
                                                              )}
                                                            </span>
                                                            {
                                                              quoteItem?.directional
                                                            }
                                                          </p>
                                                        )}
                                                        {quoteItem?.min_blower_drive && (
                                                          <p className="ratingamount mb-1">
                                                            <span className="ratingsubhead">
                                                              {t(
                                                                'text.requestViewDetails.minimumBlowerDrive'
                                                              )}
                                                            </span>
                                                            {
                                                              quoteItem?.min_blower_drive
                                                            }
                                                          </p>
                                                        )}

                                                        {quoteItem?.rebate
                                                          ?.brandRebate && (
                                                            <p className="ratingamount mb-1">
                                                              <span className="ratingsubhead">
                                                                {t(
                                                                  'text.requestViewDetails.efficiency'
                                                                )}
                                                              </span>
                                                              {
                                                                quoteItem?.rebate
                                                                  ?.brandRebate
                                                              }
                                                            </p>
                                                          )}
                                                        {quoteItem?.system_part_warranty && (
                                                          <p className="ratingamount mb-1">
                                                            <span className="ratingsubhead">
                                                              {t(
                                                                'text.requestViewDetails.partsWarranty'
                                                              )}
                                                            </span>{' '}
                                                            {
                                                              quoteItem?.system_part_warranty
                                                            }
                                                          </p>
                                                        )}
                                                        {quoteItem?.energy_guide_label && (
                                                          <p className="ratingamount mb-1">
                                                            <span className="ratingsubhead">
                                                              {t(
                                                                'text.requestViewDetails.energyGuideLabel'
                                                              )}
                                                            </span>
                                                            {
                                                              quoteItem?.energy_guide_label
                                                            }
                                                          </p>
                                                        )}
                                                      </div>
                                                    </div>
                                                    {quoteItem?.admin_material_total_amount && (
                                                      <div className="ratingPage_amount">
                                                        <p className="ratingamount mb-1">
                                                          {checkValidPrice(
                                                            quoteItem?.admin_material_total_amount
                                                          )}
                                                        </p>
                                                      </div>
                                                    )}
                                                  </div>
                                                </Checkbox.Group>
                                                {/* Discount price Quote */}
                                                {quoteItem?.discount_list
                                                  ?.length > 0 && (
                                                    <div className="ratingPage_Services_inner d-flex justify-content-between ">
                                                      <h5 className="ratingsubhead mb-0">
                                                        {t(
                                                          'text.common.discountedPrice'
                                                        )}
                                                        <Link
                                                          to="#"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            setState({
                                                              ...state,
                                                              modalHeading:
                                                                'Discount',
                                                              showModal:
                                                                'requestDetails',
                                                              modalKey:
                                                                'discountPrice',
                                                              modalData:
                                                                quoteItem,
                                                            });
                                                          }}
                                                        >
                                                          <em className="gg-danger-circle ms-2 text-750" />
                                                        </Link>
                                                      </h5>

                                                      <p className="ratingamount mb-0 ">
                                                        {checkValidPrice(
                                                          parseFloat(
                                                            quoteItem?.appliedDiscount
                                                          ) <
                                                            parseFloat(
                                                              quoteItem?.admin_material_total_amount
                                                            )
                                                            ? parseFloat(
                                                              quoteItem?.admin_material_total_amount
                                                            ) -
                                                            parseFloat(
                                                              quoteItem?.appliedDiscount
                                                            )
                                                            : 0
                                                        )}
                                                      </p>
                                                    </div>
                                                  )}
                                                {/* Memrberhship Discount Display */}
                                                {(!editQuote && quoteItem?.membershipBenefitAvailable) &&
                                                  (
                                                    <>
                                                      <div className="ratingPage_Services_inner d-flex justify-content-between ">
                                                        <h5 className="ratingsubhead mb-0">
                                                          Membership Disount :
                                                        </h5>
                                                        <p className="ratingsubhead mb-0">
                                                          {checkValidPrice(
                                                            quoteItem?.membershipBenefitAmount
                                                          )}
                                                        </p>
                                                      </div>
                                                    </>
                                                  )}
                                                {/* Monthly payment */}

                                                {quoteItem?.financeOption &&
                                                  Object.keys(
                                                    quoteItem?.financeOption
                                                  )?.length ? (
                                                  <div className="ratingPage_Services_inner d-flex justify-content-between ">
                                                    <h5 className="ratingsubhead mb-0">
                                                      {t(
                                                        'text.requestViewDetails.monthlyApprovedCredit'
                                                      )}
                                                    </h5>
                                                    <p className="ratingamount mb-0 ">
                                                      {parseFloat(
                                                        quoteItem?.appliedDiscount
                                                      ) <
                                                        parseFloat(
                                                          quoteItem?.admin_material_total_amount
                                                        )
                                                        ? checkValidPrice(
                                                          ((parseFloat(
                                                            quoteItem?.admin_material_total_amount
                                                          ) -
                                                            parseFloat(
                                                              quoteItem?.appliedDiscount
                                                            )) /
                                                            100) *
                                                          parseFloat(
                                                            quoteItem
                                                              ?.financeOption
                                                              ?.paymentFactor
                                                          )
                                                        )
                                                        : '$0.00'}
                                                    </p>
                                                  </div>
                                                ) : null}
                                                {/* Option LIst*/}
                                                {quoteItem?.optionalItem
                                                  ?.length ||
                                                  quoteItem
                                                    ?.optionalItemForBooking
                                                    ?.length ? (
                                                  <div>
                                                    {(optionalItemIdObj &&
                                                      optionalItemIdObj.hasOwnProperty(
                                                        `${item}${index}`
                                                      ) &&
                                                      optionalItemIdObj[
                                                        `${item}${index}`
                                                      ]?.length) ||
                                                      !editQuote ||
                                                      equOptionDecline ? (
                                                      <h5 className="ratingsubhead mb-0 mt-2">
                                                        {t(
                                                          'text.requestViewDetails.optionsList'
                                                        )}
                                                      </h5>
                                                    ) : null}
                                                    <Checkbox.Group
                                                      style={{
                                                        width: '100%',
                                                      }}
                                                      value={
                                                        optionalItemIdObj[
                                                        `${item}${index}`
                                                        ]
                                                      }
                                                      key={quoteItem?.id}
                                                      onChange={(e) =>
                                                        handleOptionalChange(
                                                          e,
                                                          `${item}${index}`
                                                        )
                                                      }
                                                    >
                                                      {(
                                                        quoteItem?.optionalItem ||
                                                        quoteItem?.optionalItemForBooking
                                                      )?.map(
                                                        (optionData, i) =>
                                                          (!editQuote
                                                            ? true
                                                            : optionalItemIdObj &&
                                                            optionalItemIdObj.hasOwnProperty(
                                                              `${item}${index}`
                                                            ) &&
                                                            optionalItemIdObj[
                                                              `${item}${index}`
                                                            ].includes(
                                                              optionData?.id
                                                            )) && (
                                                            <>
                                                              <div className="ratingPage_Services_inner d-flex justify-content-between ">
                                                                <h5 className="ratingsubhead mb-0">
                                                                  {!editQuote &&
                                                                    isCheckBox && (
                                                                      <Checkbox
                                                                        value={
                                                                          optionData?.id
                                                                        }
                                                                      ></Checkbox>
                                                                    )}
                                                                  {
                                                                    optionData?.title
                                                                  }
                                                                </h5>
                                                                <div>
                                                                  {checkValidPrice(
                                                                    optionData?.cost
                                                                  )}
                                                                </div>
                                                              </div>
                                                            </>
                                                          )
                                                      )}
                                                    </Checkbox.Group>
                                                  </div>
                                                ) : null}
                                              </>
                                            )
                                        )}

                                        {/* quoteOrderIdObj &&
                                              Object.keys(quoteOrderIdObj)
                                                ?.length &&
                                              Object.keys(quoteOrderIdObj)
                                                .map((e) =>
                                                  e.replace(regexDashRemove, '').trim()
                                                )
                                                .includes(item)  */}

                                        {(quoteData?.booking?.bookingStatus ===
                                          'accepted' ||
                                          quoteData?.booking?.bookingStatus ===
                                          'canceled') &&
                                          (!editQuote ||
                                            (quoteOrderIdObj &&
                                              Object.keys(quoteOrderIdObj)?.length
                                              ? Object.keys(quoteOrderIdObj)
                                                .map((e) =>
                                                  e
                                                    .replace(
                                                      regexDashRemove,
                                                      ''
                                                    )
                                                    .trim()
                                                )
                                                .includes(item)
                                              : false)) && (
                                            <div className="ratingPage_Services_inner d-flex justify-content-between ">
                                              <h5 className="ratingsubhead mb-0">
                                                {t(
                                                  'text.requestViewDetails.totalCost'
                                                )}{' '}
                                              </h5>

                                              <p className="ratingamount mb-0 ">
                                                {checkValidPrice(
                                                  (parseFloat(
                                                    quoteDiscountedPrice[
                                                    item
                                                    ] || 0
                                                  ) || 0) +
                                                  (parseFloat(
                                                    quoteOptionPrice[item]
                                                  ) || 0) || 0
                                                )}
                                              </p>
                                            </div>
                                          )}
                                      </>
                                    )
                                  )
                                  : null}
                              </div>
                            </div>
                          )}
                        {/* For technician options */}

                        {/* equipmentIdArray?.length */}
                        {quoteData?.otherEquipment?.length > 0 &&
                          (Object.keys(equipmentIdArray)?.length ||
                            !editQuote) ? (
                          <>
                            <div className="ratingPage_Services">
                              {(quoteData?.booking?.bookingStatus ===
                                'accepted' ||
                                quoteData?.booking?.bookingStatus ===
                                'canceled') &&
                                quoteData?.bookingQuote?.providerStatus !==
                                'accepted' && (
                                  <div className="ratingPage_Services_inner d-flex justify-content-between">
                                    <h5 className="ratingsubhead mb-0">
                                      {!editQuote && isCheckBox && (
                                        <Checkbox
                                          checked={equipmentCheckAll}
                                          onChange={
                                            onNewEquipmentCheckAllChange
                                          }
                                        ></Checkbox>
                                      )}{' '}
                                      {t(
                                        'text.requestViewDetails.selectAllTechnicianOptions'
                                      )}
                                    </h5>
                                  </div>
                                )}

                              {quoteData.otherEquipment.map((val, index) => (
                                <>
                                  <Checkbox.Group
                                    style={{
                                      width: '100%',
                                    }}
                                    key={val?.id}
                                    value={equipmentIdArray[val?.id]}
                                    onChange={(e) =>
                                      handleEquipmentItemChange(e, val?.id)
                                    }
                                  >
                                    {(!editQuote
                                      ? true
                                      : equipmentAllSelectedIdArray.includes(
                                        val?.id
                                      )) && (
                                        <div className="ratingPage_Services_maintenance">
                                          <div
                                            key={val?.id}
                                            className="ratingPage_Services_inner d-flex justify-content-between "
                                          >
                                            <div className="d-flex align-items-sm-center">
                                              {!editQuote && isCheckBox && (
                                                <div>
                                                  <Checkbox
                                                    value={val?.id}
                                                  ></Checkbox>
                                                </div>
                                              )}
                                              <ImageElement
                                                previewSource={val?.imageThumbUrl}
                                                className="me-2"
                                                alt="water-heater"
                                              />
                                              <div className="ratingPage_desc">
                                                <h5 className="ratingsubhead mb-1 d-flex align-items-center">
                                                  {val?.title || ''}
                                                </h5>
                                                <p className="ratingamount mb-0 d-flex align-items-center">
                                                  {val?.description || ''}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="ratingPage_amount">
                                              <p className="ratingamount mb-0">
                                                {checkValidPrice(val?.cost || '')}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </Checkbox.Group>

                                  {val?.otherEquipment?.length ? (
                                    <>
                                      {(equOptionalItemIdObj &&
                                        equOptionalItemIdObj.hasOwnProperty(
                                          val
                                        ) &&
                                        equOptionalItemIdObj[val?.id]
                                          ?.length) ||
                                        !editQuote ? (
                                        <h5 className="ratingsubhead mb-0 mt-2">
                                          {t(
                                            'text.requestViewDetails.optionsList'
                                          )}
                                        </h5>
                                      ) : null}
                                      <Checkbox.Group
                                        style={{
                                          width: '100%',
                                        }}
                                        value={equOptionalItemIdObj[val?.id]}
                                        key={val?.id}
                                        onChange={(e) =>
                                          handleEquOptionalChange(e, val?.id)
                                        }
                                      >
                                        {val?.otherEquipment?.map(
                                          (optionData, i) =>
                                            (!editQuote
                                              ? true
                                              : equOptionalItemIdObj &&
                                              equOptionalItemIdObj.hasOwnProperty(
                                                val?.id
                                              ) &&
                                              equOptionalItemIdObj[
                                                val?.id
                                              ].includes(optionData?.id)) && (
                                              <>
                                                <div className="ratingPage_Services_inner d-flex justify-content-between ">
                                                  <h5 className="ratingsubhead mb-0">
                                                    {!editQuote &&
                                                      isCheckBox && (
                                                        <Checkbox
                                                          value={optionData?.id}
                                                        ></Checkbox>
                                                      )}
                                                    {optionData?.title}
                                                  </h5>
                                                  <div>
                                                    {checkValidPrice(
                                                      optionData?.cost
                                                    )}
                                                  </div>
                                                </div>
                                              </>
                                            )
                                        )}
                                        {/* <hr className="mb-0" /> */}
                                      </Checkbox.Group>
                                    </>
                                  ) : null}
                                </>
                              ))}
                              <div className="ratingPage_Services_inner d-flex justify-content-between ">
                                <h5 className="ratingsubhead mb-0">
                                  {t(
                                    'text.requestViewDetails.monthlyApprovedCredit'
                                  )}
                                </h5>
                                <p className="ratingamount mb-0 ">
                                  {/* {checkValidPrice(monthlyEquipmentTotalCost)} */}

                                  {checkValidPrice(
                                    parseFloat(
                                      (parseFloat(equipmentItemTotalCost) +
                                        parseFloat(equOptionalTotalCost)) /
                                      100
                                    ) *
                                    parseFloat(
                                      quoteData?.financeOption?.paymentFactor
                                    )
                                  )}
                                </p>
                              </div>
                              {(quoteData?.booking?.bookingStatus ===
                                'accepted' ||
                                quoteData?.booking?.bookingStatus ===
                                'canceled') && (
                                  <div className="ratingPage_Services_inner d-flex justify-content-between ">
                                    <h5 className="ratingsubhead mb-0">
                                      {t('text.requestViewDetails.totalCostS')}
                                    </h5>
                                    <p className="ratingamount mb-0 ">
                                      {checkValidPrice(
                                        parseFloat(equipmentItemTotalCost) +
                                        parseFloat(equOptionalTotalCost)
                                      )}
                                    </p>
                                  </div>
                                )}
                            </div>
                          </>
                        ) : null}

                        {/* For technician options End */}
                        {(quoteData?.lineItems?.length > 0 ||
                          quoteData?.booking?.BookingLineItems?.length > 0) && (
                            <>
                              {lineItemIdArray?.length || !editQuote ? (
                                <div className="ratingPage_Services_inner d-flex justify-content-between">
                                  <h5 className="ratingsubhead mb-0">
                                    {' '}
                                    {quoteData?.booking?.ServiceCategory
                                      ?.isSalesQuote === 1 &&
                                      !editQuote &&
                                      isCheckBox && (
                                        <Checkbox
                                          checked={lineCheckAll}
                                          onChange={onLineItemCheckAllChange}
                                        ></Checkbox>
                                      )}{' '}
                                    {t('text.requestViewDetails.lineItems')}
                                  </h5>
                                </div>
                              ) : null}

                              <Checkbox.Group
                                style={{
                                  width: '100%',
                                }}
                                value={lineItemIdArray}
                                onChange={handleLineItemChange}
                              >
                                <div className="ratingPage_Services_maintenance">
                                  {quoteData?.lineItems?.length > 0 &&
                                    (quoteData?.lineItems).map((val) => (
                                      <>
                                        {(quoteData?.booking?.ServiceCategory
                                          ?.isSalesQuote === 0 || !editQuote
                                          ? true
                                          : lineItemIdArray.includes(
                                            val?.id
                                          )) && (
                                            <div
                                              key={val?.id}
                                              className="ratingPage_Services_inner d-flex justify-content-between "
                                            >
                                              <div className="d-flex align-items-sm-center">
                                                {quoteData?.booking?.ServiceCategory
                                                  ?.isSalesQuote === 1 &&
                                                  !editQuote &&
                                                  isCheckBox && (
                                                    <div>
                                                      <Checkbox
                                                        value={val?.id}
                                                      ></Checkbox>
                                                    </div>
                                                  )}

                                                <ImageElement
                                                  previewSource={
                                                    val?.imageThumbUrl ||
                                                    val?.imageUrl
                                                  }
                                                  className="me-2"
                                                  alt="water-heater"
                                                />
                                                <div className="ratingPage_desc">
                                                  <h5 className="ratingsubhead mb-1 d-flex align-items-center">
                                                    {val?.name || ''} ($
                                                    {val?.unitPrice} * {val?.qty})
                                                  </h5>
                                                  <p className="ratingamount mb-0 d-flex align-items-center">
                                                    {val?.description || ''}
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="ratingPage_amount">
                                                <p className="ratingamount mb-0">
                                                  $
                                                  {val?.unitPrice *
                                                    (val?.qty || 1) || '$0.00'}
                                                </p>
                                              </div>
                                            </div>
                                          )}
                                      </>
                                    ))}
                                </div>
                              </Checkbox.Group>
                              {/* Old Quote Line Item Start */}
                              {quoteData?.booking?.BookingLineItems?.length >= 0 &&
                                quoteData?.booking?.ServiceCategory
                                  ?.isSalesQuote === 0 &&
                                quoteData?.lineItems?.length === 0 && (
                                  <>
                                    <div className="ratingPage_Services_inner d-flex justify-content-between mt-0">
                                      <h5 className="ratingsubhead mb-0">
                                        {t('text.requestViewDetails.lineItems')}
                                      </h5>
                                    </div>

                                    <div className="ratingPage_Services_maintenance">
                                      {(quoteData?.booking?.BookingLineItems).map(
                                        (val) => (
                                          <div
                                            key={val?.id}
                                            className="ratingPage_Services_inner d-flex justify-content-between "
                                          >
                                            <div className="d-flex align-items-sm-center">
                                              <ImageElement
                                                previewSource={
                                                  val?.imageThumbUrl ||
                                                  val?.imageUrl
                                                }
                                                className="me-2"
                                                alt="water-heater"
                                              />
                                              <div className="ratingPage_desc">
                                                <h5 className="ratingsubhead mb-1 d-flex align-items-center">
                                                  {val?.name || ''} ($
                                                  {val?.unitPrice} * {val?.qty})
                                                </h5>
                                                <p className="ratingamount mb-0 d-flex align-items-center">
                                                  {val?.description || ''}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="ratingPage_amount">
                                              <p className="ratingamount mb-0">
                                                $
                                                {val?.unitPrice *
                                                  (val?.qty || 1) || '$0.00'}
                                              </p>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </>
                                )}

                              {(quoteData?.booking?.bookingStatus ===
                                'accepted' ||
                                quoteData?.booking?.bookingStatus ===
                                'canceled') &&
                                quoteData?.booking?.ServiceCategory
                                  ?.isSalesQuote === 1 &&
                                (lineItemIdArray?.length || !editQuote) && (
                                  <div className="ratingPage_Services">
                                    <div className="d-flex justify-content-between ">
                                      <h5 className="ratingsubhead mb-0">
                                        {t('text.requestViewDetails.totalCostS')}
                                      </h5>
                                      <p className="ratingamount mb-0 ">
                                        {checkValidPrice(
                                          (parseFloat(lineItemTotalCost) || 0)
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                )}
                            </>
                          )}

                        {/* Block For Display Total Cost   */}
                        {quoteData?.booking?.ServiceCategory
                          ?.isSalesQuote === 1 ?
                          <div className='ratingPage_Services_inner d-flex justify-content-between align-items-center mt-2'>
                            <h5 className="ratingsubhead mb-0">
                              {t('text.ratingReview.cost')}
                            </h5>
                            <p className="ratingsubhead mb-0 ratingPage_amount pe-0">
                              {checkValidPrice(
                                (parseFloat(lineItemTotalCost) || 0) +
                                (parseFloat(quoteTotalCost) || 0) +
                                (parseFloat(equipmentItemTotalCost) || 0) +
                                (parseFloat(optionTotalAmount) || 0) +
                                (parseFloat(equOptionalTotalCost) || 0)
                              )}
                            </p>
                          </div>
                          :
                          <div className='ratingPage_Services_inner d-flex justify-content-between align-items-center mt-2'>
                            <h5 className="ratingsubhead mb-0">
                              {t('text.ratingReview.cost')}
                            </h5>
                            <p className="ratingsubhead mb-0 ratingPage_amount pe-0">
                              {quoteData?.booking?.totalCost
                                ? checkValidPrice(
                                  quoteData?.booking?.totalCost
                                )
                                : '0'}
                            </p>
                          </div>
                        }

                        {/* For Promo code discount Display  */}
                        {quoteData?.booking?.ServiceCategory
                          ?.isSalesQuote === 0 && ((quoteData?.booking?.promoCodeDiscount) && +(quoteData?.booking?.promoCodeDiscount) > 0) &&
                          <>
                            <div className="ratingPage_Services_inner d-flex justify-content-between align-items-center mt-2">
                              <h5 className="ratingsubhead mb-0">
                                {t('text.ratingReview.promoCodeDiscount')}{':'}
                                <Link
                                  to="#"
                                  className="ms-1"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setState({
                                      ...state,
                                      showModal: 'promoCodeInfo',
                                      modalHeading: t('text.ratingReview.promoCodeDiscount'),
                                    });
                                  }
                                  }
                                >
                                  <em className="gg-danger-circle ms-2 text-750" />
                                </Link>
                              </h5>
                              {/* <p className="ratingsubhead mb-0 ratingPage_amount pe-0">
                                {quoteData?.booking?.PromoCode?.discountType
                                  ? quoteData?.booking?.PromoCode
                                    ?.discountType === 'percentage'
                                    ? `${parseFloat(
                                      quoteData?.booking?.PromoCode
                                        ?.discountValue
                                    ).toFixed(2)}%`
                                    : checkValidPrice(
                                      quoteData?.booking?.PromoCode
                                        ?.discountValue *
                                      (quoteData?.booking?.quantity || 1)
                                    )
                                  : Number(
                                    quoteData?.booking?.customerPromoCodeDiscount
                                  ) ? (
                                    checkValidPrice(
                                      quoteData?.booking?.customerPromoCodeDiscount
                                    )
                                  ) : (
                                    '$0.00'
                                  )}
                              </p> */}
                              <p className="ratingsubhead mb-0 ratingPage_amount pe-0">
                                {quoteData?.booking?.promoCodeDiscount
                                  ? checkValidPrice(
                                    quoteData?.booking?.promoCodeDiscount
                                  )
                                  : '0'}
                              </p>
                            </div>
                          </>
                        }

                        {/* Edit quote end  */}
                        {/* sub total block */}
                        {quoteData?.booking?.ServiceCategory
                          ?.isSalesQuote === 1 ?
                          <div className="ratingPage_Services_inner d-flex justify-content-between ">
                            <h5 className="ratingsubhead mb-0">
                              {t('text.requestViewDetails.subTotalCost')}{' '}
                            </h5>

                            <p className="ratingamount mb-0 ">
                              {checkValidPrice(
                                (parseFloat(lineItemTotalCost) || 0) +
                                (parseFloat(quoteTotalCost) || 0) +
                                (parseFloat(equipmentItemTotalCost) || 0) +
                                (parseFloat(optionTotalAmount) || 0) +
                                (parseFloat(equOptionalTotalCost) || 0)
                              )}
                            </p>
                          </div>
                          :
                          <div className="ratingPage_Services_inner d-flex justify-content-between ">
                            <h5 className="ratingsubhead mb-0">
                              {t('text.requestViewDetails.subTotalCost')}{'  '}
                            </h5>

                            <p className="ratingamount mb-0 ">
                              {quoteData?.booking?.subTotal
                                ? checkValidPrice(
                                  quoteData?.booking?.subTotal
                                )
                                : '0'}
                            </p>
                          </div>
                        }

                        {/* For Membership Display */}
                        {quoteData?.booking?.membershipBenefitAvailable ?
                          <>
                            {(quoteData?.booking?.ServiceCategory?.isSalesQuote === 1
                              ? editQuote ?
                                <div className="ratingPage_Services_inner d-flex justify-content-between ">
                                  <h5 className="ratingsubhead mb-0">
                                    {t('text.common.memberShipSavings')}
                                    :
                                    <Link
                                      to="#"
                                      className="ms-1"
                                      onClick={() => {
                                        setState({
                                          ...state,
                                          showModal: 'memberShip',
                                          modalHeading: t(
                                            'text.common.memberShipSavings'
                                          ),
                                          modalKey: 'memberShip',
                                          modalData: {
                                            lineItemMembershipBenefit:
                                              quoteData?.booking
                                                ?.lineItemMembershipBenefit,
                                            membershipType:
                                              quoteData?.booking
                                                ?.membershipType,
                                            serviceMembershipBenefit:
                                              quoteData?.booking
                                                ?.membershipBenefit,
                                            membershipBenefitAvailable:
                                              quoteData?.booking
                                                ?.membershipBenefitAvailable,
                                          },
                                        });
                                      }}
                                    >
                                      <em className="gg-danger-circle ms-2 text-750" />
                                    </Link>
                                  </h5>
                                  <p className="ratingamount mb-0 ">
                                    {quoteData?.booking
                                      ?.membershipBenefitAvailable
                                      ? checkValidPrice(
                                        Number(
                                          quoteData?.booking
                                            ?.membershipBenefit
                                        ) +
                                        Number(
                                          quoteData?.booking
                                            ?.lineItemMembershipBenefit
                                        )
                                      )
                                      : '$0.00'}
                                  </p>
                                </div>
                                :
                                (!editQuote && ((quoteData?.lineItemMembershipAvailable && adminAddedCheckedlineItemsAmount > 0) || selectQuoteMembershipCost > 0)) && (
                                  <div className="ratingPage_Services_inner d-flex justify-content-between ">
                                    <h5 className="ratingsubhead mb-0">
                                      {t('text.common.memberShipSavings')}
                                      :
                                      <Link
                                        to="#"
                                        className="ms-1"
                                        onClick={() => {
                                          setState({
                                            ...state,
                                            showModal: 'memberShip',
                                            modalHeading: t(
                                              'text.common.memberShipSavings'
                                            ),
                                            modalKey: 'memberShip',
                                            modalData: {
                                              lineItemMembershipBenefit: adminAddedCheckedlineItemsAmount > 0 ?
                                                Number(
                                                  quoteData.lineItemMembershipAvailable ?
                                                    quoteData?.lineItemMembershipType === 'flat' ?
                                                      adminAddedCheckedlineItemsAmount > 0 ?
                                                        (quoteData.lineItemMembershipAmount > adminAddedCheckedlineItemsAmount) ?
                                                          adminAddedCheckedlineItemsAmount : quoteData.lineItemMembershipAmount :
                                                        0 :
                                                      ((adminAddedCheckedlineItemsAmount * quoteData.lineItemMembershipAmount) / 100).toFixed(2)
                                                    : 0
                                                ) : 0,
                                              membershipType:
                                                quoteData?.booking
                                                  ?.membershipType,
                                              serviceMembershipBenefit:
                                                selectQuoteMembershipCost,
                                              membershipBenefitAvailable:
                                                quoteData?.booking
                                                  ?.membershipBenefitAvailable,
                                            },
                                          });
                                        }}
                                      >
                                        <em className="gg-danger-circle ms-2 text-750" />
                                      </Link>
                                    </h5>
                                    <p className="ratingamount mb-0 ">
                                      {quoteData?.booking
                                        ?.membershipBenefitAvailable
                                        ? checkValidPrice(
                                          Number(
                                            selectQuoteMembershipCost ? selectQuoteMembershipCost : 0
                                          )
                                          +
                                          Number(
                                            quoteData.lineItemMembershipAvailable ?
                                              quoteData?.lineItemMembershipType === 'flat' ?
                                                adminAddedCheckedlineItemsAmount > 0 ?
                                                  (quoteData.lineItemMembershipAmount > adminAddedCheckedlineItemsAmount) ?
                                                    adminAddedCheckedlineItemsAmount : quoteData.lineItemMembershipAmount : 0 :
                                                ((adminAddedCheckedlineItemsAmount * quoteData.lineItemMembershipAmount) / 100).toFixed(2)
                                              : 0
                                          )
                                        )
                                        : '$0.00'}
                                    </p>
                                  </div>
                                )
                              :
                              <div className="ratingPage_Services_inner d-flex justify-content-between ">
                                <h5 className="ratingsubhead mb-0">
                                  {t('text.common.memberShipSavings')}
                                  :
                                  <Link
                                    to="#"
                                    className="ms-1"
                                    onClick={() => {
                                      setState({
                                        ...state,
                                        showModal: 'memberShip',
                                        modalHeading: t(
                                          'text.common.memberShipSavings'
                                        ),
                                        modalKey: 'memberShip',
                                        modalData: {
                                          lineItemMembershipBenefit:
                                            quoteData?.booking
                                              ?.lineItemMembershipBenefit,
                                          membershipType:
                                            quoteData?.booking
                                              ?.membershipType,
                                          serviceMembershipBenefit:
                                            quoteData?.booking
                                              ?.membershipBenefit,
                                          membershipBenefitAvailable:
                                            quoteData?.booking
                                              ?.membershipBenefitAvailable,
                                        },
                                      });
                                    }}
                                  >
                                    <em className="gg-danger-circle ms-2 text-750" />
                                  </Link>
                                </h5>
                                <p className="ratingamount mb-0 ">
                                  {quoteData?.booking
                                    ?.membershipBenefitAvailable
                                    ? checkValidPrice(
                                      Number(
                                        quoteData?.booking
                                          ?.membershipBenefit
                                      ) +
                                      Number(
                                        quoteData?.booking
                                          ?.lineItemMembershipBenefit
                                      )
                                    )
                                    : '$0.00'}
                                </p>
                              </div>
                            )}
                          </>
                          :
                          (!editQuote && ((quoteData?.lineItemMembershipAvailable && adminAddedCheckedlineItemsAmount > 0) || selectQuoteMembershipCost > 0)) && (
                            <div className="ratingPage_Services_inner d-flex justify-content-between ">
                              <h5 className="ratingsubhead mb-0">
                                {t('text.common.memberShipSavings')}
                                :
                                <Link
                                  to="#"
                                  className="ms-1"
                                  onClick={() => {
                                    setState({
                                      ...state,
                                      showModal: 'memberShip',
                                      modalHeading: t(
                                        'text.common.memberShipSavings'
                                      ),
                                      modalKey: 'memberShip',
                                      modalData: {
                                        lineItemMembershipBenefit:
                                          Number(
                                            quoteData.lineItemMembershipAvailable ?
                                              quoteData?.lineItemMembershipType === 'flat' ?
                                                adminAddedCheckedlineItemsAmount > 0 ?
                                                  (quoteData.lineItemMembershipAmount > adminAddedCheckedlineItemsAmount) ? adminAddedCheckedlineItemsAmount : quoteData.lineItemMembershipAmount : 0 :
                                                ((adminAddedCheckedlineItemsAmount * quoteData.lineItemMembershipAmount) / 100).toFixed(2)
                                              : 0
                                          ),
                                        membershipType:
                                          quoteData?.booking
                                            ?.membershipType ? quoteData?.booking
                                            ?.membershipType : quoteData?.lineItemMembershipAvailable ? 'line_item' : 'quote',
                                        serviceMembershipBenefit:
                                          selectQuoteMembershipCost,
                                        membershipBenefitAvailable: true
                                      },
                                    });
                                  }}
                                >
                                  <em className="gg-danger-circle ms-2 text-750" />
                                </Link>
                              </h5>
                              <p className="ratingamount mb-0 ">
                                {!editQuote && (quoteData?.lineItemMembershipAvailable || selectQuoteMembershipCost > 0)
                                  ? checkValidPrice(
                                    Number(
                                      selectQuoteMembershipCost ? selectQuoteMembershipCost : 0
                                    )
                                    +
                                    Number(
                                      quoteData.lineItemMembershipAvailable ?
                                        quoteData?.lineItemMembershipType === 'flat' ?
                                          adminAddedCheckedlineItemsAmount > 0 ?
                                            (quoteData.lineItemMembershipAmount > adminAddedCheckedlineItemsAmount) ?
                                              adminAddedCheckedlineItemsAmount : quoteData.lineItemMembershipAmount : 0 :
                                          ((adminAddedCheckedlineItemsAmount * quoteData.lineItemMembershipAmount) / 100).toFixed(2)
                                        : 0
                                    )
                                  )
                                  : '$0.00'}
                              </p>
                            </div>
                          )
                        }

                        {/* discount applied block */}
                        {quoteData?.booking?.ServiceCategory
                          ?.isSalesQuote === 1 && parseFloat(totalDiscount) > 0 ||
                          (parseFloat(spDiscount) > 0 &&
                            (parseFloat(quoteTotalCost) > 0 ||
                              parseFloat(lineItemTotalCost) > 0 ||
                              parseFloat(equipmentItemTotalCost) > 0 ||
                              parseFloat(optionTotalAmount) > 0 ||
                              parseFloat(equOptionalTotalCost) > 0)) ? (
                          <div className="ratingPage_Services_inner d-flex justify-content-between ">
                            <h5 className="ratingsubhead mb-0">
                              {t('text.requestViewDetails.discountApplied')}

                              {editQuote && (
                                <>
                                  :
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setState({
                                        ...state,
                                        modalHeading: 'Discount',
                                        showModal: 'requestDetails',
                                        modalKey: 'discountApplied',
                                        modalData: compareDiscountApplied(),
                                      });
                                    }}
                                  >
                                    <em className="gg-danger-circle ms-2 text-750" />
                                  </Link>
                                </>
                              )}
                            </h5>
                            <p className="ratingamount mb-0 ">
                              {checkValidPrice(
                                parseFloat(totalDiscount) +
                                parseFloat(spDiscount)
                              )}
                            </p>
                          </div>
                        ) :
                          (
                            quoteData?.bookingQuote?.discount > 0 ?
                              <div className="ratingPage_Services_inner d-flex justify-content-between mt-2 ">
                                <h5 className="ratingsubhead mb-0">
                                  {quoteData?.booking?.ServiceCategory
                                    ?.isSalesQuote === 1 ? t('text.requestViewDetails.discountApplied') : t('text.requestViewDetails.discountS')}
                                </h5>
                                <p className="ratingamount mb-0 ">
                                  {
                                    quoteData?.bookingQuote?.discount && (quoteData?.bookingQuote?.discount > 0) ?
                                      quoteData?.booking?.ServiceCategory?.isSalesQuote === 1 ?
                                        parseFloat(totalDiscount) > 0 ?
                                          checkValidPrice(
                                            quoteData?.bookingQuote?.discount
                                          )
                                          :
                                          checkValidPrice('0')
                                        :
                                        checkValidPrice(quoteData?.bookingQuote?.discount)
                                      : '0'
                                  }
                                </p>
                              </div>
                              : null)
                        }

                        {/* total block */}
                        {quoteData?.booking?.ServiceCategory?.isSalesQuote === 1
                          ? editQuote ?
                            < div className="ratingPage_Services_inner d-flex justify-content-between ">
                              <h5 className="ratingsubhead mb-0">
                                {t('text.requestViewDetails.total')}
                              </h5>
                              <p className="ratingamount mb-0 ">
                                {checkValidPrice(
                                  (parseFloat(lineItemTotalCost) || 0) +
                                  (parseFloat(quoteTotalCost) || 0) +
                                  (parseFloat(equipmentItemTotalCost) || 0) +
                                  (parseFloat(optionTotalAmount) || 0) +
                                  (parseFloat(equOptionalTotalCost) || 0) -
                                  ((parseFloat(totalDiscount) || 0) +
                                    (parseFloat(spDiscount) || 0) +
                                    (editQuote
                                      ? parseFloat(memberShipDiscount) || 0
                                      : 0))
                                )}
                              </p>
                            </div>
                            :
                            < div className="ratingPage_Services_inner d-flex justify-content-between ">
                              <h5 className="ratingsubhead mb-0">
                                {t('text.requestViewDetails.total')}
                              </h5>
                              <p className="ratingamount mb-0 ">
                                {checkValidPrice(
                                  (parseFloat(lineItemTotalCost) || 0) +
                                  (parseFloat(quoteTotalCost) || 0) +
                                  (parseFloat(equipmentItemTotalCost) || 0) +
                                  (parseFloat(optionTotalAmount) || 0) +
                                  (parseFloat(equOptionalTotalCost) || 0) -
                                  ((parseFloat(totalDiscount) || 0) +
                                    (parseFloat(spDiscount) || 0) +
                                    (parseFloat(Number(selectQuoteMembershipCost ? selectQuoteMembershipCost : 0) +
                                      Number(
                                        quoteData.lineItemMembershipAvailable ?
                                          quoteData?.lineItemMembershipType === 'flat' ?
                                            adminAddedCheckedlineItemsAmount > 0 ?
                                              (quoteData.lineItemMembershipAmount > adminAddedCheckedlineItemsAmount) ?
                                                adminAddedCheckedlineItemsAmount : quoteData.lineItemMembershipAmount : 0 :
                                            ((adminAddedCheckedlineItemsAmount * quoteData.lineItemMembershipAmount) / 100).toFixed(2)
                                          : 0
                                      )
                                    ) || 0)
                                  )
                                )}
                              </p>
                            </div>
                          :
                          < div className="ratingPage_Services_inner d-flex justify-content-between ">
                            <h5 className="ratingsubhead mb-0">
                              {t('text.requestViewDetails.total')}
                            </h5>
                            <p className="ratingamount mb-0 ">
                              {quoteData?.booking?.total
                                ? checkValidPrice(
                                  quoteData?.booking?.total
                                )
                                : '0'}
                            </p>
                          </div>
                        }

                        {/* Montyly Payment block */}
                        {quoteData?.financeOption?.paymentFactor > 0 &&
                          quoteTotalAmount >=
                          (parseFloat(
                            quoteData?.financeOption?.minimumLoanAmount
                          ) || 0) ? (
                          <div className="ratingPage_Services_inner d-flex justify-content-between ">
                            <h5 className="ratingsubhead mb-0">
                              {t(
                                'text.requestViewDetails.monthlyApprovedCredit'
                              )}

                              {editQuote && (
                                <>
                                  :
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setState({
                                        ...state,
                                        modalHeading:
                                          'Monthly Payment (with approved credit)',
                                        showModal: 'requestDetails',
                                        modalKey: 'monthlyPayment',
                                        modalData: quoteData?.financeOption,
                                      });
                                    }}
                                  >
                                    <em className="gg-danger-circle ms-2 text-750" />
                                  </Link>
                                </>
                              )}
                            </h5>

                            <p className="ratingamount mb-0 ">
                              {editQuote
                                ? checkValidPrice(
                                  state?.details?.monthlyPayment
                                )
                                : checkValidPrice(
                                  (((parseFloat(lineItemTotalCost) || 0) +
                                    (parseFloat(quoteTotalCost) || 0) +
                                    (parseFloat(equipmentItemTotalCost) ||
                                      0) +
                                    (parseFloat(optionTotalAmount) || 0) +
                                    (parseFloat(equOptionalTotalCost) ||
                                      0) -
                                    ((parseFloat(totalDiscount) || 0) +
                                      (parseFloat(spDiscount) || 0) +
                                      (parseFloat(Number(selectQuoteMembershipCost ? selectQuoteMembershipCost : 0) +
                                        Number(
                                          quoteData.lineItemMembershipAvailable ?
                                            quoteData?.lineItemMembershipType === 'flat' ?
                                              adminAddedCheckedlineItemsAmount > 0 ?
                                                (quoteData.lineItemMembershipAmount > adminAddedCheckedlineItemsAmount) ?
                                                  adminAddedCheckedlineItemsAmount : quoteData.lineItemMembershipAmount : 0 :
                                              ((adminAddedCheckedlineItemsAmount * quoteData.lineItemMembershipAmount) / 100).toFixed(2)
                                            : 0
                                        )
                                      ) || 0))) /
                                    100) *
                                  (quoteData?.financeOption
                                    ?.paymentFactor || 1)
                                )}
                            </p>
                          </div>
                        ) : null}

                        {/* rebate code  */}
                        {quoteData?.booking?.ServiceCategory?.isSalesQuote ===
                          1 &&
                          !editQuote && (
                            <>
                              {Object.keys(rebateObj)?.length &&
                                Object.keys(rebateObj).filter(
                                  (item) => rebateObj[item]?.length
                                )?.length ? (
                                <>
                                  <h5 className="ratingsubhead mb-0 mt-3 ratingPage_desc">
                                    {t('text.requestViewDetails.rebates')}
                                    <Link
                                      to="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setState({
                                          ...state,
                                          modalHeading: 'Rebates',
                                          showModal: 'requestDetails',
                                          modalKey: 'rebates',
                                        });
                                      }}
                                    >
                                      <em className="gg-danger-circle ms-2 text-750" />
                                    </Link>{' '}
                                    <br />
                                  </h5>

                                  {Object.keys(rebateObj).map((item) =>
                                    rebateObj[item]?.map(
                                      (rebate, index) =>
                                        quoteSelectID?.includes(
                                          Number(item)
                                        ) && (
                                          <div
                                            key={index}
                                            className="ratingPage_Services_inner d-flex justify-content-between "
                                          >
                                            <span className="ratingPage_desc">
                                              {rebate?.title}
                                            </span>
                                            <p className="ratingamount mb-0 ">
                                              {rebate?.cost}
                                            </p>
                                          </div>
                                        )
                                    )
                                  )}
                                </>
                              ) : null}
                            </>
                          )}
                      </div>
                    </div>

                    <>
                      {quoteData?.booking?.bookingStatus === 'accepted' &&
                        quoteData?.bookingQuote?.customerStatus ===
                        'accepted' &&
                        quoteData?.bookingQuote?.customerSignatureUrl && (
                          <div className="ratingPage_Services_image">
                            <ImageElement
                              previewSource={
                                quoteData?.bookingQuote?.customerSignatureUrl
                              }
                              alt="signature"
                            />
                          </div>
                        )}

                      {quoteData?.booking?.bookingStatus === 'accepted' &&
                        quoteData?.bookingQuote?.customerStatus !==
                        'accepted' &&
                        quoteData?.bookingQuote?.providerStatus !==
                        'accepted' &&
                        editQuote && (
                          <>
                            <div className="ratingPage_Services_image">
                              <SignatureCanvas
                                ref={(ref) => {
                                  sigPad.current = ref;
                                }}
                                onEnd={(e) => [
                                  setState({
                                    ...state,
                                    isSigned: true,
                                  }),
                                  handleSignature(),
                                ]}
                                canvasProps={{
                                  width: 600,
                                  height: 115,
                                  className: 'sigCanvas',
                                }}
                              />
                            </div>
                            <p className="ratingamount text-center">
                              {t('text.requestViewDetails.signForApproval')}
                            </p>
                          </>
                        )}
                      {(quoteData?.booking?.ServiceCategory?.isSalesQuote ===
                        0 ||
                        editQuote) && (
                          <div className="mt-3">
                            <h4 className="ratingheading">
                              {t('text.requestViewDetails.pleaseNote')} :
                            </h4>
                            <div className="note">
                              <p>
                                {data?.note}
                                {t('text.requestViewDetails.note')}
                              </p>
                            </div>
                            {quoteData?.booking?.bookingStatus === 'accepted' &&
                              quoteData?.bookingQuote?.customerStatus ===
                              'pending' &&
                              quoteData?.bookingQuote?.providerStatus ===
                              'pending' && (
                                <p className="quote text-center">
                                  {quoteData?.bookingQuote?.quoteExpiredAt > 0 ? (
                                    <>
                                      {t('text.requestViewDetails.quoteExpire')}{' '}
                                      <span>
                                        {quoteData?.bookingQuote?.quoteExpiredAt}{' '}
                                        {t('text.common.days')}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      {t('text.requestViewDetails.quoteExpire')}
                                      <br />
                                      {expireMessage()}
                                    </>
                                  )}
                                </p>
                              )}
                          </div>
                        )}
                    </>
                    <div className="d-flex requestBtns justify-content-end mt-sm-4 mt-3 flex-wrap">
                      {editQuote &&
                        quoteData?.booking?.bookingStatus === 'accepted' &&
                        quoteData?.bookingQuote?.customerStatus !==
                        'accepted' &&
                        quoteData?.bookingQuote?.providerStatus !==
                        'accepted' && (
                          <CommonButton
                            type="button"
                            className="font-sm btn btn-primary btn-lg me-2 min-170"
                            onClick={() => {
                              if (
                                quoteData?.bookingQuote?.submitQuote ===
                                'expired'
                              ) {
                                modalNotification({
                                  type: 'error',
                                  message: expireMessage(),
                                });
                              } else if (isEmpty()) {
                                modalNotification({
                                  type: 'error',
                                  message: 'Please Sign Here',
                                });
                              } else {
                                setState({
                                  ...state,
                                  modalHeading: 'Approve Booking',
                                  showModal: 'approve',
                                });
                              }
                            }}
                          >
                            {t('text.requestViewDetails.approve')}
                          </CommonButton>
                        )}

                      {quoteData?.booking?.bookingStatus === 'accepted' &&
                        quoteData?.bookingQuote?.customerStatus !==
                        'accepted' &&
                        quoteData?.bookingQuote?.providerStatus !==
                        'accepted' && (
                          <CommonButton
                            onClick={() => {
                              if (
                                quoteData?.bookingQuote?.submitQuote ===
                                'expired'
                              ) {
                                modalNotification({
                                  type: 'error',
                                  message: expireMessage(),
                                });
                              } else {
                                setState({
                                  ...state,
                                  modalHeading: 'Decline Booking',
                                  showModal: 'decline',
                                });
                              }
                            }}
                            type="button"
                            className="font-sm btn btn-light btn-lg me-2 min-170"
                          >
                            {t('text.requestViewDetails.decline')}
                          </CommonButton>
                        )}

                      {quoteData?.booking?.ServiceCategory?.isSalesQuote ===
                        1 &&
                        !editQuote &&
                        quoteData?.bookingQuote?.customerStatus !==
                        'accepted' &&
                        quoteData?.booking?.bookingStatus !== 'canceled' &&
                        quoteData?.booking?.bookingStatus !== 'declined' &&
                        quoteData?.bookingQuote?.providerStatus !==
                        'accepted' && (
                          <CommonButton
                            type="button"
                            className="font-sm btn btn-primary btn-lg min-170"
                            onClick={() => {
                              if (
                                quoteData?.bookingQuote?.submitQuote ===
                                'expired'
                              ) {
                                modalNotification({
                                  type: 'error',
                                  message: expireMessage(),
                                });
                              } else {
                                handleNext(true);
                              }
                            }}
                          >
                            {t('text.common.next')}
                          </CommonButton>
                        )}

                      {quoteData?.booking?.ServiceCategory?.isSalesQuote ===
                        1 &&
                        editQuote &&
                        quoteData?.bookingQuote?.customerStatus !==
                        'accepted' &&
                        quoteData?.bookingQuote?.customerStatus !==
                        'declined' &&
                        quoteData?.bookingQuote?.providerStatus !==
                        'accepted' &&
                        quoteData?.bookingQuote?.providerStatus !==
                        'declined' &&
                        details?.bookingStatus === 'accepted' && (
                          <CommonButton
                            type="button"
                            className="font-sm btn btn-primary btn-lg min-170"
                            onClick={() => handleNext('edit')}
                          >
                            {t('text.common.editQuote')}
                          </CommonButton>
                        )}

                      {(quoteData?.booking?.bookingStatus === 'declined' ||
                        quoteData?.booking?.bookingStatus === 'canceled') && (
                          <CommonButton
                            type="button"
                            className=" btn-lg me-2 min-170"
                            disabled={true}
                          >
                            {t('text.common.declined')}
                          </CommonButton>
                        )}

                      {(quoteData?.bookingQuote?.customerStatus ===
                        'accepted' ||
                        quoteData?.bookingQuote?.providerStatus ===
                        'accepted') &&
                        quoteData?.booking?.bookingStatus === 'accepted' && (
                          <CommonButton
                            type="button"
                            variant="primary"
                            className=" btn-lg me-2 min-170"
                            disabled={true}
                          >
                            {quoteData?.bookingQuote?.providerStatus ===
                              'accepted' &&
                              quoteData?.bookingQuote?.customerStatus !==
                              'accepted'
                              ? t('text.common.approvedTechnician')
                              : t('text.common.approved')}
                          </CommonButton>
                        )}
                    </div>
                  </div>
                </>
              ) : !state?.isLoading && !Object.keys(details)?.length ? (
                <NoDataFound
                  source="/no-booking.svg"
                  text={t('text.common.noRequestDetails')}
                  extraClass="noDataFoundWrapper w-100"
                  isCustomer={'/assets/images/admin/no-booking.svg'}
                />
              ) : (
                <GlobalLoader />
              )}
            </Container>
            {!state?.isLoading && Object.keys(state?.details)?.length > 0 && (
              <div className="d-flex justify-content-center mt-sm-3 mt-3">
                <CommonButton
                  type="button"
                  className="font-sm btn btn-info btn-lg min-170 justify-content-center align-center"
                  onClick={() => {
                    handlePrint('quoteWrapper', userType);
                  }}
                >
                  {t('text.common.print')}
                </CommonButton>
              </div>
            )}
          </div>
        </div>
      </div >

      {state?.showModal && (
        <ModalWrapper
          heading={state?.modalHeading}
          show={state?.showModal}
          content={modalObj[state?.showModal]}
          onCancel={handleClose}
          extraClassName={state?.showModal === 'decline' && 'modal-lg'}
          extraTitleClassName={`align-items-start ${state?.showModal !== 'memberShip' ? 'text-uppercase' : ''
            }modal-title h4`}
          size={state?.showModal === 'requestDetails' && 'lg'}
        ></ModalWrapper>
      )
      }
    </>
  );
}

export default RequestDetails;
