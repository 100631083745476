import i18next from 'i18next';
import {
  CommonButton,
  getColumnSelectSearchProps,
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import {
  ButtonFormatter,
  ButtonTextFormatter,
  cancelFormatter,
  checkValidData,
  checkValidPrice,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { accessRoute } from '../../routes/ScheduledBooking';
import { accessRoute as dashboardAccessRoutes } from '../../routes/Dashboard';
import {
  capitalizeFirstLetter,
  minuteIntoHHMMSS,
  readMoreTextShow,
  showDateInBrowser,
  textFormatter,
} from '../../utils';
import { firstLetterCaps, getFullName } from '../../utils/text.util';
import { CustomersService } from '../Customers';
import { getScheduledStatusData } from '../index.service';
import { providerListService } from '../TeamSetting/provider.service';
import { generatePath } from 'react-router-dom';
import { accessRoute as ManualBookingRoute } from '../../routes/ManualBooking';
import { accessRoute as commonAccessRoutes } from '../../routes/CommonRoutes';

export const scheduleBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.scheduleBooking.scheduledBookings'),
    },
  ];
};
export const scheduleBookingLogBreadcrumb = () => {
  return [
    {
      path: dashboardAccessRoutes.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: accessRoute.SCHEDULE_MANAGEMENT.path,
      name: i18next.t('text.scheduleBooking.scheduledBookings'),
    },
    {
      path: '#',
      name: i18next.t('text.serviceManagement.bookingLog'),
    },
  ];
};
export const scheduledBookingsInvoiceServiceBreadcrumb = () => {
  return [
    {
      path: dashboardAccessRoutes.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: accessRoute.SCHEDULE_MANAGEMENT.path,
      name: i18next.t('text.scheduleBooking.scheduledBookings'),
    },
    {
      path: '#',
      name: i18next.t('text.report.invoiceHeading'),
    },
  ];
};
const getUserListData = () => {
  return [
    {
      name: 'abhishekYadav',
      value: 'Abhishek Yadav',
    },
    {
      name: 'aditiTest',
      value: 'Aditi Test',
    },
  ];
};

const getProviderListData = () => {
  return [
    {
      name: 'abhishekYadav',
      value: 'Abhishek Yadav',
    },
    {
      name: 'aditiTest',
      value: 'Aditi Test',
    },
  ];
};

const TextFormatter = (name, heading) => {
  return (
    <>
      {name}
      <br />({i18next.t('text.manageServices.serviceType')}: {heading})
    </>
  );
};

const options = (cell, row) => {
  let ele = {};
  ele = {
    path: `/admin/payment-report/invoice`,
    text: cell,
  };
  return ele;
};

const NavigatePath = (cell, row) => {
  let ele = {};
  ele = {
    path: accessRoute.BOOKING_INVOICE.path,
    text: cell,
  };
  return ele;
};

export const scheduledBookingsTableColumn = ({
  viewInVoicePage,
  BookingCancelModal,
  handleShow,
  onFilterChange,
  onSort,
  filter = {},
  page,
  sizePerPage,
  handleSendQuote,
  sendQuoteBookingId,
  isUserCanEdit,
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'bookingNo',
      title: i18next.t('text.serviceManagement.booking'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('bookingNo', order),
      render: (cell, row) =>
        ButtonTextFormatter(
          generatePath(accessRoute.BOOKING_VIEW.path, {
            id: row?.id,
            viewQuote:
              row?.ServiceCategory?.servicePrice === 'free'
                ? 'isViewQuote'
                : 'isNoViewQuote',
          }),
          cell,
          false,
          {},
          '_blank'
        ),
      ...getInputSearchFilter({
        dataIndex: 'bookingNo',
        name: 'bookingNo',
        placeholder: 'booking number',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'customer',
      filterDataIndex: ['customer'],
      title: i18next.t('text.common.user'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('customerUserId', order),
      export: true,
      exportName: i18next.t('text.common.user'),
      exportKey: 'Customer',
      exportFormatter: (cell, row) =>
        getFullName(
          row?.Customer?.firstName,
          row?.Customer?.lastName,
          '',
          row?.Customer?.CustomerMembership?.MembershipPlan?.name
          // add customer plan with amount temporary commented with temporary key
          // 101,
          // 'percentage'
        ),
      render: (_, row) =>
        getFullName(
          row?.Customer?.firstName,
          row?.Customer?.lastName,
          '',
          row?.Customer?.CustomerMembership?.MembershipPlan?.name
          // add customer plan with amount temporary commented with temporary key
          // 101,
          // 'percentage'
        ),
      ...getMultiSelectSearchFilter({
        dataIndex: 'customer',
        onFilterChange,
        name: 'customer name',
        list: [],
        mode: 'multiple',
        // scope: 'all',
        searchOrNot: true,
        listKeys: { nameKey: ['firstName', 'lastName'], nameId: 'id' },
        listService: CustomersService?.CustomersListService,
        filter,
        extraQuary: { isFilter: 1 },
      }),
    },
    {
      dataIndex: 'bookingScheduleDate',
      title: i18next.t('text.scheduleBooking.bookingDate'),
      headerClasses: 'sorting',
      sorter: true,
      exportName: 'Date',
      onSort: (field, order) => onSort('bookingDate', order),
      exportFormatter: (cell, row) =>
        showDateInBrowser(row?.bookingScheduleDate),
      render: (cell, row) => showDateInBrowser(row?.bookingScheduleDate),
    },
    {
      dataIndex: 'jobLocationAddress',
      title: i18next.t('text.scheduleBooking.expectedSourceLocation'),
      headerClasses: 'sorting',
      onSort: (field, order) => onSort('jobLocationAddress', order),
      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(cell),
          i18next.t('text.common.description'),
          25
        ),
    },
    {
      dataIndex: 'bookingNote',
      title: i18next.t('text.scheduleBooking.specialInstruction'),
      headerClasses: 'sorting',
      export: false,
      onSort: (field, order) =>
        onSort('specialInstructions', order?.bookingNote),
      render: (cell, row) =>
        readMoreTextShow(
          checkValidData(cell),
          i18next.t('text.common.description'),
          25
        ),
    },
    {
      dataIndex: 'provider',
      title: i18next.t('text.common.provider'),
      headerClasses: 'sorting',
      sorter: true,
      exportKey: 'Provider',
      exportFormatter: (cell, row, index) =>
        getFullName(row?.Provider?.firstName, row?.Provider?.lastName),
      onSort: (field, order) => onSort('provider', order),
      render: (_, row) => {
        return (
          <>
            {row?.bookingStatus === 'pending' && row?.isNacJob ? (
              <>
                {ButtonFormatter(
                  generatePath(ManualBookingRoute.ADD_BOOKING.path, {
                    id: row?.id,
                  }),
                  'Assign Edit Provider',
                  '_blank',
                  {
                    key: 'bookingDetails',
                    value: JSON.stringify({ ...row, type: 'assign' }),
                  },
                  false
                )}
              </>
            ) : (
              ''
            )}
            {TextFormatter(
              getFullName(row?.Provider?.firstName, row?.Provider?.lastName),
              row?.ServiceCategory?.name
            )}
          </>
        );
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'provider',
        onFilterChange,
        name: 'provider name',
        list: [],
        mode: 'multiple',
        listKeys: { nameKey: ['firstName', 'lastName'], nameId: 'id' },
        // scope: 'activeUser',
        listService: providerListService,
        searchOrNot: true,
        filter,
      }),
    },
    {
      dataIndex: 'memberShipPlan',
      // filterDataIndex: ['customerPaid'],
      title: i18next.t('text.common.memberPlan'),
      headerClasses: 'sorting',
      // sorter: true,
      exportName: i18next.t('text.common.memberPlan'),
      // export: true,
      exportFormatter: (cell, row) =>
        checkValidData(row?.CustomerMembership?.MembershipPlan?.name),
      render: (cell, row) =>
        checkValidData(row?.CustomerMembership?.MembershipPlan?.name),
      // ...getPriceFilter({
      //   dataIndex: 'totalFees',
      //   onFilterChange,
      //   fields: totalFeesFields,
      //   filter,
      //   dataIndexKey: 'filterColumn',
      // }),
      // ...getColumnSelectAmountProps(),
      width: 150,
    },
    {
      dataIndex: 'jobDetails',
      title: i18next.t('text.scheduleBooking.jobDetails'),
      headerClasses: 'sorting',
      render: (cell, row) => {
        if (row?.bookingStatus === 'completed') {
          return ButtonFormatter(
            generatePath(commonAccessRoutes.INVOICE.path, {
              viewInvoice: 'invoice',
              routePath: 'scheduled-bookings',
              id: row?.id,
            }),
            'View',
            '_blank'
          );
        } else return '-';
      },
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.common.status'),
      headerClasses: 'sorting',
      onSort: (field, order) => onSort('status', order),
      sorter: true,
      exportKey: 'bookingStatus',
      exportFormatter: (cell, row) => {
        if (
          row?.NcaBookingRequests &&
          row?.NcaBookingRequests?.length &&
          row?.NcaBookingRequests[0]?.isWillCallList &&
          row?.bookingStatus === 'pending'
        ) {
          return <>Will Call List</>;
        } else if (row?.bookingStatus === 'pending' && row?.isNacJob) {
          return <>NAC</>;
        } else {
          return row?.bookingStatus !== 'declined' &&
            row?.bookingStatus !== 'canceled'
            ? capitalizeFirstLetter(row?.bookingStatus)
            : !row?.cancelUser?.Role?.name
            ? row?.bookingStatus === 'canceled'
              ? 'Canceled By Provider'
              : 'Declined'
            : row?.bookingStatus === 'canceled'
            ? row?.cancelUser?.Role?.name === 'provider'
              ? 'Canceled By Provider'
              : row?.cancelUser?.Role?.name === 'customer'
              ? 'Canceled By User'
              : 'Canceled By Admin'
            : '	Declined';
        }
      },

      render: (cell, row) => {
        if (
          row?.NcaBookingRequests &&
          row?.NcaBookingRequests?.length &&
          row?.NcaBookingRequests[0]?.isWillCallList &&
          row?.bookingStatus === 'pending'
        ) {
          return <>Will Call List</>;
        } else if (row?.bookingStatus === 'pending' && row?.isNacJob) {
          return <>NAC</>;
        } else {
          return (
            <>
              {cancelFormatter({
                status: row?.bookingStatus,
                BookingCancelModal,
                handleShow,
                providerStatus: row?.cancelUser?.Role?.name,
                cancelReason: row?.cancelReason,
                id: row?.id,
                isUserCanEdit: isUserCanEdit,
              })}
            </>
          );
        }
      },

      ...getColumnSelectSearchProps({
        dataIndex: 'status',
        onFilterChange,
        name: 'status',
        mode: false,
        filter,
        list: getScheduledStatusData(),
      }),
    },
    {
      dataIndex: 'sendQuote',
      title: i18next.t('text.scheduleBooking.sendQuote'),
      headerClasses: 'sorting',
      render: (_, row) => {
        if (
          row?.BookingQuote?.customerStatus === 'pending' &&
          row?.BookingQuote?.providerStatus === 'pending' &&
          row?.bookingStatus !== 'accepted' &&
          row?.bookingStatus !== 'completed' &&
          row?.bookingStatus !== 'declined' &&
          row?.bookingStatus !== 'canceled' &&
          row?.isPaid === 0
        ) {
          return (
            <CommonButton
              disabled={sendQuoteBookingId}
              loading={row?.id === sendQuoteBookingId}
              onClick={() => handleSendQuote(row?.id)}
              className="btn btn-primary btn-sm"
            >
              {i18next.t('text.scheduleBooking.sendQuote')}&nbsp;
              {checkValidPrice(row?.BookingQuote?.quoteAmount)}
            </CommonButton>
          );
        } else return '-';
      },
    },
    {
      dataIndex: 'BookingDetail.serviceDuration',
      title: i18next.t('text.scheduleBooking.jobCompletion'),
      headerClasses: 'sorting',
      export: true,
      exportName: i18next.t('text.scheduleBooking.jobCompletion'),
      exportFormatter: (cell, row) =>
        checkValidData(minuteIntoHHMMSS(row?.BookingDetail?.serviceDuration)),
      render: (cell, row) =>
        checkValidData(
          minuteIntoHHMMSS(
            row?.BookingDetail?.serviceDuration > 0
              ? row?.BookingDetail?.serviceDuration
              : ''
          )
        ),
    },
  ];
};

export const daysOptions = [
  {
    id: 0,
    name: 'Sunday',
  },
  {
    id: 1,
    name: 'Monday',
  },
  {
    id: 2,
    name: 'Tuesday',
  },
  {
    id: 3,
    name: 'Wednesday',
  },
  {
    id: 4,
    name: 'Thursday',
  },
  {
    id: 5,
    name: 'Friday',
  },
  {
    id: 6,
    name: 'Saturday',
  },
];
