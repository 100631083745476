import React, { useEffect, useState } from 'react';
import {
  AddEditProductForm,
  GlobalLoader,
  PageContainerWrapper,
} from '../../../../components';
import {
  Columns,
  addProductService,
  editProductService,
  couponListService,
  getAllCategoryListService,
  getAllVariantListService,
  getProductDetailsService,
  getProductVariantAttributeService,
} from '../../../../services/index.service';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { backToPage, logger, modalNotification } from '../../../../utils';
import { useNavigate } from 'react-router-dom';
import { accessRoute } from '../../../../routes/ShoppingCart';

export default function AddEditProduct() {
  const breadcrumb = Columns.ProductManagementEditBreadcrumb();
  const { t } = useTranslation();
  const [variantArray, setVariantArray] = useState([]);
  const [selectedPrimaryVariant, setSelectedPrimaryVariant] = useState(null);
  const [selectedExtraVariant, setSelectedExtraVariant] = useState([]);
  const [variants, setVariants] = useState([]);
  const [variantLoader, setVariantLoader] = useState(false);
  const [attributeLoader, setAttributeLoader] = useState(false);
  const [attribute, setAttribute] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryLoader, setCategoryLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [extraAttributeLoader, setExtraAttributeLoader] = useState(false);
  const [couponCodeList, setCouponCodeLis] = useState([]);
  const navigate = useNavigate();
  const [handleCouponCode, setHandleCouponCode] = useState(false);
  const [handleVariantIndexLoader, setVariantIndexLoader] = useState();
  const [productLoader, setProductLoader] = useState(false);
  const [attributeSelectedValues, setAttributeSelectedValues] = useState({});

  const { id } = useParams();

  let identifier = Date.now();
  // Creating intial single varint data
  useEffect(() => {
    setVariantArray(() => [
      {
        extraVariantIdFieldName: `variantId${identifier}`,
        extraAttributeIdFieldName: `attributeId${identifier}`,
        [`variantId${identifier}`]: [],
        [`attributeId${identifier}`]: [],
        identifier,
      },
    ]);
  }, []);

  // Submit data of product form
  const onSubmit = async (value) => {
    try {
      setLoading(true);
      let productVariantArr = [];
      if (variantArray.length > 0) {
        variantArray.map((item) => {
          if (
            value[`variantId${item.identifier}`] &&
            value[`attributeId${item.identifier}`]
          ) {
            productVariantArr.push({
              variantId: value[`variantId${item.identifier}`],
              productVariantAttributeId: value[`attributeId${item.identifier}`],
            });
          }
        });
      }
      if (productVariantArr.length > 0) {
        value.productVariant = productVariantArr;
      }
      const transformedArray = productVariantArr.flatMap((item) =>
        item.productVariantAttributeId.map((attributeId) => ({
          variantId: item.variantId,
          productVariantAttributeId: attributeId,
        }))
      );
      let productImagesArray = [];
      if (value.productImage1) {
        productImagesArray.push({
          basePath: value.productImage1,
          imageNo: 1,
        });
      }
      if (value.productImage2) {
        productImagesArray.push({
          basePath: value.productImage2,
          imageNo: 2,
        });
      }
      if (value.productImage3) {
        productImagesArray.push({
          basePath: value.productImage3,
          imageNo: 3,
        });
      }

      let bodyData = {
        productName: value.productTitle,
        price: value.regularPrice,
        categoryId: value.productCategory,
        coupon: value.couponCode === 'true' ? true : false,
        description: value.description,
        productSku: value.productSKU,
        productVariant: transformedArray,
        productImages: productImagesArray,
      };
      // if (value.couponCode === 'true') {
      //   bodyData = {
      //     ...bodyData,
      //     couponCodeIds: value.couponCodeIds,
      //   };
      // } else {
      //   bodyData = {
      //     ...bodyData,
      //     couponCodeIds: ['0'],
      //   };
      // }
      if (value.sellingPrice) {
        bodyData = {
          ...bodyData,
          sellingPrice: value.sellingPrice,
        };
      }
      let response;
      if (id) {
        response = await editProductService(parseInt(id), bodyData);
      } else {
        response = await addProductService(bodyData);
      }
      if (response.success) {
        modalNotification({
          type: 'success',
          message: response.message,
        });
        setLoading(false);
        navigate(accessRoute.PRODUCTS_LIST.path);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };
  // variant list
  const getVariants = async () => {
    try {
      setProductLoader(true);
      setVariantLoader(true);
      const queryParams = {
        scope: 'activeProductVariant',
      };
      const response = await getAllVariantListService({ queryParams });
      if (response.success) {
        let data = response.data?.rows;
        data?.map((item) => {
          item.disabled = false;
        });
        setVariants([...data]);
        setVariantArray((prevState) => [
          {
            ...prevState[0],
            [`variantId${identifier}`]: [
              ...prevState[0][`variantId${identifier}`],
              ...data,
            ],
          },
        ]);
      }
      setVariantLoader(false);
    } catch (error) {
      console.log(error);
    }
    setVariantLoader(false);
    setProductLoader(false);
  };
  // categroy list
  const getCategories = async () => {
    try {
      setCategoryLoader(true);
      let queryParams = {
        scope: id ? 'allProductCategory' : 'activeProductCategory',
        productId: id,
      };
      const response = await getAllCategoryListService({ queryParams });
      if (response.success) {
        setCategories([...response.data.rows]);
        setCategoryLoader(false);
      }
    } catch (error) {
      console.log(error);
      setCategoryLoader(false);
    }
  };
  // coupon list
  const getCouponList = async () => {
    try {
      // setCategoryLoader(true);
      let queryParams = {
        scope: 'activeCouponCode',
        productId: id,
      };
      const response = await couponListService({ queryParams });
      if (response.success) {
        setCouponCodeLis([...response.data.rows]);
        // setCategoryLoader(false);
      }
    } catch (error) {
      console.log(error);
      // setCategoryLoader(false);
    }
  };

  useEffect(() => {
    getVariants();
    getCategories();
    getCouponList();
  }, []);

  const fetchVariantsAttributes = async (id) => {
    try {
      const response = await getProductVariantAttributeService(id);
      if (response.success) {
        let tempArr = response.data.productVariantAttributeDetail;
        tempArr.map((item) => {
          item.name = item.attributeNames;
        });
        setAttribute([...tempArr]);
      }
      setAttributeLoader(false);
    } catch (error) {
      logger(error);
    }
  };

  const getProductDetails = async (id) => {
    try {
      setVariantLoader(true);
      setAttributeLoader(true);
      const response = await getProductDetailsService(id);
      const { success, data } = response;
      if (success) {
        let initialVariants;
        if (data?.AdminProductVariantDetails) {
          let temp = data?.AdminProductVariantDetails?.map((val) => {
            return val.ProductVariant?.id;
          });
          const queryParams = {
            scope: 'activeProductVariant',
            productId: id,
          };
          const res = await getAllVariantListService({ queryParams });
          if (res.success) {
            initialVariants = res?.data?.rows;
            initialVariants?.map((item) => {
              item.disabled = false;
            });

            if (temp.length > 0) {
              temp.forEach((val) => {
                const index = initialVariants.findIndex((i) => i.id === val);
                if (index !== -1) {
                  initialVariants[index].disabled = true;
                } else {
                  console.warn(`No matching id found for ${val}`);
                }
              });
            }
            setVariants([...initialVariants]);
          }
        }
        let getCategoryIdList =
          data &&
          data?.categoryDetails.map((itemCategory) => {
            return itemCategory?.categoryId;
          });
        if (data?.coupon) {
          setHandleCouponCode(true);
        }
        let prdImage1 = data?.productImageDetail.find(
          (item) => item.imageNo === 1
        );
        let prdImage2 = data?.productImageDetail.find(
          (item) => item.imageNo === 2
        );
        let prdImage3 = data?.productImageDetail.find(
          (item) => item.imageNo === 3
        );
        let tempInitial = {
          productImage1: prdImage1?.productImage,
          productImage2: prdImage2?.productImage,
          productImage3: prdImage3?.productImage,
          productTitle: data?.productName,
          productCategory: getCategoryIdList || undefined,
          productSKU: data?.productSku,
          regularPrice: data?.price,
          sellingPrice: data?.sellingPrice === '0.00' ? '' : data?.sellingPrice,
          description: data?.description,
          couponCode: `${data?.coupon}`,
          viewImage1: data?.productImageDetail
            ? [prdImage1?.productImageUrl]
            : undefined,
          // couponCodeIds:
          //   data?.CouponDetails[0]?.couponId === 0
          //     ? []
          //     : data?.CouponDetails?.map((item) => {
          //         return item?.couponId;
          //       }),

          viewImage2: data?.productImageDetail
            ? [prdImage2?.productImageUrl]
            : undefined,
          viewImage3: data?.productImageDetail
            ? [prdImage3?.productImageUrl]
            : undefined,
        };

        const variantResponseArray = data?.AdminProductVariantDetails || [];
        let tempVariantArray = variantResponseArray?.map((item, index) => {
          identifier = identifier + index;
          const variantAttributesList =
            item?.ProductVariant?.productVariantAttributeDetail || [];
          let attributeArr = [];
          if (variantAttributesList?.length > 0) {
            variantAttributesList?.map((value) => {
              value.name = value.attributeNames;
              delete value.attributeNames;
              attributeArr.push(value);
            });
          }
          const selectedIds =
            item?.ProductVariant?.productVariantAttributeDetail
              .filter((item) => item.isSelected === 1)
              .map((item) => item.id);
          tempInitial[`variantId${identifier}`] = item?.variantId;
          tempInitial[`attributeId${identifier}`] = selectedIds;
          let finalValidVariants = attributeArr?.filter((item) => {
            return item.status === 'active' || selectedIds.includes(item.id)
          });
          let finalValue = {
            extraVariantIdFieldName: `variantId${identifier}`,
            extraAttributeIdFieldName: `attributeId${identifier}`,
            [`variantId${identifier}`]: [...initialVariants],
            [`attributeId${identifier}`]: [...finalValidVariants],
            identifier,
          };
          return finalValue;
        });
        setFormData({ ...tempInitial });
        if (tempVariantArray?.length > 0) {
          let tempSelectedArr = tempVariantArray?.map((val) => {
            return (
              val[`attributeId${val.identifier}`][0] &&
              val[`attributeId${val.identifier}`][0].variantId
            );
          });
          setAttributeSelectedValues(tempInitial);
          setSelectedExtraVariant([...tempSelectedArr]);
          setVariantArray([...tempVariantArray]);
        }
        setVariantLoader(false);
        setAttributeLoader(false);
      }
    } catch (error) {
      setVariantLoader(false);
      console.log(error);
    }
  };

  const onSelectVariant = async (setFieldValue, e) => {
    try {
      setAttributeLoader(true);
      if (e) {
        setFieldValue('attributeId', undefined);
        if (selectedPrimaryVariant) {
          let variantsData = variants;
          variantsData.map((item) => {
            if (item.id.toString() === selectedPrimaryVariant.toString()) {
              item.disabled = false;
            }
          });
        }
        setSelectedPrimaryVariant(e);
        fetchVariantsAttributes(e);
        let data = variants;
        data.map((item) => {
          if (item.id.toString() === e.toString()) {
            item.disabled = true;
          }
        });
        setVariants([...data]);
      } else {
        setAttribute([]);
      }
    } catch (error) {
      logger(error);
    }
  };

  const removeVariant = (index) => {
    let tempArr = variantArray;
    let tempAttributeArray =
      tempArr[index][`attributeId${tempArr[index].identifier}`];
    if (tempAttributeArray.length > 0) {
      let data = variants;
      data.map((item) => {
        if (item.id.toString() === tempAttributeArray[0].variantId.toString()) {
          item.disabled = false;
        }
      });
      setVariants([...data]);
    }
    if (tempArr.length >= index + 1) {
      tempArr.splice(index, 1);
    }
    let tempSelectedVar = selectedExtraVariant;
    tempSelectedVar.splice(index, 1);
    setSelectedExtraVariant([...tempSelectedVar]);
  };

  const addNewVariant = () => {
    try {
      let tempArr = variantArray;
      let tempSelectedVar = selectedExtraVariant;

      let tempValues = {
        extraVariantIdFieldName: `variantId${identifier}`,
        extraAttributeIdFieldName: `attributeId${identifier}`,
        [`variantId${identifier}`]: [...variants],
        [`attributeId${identifier}`]: [],
        identifier,
      };
      tempSelectedVar.push(null);
      tempArr.push(tempValues);
      setSelectedExtraVariant([...tempSelectedVar]);
      setVariantArray([...tempArr]);
    } catch (error) {
      logger(error);
    }
  };

  const fetchAttributesOfVariants = async (
    id,
    attributeId,
    setFieldValue,
    extraVariantIndex
  ) => {
    setVariantIndexLoader(extraVariantIndex);
    setAttributeSelectedValues({});
    try {
      setExtraAttributeLoader(true);
      setFieldValue(attributeId, undefined);
      let temp = variants;
      let tempSelectedVariantsArray = selectedExtraVariant;
      temp.map((item) => {
        if (item.id.toString() === id.toString()) {
          item.disabled = true;
        }
      });
      if (tempSelectedVariantsArray[extraVariantIndex]) {
        temp.map((item) => {
          if (
            item.id.toString() ===
            tempSelectedVariantsArray[extraVariantIndex].toString()
          ) {
            item.disabled = false;
          }
        });
      }
      tempSelectedVariantsArray.splice(extraVariantIndex, 1, id);
      setVariants([...temp]);
      setSelectedExtraVariant([...tempSelectedVariantsArray]);
      const response = await getProductVariantAttributeService(id);
      let tempArr = response.data.productVariantAttributeDetail;
      tempArr.map((item) => {
        item.name = item.attributeNames;
      });
      if (response.success) {
        const variantList = variantArray.map((item) => {
          if (item.extraAttributeIdFieldName === attributeId) {
            item[item.extraAttributeIdFieldName] = tempArr || [];
          }
          return item;
        });
        setVariantArray([...variantList]);
      }
    } catch (error) {
      logger(error);
      setExtraAttributeLoader(false);
    }
    setExtraAttributeLoader(false);
  };

  useEffect(() => {
    if (id) {
      getProductDetails(parseInt(id));
    }
  }, [id]);
  useEffect(() => {
    // reload component on variant select
  }, [variants]);

  useEffect(() => {
    // reload component on add extra variant
  }, [variantArray]);
  const handleFieldValue = (props, index) => {
    let tempArr = variantArray;
    let tempAttributeArray =
      tempArr[index][`attributeId${tempArr[index].identifier}`];
    if (tempAttributeArray.length > 0) {
      let data = variants;
      data.map((item) => {
        if (item.id.toString() === tempAttributeArray[0].variantId.toString()) {
          item.disabled = false;
        }
      });
      setVariants([...data]);
    }
    const newArray = variantArray?.map((obj) => ({
      extraVariantIdFieldName: `variantId${identifier}`,
      extraAttributeIdFieldName: `attributeId${identifier}`,
      [`variantId${identifier}`]: [...variants],
      [`attributeId${identifier}`]: [],
      identifier,
    }));
    setVariantArray(newArray);
  };

  const handleBookingChange = (value, formik, extraAttributeIdFieldName) => {
    if (value?.length === 0) {
      setAttributeSelectedValues((prev) => ({
        ...prev,
        [extraAttributeIdFieldName]: [],
      }));
    } else {
      setAttributeSelectedValues((prev) => ({
        ...prev,
        [extraAttributeIdFieldName]: value,
      }));
    }
  };

  return (
    <PageContainerWrapper
      heading={id ? t('text.common.editProduct') : t('text.common.addProduct')}
      breadcrumb={breadcrumb}
      btnArray={['extraButton']}
      extraBtnText={t('text.common.back')}
      extraBtnClass="btn btn-outline-light bg-white"
      onExtraButtonHandleShow={() =>
        backToPage(navigate, '/admin/product-management')
      }
    >
      <div className="nk-block">
        <div className="card">
          <div className="card-inner">
            {productLoader ? (
              <GlobalLoader />
            ) : (
              <AddEditProductForm
                onSubmit={onSubmit}
                variants={variants}
                categories={categories}
                attribute={attribute}
                loading={loading}
                onSelectVariant={onSelectVariant}
                fetchAttributesOfVariants={fetchAttributesOfVariants}
                variantLoader={variantLoader}
                attributeLoader={attributeLoader}
                categoryLoader={categoryLoader}
                extraAttributeLoader={extraAttributeLoader}
                formData={formData}
                couponCodeList={couponCodeList}
                setFormData={setFormData}
                variantIndex={handleVariantIndexLoader}
                handleCouponCode={handleCouponCode}
                setHandleCouponCode={setHandleCouponCode}
                variantArray={variantArray}
                removeVariant={removeVariant}
                addNewVariant={addNewVariant}
                handleRemoveFieldsValue={handleFieldValue}
                handleBookingChange={handleBookingChange}
                attributeSelectedValues={attributeSelectedValues}
                setAttributeSelectedValues={setAttributeSelectedValues}
              />
            )}
          </div>
        </div>
      </div>
    </PageContainerWrapper>
  );
}
