import i18next from 'i18next';
import {
  checkValidData,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { getFullName } from '../../utils/text.util';
import {
  capitalizeFirstLetter,
  showDateInBrowser,
} from '../../utils';
import { MONTH_DATE_YEAR } from '../../config';
import { getInputSearchFilter, getMultiSelectSearchFilter } from '../../components';
import { getProviderListService } from '../index.service';
import { getFromToDateFilter } from '../../components/Filter/Common/date.filter';

export const serviceProviderActivityReportBreadcrumb = () => {
  return [
    {
      path: '/admin/dashboard',
      name: 'Dashboard',
    },
    {
      path: '#',
      name: i18next.t('text.report.providerActivityReportList'),
    },
  ];
};

const offlineAction = () => {
  return [
    {
      value: 'force',
      name: 'Force',
    },
    {
      value: 'manual',
      name: 'Manual',
    },
    {
      value: 'booking',
      name: 'Booking',
    },
  ];
};

export const serviceProviderActivityReportColumn = ({
  onSort,
  page,
  sizePerPage,
  onFilterChange,
  filter
}) => {
  return [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'providerName',
      filterDataIndex: ['providerUserId'],
      title: i18next.t('text.report.providerName'),
      onSort: (field, order) => onSort('providerName', order),
      render: (cell, row) =>
        checkValidData(
          getFullName(row?.User?.firstName, null, row?.User?.lastName)
        ),
      ...getMultiSelectSearchFilter({
        dataIndex: 'providerUserId',
        onFilterChange,
        name: 'provider name',
        list: [],
        mode: 'multiple',
        listService: getProviderListService,
        listKeys: { nameKey: ['firstName', 'lastName'], nameId: 'id' },
        searchOrNot: true,
        filter,
      }),
    },
    {
      dataIndex: 'onlineTime',
      filterDataIndex:['onlineFromDate', 'onlineToDate'],
      title: i18next.t('text.report.providerOnlineDateTime'),
      render: (cell, row) =>
        row?.onlineTime
          ? showDateInBrowser(cell, MONTH_DATE_YEAR)
          : '-',
      ...getFromToDateFilter({
        dataStartIndex: 'onlineFromDate',
        dataEndIndex: 'onlineToDate',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY/MM/DD',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'offlineTime',
      filterDataIndex:['offlineFromDate', 'offlineToDate'],
      title: i18next.t('text.report.providerOfflineDateTime'),
      render: (cell, row) =>
        row?.offlineTime
          ? showDateInBrowser(cell, MONTH_DATE_YEAR)
          : '-',
      ...getFromToDateFilter({
        dataStartIndex: 'offlineFromDate',
        dataEndIndex: 'offlineToDate',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY/MM/DD',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'totalOnlineTime',
      title: i18next.t('text.report.providerTotalTimeOnline'),
      render: (cell, row) =>  {
        const time = row?.totalOnlineTime || {};
        let timeString = '';
        if (time.days) timeString += `${time.days} day${time.days > 1 ? 's' : ''} `;
        if (time.hours) timeString += `${time.hours} hour${time.hours > 1 ? 's' : ''} `;
        if (time.minutes) timeString += `${time.minutes} minute${time.minutes > 1 ? 's' : ''} `;
        if (time.seconds) timeString += `${time.seconds} second${time.seconds > 1 ? 's' : ''} `;
      
        return timeString.trim() || '-';
      }
    },
    {
      dataIndex: 'scheduledJobRadius',
      filterDataIndex: ['scheduleRadius'],
      title: i18next.t('text.report.providerScheduledRadius'),
      render: (cell, row) =>
        checkValidData(row?.scheduledJobRadius),
      ...getInputSearchFilter({
        dataIndex: 'scheduleRadius',
        placeholder: 'scheduled radius',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'emergencyJobRadius',
      filterDataIndex: ['emergencyRadius'],
      title: i18next.t('text.report.providerEmergencyRadiusSetOnline'),
      render: (cell, row) =>
        checkValidData(row?.emergencyJobRadius),
      ...getInputSearchFilter({
        dataIndex: 'emergencyRadius',
        placeholder: 'emergency radius',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'offlineAction',
      filterDataIndex: ['action'],
      title: i18next.t('text.report.providerActionAfterGoingOffline'),
      render: (cell, row) =>
        checkValidData(
          row?.offlineAction === 'booking' ?
            `Received Job #${row?.Booking?.bookingNo}` :
            capitalizeFirstLetter(row?.offlineAction)
        ),
      ...getMultiSelectSearchFilter({
        dataIndex: 'action',
        onFilterChange,
        name: 'action',
        listKeys: { nameKey: 'name', nameId: 'value' },
        mode: false,
        listService: offlineAction,
        filter,
      }),
    },
    // {
    //   dataIndex: 'providerPaymentStatus',
    //   title: i18next.t('text.report.viewDetails'),
    //   headerClasses: 'nk-tb-col-tools text-end',
    //   render: (cell, row) => {
    //     return <Action row={row} cell={cell} />;
    //   },
    // },
  ];
};
