import { accessRoute } from '../../routes/Dashboard';
import { accessRoute as TeamSettingRoute } from '../../routes/TeamSetting';
import i18next from 'i18next';
import {
  dateFormatter,
  formatPhoneNumber,
  phoneNumberFormate,
} from '../../utils';
import {
  iconFormatter,
  ButtonFormatter,
  checkValidData,
  linkFormatter,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import {
  EditAvailabilityForm,
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import EditUnavailabilityForm from '../../components/Forms/Providers/editUnavailability.form';
import { getFullName } from '../../utils/text.util';
import { getActions, getTupleStatusList } from '../index.service';
import { getFromToDateFilter } from '../../components/Filter/Common/date.filter';
import { listAdminNCACompanyService } from '../../services/NCA/nca.service';
import { DATE_TH_MONTH_YEAR } from '../../config';

export const providerTableColumn = ({
  Action,
  Toggle,
  onSort,
  onEditService,
  onAvailService,
  onDocument,
  onHandleShow,
  onFilterChange,
  filter,
  page,
  InvoicePermissionToggle,
  sizePerPage,
  userType,
}) => {
  let columns = [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      render: (cell, row, index) =>
        serialNumberFormatter({ page, index, sizePerPage }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'userName',
      filterDataIndex: ['search'],
      title: i18next.t('text.provider.providerName'),
      headerClasses: 'sorting',
      sorter: true,
      exportKey: 'firstName',
      exportName: 'Driver Name',
      onSort: (field, order) => onSort('firstName', order),

      exportFormatter: (cell, row) =>
        getFullName(row?.firstName, row?.middleName, row?.lastName),

      render: (cell, row) => {
        return row?.firstName || row?.lastName
          ? linkFormatter(
              onHandleShow,
              getFullName(row?.firstName, row?.middleName, row?.lastName),
              row?.id
            )
          : '-';
      },
      ...getInputSearchFilter({
        dataIndex: 'search',
        placeholder: 'provider name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'email',
      title: i18next.t('text.common.email'),
      headerClasses: 'sorting',
      sorter: true,
      // exportName: 'Email',
      onSort: (field, order) => onSort('email', order),
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'email',
        placeholder: 'email',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'createdAt',
      filterDataIndex: ['fromDate', 'toDate'],
      title: i18next.t('text.common.signUpDate'),
      sorter: true,
      render: (cell, row) => dateFormatter(cell, 'Do MMMM, YYYY'),
      exportFormatter: (cell, row, index) =>
        dateFormatter(cell, 'Do MMMM, YYYY'),
      ...getFromToDateFilter({
        dataStartIndex: 'fromDate',
        dataEndIndex: 'toDate',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY/MM/DD',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'phoneNumber',
      title: i18next.t('text.common.mobile'),
      sorter: false,
      render: (cell, row) => {
        const formatNumber = phoneNumberFormate(cell, row?.countryPhoneCode);
        return formatNumber ?? '-';
      },
      exportFormatter: (cell, row, index) => {
        const formatNumber = phoneNumberFormate(cell, row?.countryPhoneCode);
        return formatNumber ?? '-';
      },

      ...getInputSearchFilter({
        dataIndex: 'phoneNumber',
        placeholder: 'Phone number',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'viewEditDocument',
      title: i18next.t('text.provider.documents'),
      action: getActions('EDIT'),
      sort: false,
      render: (cell, row) =>
        iconFormatter('icon ni ni-file-doc', onDocument(row?.id)),
    },
    {
      dataIndex: 'manageServices',
      title: i18next.t('text.provider.manageService'),
      sort: false,
      render: (cell, row) =>
        iconFormatter('icon ni ni-file-text', onEditService(row?.id)),
    },
    {
      dataIndex: 'id',
      title: i18next.t('text.provider.availability'),
      sort: false,
      export: false,
      render: (cell, row) =>
        ButtonFormatter(
          onAvailService(row?.id),
          `${i18next.t('text.common.views')} / ${i18next.t(
            `text.common.edit`
          )}`,
          'trigger'
        ),
    },
    {
      dataIndex: 'isInvoiceMessageMandatory',
      title: i18next.t('text.provider.invoiceMessagePermission'),
      sorter: false,
      render: (cell, row) => {
        return <InvoicePermissionToggle row={row} cell={cell} />;
      },
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.common.status'),
      headerClasses: 'sorting',
      exportName: 'Status',
      sorter: true,
      onSort: (field, order) => onSort('status', order),
      render: (cell, row) => {
        return <Toggle row={row} cell={cell} />;
      },
      ...getMultiSelectSearchFilter({
        dataIndex: 'status',
        onFilterChange,
        filter,
        name: 'status',
        list: [],
        mode: false,
        listService: getTupleStatusList,
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
  ];
  if (userType !== 'nca') {
    columns = [
      ...columns.slice(0, 5),
      {
        dataIndex: 'providerNca',
        title: i18next.t('text.provider.providerNca'),
        headerClasses: 'sorting',
        sorter: true,
        export: true,
        exportKey: 'Provider',
        // exportName: 'Provider Nca',
        onSort: (field, order) => onSort('', order),
        exportFormatter: (cell, row) =>
          checkValidData(
            row?.Provider?.CompanyNca?.name
              ? row?.Provider?.CompanyNca?.name
              : i18next.t('text.common.goodGuysAdmin')
          ),
        render: (cell, row) =>
          checkValidData(
            row?.Provider?.CompanyNca?.name
              ? row?.Provider?.CompanyNca?.name
              : i18next.t('text.common.goodGuysAdmin')
          ),
        ...getMultiSelectSearchFilter({
          dataIndex: 'providerNca',
          onFilterChange,
          name: 'Provider Nca',
          list: [],
          mode: false,
          listService: listAdminNCACompanyService,
          listKeys: { nameKey: 'name', nameId: 'name' },
          filter,
          innerKey: 'CompanyNca',
        }),
      },
      ...columns.slice(5),
    ];
  }
  return columns;
};

export const providerBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.provider.heading'),
    },
  ];
};
export const addProviderBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: TeamSettingRoute.PROVIDER.path,
      name: i18next.t('text.provider.heading'),
    },
    {
      path: '#',
      name: i18next.t('text.common.addProvider'),
    },
  ];
};
export const editAvailabilityBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: TeamSettingRoute.PROVIDER.path,
      name: i18next.t('text.provider.heading'),
    },
    {
      path: '#',
      name: i18next.t('text.common.editAvailability'),
    },
  ];
};

export const editProviderServiceBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: TeamSettingRoute.PROVIDER.path,
      name: i18next.t('text.provider.heading'),
    },
    {
      path: '#',
      name: i18next.t('text.provider.editService'),
    },
  ];
};

export const scheduleJobData = () => {
  return [
    {
      id: '5',
      name: '5',
    },
    {
      id: '10',
      name: '10',
    },
    {
      id: '15',
      name: '15',
    },
    {
      id: 'other',
      name: 'Other',
    },
  ];
};

export const scheduleJobDataWithOutOther = () => {
  return [
    {
      id: '5',
      name: '5',
    },
    {
      id: '10',
      name: '10',
    },
    {
      id: '15',
      name: '15',
    },
  ];
};

export const serviceProviderManual = () => {
  return [
    {
      label: 'No',
      value: 2,
    },
    {
      label: 'Yes (Set Service Provider Working Time Manually)',
      value: 1,
    },
  ];
};

export const timeSelectType = () => {
  return [
    {
      label: 'Same for All days',
      value: 1,
    },
    {
      label: 'Different for All days',
      value: 2,
    },
  ];
};

export const diffTimePicker = () => {
  return [
    {
      label: 'Monday',
      name: 'monday',
    },
    {
      label: 'Tuesday',
      name: 'tuesday',
    },
    {
      label: 'Wednesday',
      name: 'wednesday',
    },
    {
      label: 'Thursday',
      name: 'thursday',
    },
    {
      label: 'Friday',
      name: 'friday',
    },
    {
      label: 'Saturday',
      name: 'saturday',
    },
    {
      label: 'Sunday',
      name: 'sunday',
    },
  ];
};

// for Availability and Unavailability
export const editAvailabilityTabContent = ({
  setModalShow,
  onSubmit,
  isLoading,
  userId,
  unavailabilitySubmit,
  slots,
}) => {
  return [
    {
      name: i18next.t('text.common.availability'),
      key: 'availability',
      content: (
        <EditAvailabilityForm
          onSubmit={onSubmit}
          isLoading={isLoading}
          userId={userId}
          slots={slots}
        />
      ),
    },
    {
      name: i18next.t('text.common.unavailability'),
      key: 'unavailability',
      content: (
        <EditUnavailabilityForm
          setModalShow={setModalShow}
          isLoading={isLoading}
          onSubmit={unavailabilitySubmit}
          userId={userId}
        />
      ),
    },
  ];
};

export const autoApproveBookingList = () => {
  return [
    {
      id: 0,
      name: 'Select approve all booking automatically',
    },
    {
      id: 2,
      name: 'If more than 2 days out',
    },
    {
      id: 3,
      name: 'If more than 3 days out',
    },
    {
      id: 5,
      name: 'If more than 5 days out',
    },
    {
      id: 7,
      name: 'If more than 7 days out',
    },
  ];
};

export const spWorkLocationList = () => {
  return [
    {
      id: 'any_location',
      name: 'Any Location',
    },
    {
      id: 'specified_location',
      name: 'Specified Location',
    },
  ];
};

export const EditProviderBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: TeamSettingRoute.PROVIDER.path,
      name: i18next.t('text.provider.heading'),
    },
    {
      path: '#',
      name: 'Edit Provider',
    },
  ];
};

export const weekNameList = () => {
  return [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY',
  ];
};

export const slotList = () => {
  return [
    {
      id: 1,
      value: '05:00-08:00',
      label: '05 AM - 08 AM',
    },
    {
      id: 2,
      value: '08:00-11:00',
      label: '08 AM - 11 AM',
    },
    {
      id: 3,
      value: '11:00-14:00',
      label: '11 AM - 02 PM',
    },
    {
      id: 4,
      value: '14:00-17:00',
      label: '02 PM - 05 PM',
    },
    {
      id: 5,
      value: '17:00-20:00',
      label: '05 PM - 08 PM',
    },
  ];
};

export const businessTypeData = () => {
  return [
    {
      id: 'individual',
      name: 'Individual',
    },
    {
      id: 'company_single',
      name: 'Company-Single Member LLC',
    },
    {
      id: 'company_sole',
      name: 'Company-Sole Proprietorship',
    },
  ];
};

export const businessTypeNCAData = () => {
  return [
    {
      id: 'individual',
      name: 'Individual',
    },
    {
      id: 'company_sole',
      name: 'Company-Sole Proprietorship',
    },
    {
      id: 'company_single',
      name: 'Company-Single Member LLC',
    },
    {
      id: 'company_multi',
      name: 'Company-Multi Member LLC',
    },
    {
      id: 'company_private',
      name: 'Company-Private Partnership',
    },
    {
      id: 'company_pcorporation',
      name: 'Company-Private Corporation',
    },
    {
      id: 'company_unicorporated',
      name: 'Company-Unicorporated Association',
    },
  ];
};

export const businessTypeNonIndividualNCAData = () => {
  return [
    {
      id: 'company_sole',
      name: 'Company-Sole Proprietorship',
    },
    {
      id: 'company_single',
      name: 'Company-Single Member LLC',
    },
    {
      id: 'company_multi',
      name: 'Company-Multi Member LLC',
    },
    {
      id: 'company_private',
      name: 'Company-Private Partnership',
    },
    {
      id: 'company_pcorporation',
      name: 'Company-Private Corporation',
    },
    {
      id: 'company_unicorporated',
      name: 'Company-Unicorporated Association',
    },
  ];
};

export const documentBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: TeamSettingRoute.PROVIDER.path,
      name: i18next.t('text.provider.heading'),
    },
    {
      path: '#',
      name: 'Document',
    },
  ];
};

// const queryParams = { scope: 'basic' };
