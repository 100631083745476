import { createSlice } from '@reduxjs/toolkit';
import {
  removeLocalStorage,
  removeLocalStorageToken,
  removeSessionStorageToken,
} from '../../../utils';
import logger from '../../../utils/logger';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    userData: {},
    sideBarKey: '0',
    customerData: {},
    storeData: {},
    displayPerPage: 10,
  },
  reducers: {
    loginAction: (state, action) => {
      return (state = {
        ...state,
        userData: {
          ...action.payload,
        },
      });
    },

    logoutAction: (state, action) => {
      return (state = {
        ...state,
        userData: {},
        ncaBeforeLogin: {},
      });
    },

    unAuthUserAction: (state, action) => {
      return (state = {
        ...state,
        unAuthUserData: {
          ...action.payload,
        },
      });
    },
    
    clearUnAuthUserAction: (state, action) => {
      return (state = {
        ...state,
        unAuthUserData: {},
      });
    },

    loginCustomerAction: (state, action) => {
      return (state = {
        ...state,
        customerData: {
          ...action.payload,
        },
      });
    },
    customerLogoutAction: (state, action) => {
      return (state = {
        ...state,
        customerData: {},
      });
    },

    loginStoreAction: (state, action) => {
      return (state = {
        ...state,
        storeData: {
          ...action.payload,
        },
      });
    },
    storeLogoutAction: (state, action) => {
      return (state = {
        ...state,
        storeData: {},
      });
    },
    updateStoreDataAction: (state, action) => {
      return (state = {
        ...state,
        storeData: {
          ...state.storeData,
          ...action.payload,
        },
      });
    },

    updateUserDataAction: (state, action) => {
      return (state = {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload,
        },
      });
    },
    sideBarAction: (state, action) => {
      return (state = {
        ...state,
        sideBarKey: action.payload,
      });
    },
    setUserDetails: (state, action) => {
      return (state = {
        ...state,
        ...action.payload,
      });
    },
    setDisplayRecord: (state, action) => {
      return (state = {
        ...state,
        displayPerPage: action.payload,
      });
    },
    setCoachMarkKeyValue: (state, action) => {
      return (state = {
        ...state,
        customerData: {
          ...state.customerData,
          Customer: {
            ...state.customerData.Customer,
            isCoachMarkView: action.payload
          }
        }
      });
    },
  },
});

export const {
  loginAction,
  loginCustomerAction,
  loginStoreAction,
  updateUserDataAction,
  updateStoreDataAction,
  logoutAction,
  customerLogoutAction,
  unAuthUserAction,
  storeLogoutAction,
  clearUnAuthUserAction,
  sideBarAction,
  setUserDetails,
  setDisplayRecord,
  setCoachMarkKeyValue,
} = authSlice.actions;

export const login = (data) => async (dispatch) => {
  try {
    dispatch(loginAction(data));
  } catch (error) {
    logger(error);
  }
};

export const logout =
  (
    remember,
    userRole
    // navigate
  ) =>
    async (dispatch) => {
      try {
        if (!remember) {
          removeSessionStorageToken();
        }
        removeLocalStorageToken(null, userRole);
        removeLocalStorage('role');
        dispatch(logoutAction());
        // navigate(routesMap.LOGIN.path);
      } catch (error) {
        logger(error);
      }
    };

export const unAuthUserData = (data) => async (dispatch) => {
  try {
    dispatch(unAuthUserAction(data));
  } catch (error) {
    logger(error);
  }
};

export const clearUnAuthUserData = (data) => async (dispatch) => {
  try {
    dispatch(clearUnAuthUserAction());
  } catch (error) {
    logger(error);
  }
};
export const customerLogin = (data) => async (dispatch) => {
  try {
    dispatch(loginCustomerAction(data));
  } catch (error) {
    logger(error);
  }
};
export const customerLogout = (remember) => async (dispatch) => {
  try {
    if (!remember) {
      removeSessionStorageToken();
    }
    removeLocalStorageToken(null, 'customer');
    dispatch(customerLogoutAction());
  } catch (error) {
    logger(error);
  }
};

export const storeLogin = (data) => async (dispatch) => {
  try {
    dispatch(loginStoreAction(data));
  } catch (error) {
    logger(error);
  }
};

export const storeLogout = (remember) => async (dispatch) => {
  try {
    if (!remember) {
      removeSessionStorageToken();
    }
    removeLocalStorageToken(null, 'store');
    dispatch(storeLogoutAction());
  } catch (error) {
    logger(error);
  }
};

export const updateUserData = (data) => async (dispatch) => {
  try {
    dispatch(updateUserDataAction(data));
  } catch (error) {
    logger(error);
  }
};

export const updateStoreData = (data) => async (dispatch) => {
  try {
    dispatch(updateStoreDataAction(data));
  } catch (error) {
    logger(error);
  }
};

export const updateSidebarKey = (data) => async (dispatch) => {
  try {
    dispatch(sideBarAction(data));
  } catch (error) {
    logger(error);
  }
};

export const updateCoachMarkKeyValue = (data) => async (dispatch) => {
  try {
    dispatch(setCoachMarkKeyValue(data));
  } catch (error) {
    logger(error);
  }
};

export const selectUserData = (state) => state.auth.userData;
export const selectStoreData = (state) => state.auth.storeData;
export const getSidebarKey = (state) => state.auth.sideBarKey;

export default authSlice.reducer;
