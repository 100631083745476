export const text = {
  userAddress: 'Customer Address',
  heading: 'Customers',
  serviceAddress: 'Service Address',
  customerNoteOne: 'Customers module will list all Customers on this page.',
  customerNoteTwo:
    'Administrator can Activate / Deactivate / Delete any Customers',
  addUser: 'Add Customer',
  addCustomer: 'Add Customer',
  updateCustomer: 'Update Customer',
  editUser: 'Edit Customer',
  userProfile: 'Customer Details',
  addUserAddress: 'Add Customer Address',
  addressOne: 'Address Line 1',
  addressOnePlaceholder: 'Enter address line 1 ',
  name: 'Name',
  namePlaceholder: 'Enter name',
  addressTwo: 'Address Line 2',
  addressTwoPlaceholder: 'Enter address line 2',
  suiteNumber: 'Suite Number',
  suiteNumberPlaceholder: 'Enter suite number',
  userAddressDeleteSuccessFull: 'Deleted customer address successfully',
  signupVia:'Signup Via',
  firstName: 'First Name',
  lastName: ' Last Name',
  userName: 'User Name',
  userCompany: 'Customer Company',
  signupDate: 'Sign Up Date',
  mobile: 'Mobile',
  location: 'Location',
  customerTag: 'Customer Tag',
  jobTag: 'Job Tag',
  customerNotes: 'Customer Notes',
  noTag: 'N/A',
  permissionUpdatedSuccessfully: 'Role permissions updated successfully',
  bookingTag: 'Booking Tag',
  viewDetails: 'View Job details',
  homeInventory: 'Home Inventory',
  viewAddress: 'View Customer Address',
  bookingDetails: 'View Booking Details',
  bookNow: 'Book Now',
  firstNamePlace: 'Enter first name',
  lastNamePlace: 'Enter last name',
  emailPlace: 'Enter email ',
  passwordPlace: 'Enter password',
  countryPlace: 'Select country',
  monthPlace: 'Select month',
  memberShipPlace: 'Select membership',
  phonePlace: 'Enter phone',
  usersCompany: 'Users Company',
  ViewBookingDetail: 'View Booking Detail',
  viewUserAddress: 'View User Address',
  signUpDate: 'Sign Up Date',
  enterNotes: 'Enter notes',
  addCustomerNote: 'Add Customer Note',
  addNote: 'Add Note',
  jobNumberPlaceHolder: 'Select job number',
  jobNumberLabel: 'Select By Job Number',
  enterByAdmin: 'Enter by Admin',
  spNoteBy: 'SP Note by',
  enterNote: 'Please enter note',
  providerContactLog: 'Provider Contact Logs',
  subject: 'Subject',
  message: 'Message',
  minNote: 'Please enter at least 2 character',
  customerNoteThree:
    'For respective Customer, the recurring of membership plan will stop by Inactive/Delete Customer.',
};
