import { Outlet } from 'react-router-dom';
import {
  ListEmail,
  EditEmail,
  ListMessage,
  EditMessage,
  NotificationTemplate
} from '../../views/EmailMessage';
import { getActions } from '../../services/Auth/index.service';

export default function route() {
  return [
    {
      private: true,
      name: 'SMS & Email Messages',
      key: 'emailSettings',
      belongsToSidebar: true,
      moduleKeys: [
        accessRoute.EMAIL_TEMPLATES.moduleKey,
        accessRoute.SMS_TEMPLATES.moduleKey,
        accessRoute.NOTIFICATION_TEMPLATE.moduleKey,
      ],
      icon: (
        <span className="nk-menu-icon">
          <em className="icon ni ni-mail-fill" />
        </span>
      ),
      element: <Outlet />,
      submenu: true,
      children: [
        {
          private: true,
          name: accessRoute.EMAIL_TEMPLATES.name,
          key: accessRoute.EMAIL_TEMPLATES.key,
          path: accessRoute.EMAIL_TEMPLATES.path,
          action: accessRoute.EMAIL_TEMPLATES.action,
          belongsToSidebar: true,
          element: <ListEmail />,
          ...accessRoute.EMAIL_TEMPLATES,
        },
        {
          private: true,
          name: accessRoute.EDIT_EMAIL_TEMPLATES.name,
          key: accessRoute.EDIT_EMAIL_TEMPLATES.key,
          path: accessRoute.EDIT_EMAIL_TEMPLATES.path,
          belongsToSidebar: false,
          element: <EditEmail />,
          ...accessRoute.EDIT_EMAIL_TEMPLATES,
        },
        {
          private: true,
          name: accessRoute.SMS_TEMPLATES.name,
          key: accessRoute.SMS_TEMPLATES.key,
          path: accessRoute.SMS_TEMPLATES.path,
          belongsToSidebar: true,
          element: <ListMessage />,
          ...accessRoute.SMS_TEMPLATES,
        },
        {
          private: true,
          name: accessRoute.EDIT_SMS_TEMPLATES.name,
          key: accessRoute.EDIT_SMS_TEMPLATES.key,
          path: accessRoute.EDIT_SMS_TEMPLATES.path,
          belongsToSidebar: false,
          element: <EditMessage />,
          ...accessRoute.EDIT_SMS_TEMPLATES,
        },
        {
          private: true,
          path: accessRoute.NOTIFICATION_TEMPLATE.path,
          name: 'Notification Templates',
          key: accessRoute.NOTIFICATION_TEMPLATE.key,
          belongsToSidebar: true,
          // icon: accessRoute.NOTIFICATION_TEMPLATE.icon,
          element: <NotificationTemplate />,
          ...accessRoute.NOTIFICATION_TEMPLATE,
        },
      ],
    },
  ];
}

export const accessRoute = {
  EMAIL_TEMPLATES: {
    path: '/admin/email-templates',
    name: 'Email Templates',
    key: 'emailSettings.email-templates',
    action: getActions('LIST').value,
    moduleKey: 'email-templates',
    listAction: ['view', 'edit'],
    order: 17,
  },
  EDIT_EMAIL_TEMPLATES: {
    path: '/admin/email-templates/edit/:id',
    name: 'Edit Email Templates',
    action: getActions('EDIT').value,
    moduleKey: 'email-templates',
    key: 'emailSettings.email-templates',
  },
  SMS_TEMPLATES: {
    path: '/admin/sms-templates',
    action: getActions('LIST').value,
    moduleKey: 'sms-templates',
    name: 'SMS Templates',
    key: 'emailSettings.sms-templates',
    order: 36,
  },
  EDIT_SMS_TEMPLATES: {
    path: '/admin/sms-templates/edit/:id',
    name: 'Edit SMS Templates',
    action: getActions('EDIT').value,
    moduleKey: 'sms-templates',
    key: 'emailSettings.sms-templates',
  },
  NOTIFICATION_TEMPLATE: {
    path: '/admin/notification-template',
    key: 'emailSettings.notification-template',
    moduleKey: 'notification-templates',
    for: ['admin', 'subadmin','nca'],
    action: getActions('LIST').value,
    order: 40,
    // icon: (
    //   <span className="nk-menu-icon">
    //     <em className="icon ni ni-grid-alt" />
    //   </span>
    // ),
  },
};

export const getChildrenRoutes = () => {
  return route()[0]?.children;
};
