import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';
import {
  EMAIL_TEMPLATE_ENDPOINT,
  SMS_TEMPLATE_ENDPOINT,
  NOTIFICATION_TEMPLATE_ENDPOINT
} from '../../apiEndpoints/index.endpoint';

export const listEmailService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...EMAIL_TEMPLATE_ENDPOINT.getEmailTemplateList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const editEmailService = async ({ body, id }) => {
  try {
    const payload = {
      ...EMAIL_TEMPLATE_ENDPOINT.editEmailTemplate(id),
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const viewEmailService = async ({ id, paramsValue = {} }) => {
  try {
    const payload = {
      ...EMAIL_TEMPLATE_ENDPOINT?.getEmailTemplateDetails(id),
      queryParams: paramsValue,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

// For sms template

export const listSmsService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...SMS_TEMPLATE_ENDPOINT.getSmsTemplateList,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    logger(err);
  }
};

export const editSmsService = async ({ body, id }) => {
  try {
    const payload = {
      ...SMS_TEMPLATE_ENDPOINT.editSmsTemplate(id),
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const viewSmsService = async ({ id, paramsValue={} }) => {
  try {
    const payload = {
      ...SMS_TEMPLATE_ENDPOINT?.getSmsTemplateDetails(id),
      queryParams : paramsValue,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const viewShortCodeService = async () => {
  try {
    const payload = {
      ...SMS_TEMPLATE_ENDPOINT?.getSmsShortCodeList,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const notificationTemplateService = async ({ queryParams }) => {
  try {
    const payload = {
      ...NOTIFICATION_TEMPLATE_ENDPOINT.getNotificationTemplate,
      queryParams,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const updateNotification = async ({ resourceId, body }) => {
  try {
    const payload = {
      ...NOTIFICATION_TEMPLATE_ENDPOINT.updateNotificationId(resourceId),
      bodyData: body,
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err); 
    logger(err);
  }
}

export const getNotificationTemplateService = async ({ resourceId }) => {
  try {
    const payload = {
      ...NOTIFICATION_TEMPLATE_ENDPOINT.getNotificationById(resourceId),
    };

    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};
