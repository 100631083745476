import { REPORT_AN_ISSUE_ENDPOINT } from '../../apiEndpoints/index.endpoint';
import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';

export const getIssueReportService = async ({ queryParams, type }) => {
  try {
    const payload = {
      ...REPORT_AN_ISSUE_ENDPOINT?.getIssueReportService,
      queryParams,
    };
    const res = await APIRequest(payload);
    if (res?.data?.rows && type === 'all') {
      return res?.data?.rows;
    }
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const addAdminNoteDataService = async ({ id, body }) => {
  try {
    const payload = {
      ...REPORT_AN_ISSUE_ENDPOINT?.addAdminNote(id),
      bodyData: body,
    };
    return await APIRequest(payload);
  } catch (err) {
    console.log(err);
  }
};

export const changePriorityAndStatusService = async ({ id, body }) => {
  try {
    const payload = {
      ...REPORT_AN_ISSUE_ENDPOINT?.changePriorityAndStatus(id),
      bodyData: body,
    };
    return await APIRequest(payload);
  } catch (err) {
    console.log(err);
  }
};

export const issueAssignService = async ({ id, userId }) => {
  try {
    const payload = {
      ...REPORT_AN_ISSUE_ENDPOINT?.issueAssign(id,userId),
    };
    return await APIRequest(payload);
  } catch (err) {
    console.log(err);
  }
};
