export const text = {
  heading: 'Payment Report',
  showHide: 'Show / Hide Graph',
  providerDiscountHeading: 'Provider Discount Details',
  purchaseOrderAdd: 'Purchase Order Details',
  SubLaborPayAdd: 'Sub-Labor Pay Details',
  SubLaborPayDetail: 'Sub-Labor Pay Details',
  returnAmountBreadcrumb: 'Return Amount',
  paymentMethodHeader: 'Provider Request a Payment',
  billingReport: 'Billing Report',
  billingReportDetails: 'Billing Report Details',
  billedTo: 'Billed To',
  receiptDate: 'RECEIPT DATE',
  payMethod: 'PAYMENT METHOD',
  paymentStatus: 'PAYMENT STATUS',
  yourPlan: 'Your Plan',
  transactionProcessingFee: 'Transaction Processing Fee(s) Expense Report',
  planBilled: 'Plan Billed',
  sno: 'S.No.',
  nca: 'NCA',
  role: 'Role',
  lastFourDigitsOfCard: 'Last Four Digits of Card',
  billType: 'Bill Type',
  planAmount: 'Plan Amount',
  totalAmount: 'Total Amount',
  paymentStatusSmall: 'Payment Status',
  planFailedType: 'Plan Failed Type',
  date: 'Date',
  providerInvoice: 'Provider Invoice Update Details',
  splitPayment: 'Split Payment Details',
  invoiceHeading: 'Invoice Detail',
  provider: 'Provider',
  user: 'User',
  bookingAddress: 'Booking Address',
  jobId: 'Job ID',
  jobDate: 'Job Date Timing',
  serviceDetails: 'Service Details',
  serviceName: 'Service Name',
  serviceCharge: 'Service Charge',
  lineItems: 'Line Items',
  image: 'Image',
  name: 'Name',
  quantity: 'Quantity',
  price: 'Price',
  total: 'Total',
  servicePrice: 'Service Price',
  pleaseNote: 'Please Note',
  quoteExpires: 'Quote Expires',
  accepted: 'Accepted',
  eMail: 'E-mail',
  print: 'Print',
  reviews: 'Reviews',
  jobNo: 'Job No',
  userNameAvg: 'User Name(Average Rate)',
  providerNameAvg: 'Provider Name(Average Rate)',
  rate: 'Rate',
  comment: 'Comment',
  providerName: 'Provider Name',
  providerAccountName: 'Provider Account Name',
  serviceProviderPaymentReport: 'Service Provider Payment Report',
  bankName: 'Bank Name',
  accountNumber: 'Account Number',
  routingNumber: 'Routing Number',
  location: 'Location',
  totalJobTiming: 'Total Job Timing',
  aTotalJobTiming: 'A=Total Job Fees',
  bTotalPlatFormFee: 'B=Total Platform Fees',
  cTotalSubLaborPay: 'C=Total Sublabor Pay',
  dTotalDeductAmount: 'D=Total Deduct Amount(Sublabor Pay)',
  eTotalDeductAmount: 'E=Total Deduct Amount (Purchase Order)',
  fTotalDeductAmount: 'F=Total Provider Payment (((A-B)+C)-D)-E',
  providerPaymentStatus: 'Provider Payment Status',
  paymentSettledByAdmin: 'Payment Settled By Admin',
  returnAmount: 'Return Amount Details',
  jobsDate: 'Job Date',
  atotalFees: 'A=Total Fees',
  bPlatformFees: 'B=Platform Fees',
  ePromoCodeDiscount: 'C= Promo Code Discount',
  dJobProviderDiscountAmount: 'D=Job Provider Discount Amount',
  dJobProviderDiscountAmountExport: 'D = Provider Discount',
  ePurchaseOrderTotal: 'E=Purchase Order Total',
  fSubLaborTotalFees: 'F=Sublabor Total Fees ',
  gReturnedPO: 'G = Returned PO',
  hPlanDiscount: 'H = Plan Discount',
  providerPayAmount: 'Provider Pay Amount I =A-B-E-F+G',
  providerPayAmountExport: 'Provider Pay Amount',
  jobStatus: 'Job Status',
  jobStartTime: 'Job Start Time',
  jobStartTimeExport: 'Start Timing',
  jobEndTime: 'Job End Time',
  jobEndTimeExport: 'End Timing',
  jobCompletionTime: 'Job Completion Time',
  jobCompletionTimeExport: 'Job Timing',
  paymentMethod: 'Payment method',
  jobPaymentType: 'Job Payment Type',
  selectAll: 'Select All',
  paymentTransferStatus: 'Payment Transfer Status',
  viewInvoice: 'View Invoice',
  splitPayments: 'Split Payment',
  fullPayment: 'Full Payment ',
  addEdit: 'Add / Edit',
  viewDetails: 'View Details ',
  jobNumber: 'Job No',
  jobNumberExport: 'Job No.',
  discountMessage: 'Provider discount successfully updated',
  errorMarkAsMessage: 'Please Check At Least One Record',
  sublaborPayName: 'Sublabor Pay Name',
  adminAttentionModalTitle: 'Admin Attention Required Reason',
  clickToPayOnline: 'Click to Pay Online',
  paymentReceived: 'Payment Received',
  thankYou: 'Thank You!',
  leaveUsAReview: 'Leave us a Review',
  viewNote: 'View Note',
  totalJobAmountDetail: 'Total Jobs Amount Details',
  membershipJobAmountDetail: 'Total Amount',
  markAsSettled: 'Mark As Settled',
  check: 'Check',
  creditCard: 'Credit Card',
  cash: 'Cash',
  financing: 'Financing',
  warrantyCharge: 'Warranty/No Charge',
  adminWaiveCost: 'Admin Waive Cost',
  adminWaiveTitle: 'Admin Waived',
  amountToPay: 'Amount To Pay',
  paidBy: 'Paid By',
  jobTotalAmount: 'Job Total Amount',
  checkOrFinancingNum: 'Check or Financing No.',
  jobPaymentStatus: 'Job Payment Status',
  visaEnd: 'Visa ending in',
  ncaRoleName: 'NCA Role Name',
  billed: 'Billed',
  activeServiceProvider: 'Active Service Provider',
  qty: 'QTY',
  costPaid: 'Cost Paid',
  creditCardTransaction: 'Credit Card Transaction',
  transactionCount: 'Transaction Count',
  transactionAmount: 'Transaction Amount',
  planAmount: 'Plan Amount Paid',
  purchaseSubLabor: 'Purchase Order / Sub-Labor / Returned',
  purchaseSubLaborName: 'Name(Purchase Order / Sub-Labor / Returned)',
  goodGuyFee: 'Good Guy Fee',
  earnedAmount: 'Earned Amount',
  dateTimes: 'DateTime',
  providerNumber: 'Provider Number',
  totalFees: 'Total Fees',
  userName: 'User Name',
  sublaborPayDetails: 'Sublabor Pay Details',
  providerOnlineDateTime: 'SP Online Date/Time',
  providerOfflineDateTime: 'SP Offline Date/Time',
  providerTotalTimeOnline:'SP Total Time Online',
  providerEmergencyRadiusSetOnline:'SP Radius(Emergency) Set for Online',
  providerScheduledRadius:'SP Radius(Scheduled)',
  providerActionAfterGoingOffline:'SP Action After Going Offline',
  providerActivityReportList:'Service Provider Activity Report',
  totalOnlineTime : 'Total Online Time',
  averageTimeSpentOnline : 'Average Time Spent Online',
  reportAnIssue:'Report An Issue',
  dateReported:'Date Reported',
  issueType:'Type of Issue',
  typeOfPhone:'Type of Phone',
  typeOfModel:'Type of Model',
  issueConcern:'Issue Concern',
  issueStatusOnDateReported:'Issue Status On Date Reported',
  imageOrVideoLinks:'Image/Video Links',
  issueIncharge:'Person Incharge of Issue',
  adminNote:'Admin Note',
  dateResolved:'Date Resolved',
  priorityLevel:'Priority Level',
  issueCurrentStatus:'Current Status Of Issue',
  addNote:'Add Note',
  editNote:'Edit Note',
  reportedIssueAssign:'Reported Issue Assign',
  supplyHouse:'Supply House',
  selectServiceProvider:'Selcet Service Provider',
  selectSubAdmin:'Select Sub Admin',
  selectProvider:'Select Provider',
  resolutionSuggestion:'Resolution/Suggestion',
  reporterPerson:'Reporter Person',
  reportedIssueMedia:'Reported Issue Media',
};
