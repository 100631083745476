/* eslint-disable no-empty-pattern */
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  PageContainerWrapper,
  ModalWrapper,
  ActionWrapper,
  NotificationForm,
  NotificationFilterForm,
  TablePlus,
  CommonButton,
} from '../../../../components';
import { useTable } from '../../../../hooks/index.hook';
import {
  Columns,
  EmailMessage,
  getActions,
} from '../../../../services/index.service';

function NotificationTemplate() {
  const { t } = useTranslation();
  const [tableHandler, tableProps] = useTable(
    EmailMessage.notificationTemplateService
  );
  const {
    data,
    isLoading,
    count,
    page,
    noOfPages,
    sizePerPage,
    filter,
    sortBy,
    sortType,
  } = tableProps;
  const {
    tableReset,
    onRefresh,
    onFilterChange,
    onExport,
    onTableSort,
    onFilterResetChange,
  } = tableHandler;
  const [state, setState] = useState({
    loader: null,
    modal: null,
    modalId: null,
    selectedRows: [],
    submitText: 'add',
  });
  const breadcrumb = Columns.notificationTemplateBreadcrumb();
  const { loader, modal, modalId, submitText } = state;

  function onAction(e, handler, item) {
    e.preventDefault();
    e.stopPropagation();
    handler(item);
  }

  const actionFormatter = (options) => {
    return (
      <ActionWrapper onClick={(e) => e.stopPropagation()}>
        {options.map((item, key) => {
          return (
            <li
              onClick={(e) => onAction(e, item.handler, item)}
              key={key}
              className="action_list"
              action={item?.action}
            >
              <CommonButton>
                <em className={item?.icon} />
                {item?.name}
              </CommonButton>
            </li>
          );
        })}
      </ActionWrapper>
    );
  };

  const columns = Columns.notificationTemplateList({
    onFilterChange: onFilterChange,
    filter: filter,
    onSort: onTableSort,
    t: t,
    page,
    sizePerPage,
    Action: (props) =>
      actionFormatter([
        {
          name: t('text.common.edit'),
          icon: 'icon ni ni-edit',
          action: getActions('EDIT').value,
          handler: () => {
            setState({
              ...state,
              modal: 'edit-notification',
              submitText: t('text.common.update'),
              modalId: props?.row?.id,
            });
          },
        },
      ]),
  });

  const onAdd = async (payload, modalId) => {
    try {
      let res;
      if (modalId && !isNaN(modalId)) {
        setState({ ...state, loader: 'edit-notification' });
        res = await EmailMessage.updateNotification({
          resourceId: modalId,
          body: payload,
        });
      } else {
        setState({ ...state, loader: 'add-notification' });
      }
      if (res.success) {
        setState({
          ...state,
          modal: null,
          modalId: null,
          loader: null,
          isProcessing: null,
        });
        tableReset();
      }
    } catch (err) {
      setState({ ...state, isProcessing: null });
      console.log(err);
    }
  };

  const btn = {
    csvExport: { handler: () => onExport('', columns), name: 'Notification' },
  };

  async function getDetail(id) {
    try {
      const res = await EmailMessage.getNotificationTemplateService({
        resourceId: id,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  function onCancel() {
    setState({ ...state, modal: null, modalId: null });
  }

  const formProps = {
    onCancel: onCancel,
    onSubmit: onAdd,
  };

  const modalForm = {
    add: (
      <NotificationForm
        onEdit={getDetail}
        loading={loader === 'edit-notification'}
        modalId={modalId}
        submitText={submitText}
        {...formProps}
      />
    ),
    filter: <NotificationFilterForm />,
  };

  return (
    <>
      <PageContainerWrapper
        breadcrumb={breadcrumb}
        btnArray={['create', 'csvExport']}
        btn={btn}
        filter={filter}
        heading={t('text.common.notificationTemplates')}
        form={
          <NotificationFilterForm
            onFilterResetChange={onFilterResetChange}
            onSubmit={onFilterChange}
            onRefresh={onRefresh}
          />
        }
      >
        <ModalWrapper
          heading={`${
            submitText === 'Add' ? t('text.common.add') : t('text.common.edit')
          }  Notification`}
          onCancel={onCancel}
          content={modalForm['add']}
          show={['add-notification', 'edit-notification'].includes(modal)}
        />
        <TablePlus
          hasLimit
          noOfPage={noOfPages}
          sizePerPage={sizePerPage}
          page={page}
          count={count}
          tableData={data}
          tableColumns={columns}
          selectRow
          showAction={false}
          tableLoader={isLoading}
          onFilterResetChange={onFilterResetChange}
          filter={filter}
          sortBy={sortBy}
          sortType={sortType}
        />
        <div className="card mt-3">
          <div className="card-inner">
            <div className="bq-note">
              <div className="bq-note-text">
                <div className="nk-block-head nk-block-head-sm nk-block-between">
                  <h5 className="title">{t('text.common.notes')}</h5>
                </div>
                <ul className="list list-sm list-checked">
                  <li>{t('text.notificationTemplate.notesOne')}</li>
                  <li>{t('text.notificationTemplate.notesTwo')}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </PageContainerWrapper>
    </>
  );
}

export default NotificationTemplate;
